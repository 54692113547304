<template>
  <div class="result d-flex-1 flex-column m-10">
    <div class="d-flex-1 flex-column">
      
    </div>
  </div>
</template>

<script>

export default {
  name: 'Dashboard',
  components: {  },
  data() {
    return {
      
    };
  },
  props: {
   
  },
  async created() {
    
  },
  async mounted() {
    this.$router.push("/dataContainer/MasterData/dashboard");
  },
  computed: {
    
  },
  methods: {
    
  }
}
</script>

<style scoped>

</style>