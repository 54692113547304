<template>
    <div>
        <div class="editDataTransformationStepSelectColumnsPopup">
            <CustomDxPopup :ref="editDataTransformationStepSelectColumnsPopupRefName"
                           :drag-enabled="false"
                           :show-title="true"
                           title="Adattranszformációs lépés szerkesztése - Oszlopok kiválasztása"
                           :onShowing="onEditDataTransformationStepSelectColumnsPopupShowing"
                           :onHiding="onEditDataTransformationStepSelectColumnsPopupHiding"
                           container="#app"
                           width="95vw"
                           height="95vh">

                <DxScrollView :show-scrollbar="'onScroll'"
                              :use-native="false">
                    <DxLoadIndicator v-if="!loaded" />

                    <DxForm :visible="loaded"
                            :form-data="dataTransformationStepFormData"
                            :show-validation-summary="true"
                            validation-group="editDataTransformationStepSelectColumnsValidationGroup">

                        <DxGroupItem caption="Oszlop adatok">
                            <template #default>
                                <div class="transaction-step-table">
                                    <div class="transaction-step-table-thead">
                                        <div class="transaction-step-table-thead-row dx-theme-accent-as-background-color dx-theme-background-color-as-text-color">
                                            <div class="transaction-step-table-thead-row-cell transaction-step-column-handle dx-theme-border-color"></div>
                                            <div class="transaction-step-table-thead-row-cell transaction-step-column-dbColumnName dx-theme-border-color">Forrásoszlop azonosítója</div>
                                            <div class="transaction-step-table-thead-row-cell transaction-step-column-isSelectedColumn dx-theme-border-color">Oszlop kiválasztása</div>
                                            <div class="transaction-step-table-thead-row-cell transaction-step-column-displayedName dx-theme-border-color">Céloszlop neve</div>
                                        </div>
                                    </div>
                                    <DxSortable class="transaction-step-table-tbody"
                                                handle=".transaction-step-column-handle"
                                                dragTemplate="drag"
                                                @drag-start="onColumnDragStarted"
                                                @reorder="onColumnReorder">
                                        <div v-for="column in dataTransformationStepFormData.columns" :key="column.Index" class="dx-theme-border-color transaction-step-table-tbody-row">
                                            <div class="transaction-step-table-tbody-row-cell transaction-step-column-handle"><div class="dx-icon-dragvertical"></div></div>
                                            <div class="transaction-step-table-tbody-row-cell transaction-step-column-dbColumnName">
                                                <DxTextBox v-model="column.DbColumnName" :read-only="true" />
                                            </div>
                                            <div class="transaction-step-table-tbody-row-cell transaction-step-column-isSelectedColumn">
                                                <DxCheckBox v-model="column.IsSelectedColumn" :on-value-changed="refreshColumns">
                                                    <DxValidator validation-group="editDataTransformationStepSelectColumnsValidationGroup" :on-initialized="e => column.isSelectedValidator = e.component">
                                                        <DxAsyncRule message="Legalább egy oszlopot ki kell választani."
                                                                     :validation-callback="e => isAnyColumnSelected(e, column)" />
                                                    </DxValidator>
                                                </DxCheckBox>
                                            </div>
                                            <div class="transaction-step-table-tbody-row-cell transaction-step-column-displayedName">
                                                <DxTextBox v-model="column.DisplayedName" :visible="column.IsSelectedColumn" :on-value-changed="(e) => onDisplayedNameChange(e)">
                                                    <DxValidator validation-group="editDataTransformationStepSelectColumnsValidationGroup">
                                                        <DxAsyncRule :validation-callback="e=>isColumnNameUnique(e, column)"
                                                                    message="Az oszlop neve nem egyedi a táblában" />
                                                        <DxStringLengthRule :max="sourceDataColumnNameMaxLength" :message="`Az oszlop neve legfeljebb ${sourceDataColumnNameMaxLength} karaketer hosszú lehet.`" />
                                                    </DxValidator>
                                                </DxTextBox>
                                            </div>
                                        </div>

                                        <template #drag="{ data }">
                                            <div class="transaction-step-table dx-widget">
                                                <div class="transaction-step-table-tbody">
                                                    <div class="dx-theme-border-color transaction-step-table-tbody-row dx-theme-background-color">
                                                        <div class="transaction-step-table-tbody-row-cell transaction-step-column-handle"><div class="dx-icon-dragvertical"></div></div>
                                                        <div class="transaction-step-table-tbody-row-cell transaction-step-column-dbColumnName">
                                                            <DxTextBox v-model="data.itemData.DbColumnName" :read-only="true" />
                                                        </div>
                                                        <div class="transaction-step-table-tbody-row-cell transaction-step-column-selectedColumn">
                                                            <DxCheckBox v-model="data.itemData.IsSelectedColumn" :on-click="refreshColumns" />
                                                        </div>
                                                        <div class="transaction-step-table-tbody-row-cell transaction-step-column-displayedName">
                                                            <DxTextBox v-model="data.itemData.DisplayedName" :visible="data.itemData.IsSelectedColumn" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </DxSortable>
                                </div>
                            </template>
                        </DxGroupItem>

                        <DxGroupItem caption="Törölhető oszlopok" :visible="isDeleteButtonVisible" cssClass="deletableColumnsGroupItem">
                            <DxButtonItem alignment="right" cssClass="button-item">
                                <DxButtonOptions text="Oszlopok törlése"
                                                type="default"
                                                styling-mode="contained"
                                                :on-click="removeDeletedColumns"
                                                :disabled="!isDeleteButtonVisible" />
                            </DxButtonItem>
                            <DxGroupItem>
                                <template #default>
                                    <div>
                                        <div class="deletable-columns-table">
                                            <div class="deletable-columns-table-thead">
                                                <div class="deletable-columns-table-thead-row dx-theme-accent-as-background-color dx-theme-background-color-as-text-color">
                                                    <div class="deletable-columns-table-thead-row-cell deletable-columns-column-dbColumnName dx-theme-border-color">Forrásoszlop azonosítója</div>
                                                </div>
                                            </div>
                                            <div v-for="deletableColumn in deletableColumns" :key="deletableColumn.DbColumnName" class="dx-theme-border-color deletable-columns-table-tbody-row">
                                                <div class="deletable-columns-table-tbody-row-cell deletable-columns-column-dbColumnName">
                                                    <DxTextBox v-model="deletableColumn.DbColumnName" :read-only="true">
                                                        <DxValidator validation-group="editDataTransformationStepSelectColumnsValidationGroup">
                                                            <DxAsyncRule :validation-callback="validateToDeleteColumns"
                                                                message="Törlendő oszloppal a transzformációs lépés nem menthető." />
                                                        </DxValidator>
                                                    </DxTextBox>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </DxGroupItem>
                        </DxGroupItem>

                        <DxGroupItem cssClass="functionButtons">
                            <DxButtonItem alignment="left">
                                <DxButtonOptions text="Mégse"
                                                 type="default"
                                                 styling-mode="contained"
                                                 :on-click="hidePopup"
                                                 :disabled="submitting" />
                            </DxButtonItem>
                            <DxButtonItem alignment="left">
                                <DxButtonOptions :text="saveButtonLabel"
                                                 type="default"
                                                 styling-mode="contained"
                                                 validation-group="editDataTransformationStepSelectColumnsValidationGroup"
                                                 :on-click="(e) => saveDataTransformationStep(e)"
                                                 :disabled="submitting" />
                            </DxButtonItem>
                        </DxGroupItem>

                        <DxValidationSummary id="summary" validation-group="editDataTransformationStepSelectColumnsValidationGroup" v-if="dataTransformationStepFormData.columns.length > 0" />
                    </DxForm>
                </DxScrollView>
            </CustomDxPopup>
        </div>
    </div>
</template>

<script>
    import EventBus from "@/utils/event-bus";
    import cloneDeep from "clone-deep";
    import DxPopup, {
        DxToolbarItem as DxPopupToolbarItem
    } from "devextreme-vue/popup";
    import DxButton from 'devextreme-vue/button';
    import { DxScrollView } from 'devextreme-vue/scroll-view';
    import DxDropDownButton, { DxDropDownOptions, DxPosition } from 'devextreme-vue/drop-down-button';
    import CustomDxPopup from "@appfrm/core/components/CustomDxPopup";
    import SourceDataColumnTypeParameters from "../components/SourceDataColumnTypeParameters";
    import sourceDataService, {
        DataContainerApiBasePath,
        TABLE_DISPLAYED_NAME_MAX_LENGTH,
        TABLE_COLUMN_DISPLAYED_NAME_MAX_LENGTH
    } from "../services/sourceData.service";
    import { DxLoadIndicator } from 'devextreme-vue/load-indicator';
    import DxTextBox from 'devextreme-vue/text-box';
    import { DxCheckBox } from 'devextreme-vue/check-box';
    import { DxSortable } from 'devextreme-vue/sortable';
    import DxForm, {
        DxGroupItem,
        DxSimpleItem,
        DxItem,
        DxButtonItem,
        DxButtonOptions,
        DxLabel,
        DxRequiredRule,
        DxCompareRule,
        DxRangeRule,
        DxStringLengthRule,
        DxPatternRule,
        DxEmailRule,
        DxAsyncRule,
        DxCustomRule
    } from 'devextreme-vue/form';
    import {
        DxValidator,
    } from 'devextreme-vue/validator';
    import { dataContainerPathFactory, dataContainerTypes, dataContainerTypeCapabilities, dataContainerOperation, dataTransformationStepTypes } from "../data-container"
    import Breadcrumb from "@appfrm/core/components/Breadcrumb";
    import DxSelectBox from "devextreme-vue/select-box";
    import notify from 'devextreme/ui/notify';
    import DxValidationSummary from 'devextreme-vue/validation-summary';
    import DxTagBox from 'devextreme-vue/tag-box';

    export const TRANSFORMATION_DATA_SOURCE_TYPE_DATA_CONTAINER = 1;
    export const TRANSFORMATION_DATA_SOURCE_TYPE_DATA_TRANSFORMATION = 2;

    export default {
        name: "EditDataTransformationStepSelectColumnsPopup",
        components: {
            DxPopup,
            DxPopupToolbarItem,
            DxScrollView,
            CustomDxPopup,
            DxLoadIndicator,
            DxButton,
            DxForm,
            DxGroupItem,
            DxSimpleItem,
            DxItem,
            DxButtonItem,
            DxButtonOptions,
            DxLabel,
            DxRequiredRule,
            DxCompareRule,
            DxRangeRule,
            DxStringLengthRule,
            DxPatternRule,
            DxEmailRule,
            DxAsyncRule,
            DxCustomRule,
            DxTextBox,
            DxCheckBox,
            DxValidator,
            DxSortable,
            Breadcrumb,
            DxSelectBox,
            SourceDataColumnTypeParameters,
            notify,
            DxValidationSummary,
            DxTagBox
        },
        props: [],
        data() {
            return {
                editDataTransformationStepSelectColumnsPopupRefName: 'editDataTransformationStepSelectColumnsPopup',
                sourceDataColumnNameMaxLength: TABLE_COLUMN_DISPLAYED_NAME_MAX_LENGTH,
                dataContainerApiBasePath: DataContainerApiBasePath,
                dataTransformationStepTypes: dataTransformationStepTypes,
                loaded: false,
                submitting: false,
                submitValidationResult: [],
                dataTransformationStep: {
                    stepParameters: {}
                },
                dataTransformationStepFormData: {
                    columns: [
                    ]
                },
                popupInstance: false,
                onHideCallbackFunction: null,
                initInProgress: false,
                deletableColumns: []
            }
        },
        created() {
        },
        mounted() {
            this.loaded = true;
        },
        watch: {
        },
        methods: {
            async onDisplayedNameChange(e) {
                await this.dataTransformationStepFormData.columns.forEach((v) => {
                    if(v.DisplayedName == e.value) {
                        v.IsDisplayedNameModified = true;
                    }
                });
            },
            async isAnyColumnSelected(e, column) {
                var selectedColumns = this.dataTransformationStepFormData.columns
                    .filter(column => column.IsSelectedColumn)

                if (!column.isSelectedValidationInProgress) {
                    this.dataTransformationStepFormData.columns.forEach(c => {
                        if (!c.isSelectedValidationInProgress) {
                            c.isSelectedValidationInProgress = true;
                            c.isSelectedValidator.validate()
                            c.isSelectedValidationInProgress = false;
                        }
                    })
                    return selectedColumns.length > 0;
                }
                else {
                    return true;
                }
            },

            refreshColumns(e) {
                this.dataTransformationStepFormData.columns.push(null);
                this.dataTransformationStepFormData.columns.pop();
            },
            removeDeletedColumns() {
                this.deletableColumns = [];
            },
            async isColumnNameUnique(e, column) {
                var columns = this.dataTransformationStepFormData.columns.filter(c => c.IsSelectedColumn);
                return !columns.some(c => column.IsSelectedColumn && c.IsSelectedColumn && c.DisplayedName == column.DisplayedName && c.Order != column.Order);
            },
            stepTypeEquals(stepTypeId) {
                if(this.dataTransformationStep !== undefined && this.dataTransformationStep !== null && this.dataTransformationStep.StepType !== undefined) {
                    return stepTypeId == this.dataTransformationStep.StepType;
                }
                return false;
            },
            async setDataTransformationStep(transformationStep) {
                this.dataTransformationStep = transformationStep;
            },
            show(callbackFunction) {
                this.onHideCallbackFunction = callbackFunction;
                if (this.popup) {
                    this.popup.show();
                }
            },
            dataSourceTypeChanged(e) {
                if(!this.initInProgress) {
                    this.dataTransformationStepFormData.dataContainerType = null;
                    this.dataTransformationStepFormData.dataContainerId = null;
                    this.dataTransformationStepFormData.dataTransformationId = null;
                }
                if(e.value == this.dataTransformationDataSourceType.TRANSFORMATION_DATA_SOURCE_TYPE_DATA_TRANSFORMATION) {
                    sourceDataService.getAllTransformations()
                        .then((response) => {
                            this.dataTransformationDS = response.map((value) => {
                                return {
                                    id: value.id,
                                    name: value.name
                                }
                            });
                        });
                }
                this.dataContainerTypeChooserVisibility();
                this.dataTransformationChooserVisibility()
            },
            dataContainerTypeChanged(e) {
                var dataContainer = this.dataContainerTypes.getById(e.value);
                if(dataContainer !== undefined) {
                    sourceDataService.getAllDefinitions(dataContainer)
                        .then((response) => {

                            this.dataContainerDS = response.map((value) => {
                                return {
                                    id: value.id,
                                    name: value.DisplayedName
                                }
                            });
                            this.dataContainerChooserVisibility();
                        });
                }
            },
            async onEditDataTransformationStepSelectColumnsPopupShowing(e) {
                var formDataColumns;
                if(this.dataTransformationStep.StepId == null) {
                    // Új transzformációs lépés
                    var result = await sourceDataService.getTransformationResult(this.dataTransformationStep.TransformationId, this.dataTransformationStep.PreviousStep?(this.dataTransformationStep.PreviousStep.StepId?this.dataTransformationStep.PreviousStep.StepId:null):null);
                    if(result && result.errortype === undefined) {
                        formDataColumns = result.DataTable.Definition.Columns;
                        formDataColumns.forEach((v) => {
                            v.IsSelectedColumn = true;
                            v.IsDisplayedNameModified = false;
                        });
                    }
                    this.dataTransformationStepFormData.columns = formDataColumns;
                } 
                else 
                {
                    // Létező transzformációs lépés
                    var result = await sourceDataService.getTransformationResultBeforeStep(this.dataTransformationStep.TransformationId, this.dataTransformationStep.StepId);
                    if(result && result.Succeeded === true) {
                        formDataColumns = result.DataTable.Definition.Columns;
                    }

                    formDataColumns.map(c => c.Order = formDataColumns.length + 1);

                    var result = await sourceDataService.getTransformationStep(this.dataTransformationStep.TransformationId, this.dataTransformationStep.StepId);
                    if(result) {
                        result.StepParameters.SelectedColumns.forEach((v) => {
                            var filterResult = formDataColumns.filter(c => c.DbColumnName == v.SourceColumnDbColumnName);
                            if(filterResult.length == 0) {
                                // Nem található oszlop, törlendőként felvesszük.
                                var newColumn = {
                                    DbColumnName: v.SourceColumnDbColumnName,
                                    DisplayedName: v.DisplayedName,
                                    Order: v.Order,
                                    IsSelectedColumn: true
                                };
                                this.deletableColumns.push(newColumn);
                            } else {
                                // Megvan az oszlop, betöltjük az adatait.
                                filterResult[0].IsSelectedColumn = true;
                                filterResult[0].Order = v.Order;
                                if(v.DisplayedNameChanged === true) {
                                    filterResult[0].IsDisplayedNameModified = true;
                                    filterResult[0].DisplayedName = v.DisplayedName;
                                } else {
                                    filterResult[0].IsDisplayedNameModified = false;
                                }
                            }
                        });
                        formDataColumns.filter(c => !c.IsSelectedColumn).map(c => {
                            c.IsSelectedColumn = false;
                        });
                        formDataColumns.filter(c => !c.IsDisplayedNameModified).map(c => {
                            c.IsDisplayedNameModified = false;
                        });
                        formDataColumns.sort((a, b) => {
                            return a.Order - b.Order;
                        });
                        formDataColumns.forEach(function (v, i) {
                            v.Order = i + 1;
                        });
                        this.dataTransformationStepFormData.columns = formDataColumns;
                    }
                }
            },
            onEditDataTransformationStepSelectColumnsPopupHiding(e) {
                this.dataTransformationStepFormData.columns = [];
                if (typeof this.onHideCallbackFunction !== null) {
                    this.onHideCallbackFunction();
                }
            },
            onColumnReorder(e) {
                const newColumns = [...this.dataTransformationStepFormData.columns];

                newColumns.splice(e.fromIndex, 1);
                newColumns.splice(e.toIndex, 0, e.itemData);

                newColumns.forEach(function (element, index, array) {
                    element.Order = index + 1;
                });

                this.dataTransformationStepFormData.columns = newColumns;
            },
            onColumnDragStarted(e) {
                e.itemData = this.dataTransformationStepFormData.columns[e.fromIndex];
            },
            async saveDataTransformationStep(e) {
                if (!this.submitting) {
                    var validationResult = e.validationGroup.validate();

                    if (validationResult.status === 'pending') {
                        validationResult = await validationResult.complete;
                    }
                    if (validationResult.isValid) {
                        this.submitting = true;
                        
                        var selectedColumns = this.dataTransformationStepFormData.columns.filter((value) => value.IsSelectedColumn == true).map((value) => {
                            return {
                                sourceColumnDbColumnName: value.DbColumnName,
                                order: value.Order,
                                displayedNameChanged: value.IsDisplayedNameModified === true,
                                displayedName: value.DisplayedName
                            }
                        });

                        var postData = {
                            StepId: this.dataTransformationStep.StepId===null?-1:this.dataTransformationStep.StepId,
                            TransformationId: this.dataTransformationStep.TransformationId,
                            StepType: this.dataTransformationStep.StepType,
                            Order: this.dataTransformationStep.Order,
                            StepParameters: {
                                SelectedColumns: selectedColumns,
                            }
                        };

                        if(this.isNewStep) {
                            // Új lépés beszúrása
                            sourceDataService.addTransformationStepSelectColumns(this.dataTransformationStep.TransformationId, postData).then(
                                (result) => {
                                    if (result.errortype === undefined) {
                                        this.submitting = false;
                                        this.hidePopup();
                                    } else {
                                        this.submitting = false;
                                        if (result.errortype != undefined) {

                                            if (result.errortype == "application") {
                                                notify({
                                                    message: "Nem sikerült a transzformációs lépés módosítása: " + result.data,
                                                    position: {
                                                        at: "top",
                                                        offset: "0 40"
                                                    }
                                                }, "error", 5000);
                                            }
                                        }
                                    }
                                },
                                (error) => {
                                    submitting = false;
                                    notify({
                                        message: "Nem sikerült a transzformációs lépés módosítása: " + result.data,
                                        position: {
                                            at: "top",
                                            offset: "0 40"
                                        }
                                    }, "error", 5000);
                                }
                            );
                        } else {
                            // Létező lépés update-je
                            sourceDataService.updateTransformationStepSelectColumns(this.dataTransformationStep.TransformationId, postData).then(
                                (result) => {
                                    if (result.errortype === undefined) {
                                        this.submitting = false;
                                        this.hidePopup();
                                    } else {
                                        this.submitting = false;
                                        if (result.errortype != undefined) {

                                            if (result.errortype == "application") {
                                                notify({
                                                    message: "Nem sikerült a transzformációs lépés módosítása: " + result.data,
                                                    position: {
                                                        at: "top",
                                                        offset: "0 40"
                                                    }
                                                }, "error", 5000);
                                            }
                                        }
                                    }
                                },
                                (error) => {
                                    submitting = false;
                                    notify({
                                        message: "Nem sikerült a transzformációs lépés módosítása: " + result.data,
                                        position: {
                                            at: "top",
                                            offset: "0 40"
                                        }
                                    }, "error", 5000);
                                }
                            );
                        }

                    }
                }
            },
            // submit error methods
            async validateSubmitErrors(e) {
                if (e.formItem) {
                    return !this.submitValidationResult.some(v => v.fieldName == e.formItem.dataField)
                }
                return true;
            },
            async validateToDeleteColumns(e) {
                return !this.isDeleteButtonVisible;
            },
            getSubmitErrorMessage(fieldName) {
                var serverValidation = this.submitValidationResult.find(v => v.fieldName === fieldName);
                return serverValidation ? serverValidation.message : "";
            },
            hidePopup() {
                this.instance.hide();
            },
        },
        computed: {
            isDeleteButtonVisible() {
                return this.deletableColumns.length > 0;
            },
            saveButtonLabel() {
                return this.dataTransformationStep.StepId == null ? "Hozzáadás" : "Módosítás";
            },
            instance() {
                return this.$refs[this.editDataTransformationStepSelectColumnsPopupRefName].instance;
            },
            popup() {
                if (!this.popupInstance) {
                    this.popupInstance = this.$refs[this.editDataTransformationStepSelectColumnsPopupRefName].instance;
                }
                return this.popupInstance;
            },
            isNewStep() {
                return (this.dataTransformationStep.StepId === null && this.dataTransformationStep.StepType != this.dataTransformationStepTypes.find((item) => item.const === 'dataSource').id);
            }
        }
    };
</script>

<style scoped lang="scss">
    .nav > li > a {
        text-decoration: none;
    }
    .nav > li > a > .img-circle {
        width: 100%;
        max-width: 20px;
        height: auto;
        border-radius: 50%;
        border: 0;
        position: relative;
        bottom: 1px;
        vertical-align:middle
    }
    .nav > li > a > span {
        margin-left: 5px;
        line-height: 24px;
        font-size: 14px;
    }
    ::v-deep .localDropdown {
        .dx-button-content {
            line-height: 21px;
            padding: 12px 18px 7px;
        }
        .dx-button-text {
            color: #ffffff;
        }
        .dx-button-mode-outlined {
            border: 0;
        }
        .dx-icon {
            vertical-align: bottom;
            height: 20px !important;
            width: 28px;
            margin-right: 0 !important;
        }
        .dx-icon.dx-icon-right{
            width: 0;
        }
        .dx-overlay-content {
            left: 10px;
        }
        .dx-button-text {
            display: none;
        }
    }
    ul.navbar-nav li {
        padding-left: 31px !important;
        line-height: 40px;
    }
    .bug-report a {
        color: #fff;
    }
    .bug-report-chooser {
        position: absolute;
        left: -120px;
        top: 40px;
        background-color: #012A4A;
        color: #fff;
    }
    ul.bug-report-chooser {
        list-style: none;
        padding: 0px;
    }
    .bug-report-chooser li {
        padding: 0px 20px;
        list-style: none;
        white-space: nowrap;
    }
    .notificationCount {
        position: relative;
        top: -2px;
        background-color: #ff0000;
        color: #fff;
        font-size: 11px;
        font-weight: bold;
        line-height: 22px;
        padding: 0px 0px;
        -webkit-border-radius: 22px;
        -moz-border-radius: 22px;
        border-radius: 22px;
        display: inline-block;
        width: 22px;
        height: 22px;
        text-align: center;
    }


    .import_errors {
        color: #ff0000;
        font-size: 12px;

        .import_errors_header {
            font-weight: bold;
        }
    }

    .transaction-step-table {
        border-collapse: collapse;
        display: table;

        .transaction-step-column-handle {
            width: 37px;
        }

        .transaction-step-column-index {
            text-align: center;
            width: 37px;
        }

        .transaction-step-column-dbColumnName,
        .transaction-step-column-claims-data {
            width: 200px;
        }

        .transaction-step-column-toBeImported {
            width: 100px;
        }

        .transaction-step-column-isDisplayedNameModified {
            width: 100px;
        }

        .transaction-step-column-displayedName.colspan-1 {
            width: 210px;
        }

        .transaction-step-column-displayedName.colspan-2 {
        }

        .transaction-step-column-parameters {
            width: 210px;
        }

        .transaction-step-column-description {
            width: 210px;
        }

        .column-claim-select-wrapper {
            margin-top: 2px;
        }
    }

    .deletable-columns-table {

        margin: 0px;
        padding: 0px;

        .deletable-columns-column-dbColumnName {
            width: 200px;
        }
    }

    .deletable-columns-table-thead {
        display: table-header-group;

        .deletable-columns-table-thead-row {
            display: table-row;

            .deletable-columns-table-thead-row-cell {
                display: table-cell;
                border-style: solid;
                border-width: 1px;
                padding: 7px;
                font-weight: 400;
            }
        }
    }

    .deletable-columns-table-tbody {
        display: table-row-group;

        .deletable-columns-table-tbody-row {
            display: table-row;
            border-style: solid;
            border-width: 1px;

            .deletable-columns-table-tbody-row-cell {
                display: table-cell;
                vertical-align: middle;
            }

            .deletable-columns-column-dbColumnName {
                width: 200px;
            }
        }
    }


    .transaction-step-table-thead {
        display: table-header-group;

        .transaction-step-table-thead-row {
            display: table-row;

            .transaction-step-table-thead-row-cell {
                display: table-cell;
                border-style: solid;
                border-width: 1px;
                padding: 7px;
                font-weight: 400;
            }
        }
    }

    .transaction-step-table-tbody {
        display: table-row-group;

        .transaction-step-table-tbody-row {
            display: table-row;
            border-style: solid;
            border-width: 1px;

            .transaction-step-table-tbody-row-cell {
                display: table-cell;
                vertical-align: middle;
            }

            .transaction-step-table-tbody-row-cell.colspan-2 {
                width: max-content;
                vertical-align: middle;
            }

            .transaction-step-column-handle {
                width: 37px;
                text-align: center;
                cursor: move;
            }

            .transaction-step-column-index {
                text-align: center;
            }

            .transaction-step-column-toBeImported,
            .transaction-step-column-isDisplayedNameModified,
            .transaction-step-column-isSelectedColumn,
            .transaction-step-column-toDelete,
            .transaction-step-column-is-company-id {
                text-align: center;
            }

            .transaction-step-column-actions {
                font-size: 18px;
                color: #0c3c60;
                text-align: center;
                text-decoration: none;
                width: 60px;
            }

            .transaction-step-column-actions a {
                font-size: 18px;
                color: #0c3c60;
                text-decoration: none;
            }
        }
    }

    .container-claims .dx-field {
        max-width: 600px;
    }

</style>
<style>
    .dx-scrollable.dx-scrollview.dx-widget .dx-scrollable-wrapper {
        border: none;
    }
    .dx-list-item-icon {
        vertical-align: bottom;
        height: 13px !important;
    }
    .dx-popup-content {
        text-align: left !important;
    }

    .button-item {
        margin-top: -66px;
    }

    .functionButtons .dx-layout-manager {
        text-align: right;
    }

    .functionButtons .dx-collection {
        display: inline-block !important;
    }

    .functionButtons .dx-item {
        display: inline-block !important;
        margin-left: 16px;
    }

    .functionButtonsLeft .dx-layout-manager {
        text-align: left;
    }

    .functionButtonsLeft .dx-collection {
        display: inline-block !important;
    }

    .functionButtonsLeft .dx-item {
        display: inline-block !important;
        margin-right: 16px;
    }
</style>
