import RequestHandler from "@appfrm/core/services/request-handler";
class PositionService {
    constructor() {
        this.path = "positions";
        if (PositionService.instance) {
            return PositionService.instance;
        }
        PositionService.instance = this;
        return this;
    }
    async get(loadOptions) {
        return await RequestHandler.getWithParams(this.path, loadOptions);
    }
    async insert(data) {
        return await RequestHandler.post(this.path, data);
    }
    async update(data, reorder) {
        return RequestHandler.putAsForm(this.path, data);
    }
    async delete(id) {
        return await RequestHandler.delete(this.path, id);
    }
}
const instance = new PositionService();
export default instance;