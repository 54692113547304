import { HubConnectionBuilder, HttpTransportType, LogLevel } from "@microsoft/signalr";
import {WebsocketMethodType} from "@/enums/WebsocketMethodType";
import {Logout} from "@/enums/auth-actions";

let _callbackOptions;
let store = null;

export class MainWebsocketHub {
    constructor(url, callbackOptions) {
        this.hubConnection = null;
        this.url = url;
        _callbackOptions = callbackOptions;
    }
    
    startConnection(storeInput) {
        return new Promise((resolve, reject) => {
            store = storeInput;
            
            this.hubConnection = new HubConnectionBuilder()
                .withUrl(this.url, {
                    skipNegotiation: false,
                    transport: HttpTransportType.WebSockets,
                    accessTokenFactory: () => {
                        return store.state.accessToken;
                    }
                })
                .withAutomaticReconnect(new RetryPolicy(this.retry))
                .configureLogging(LogLevel.Information)
                .build();
                        
            this.hubConnection.on(WebsocketMethodType.ForceRefreshToken, () => {
                _callbackOptions.context.dispatch('forceRefreshToken');
            });
            
            this.hubConnection.on(WebsocketMethodType.ForceLogout, () => {
                _callbackOptions.context.dispatch(Logout);
            });
            
            this.hubConnection.on(WebsocketMethodType.Message, (user, messageType, message) => {
                const msg = message
                    .replace(/&/g, "&amp;")
                    .replace(/</g, "&lt;")
                    .replace(/>/g, "&gt;");
                _callbackOptions.context.dispatch('receiveMessage', { user, msg });
            });
            
            this.hubConnection.onclose(() => {
                _callbackOptions.context.dispatch('reconnecting');
            });
            
            this.hubConnection.onreconnected(() => {
                _callbackOptions.context.dispatch('startRefreshTokenTimer');
            });
            
            this.hubConnection.start().then((r) => {
                resolve();
            }).catch((err) => {
                return console.error(err.toString());
            });
        });
    }
    
    retry() {
        _callbackOptions.context.dispatch('forceRefreshToken');
    }
    
    sendMessage(user, message) {
        this.hubConnection
            .invoke("SendMessage", user, message)
            .catch((err) => {
                return console.error(err.toString());
            });
        // event.preventDefault(); // TODO WTF is this
    }
}
export class MainWebsocketCallbackOptions {
    constructor(context) {
        this.context = context;
    }
}

class RetryPolicy {
    
    constructor(retry) {
        this.retry = retry;
    }
    
    nextRetryDelayInMilliseconds() {
        this.retry();
        return 5000;
    }
}