<template>
    <nav class="navbar">
        <div class="collapse navbar-collapse" id="navbar-collapse">
            <ul class="nav navbar-nav">
                <li>
                    <router-link v-tt="'header-profile'" to="/account">
                        <img src="https://www.gravatar.com/avatar/e7c88187f32c08e7ce5296a7a297515c.jpg?s=80&amp;d=mm&amp;r=g"
                             class="img-circle"
                             alt="User Image" />
                        <span>{{ username }}</span>
                    </router-link>
                </li>
                <li>
                    <DxDropDownButton id="langSet"
                                      v-tt="'header-langchooser'"
                                      :items="languages"
                                      key-expr="id"
                                      class="localDropdown"
                                      title-expr="text"
                                      display-expr="text"
                                      :use-select-mode="true"
                                      :selected-item-key="1"
                                      @selection-changed="changeLocal">
                        <DxDropDownOptions :width="110" />
                    </DxDropDownButton>
                </li>
                <li>
                    <div v-tt="'header-colorchooser'">
                        <img src="/img/icons/theme_icon.svg" style="height: 23px;vertical-align:middle;padding-bottom:1px;" />
                    </div>
                </li>
                <li class="bug-report">
                    <div v-tt="'header-errorreport'" :class="['bug-report', bugReportSpinnerActive ? 'spinner' : '']">
                        <a @click="onClickBugReport($event)">
                            <i class="iconset bug_report"></i>
                        </a>
                    </div>
                </li>
                <li>
                    <a v-tt="'header-logout'" @click="logoutRequest">
                        <i class="iconset logout_log-out"
                           data-toggle="tooltip"
                           data-placement="bottom"
                           data-original-title="Logout"></i>
                    </a>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>

    import DxDropDownButton, { DxDropDownOptions, DxPosition } from 'devextreme-vue/drop-down-button';

    export default {
        name: "Header",
        components: {
            DxDropDownButton,
            DxDropDownOptions,
            DxPosition
        },
        props: ["username", "user-roles"],
        data() {
            return {
                languages: [
                    { id: 1, value: "hu", text: 'Magyar', icon: "/img/flags/hu.svg" },
                    { id: 2, value: "en", text: 'English', icon: "/img/flags/gb.svg" },
                    { id: 3, value: "de", text: 'Deutsch', icon: "/img/flags/de.svg" },
                    { id: 4, value: "ru", text: 'Russian', icon: "/img/flags/ru.svg" },
                    { id: 5, value: "ua", text: 'Ukrainian', icon: "/img/flags/ua.svg" },
                    { id: 6, value: "sr", text: 'Serbian', icon: "/img/flags/sr.svg" }
                ],
                bugReportSpinnerActive: false,
            }
        },
        mounted() {
            this.$root.$on('get_diagnostics_data', (callbackFunction) => {
                var jsonData = this._data;
                callbackFunction(this.$options._componentTag, JSON.stringify(jsonData));
            });
            this.$root.$on('activate_bug_spinner', () => {
                this.bugReportSpinnerActive = true;
            });
            this.$root.$on('deactivate_bug_spinner', () => {
                this.bugReportSpinnerActive = false;
            });
        },
        methods: {
            logoutRequest: function () {
                this.$emit('logout');
            },
            changeLocal(e) {
                locale(e.itemData.value);
            },
            onClickBugReport(e) {
                if (!this.bugReportSpinnerActive) {
                    this.$root.$emit("show_bugreport_popup");
                }
            }
        }
    };
</script>

<style scoped lang="scss">
    .nav > li > a {
        text-decoration: none;
    }
    .nav > li > a > .img-circle {
        width: 100%;
        max-width: 20px;
        height: auto;
        border-radius: 50%;
        border: 0;
        position: relative;
        bottom: 1px;
        vertical-align:middle
    }
    .nav > li > a > span {
        margin-left: 5px;
        line-height: 24px;
        font-size: 14px;
    }
    ::v-deep .localDropdown {
        .dx-button-content {
            line-height: 21px;
            padding: 12px 18px 7px;
        }
        .dx-button-text {
            color: #ffffff;
        }
        .dx-button-mode-outlined {
            border: 0;
        }
        .dx-icon {
            vertical-align: bottom;
            height: 20px !important;
            width: 28px;
            margin-right: 0 !important;
        }
        .dx-icon.dx-icon-right{
            width: 0;
        }
        .dx-overlay-content {
            left: 10px;
        }
        .dx-button-text {
            display: none;
        }
    }
    ul.navbar-nav li {
        padding-left: 31px !important;
        line-height: 40px;
    }
    .bug-report a {
        color: #fff;
    }
    .bug-report-chooser {
        position: absolute;
        left: -120px;
        top: 40px;
        background-color: #012A4A;
        color: #fff;
    }
    ul.bug-report-chooser {
        list-style: none;
        padding: 0px;
    }
    .bug-report-chooser li {
        padding: 0px 20px;
        list-style: none;
        white-space: nowrap;
    }
    .notificationCount {
        position: relative;
        top: -2px;
        background-color: #ff0000;
        color: #fff;
        font-size: 11px;
        font-weight: bold;
        line-height: 22px;
        padding: 0px 0px;
        -webkit-border-radius: 22px;
        -moz-border-radius: 22px;
        border-radius: 22px;
        display: inline-block;
        width: 22px;
        height: 22px;
        text-align: center;
    }
    .spinner {
        background-position: 4px 11px;
        background-size: 20px 20px;
        background-image: url("/img/icons/spinner.gif") !important;
        background-color: rgba(255, 255, 255, 0) !important;
        transition: none !important;
    }
    .spinner a i {
        color: rgba(255, 255, 255, 0.5);
    }
</style>
<style>
    .dx-scrollable.dx-scrollview.dx-widget .dx-scrollable-wrapper {
        border: none;
    }
    .dx-list-item-icon {
        vertical-align: bottom;
        height: 13px !important;
    }
    .dx-popup-content {
        text-align: left !important;
    }
</style>
