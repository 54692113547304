<template>
    <div class="column-parameters">
        <input @input="handleInput" class="d-none" />
        <div class="not-chosen">
            <div v-if="!chosenType">
                Kérjük, válasszon oszlop típust!
            </div>
        </div>
        <!-- Szöveg típusú oszlop paraméterei -->
        <div v-show="chosenType == dataContainerDataType.text.id">
            <div class="fieldGroupTitle">Paraméterek</div>
            <div class="dx-field">
                <div class="dx-field-label">Maximális hossz</div>
                <div class="dx-field-value">
                    <DxTextBox data-field="parameters.TextParameters.MaxLength"
                               width="55"
                               placeholder=""
                               v-model="parameters.TextParameters.MaxLength">
                        <DxValidator validation-group="firstImportValidationGroup">
                            <DxNumericRule :message="`Csak szám lehet.`" />
                        </DxValidator>
                    </DxTextBox>
                </div>
            </div>
        </div>

        <!-- Szám típusú oszlop paraméterei -->
        <div v-show="chosenType == dataContainerDataType.number.id">
            <div v-show="chosenDataSourceType == dataSourceTypes.DATA_SOURCE_TYPE_CSV">
                <div class="fieldGroupTitle">Feldolgozási paraméterek</div>
                <div class="dx-field">
                    <div class="dx-field-label">Tizedes elválasztó</div>
                    <div class="dx-field-value">
                        <DxTextBox data-field="parameters.NumberParameters.NumberParsingParameters.CsvParameters.DecimalSeparator"
                                   width="55"
                                   placeholder=""
                                   v-model="parameters.NumberParameters.NumberParsingParameters.CsvParameters.DecimalSeparator" />
                    </div>
                </div>
                <div class="dx-field">
                    <div class="dx-field-label">Ezres elválasztó</div>
                    <div class="dx-field-value">
                        <DxTextBox data-field="parameters.NumberParameters.NumberParsingParameters.CsvParameters.ThousandSeparator"
                                   width="55"
                                   placeholder=""
                                   v-model="parameters.NumberParameters.NumberParsingParameters.CsvParameters.ThousandSeparator" />
                    </div>
                </div>
            </div>

            <div v-show="chosenDataSourceType == dataSourceTypes.DATA_SOURCE_TYPE_EXCEL && excelParsingEnabled">
                <div class="fieldGroupTitle">Feldolgozási paraméterek</div>
                <div class="dx-field">
                    <div class="dx-field-label">Tizedes elválasztó</div>
                    <div class="dx-field-value">
                        <DxTextBox data-field="parameters.NumberParameters.NumberParsingParameters.ExcelParameters.DecimalSeparator"
                                   width="55"
                                   placeholder=""
                                   v-model="parameters.NumberParameters.NumberParsingParameters.ExcelParameters.DecimalSeparator" />
                    </div>
                </div>
                <div class="dx-field">
                    <div class="dx-field-label">Ezres elválasztó</div>
                    <div class="dx-field-value">
                        <DxTextBox data-field="parameters.NumberParameters.NumberParsingParameters.ExcelParameters.ThousandSeparator"
                                   width="55"
                                   placeholder=""
                                   v-model="parameters.NumberParameters.NumberParsingParameters.ExcelParameters.ThousandSeparator" />
                    </div>
                </div>
            </div>

            <div class="fieldGroupTitle">Formázási paraméterek</div>
            <div class="dx-field">
                <div class="dx-field-label">Tizedes elválasztó</div>
                <div class="dx-field-value">
                    <DxTextBox data-field="parameters.NumberParameters.DecimalSeparator"
                               width="55"
                               placeholder=""
                               v-model="parameters.NumberParameters.DecimalSeparator" />
                </div>
            </div>
            <div class="dx-field">
                <div class="dx-field-label">Ezres elválasztó</div>
                <div class="dx-field-value">
                    <DxTextBox data-field="parameters.NumberParameters.ThousandSeparator"
                               width="55"
                               placeholder=""
                               v-model="parameters.NumberParameters.ThousandSeparator" />
                </div>
            </div>
            <div class="dx-field">
                <div class="dx-field-label">Tizedesjegyek</div>
                <div class="dx-field-value">
                    <DxTextBox data-field="parameters.NumberParameters.DecimalPlaces"
                               width="55"
                               placeholder=""
                               v-model="parameters.NumberParameters.DecimalPlaces">
                        <DxValidator validation-group="firstImportValidationGroup">
                            <DxNumericRule :message="`Csak szám lehet.`" />
                        </DxValidator>
                    </DxTextBox>

                </div>
            </div>
        </div>

        <!-- Decimal típusú oszlop paraméterei -->
        <div v-show="chosenType == dataContainerDataType.decimal.id">
            <div v-show="chosenDataSourceType == dataSourceTypes.DATA_SOURCE_TYPE_CSV">
                <div class="fieldGroupTitle">Feldolgozási paraméterek</div>
                <div class="dx-field">
                    <div class="dx-field-label">Tizedes elválasztó</div>
                    <div class="dx-field-value">
                        <DxTextBox data-field="parameters.DecimalParameters.DecimalParsingParameters.CsvParameters.DecimalSeparator"
                                   width="55"
                                   placeholder=""
                                   v-model="parameters.DecimalParameters.DecimalParsingParameters.CsvParameters.DecimalSeparator" />
                    </div>
                </div>
                <div class="dx-field">
                    <div class="dx-field-label">Ezres elválasztó</div>
                    <div class="dx-field-value">
                        <DxTextBox data-field="parameters.DecimalParameters.DecimalParsingParameters.CsvParameters.ThousandSeparator"
                                   width="55"
                                   placeholder=""
                                   v-model="parameters.DecimalParameters.DecimalParsingParameters.CsvParameters.ThousandSeparator" />
                    </div>
                </div>
            </div>

            <div v-show="chosenDataSourceType == dataSourceTypes.DATA_SOURCE_TYPE_EXCEL && excelParsingEnabled">
                <div class="fieldGroupTitle">Feldolgozási paraméterek</div>
                <div class="dx-field">
                    <div class="dx-field-label">Tizedes elválasztó</div>
                    <div class="dx-field-value">
                        <DxTextBox data-field="parameters.DecimalParameters.DecimalParsingParameters.ExcelParameters.DecimalSeparator"
                                   width="55"
                                   placeholder=""
                                   v-model="parameters.DecimalParameters.DecimalParsingParameters.ExcelParameters.DecimalSeparator" />
                    </div>
                </div>
                <div class="dx-field">
                    <div class="dx-field-label">Ezres elválasztó</div>
                    <div class="dx-field-value">
                        <DxTextBox data-field="parameters.DecimalParameters.DecimalParsingParameters.ExcelParameters.ThousandSeparator"
                                   width="55"
                                   placeholder=""
                                   v-model="parameters.DecimalParameters.DecimalParsingParameters.ExcelParameters.ThousandSeparator" />
                    </div>
                </div>
            </div>

            <div class="fieldGroupTitle">Formázási paraméterek</div>
            <div class="dx-field">
                <div class="dx-field-label">Tizedes elválasztó</div>
                <div class="dx-field-value">
                    <DxTextBox data-field="parameters.DecimalParameters.DecimalSeparator"
                               width="55"
                               placeholder=""
                               v-model="parameters.DecimalParameters.DecimalSeparator" />
                </div>
            </div>
            <div class="dx-field">
                <div class="dx-field-label">Ezres elválasztó</div>
                <div class="dx-field-value">
                    <DxTextBox data-field="parameters.DecimalParameters.ThousandSeparator"
                               width="55"
                               placeholder=""
                               v-model="parameters.DecimalParameters.ThousandSeparator" />
                </div>
            </div>
            <div class="dx-field">
                <div class="dx-field-label">Tizedesjegyek</div>
                <div class="dx-field-value">
                    <DxTextBox data-field="parameters.DecimalParameters.DecimalPlaces"
                               width="55"
                               placeholder=""
                               v-model="parameters.DecimalParameters.DecimalPlaces">
                        <DxValidator validation-group="firstImportValidationGroup">
                            <DxNumericRule :message="`Csak szám lehet.`" />
                        </DxValidator>
                    </DxTextBox>

                </div>
            </div>
        </div>

        <!-- Boolean típusú oszlop paraméterei -->
        <div v-show="chosenType == dataContainerDataType.boolean.id">
            <div v-show="chosenDataSourceType == dataSourceTypes.DATA_SOURCE_TYPE_CSV">
                <div class="fieldGroupTitle">Feldolgozási paraméterek</div>
                <div class="dx-field">
                    <div class="dx-field-label">Igaz szöveg</div>
                    <div class="dx-field-value">
                        <DxTextBox data-field="parameters.BooleanParameters.BooleanParsingParameters.CsvParameters.TrueValue"
                                   width="55"
                                   placeholder=""
                                   v-model="parameters.BooleanParameters.BooleanParsingParameters.CsvParameters.TrueValue">
                            <DxValidator validation-group="firstImportValidationGroup">
                            </DxValidator>
                        </DxTextBox>
                    </div>
                </div>
            </div>
            <div v-show="chosenDataSourceType == dataSourceTypes.DATA_SOURCE_TYPE_EXCEL && excelParsingEnabled">
                <div class="fieldGroupTitle">Feldolgozási paraméterek</div>
                <div class="dx-field">
                    <div class="dx-field-label">Igaz szöveg</div>
                    <div class="dx-field-value">
                        <DxTextBox data-field="parameters.BooleanParameters.BooleanParsingParameters.ExcelParameters.TrueValue"
                                   width="55"
                                   placeholder=""
                                   v-model="parameters.BooleanParameters.BooleanParsingParameters.ExcelParameters.TrueValue">
                            <DxValidator validation-group="firstImportValidationGroup">
                            </DxValidator>
                        </DxTextBox>
                    </div>
                </div>
            </div>
            <div v-show="chosenDataSourceType == dataSourceTypes.DATA_SOURCE_TYPE_EXCEL && !excelParsingEnabled">
                Nincs
            </div>
        </div>

        <!-- Integer típusú oszlop paraméterei -->
        <div v-show="chosenType == dataContainerDataType.integer.id">
            Nincs
        </div>

        <!-- Dátum típusú oszlop paraméterei -->
        <div v-show="chosenType == dataContainerDataType.dateTime.id" class="datetime">
            <div class="fieldGroupTitle">Paraméterek</div>
            <div class="dx-field">
                <div class="dx-field-label">Megjelenés<br /> formátuma</div>
                <div class="dx-field-value">
                    <DxTextBox data-field="parameters.DateTimeParameters.Format"
                               width="100"
                               placeholder=""
                               v-model="parameters.DateTimeParameters.Format" />
                </div>
            </div>
            <div v-show="chosenDataSourceType == dataSourceTypes.DATA_SOURCE_TYPE_CSV">
                <div class="fieldGroupTitle">Feldolgozási paraméterek</div>
                <div class="dx-field">
                    <div class="dx-field-label">Beolvasás<br /> formátuma</div>
                    <div class="dx-field-value">
                        <DxTextBox data-field="parameters.DateTimeParameters.DateTimeParsingParameters.CsvParameters.Format"
                                   width="100"
                                   placeholder=""
                                   v-model="parameters.DateTimeParameters.DateTimeParsingParameters.CsvParameters.Format">
                            <DxValidator validation-group="firstImportValidationGroup">
                            </DxValidator>
                        </DxTextBox>
                    </div>
                </div>
            </div>
        </div>

        <!-- Tulajdonság típusú oszlop paraméterei -->
        <div v-if="chosenType == dataContainerDataType.property.id">
            <EditDataTypePropertyParametersPopup :ref="'editDataTypePropertyParametersPopup_' + columnId" :columnId="columnId"></EditDataTypePropertyParametersPopup>
            <div class="fieldGroupTitle">Paraméterek</div>
            <div class="dx-field">

                <div class="dx-field-full">
                    {{getPropertySummary(columnId)}}
                </div>
                <div class="dx-field-full">
                    <DxButton :width="140"
                              style="margin-top: 11px"
                              icon="edit"
                              text="Módosítás"
                              type="default"
                              styling-mode="outlined"
                              @click="e => showPropertyParameters(e, columnId)">
                    </DxButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // Event Bus import
    import EventBus from "@/utils/event-bus";

    // DevExtreme imports
    import { DxTextBox } from 'devextreme-vue/text-box';
    import { DxValidator, DxNumericRule, DxRequiredRule } from 'devextreme-vue/validator';
    import { DxButton } from 'devextreme-vue/button';

    // Custom imports
    import { dataContainerDataType } from "../data-container"
    import EditDataTypePropertyParametersPopup from "./EditDataTypePropertyParametersPopup";
    import {
        DATA_SOURCE_TYPE_NINCS,
        DATA_SOURCE_TYPE_CSV,
        DATA_SOURCE_TYPE_EXCEL,
        EXCEL_PARSING_ENABLED
    } from "../services/sourceData.service";
    
    export default {
        name: 'SourceDataColumnTypeParameters',
        components: {
            DxTextBox,
            DxButton,
            DxValidator,
            DxNumericRule,
            DxRequiredRule,
            EditDataTypePropertyParametersPopup,
        },
        props: {
            columnId: Number,
            dataSourceType: Number,
            value: Object,
            capability: Object
        },
        data() {
            return {
                chosenType: null,
                columnTypeParameters: true,
                dataContainerDataType: dataContainerDataType,
                parametersDefaults: {
                    'TextParameters': {
                        'MaxLength': '255',
                        'TextParsingParameters': {
                            'CsvParameters': {

                            },
                            'ExcelParameters': {

                            }
                        }
                    },
                    'NumberParameters': {
                        'DecimalSeparator': '',
                        'ThousandSeparator': '',
                        'DecimalPlaces': '0',
                        'NumberParsingParameters': {
                            'CsvParameters': {
                                'DecimalSeparator': '',
                                'ThousandSeparator': ''
                            },
                            'ExcelParameters': {
                                'DecimalSeparator': '',
                                'ThousandSeparator': ''
                            }
                        }
                    },
                    'DecimalParameters': {
                        'DecimalSeparator': '',
                        'ThousandSeparator': '',
                        'DecimalPlaces': '0',
                        'DecimalParsingParameters': {
                            'CsvParameters': {
                                'DecimalSeparator': '',
                                'ThousandSeparator': ''
                            },
                            'ExcelParameters': {
                                'DecimalSeparator': '',
                                'ThousandSeparator': ''
                            }
                        }
                    },
                    'BooleanParameters': {
                        'BooleanParsingParameters': {
                            'CsvParameters': {
                                'TrueValue': ''
                            },
                            'ExcelParameters': {
                                'TrueValue': ''
                            }
                        }
                    },
                    'DateTimeParameters': {
                        'Format': '',
                        'DateTimeParsingParameters': {
                            'CsvParameters': {
                                'Format': ''
                            }
                        }
                    },
                    'PropertyParameters': {
                        'Columns': []
                    }
                },
                dataSourceTypes: {
                    DATA_SOURCE_TYPE_NINCS: DATA_SOURCE_TYPE_NINCS,
                    DATA_SOURCE_TYPE_CSV: DATA_SOURCE_TYPE_CSV,
                    DATA_SOURCE_TYPE_EXCEL: DATA_SOURCE_TYPE_EXCEL
                },
                excelParsingEnabled: EXCEL_PARSING_ENABLED
            };
        },
        computed: {
            chosenDataSourceType: {
                get() {
                    return this.dataSourceType;
                }
            },
            parameters: {
                get() {
                    if (this.value === undefined ||
                        this.value.TextParameters === undefined ||
                        this.value.NumberParameters === undefined ||
                        this.value.DecimalParameters === undefined ||
                        this.value.BooleanParameters === undefined ||
                        this.value.DateTimeParameters === undefined ||
                        this.value.PropertyParameters === undefined 
                    ) {
                        this.parameters = this.parametersDefaults;
                        return this.parametersDefaults;
                    }
                    return this.value;
                },
                set(val) {
                    this.$emit('input', val);
                }
            }
        },
        watch: {
        },
        methods: {
            columnTypeChanged(columnId, columnType) {
                if (columnId == this.columnId) {
                    this.chosenType = columnType;
                }
            },
            showPropertyParameters(e, columnId) {
                this.$refs['editDataTypePropertyParametersPopup_' + this.columnId].instance.show();
            },
            handleInput(e) {
                this.$emit('input', this.parameters);
            },
            getPropertySummary(columnId) {
                return ''; // TODO? paraméterek alapján összegzést kiírni?
            }
        },
        created() {
            EventBus.$on("SOURCE_DATA_COLUMN_TYPE_CHANGED", (columnId, columnType) => this.columnTypeChanged(columnId, columnType));
        },
        mounted() {
        },
    }
</script>

<style>
    .column-parameters {
        width: 170px;
        margin: 4px 8px 8px 8px;
    }

    .column-parameters .fieldGroupTitle {
        font-weight: bold;
        text-decoration: underline;
        padding-bottom: 8px;
    }

    .column-parameters .dx-field {
        white-space: nowrap;
    }

    .column-parameters .dx-field-label {
        width: 125px;
    }

    .column-parameters .dx-field-value {
        width: 30px !important;
    }

    .datetime .dx-field {
        width: 200px;
    }

    .datetime .dx-field-label {
        width: 100px;
    }

    .datetime .dx-field-value {
        width: 100px !important;
    }
</style>
<style lang="scss" scoped>
    
</style>