import store from "../store";
import RequestHandler from "@appfrm/core/services/request-handler";
class AccountService {
    constructor() {
        this.path = "account/users";
        if (AccountService.instance) {
            return AccountService.instance;
        }
        AccountService.instance = this;
        return this;
    }
    // getUserAndRoles(userId?: string) {
    //     return forkJoin(
    //         this.accountEndpoint.getUserEndpoint<User>(userId),
    //         this.accountEndpoint.getRolesEndpoint<Role[]>());
    // }
    async getUsers() {
        return await RequestHandler.get(this.path);
    }
    async insertUser(insertUser) {
        return await RequestHandler.post(this.path, insertUser);
    }
    async updateUser(updateUser) {
        return await RequestHandler.putAsForm(this.path, updateUser);
    }
    async removeUser(id) {
        return await RequestHandler.delete(this.path, id);
    }
    async getRoles() {
        return await RequestHandler.get("account/roles");
    }
    async changePassword(data) {
        return await RequestHandler.post(`${this.path}/changepassword`, {
            userId: data.userId,
            newPassword: data.newPassword
        });
    }
    // getUsersAndRoles(page?: number, pageSize?: number) {
    //     return forkJoin(
    //         this.accountEndpoint.getUsersEndpoint<User[]>(page, pageSize),
    //         this.accountEndpoint.getRolesEndpoint<Role[]>());
    // }
    // updateUser(user: UserEdit) {
    //     if (user.id) {
    //         return this.accountEndpoint.getUpdateUserEndpoint(user, user.id);
    //     } else {
    //         return this.accountEndpoint.getUserByUserNameEndpoint<User>(user.userName).pipe(
    //             mergeMap(foundUser => {
    //                 user.id = foundUser.id;
    //                 return this.accountEndpoint.getUpdateUserEndpoint(user, user.id);
    //             }));
    //     }
    // }
    // newUser(user: UserEdit) {
    //     return this.accountEndpoint.getNewUserEndpoint<User>(user);
    // }
    // getUserPreferences() {
    //     return this.accountEndpoint.getUserPreferencesEndpoint<string>();
    // }
    // updateUserPreferences(configuration: string) {
    //     return this.accountEndpoint.getUpdateUserPreferencesEndpoint(configuration);
    // }
    // deleteUser(userOrUserId: string | User): Observable<User> {
    //     if (typeof userOrUserId === 'string' || userOrUserId instanceof String) {
    //         return this.accountEndpoint.getDeleteUserEndpoint<User>(userOrUserId as string).pipe<User>(
    //             tap(data => this.onRolesUserCountChanged(data.roles)));
    //     } else {
    //         if (userOrUserId.id) {
    //             return this.deleteUser(userOrUserId.id);
    //         } else {
    //             return this.accountEndpoint.getUserByUserNameEndpoint<User>(userOrUserId.userName).pipe<User>(
    //                 mergeMap(user => this.deleteUser(user.id)));
    //         }
    //     }
    // }
    // unblockUser(userId: string) {
    //     return this.accountEndpoint.getUnblockUserEndpoint(userId);
    // }
    userHasPermissions(permissionValues) {
        let hasPermission = true;
        if (this.currentUser.isAdmin) {
            return true;
        }
        permissionValues.forEach(permissionValue => {
            if (!this.permissions.some(p => p == permissionValue)) {
                hasPermission = false;
            }
        });
        return hasPermission;
    }
    userHasPermission(permissionValue) {
        return (this.currentUser.isAdmin ||
            this.permissions.some(p => p == permissionValue));
    }
    get permissions() {
        return store.state.permissions;
    }
    get currentUser() {
        return store.state.user;
    }
}
AccountService.roleAddedOperation = "add";
AccountService.roleDeletedOperation = "delete";
AccountService.roleModifiedOperation = "modify";
const instance = new AccountService();
export default instance;