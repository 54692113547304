import RequestHandler from "@appfrm/core/services/request-handler";
class SystemInfoService {
    constructor() {
        this.path = "SystemInfo";
        if (SystemInfoService.instance) {
            return SystemInfoService.instance;
        }
        SystemInfoService.instance = this;
        return this;
    }
    async get() {
        return await RequestHandler.get(this.path);
    }
}
const instance = new SystemInfoService();
export default instance;
