<template>
    <div>
        <div class="newSourceDataPopup">
            <CustomDxPopup :ref="newSourceDataPopupRefName"
                           :drag-enabled="false"
                           :show-title="true"
                           title="Új adattábla létrehozása"
                           :onShowing="onNewSourceDataPopupShowing"
                           :onHiding="onNewSourceDataPopupHiding"
                           container="#app"
                           width="95vw"
                           height="95vh">

                <DxScrollView :show-scrollbar="'onScroll'"
                              :use-native="false">
                    <DxLoadIndicator v-if="!loaded" />

                    <DxForm :visible="loaded"
                            :form-data="formData"
                            :show-validation-summary="true"
                            validation-group="firstImportValidationGroup">
                        <template #file-upload-template>
                            <CustomDxFileUploader select-button-text="Fájl feltöltése"
                                                  :multiple="false"
                                                  :allowedFileExtensions="allowedFileExtensions"
                                                  upload-mode="instantly"
                                                  :minFileSize="1"
                                                  :upload-url="uploadUrl"
                                                  :onValueChanged="e => files = e.value"
                                                  :onUploaded="onUploaded"
                                                  :onUploadError="onUploadError" />

                        </template>
                        <DxGroupItem caption="Tábla adatai">
                            <DxSimpleItem data-field="sourceDataName">
                                <DxLabel text="Adattábla neve" />
                                <DxAsyncRule :validation-callback="validateSubmitErrors"
                                             :message="getSubmitErrorMessage('sourceDataName')" />
                                <DxRequiredRule />
                                <DxStringLengthRule :max="sourceDataNameMaxLength" :message="`Az adattábla neve legfeljebb ${sourceDataNameMaxLength} karaketer hosszú lehet.`" />
                                <DxAsyncRule :validation-callback="IsSourceDataNameFree"
                                             :ignore-empty-value="true"
                                             message="Már létezik adattábla ezzel a névvel." />
                            </DxSimpleItem>
                            <DxSimpleItem data-field="sourceDataDescription">
                                <DxLabel text="Adattábla leírása" />
                            </DxSimpleItem>
                            <DxSimpleItem :visible="dataContainerOperation.hasOperation(dataContainerType, dataContainerOperation.setDataSource)"
                                          data-field="dataSourceType"
                                          editor-type="dxSelectBox"
                                          :editor-options="{ searchEnabled: false, dataSource: this.dataSourceTypesDS, displayExpr: 'typeName', valueExpr: 'id', onValueChanged: this.dataSourceTypeChanged }">
                                <DxLabel text="Adatforrás típusa" />
                                <DxRequiredRule />
                            </DxSimpleItem>

                            <DxSimpleItem :visible="uploadVisibility()" template="file-upload-template">
                                <DxLabel text="Adattábla feltöltése" />
                            </DxSimpleItem>

                            <DxSimpleItem :visible="uploadVisibility()" data-field="uploadedFileName">
                                <DxLabel text="Feltöltött fájl neve" />
                                <template #default>
                                    <span>{{formData.uploadedFileName}}</span>
                                </template>
                            </DxSimpleItem>

                            <DxSimpleItem editor-type="dxSelectBox"
                                          data-field="localDbTable"
                                          :visible="tableChooserVisibility()"
                                          :editor-options="{searchEnabled: false, dataSource: this.allowedLocalDbTablesDS, displayExpr: 'name', valueExpr: 'id', onValueChanged: this.localTableChanged }">
                                >
                                <DxLabel text="Helyi adattábla kiválasztása" />
                                <DxRequiredRule />
                            </DxSimpleItem>

                            <DxSimpleItem editor-type="dxSelectBox"
                                          data-field="externalDataSourceId"
                                          :visible="externalChooserVisibility()"
                                          :editor-options="{searchEnabled: false, dataSource: this.allowedExternalDataSourcesDS, displayExpr: 'name', valueExpr: 'id', onValueChanged: this.externalDataSourceChanged }">
                                >
                                <DxLabel text="Külső adatforrás kiválasztása" />
                                <DxRequiredRule />
                            </DxSimpleItem>

                            <DxSimpleItem editor-type="dxSelectBox"
                                          data-field="externalDataTableName"
                                          :visible="externalChooserVisibility() && externalDataSourceTables.length > 0"
                                          :editor-options="{searchEnabled: false, dataSource: this.externalDataSourceTables, displayExpr: 'TableName', valueExpr: 'TableName', onValueChanged: this.externalDataSourceTableChanged }">
                                >
                                <DxLabel text="Tábla kiválasztása" />
                                <DxRequiredRule />
                                <DxAsyncRule :validation-callback="IsTableEnabled"
                                             :ignore-empty-value="true"
                                             message="Már létezik ez az adattábla." />
                            </DxSimpleItem>

                        </DxGroupItem>
                        <DxGroupItem caption="Oszlop adatok">
                            <template #default>
                                <div class="source-data-table">
                                    <div class="source-data-table-thead">
                                        <div class="source-data-table-thead-row dx-theme-accent-as-background-color dx-theme-background-color-as-text-color">
                                            <div class="source-data-table-thead-row-cell source-data-column-handle dx-theme-border-color"></div>
                                            <div class="source-data-table-thead-row-cell source-data-column-index dx-theme-border-color">#</div>
                                            <div class="source-data-table-thead-row-cell source-data-column-providerDisplayedName dx-theme-border-color">Oszlop neve a fájlban</div>
                                            <div class="source-data-table-thead-row-cell source-data-column-toBeImported dx-theme-border-color">Importálandó</div>
                                            <div class="source-data-table-thead-row-cell source-data-column-isKey dx-theme-border-color">Kulcs oszlop</div>
                                            <div class="source-data-table-thead-row-cell source-data-column-userDisplayedName dx-theme-border-color">Oszlop neve</div>
                                            <div class="source-data-table-thead-row-cell source-data-column-type dx-theme-border-color">Oszlop típusa</div>
                                            <div class="source-data-table-thead-row-cell source-data-column-parameters dx-theme-border-color">Oszlop paraméterei</div>
                                            <div class="source-data-table-thead-row-cell source-data-column-description dx-theme-border-color">Oszlop leírása</div>
                                            <div class="source-data-table-thead-row-cell source-data-column-is-company-id dx-theme-border-color">Cégazonosító?</div>
                                            <div class="source-data-table-thead-row-cell source-data-column-claims-data dx-theme-border-color">Jogosultságok</div>
                                            <div class="source-data-table-thead-row-cell source-data-column-actions dx-theme-border-color">Akciók</div>
                                        </div>
                                    </div>
                                    <DxSortable class="source-data-table-tbody"
                                                handle=".source-data-column-handle"
                                                dragTemplate="drag"
                                                @drag-start="onColumnDragStarted"
                                                @reorder="onColumnReorder">
                                        <div v-for="column in formData.columns" :key="column.index" class="dx-theme-border-color source-data-table-tbody-row">
                                            <div class="source-data-table-tbody-row-cell source-data-column-handle"><div class="dx-icon-dragvertical"></div></div>
                                            <div class="source-data-table-tbody-row-cell source-data-column-index">{{column.index}}</div>
                                            <div class="source-data-table-tbody-row-cell source-data-column-providerDisplayedName">{{column.providerDisplayedName}}</div>
                                            <div class="source-data-table-tbody-row-cell source-data-column-toBeImported">
                                                <DxCheckBox v-if="column.processType == consts.SOURCE_DATA_COLUMN_PROCESS_TYPE_IMPORTABLE && column.importable" v-model="column.toBeImported">
                                                    <DxValidator validation-group="firstImportValidationGroup" :onInitialized="e => column.toBeImportedValidator = e.component">
                                                        <DxAsyncRule message="Legalább egy importálandó oszlopot ki kell választani"
                                                                     validation-group="firstImportValidationGroup"
                                                                     :validation-callback="e => isAnyColumnSelectedForImport(e, column)" />
                                                    </DxValidator>
                                                </DxCheckBox>
                                            </div>
                                            <div class="source-data-table-tbody-row-cell source-data-column-isKey">
                                                <DxCheckBox v-model="column.isKey">
                                                    <DxValidator validation-group="firstImportValidationGroup" :onInitialized="e => column.isKeyValidator = e.component">
                                                        <DxAsyncRule :message="'Az adattábla legfeljebb ' + capability.maxKeyColumnCount + ' darab kulcs oszlopot tartalmazhat.'"
                                                                     validation-group="firstImportValidationGroup"
                                                                     :validation-callback="e => checkMaxKeyColumnsForImport(e, column)" />
                                                        <DxAsyncRule :message="'Az adattáblának minimum ' + capability.minKeyColumnCount + ' darab kulcs oszlopot kell tartalmaznia.'"
                                                                     validation-group="firstImportValidationGroup"
                                                                     :validation-callback="e => checkMinKeyColumnsForImport(e, column)" />
                                                        <DxAsyncRule :message="'Felhasználó által létrehozott mező nem állítható be kulcsként erre az adatforrástípusra, mert akkor az adatforrás nem importálható'"
                                                                     validation-group="firstImportValidationGroup"
                                                                     :validation-callback="e => checkDataSourceDependantKeyColumnsForImport(e, column)" />
                                                    </DxValidator>
                                                </DxCheckBox>
                                            </div>
                                            <div :class="'source-data-table-tbody-row-cell source-data-column-userDisplayedName colspan-' + (!column.importable || !column.toBeImported ? 2 : 1)" :colspan="!column.importable || !column.toBeImported ? 2 : 1">
                                                <DxTextBox v-if="(column.processType == consts.SOURCE_DATA_COLUMN_PROCESS_TYPE_IMPORTABLE && column.importable && column.toBeImported) || column.processType == consts.SOURCE_DATA_COLUMN_PROCESS_TYPE_NEW"
                                                           v-model="column.userDisplayedName">
                                                    <DxValidator validation-group="firstImportValidationGroup" :onInitialized="e => column.userDisplayedNameValidator = e.component">
                                                        <DxAsyncRule :validation-callback="e=>isColumnNameUnique(e, column)"
                                                                     message="Az oszlop neve nem egyedi a táblában" />
                                                        <DxRequiredRule />
                                                        <DxStringLengthRule :max="sourceDataColumnNameMaxLength" :message="`Az oszlop neve legfeljebb ${sourceDataColumnNameMaxLength} karaketer hosszú lehet.`" />
                                                    </DxValidator>
                                                </DxTextBox>
                                                <div v-if="column.processType == consts.SOURCE_DATA_COLUMN_PROCESS_TYPE_IMPORTABLE && !column.importable" style="padding: 8px 10px 9px 10px;">Több azonos oszlopnév miatt az oszlop nem importálható.</div>
                                                <div v-if="column.processType == consts.SOURCE_DATA_COLUMN_PROCESS_TYPE_IMPORTABLE && column.importable && !column.toBeImported" style="padding: 8px 10px 9px 10px;">Az oszlop nem lesz importálva.</div>
                                            </div>
                                            <div class="source-data-table-tbody-row-cell source-data-column-type" v-if="(column.processType == consts.SOURCE_DATA_COLUMN_PROCESS_TYPE_IMPORTABLE && column.importable && column.toBeImported) || column.processType == consts.SOURCE_DATA_COLUMN_PROCESS_TYPE_NEW">
                                                <DxSelectBox width="120"
                                                             :v-if="column.toBeImported"
                                                             :data-source="getAllowedDataTypes()"
                                                             v-model="column.type"
                                                             display-expr="name"
                                                             value-expr="id"
                                                             :on-selection-changed="(e) => columnTypeChanged(e, column.index)">
                                                    <DxValidator validation-group="firstImportValidationGroup">
                                                        <DxRequiredRule />
                                                    </DxValidator>
                                                </DxSelectBox>
                                            </div>
                                            <div class="source-data-table-tbody-row-cell source-data-column-parameters" v-if="(column.processType == consts.SOURCE_DATA_COLUMN_PROCESS_TYPE_IMPORTABLE && column.importable && column.toBeImported) || column.processType == consts.SOURCE_DATA_COLUMN_PROCESS_TYPE_NEW">
                                                <SourceDataColumnTypeParameters :v-if="(column.processType == consts.SOURCE_DATA_COLUMN_PROCESS_TYPE_IMPORTABLE && column.importable && column.toBeImported) || column.processType == consts.SOURCE_DATA_COLUMN_PROCESS_TYPE_NEW" v-model="column.parameters" :columnId="column.index" :dataSourceType="formData.dataSourceType" validationGroup="firstImportValidationGroup" />
                                            </div>
                                            <div class="source-data-table-tbody-row-cell source-data-column-description" v-if="(column.processType == consts.SOURCE_DATA_COLUMN_PROCESS_TYPE_IMPORTABLE && column.importable && column.toBeImported) || column.processType == consts.SOURCE_DATA_COLUMN_PROCESS_TYPE_NEW">
                                                <DxTextBox :v-if="(column.processType == consts.SOURCE_DATA_COLUMN_PROCESS_TYPE_IMPORTABLE && column.importable && column.toBeImported) || column.processType == consts.SOURCE_DATA_COLUMN_PROCESS_TYPE_NEW" v-model="column.description" />
                                            </div>
                                            <div class="source-data-table-tbody-row-cell source-data-column-is-company-id">
                                                <DxCheckBox v-model="column.isCompanyId" :disabled="true" />
                                            </div>
                                            <div class="source-data-table-tbody-row-cell source-data-column-claims-data">
                                                <DxCheckBox v-model="column.claimsAreInherited" text="táblától örökölt" />
                                                <div v-show="!column.claimsAreInherited" class="column-claim-select-wrapper">
                                                    <div class="dx-field">
                                                        <DxTagBox :items="claims" v-model="column.claims" placeholder="jogosultság kiválasztása" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="source-data-table-tbody-row-cell source-data-column-actions" v-if="column.processType == consts.SOURCE_DATA_COLUMN_PROCESS_TYPE_NEW">
                                                <a href="#" class="dx-link dx-link-delete dx-icon-trash dx-link-icon" title="Törlés" aria-label="Törlés" @click="(e) => removeColumn(column.index)"></a>
                                            </div>
                                        </div>

                                        <template #drag="{ data }">
                                            <div class="source-data-table dx-widget">
                                                <div class="source-data-table-tbody">
                                                    <div class="dx-theme-border-color source-data-table-tbody-row dx-theme-background-color">
                                                        <div class="source-data-table-tbody-row-cell source-data-column-handle"><div class="dx-icon-dragvertical"></div></div>
                                                        <div class="source-data-table-tbody-row-cell source-data-column-index">{{data.itemData.index}}</div>
                                                        <div class="source-data-table-tbody-row-cell source-data-column-providerDisplayedName">{{data.itemData.providerDisplayedName}}</div>
                                                        <div class="source-data-table-tbody-row-cell source-data-column-toBeImported">
                                                            <DxCheckBox v-if="data.itemData.importable" v-model="data.itemData.toBeImported" />
                                                        </div>
                                                        <div class="source-data-table-tbody-row-cell source-data-column-userDisplayedName">
                                                            <DxTextBox v-if="data.itemData.importable && data.itemData.toBeImported"
                                                                       v-model="data.itemData.userDisplayedName">
                                                                <DxValidator validation-group="firstImportValidationGroup">
                                                                    <DxStringLengthRule :max="sourceDataColumnNameMaxLength" :message="`Az oszlop neve legfeljebb ${sourceDataColumnNameMaxLength} karaketer hosszú lehet.`" />
                                                                </DxValidator>
                                                            </DxTextBox>
                                                            <div v-if="!data.itemData.importable" style="padding: 8px 10px 9px 10px;">Több azonos oszlopnév miatt az oszlop nem importálható.</div>
                                                            <div v-if="data.itemData.importable && !data.itemData.toBeImported" style="padding: 8px 10px 9px 10px;">Az oszlop nem lesz importálva.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </DxSortable>
                                </div>
                            </template>
                        </DxGroupItem>

                        <DxGroupItem cssClass="functionButtonsLeft">
                            <DxButtonItem>
                                <DxButtonOptions :width="270"
                                                 style="margin-top: 11px"
                                                 icon="plus"
                                                 text="Új oszlop hozzáadása"
                                                 type="default"
                                                 styling-mode="outlined"
                                                 :on-click="e => addNewColumn(e)" />
                            </DxButtonItem>
                        </DxGroupItem>
                            
                        <DxGroupItem caption="Jogosultságok">
                            <template #default>
                                <div class="dx-fieldset container-claims">
                                    <div class="dx-field">
                                        <div class="dx-field-label">Tábla szintű jogosultságok</div>
                                        <div class="dx-field-value">
                                            <DxTagBox :items="claims" placeholder="jogosultság kiválasztása" v-model="formData.dataSourceClaims" />
                                        </div>
                                    </div>
                                    <div class="dx-field">
                                        <div class="dx-field-label">Cégazonosítót tartalmazó oszlop</div>
                                        <div class="dx-field-value">
                                            <DxSelectBox :show-clear-button="true"
                                                         :data-source="formData.columns"
                                                         :value="0"
                                                         display-expr="userDisplayedName"
                                                         value-expr="index"
                                                         :on-selection-changed="(e) => companyColumnIdChanged(e)"
                                                         placeholder="oszlop kiválasztása" />
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </DxGroupItem>

                        <DxGroupItem cssClass="functionButtons">
                            <DxButtonItem alignment="left">
                                <DxButtonOptions text="Mégse"
                                                 type="default"
                                                 styling-mode="contained"
                                                 :on-click="hidePopup"
                                                 :disable="submitting" />
                            </DxButtonItem>
                            <DxButtonItem alignment="left">
                                <DxButtonOptions text="Új adatkonténer létrehozása"
                                                 type="default"
                                                 styling-mode="contained"
                                                 validation-group="firstImportValidationGroup"
                                                 :on-click="(e) => createNewDataContainer(e, false)"
                                                 :disable="submitting" />
                            </DxButtonItem>
                            <DxButtonItem alignment="left" :visible="hasMoreError">
                                <DxButtonOptions text="Új adatkonténer létrehozása minden hiba kijelzésével"
                                                 type="default"
                                                 styling-mode="contained"
                                                 validation-group="firstImportValidationGroup"
                                                 :on-click="(e) => createNewDataContainer(e, true)"
                                                 :disable="submitting" />
                            </DxButtonItem>
                        </DxGroupItem>

                        <DxValidationSummary id="summary" />
                    </DxForm>
                    <div class="import_errors" v-show="importErrors">
                        <div class="import_errors_header">A következő hibák történtek az importálás során</div>
                        <div class="import_errors_content" v-html="importErrors" />
                    </div>
                </DxScrollView>
            </CustomDxPopup>
        </div>
    </div>
</template>

<script>
    import { handleStandardErrorNotification } from "@appfrm/core/index";
    import EventBus from "@/utils/event-bus";
    import cloneDeep from "clone-deep";
    import DxPopup, {
        DxToolbarItem as DxPopupToolbarItem
    } from "devextreme-vue/popup";
    import DxButton from 'devextreme-vue/button';
    import { DxScrollView } from 'devextreme-vue/scroll-view';
    import DxDropDownButton, { DxDropDownOptions, DxPosition } from 'devextreme-vue/drop-down-button';
    import CustomDxPopup from "@appfrm/core/components/CustomDxPopup";
    import SourceDataColumnTypeParameters from "../components/SourceDataColumnTypeParameters";
    import sourceDataService, {
        DataContainerApiBasePath,
        TABLE_DISPLAYED_NAME_MAX_LENGTH,
        TABLE_COLUMN_DISPLAYED_NAME_MAX_LENGTH,
        DATA_SOURCE_TYPE_NINCS,
        DATA_SOURCE_TYPE_CSV,
        DATA_SOURCE_TYPE_EXCEL,
        DATA_SOURCE_TYPE_LOCAL_DB_TABLE,
        DATA_SOURCE_TYPE_EXTERNAL_DATA_SOURCE
    } from "../services/sourceData.service";
    import { DxLoadIndicator } from 'devextreme-vue/load-indicator';
    import DxTextBox from 'devextreme-vue/text-box';
    import { DxCheckBox } from 'devextreme-vue/check-box';
    import { DxSortable } from 'devextreme-vue/sortable';
    import DxForm, {
        DxGroupItem,
        DxSimpleItem,
        DxItem,
        DxButtonItem,
        DxButtonOptions,
        DxLabel,
        DxRequiredRule,
        DxCompareRule,
        DxRangeRule,
        DxStringLengthRule,
        DxPatternRule,
        DxEmailRule,
        DxAsyncRule,
        DxCustomRule
    } from 'devextreme-vue/form';
    import {
        DxValidator,
    } from 'devextreme-vue/validator';
    import { dataContainerPathFactory, dataContainerTypes, dataContainerTypeCapabilities, dataContainerOperation } from "../data-container"
    import Breadcrumb from "@appfrm/core/components/Breadcrumb";
    import DxSelectBox from "devextreme-vue/select-box";
    import DxValidationSummary from 'devextreme-vue/validation-summary';
    import CustomDxFileUploader from "@appfrm/core/components/CustomDxFileUploader";
    import DxTagBox from 'devextreme-vue/tag-box';

    export const SOURCE_DATA_COLUMN_PROCESS_TYPE_NEW = 1;
    export const SOURCE_DATA_COLUMN_PROCESS_TYPE_IMPORTABLE = 2;

    export default {
        name: "NewSourceDataPopup",
        components: {
            DxPopup,
            DxPopupToolbarItem,
            DxScrollView,
            CustomDxPopup,
            DxLoadIndicator,
            DxButton,
            DxForm,
            DxGroupItem,
            DxSimpleItem,
            DxItem,
            DxButtonItem,
            DxButtonOptions,
            DxLabel,
            DxRequiredRule,
            DxCompareRule,
            DxRangeRule,
            DxStringLengthRule,
            DxPatternRule,
            DxEmailRule,
            DxAsyncRule,
            DxCustomRule,
            DxTextBox,
            DxCheckBox,
            DxValidator,
            DxSortable,
            Breadcrumb,
            DxSelectBox,
            SourceDataColumnTypeParameters,
            DxValidationSummary,
            CustomDxFileUploader,
            DxTagBox
        },
        props: [],
        data() {
            var dataContainerType = dataContainerTypes.getByPath(this.$route.params.dataContainerType);
            var capability = dataContainerTypeCapabilities.find(c => c.dataContainerType.id === dataContainerType.id);

            return {
                newSourceDataPopupRefName: 'newSourceDataPopup',
                groupId: '',
                dataContainerType: dataContainerType,
                dataContainerOperation: dataContainerOperation,
                capability: capability,
                dataContainerApiBasePath: DataContainerApiBasePath,
                sourceDataNameMaxLength: TABLE_DISPLAYED_NAME_MAX_LENGTH,
                sourceDataColumnNameMaxLength: TABLE_COLUMN_DISPLAYED_NAME_MAX_LENGTH,
                importId: this.$route.params.importId,
                loaded: false,
                submitting: false,
                files: [],
                formData: {
                    uploadedFileName: "",
                    localDbTable: "",
                    sourceDataName: "",
                    sourceDataDescription: "",
                    dataSourceType: DATA_SOURCE_TYPE_NINCS,
                    dataSourceClaims: [],
                    columns: [],
                    externalDataSourceId: null,
                    externalDataTableName: null
                },
                submitValidationResult: [],
                dataSourceTypesDS: [
                    { "id": DATA_SOURCE_TYPE_NINCS, "typeName": "Nincs" },
                    { "id": DATA_SOURCE_TYPE_CSV, "typeName": "CSV" },
                    { "id": DATA_SOURCE_TYPE_EXCEL, "typeName": "Excel" },
                    { "id": DATA_SOURCE_TYPE_LOCAL_DB_TABLE, "typeName": "Helyi adatbázis tábla" },
                    { "id": DATA_SOURCE_TYPE_EXTERNAL_DATA_SOURCE, "typeName": "Külső adatforrás" }
                ],
                allowedLocalDbTablesDS: [],
                updatingAllowedLocalDbTables: false,
                importErrors: "",
                hasMoreError: false,
                consts: {
                    SOURCE_DATA_COLUMN_PROCESS_TYPE_NEW: SOURCE_DATA_COLUMN_PROCESS_TYPE_NEW,
                    SOURCE_DATA_COLUMN_PROCESS_TYPE_IMPORTABLE: SOURCE_DATA_COLUMN_PROCESS_TYPE_IMPORTABLE
                },
                saveClicked: false,
                claims: [],
                updatingAllowedExternalDataSources: false,
                allowedExternalDataSourcesDS: [],
                updatingExternalDataSourceTables: false,
                externalDataSourceTables: []
            }
        },
        computed: {
        },
        created() {
            EventBus.$on("PROPERTY_PARAMETERS_CHANGED", (columnId, propertyParameters) => this.propertyParametersChanged(columnId, propertyParameters));
        },
        mounted() {
            this.loaded = true;
        },
        watch: {
        },
        methods: {
            navigateToTransformationDetails(e) {
                let id = e.data? e.data.id : e;
                this.$router.push(`/dataTransformations/data/${id}`);
            },
            dataSourceTypeChanged(e) {
                if (!this.updatingAllowedLocalDbTables) {
                    this.updatingAllowedLocalDbTables = true;
                    this.getAllowedLocalDbTables();
                }
                if (!this.updatingAllowedExternalDataSources) {
                    this.updatingAllowedExternalDataSources = true;
                    this.getAllowedExternalSources();
                }

                if (e.value != DATA_SOURCE_TYPE_EXTERNAL_DATA_SOURCE) {
                    this.formData.externalDataSourceId = null;
                    this.formData.externalDataTableName = null;
                }

                if (e.value == DATA_SOURCE_TYPE_EXTERNAL_DATA_SOURCE) {
                    this.formData.columns = [];
                }

                if (e.previousValue == DATA_SOURCE_TYPE_EXTERNAL_DATA_SOURCE) {
                    this.formData.columns = [];
                    this.formData.externalDataSourceId = null;
                    this.formData.externalDataTableName = null;
                }
                return true;
            },
            getAllowedLocalDbTables() {
                sourceDataService.getAllowedLocalDbTables()
                    .then((response) => {
                        this.updatingAllowedLocalDbTables = false;
                        var allowedTables = response.map((table, index) => {
                            return { id: table, name: table };
                        });
                        this.allowedLocalDbTablesDS = allowedTables;
                    });
            },
            getAllowedExternalSources() {
                sourceDataService.getAllowedExternalSources()
                    .then((response) => {
                        this.updatingAllowedExternalDataSources = false;
                        var allowedSources = response.map((source, index) => {
                            return {
                                id: source.Id,
                                name: source.Name + ' (verzió: ' + source.Version + ')',
                                type: source.Type
                            };
                        });
                        this.allowedExternalDataSourcesDS = allowedSources;
                    });
            },
            loadClaims() {
                sourceDataService.getAllClaims()
                    .then((response) => {
                        if (!response || !response.length) {
                            this.claims = [];
                        }
                        else {
                            this.claims = response;
                        }
                    });
            },
            localTableChanged(e) {
                sourceDataService.getLocalDbTableColumns(e.value)
                    .then((response) => {

                        var importedColumns = response.map((column, index) => {
                            return {
                                index: column.Index,
                                providerDisplayedName: column.PropertyName,
                                userDisplayedName: column.DisplayedName,
                                description: "",
                                importable: true,
                                toBeImported: true,
                                parameters: column.Parameters ? column.Parameters : {},
                                isKey: false,
                                type: column.Type,
                                isCompanyId: false,
                                claimsAreInherited: true,
                                claims: []
                            }
                        });
                        this.removeAllImportableColumns();
                        importedColumns.forEach(function (v, i, a) {
                            this.addImportableColumn(v);
                        }, this);
                    });
            },
            externalDataSourceChanged(e) {
                this.formData.columns = [];
                this.externalDataSourceId = e.value;
                this.externalDataSourceTables = [];
                this.updatingExternalDataSourceTables = true;

                sourceDataService.getExternalDataSourceTables(e.value).then(
                    (response) => {
                        this.externalDataSourceTables = response;
                        this.updatingExternalDataSourceTables = false;
                    },
                    (failed) => {
                        this.updatingExternalDataSourceTables = false;
                    });
            },
            externalDataSourceTableChanged(e) {

                var table = this.externalDataSourceTables.filter((table) => {
                    return table.TableName == e.value;
                });

                this.formData.columns = [];

                if (table.length > 0) {
                    var importedColumns = table[0].Columns.map((column, index) => {
                        return {
                            index: index + 1,
                            providerDisplayedName: column.ProviderDisplayedName,
                            userDisplayedName: column.DisplayedName,
                            description: column.Description ? column.Description : '',
                            importable: true,
                            toBeImported: true,
                            parameters: column.Parameters ? column.Parameters : {},
                            isKey: column.IsKey,
                            type: column.Type,
                            isCompanyId: false,
                            claimsAreInherited: true,
                            claims: []
                        }
                    });
                    importedColumns.forEach(function (v, i, a) {
                        this.addImportableColumn(v);
                    }, this);
                }
            },
            getAllowedDataTypes() {
                var capability = dataContainerTypeCapabilities.find(c=>c.dataContainerType.id === this.dataContainerType.id);
                if(!capability) {
                    return []
                }
                return capability.allowedDataTypes;
            },
            uploadVisibility() {
                if (this.formData.dataSourceType == DATA_SOURCE_TYPE_CSV || this.formData.dataSourceType == DATA_SOURCE_TYPE_EXCEL) {
                    return true;
                } else {
                    return false;
                }
            },
            tableChooserVisibility() {
                if (this.formData.dataSourceType == DATA_SOURCE_TYPE_LOCAL_DB_TABLE) {
                    return true;
                } else {
                    return false;
                }
            },
            externalChooserVisibility() {
                if (this.formData.dataSourceType == DATA_SOURCE_TYPE_EXTERNAL_DATA_SOURCE) {
                    return true;
                } else {
                    return false;
                }
            },
            onNewSourceDataPopupShowing(e) {
                if (this.claims.length == 0) {
                    this.loadClaims();
                }

                this.saveClicked = false;
                this.oldFormData = cloneDeep(this.formData);
            },
            onNewSourceDataPopupHiding(e) {
                if (!this.saveClicked) {
                    this.formData = { ...this.oldFormData };
                }
            },
            getNextColumnIndex() {
                var index = 1;
                if (this.formData.columns.length == 0) return index;
                for (let i = 1; i < this.formData.columns.length + 2; i++) {
                    if ((this.formData.columns.filter(t => t.index == i)).length == 0) {
                        return i;
                    }
                }
            },
            addImportableColumn(column) {
                column.index = this.getNextColumnIndex();
                column.processType = SOURCE_DATA_COLUMN_PROCESS_TYPE_IMPORTABLE;
                this.formData.columns.push(column);
            },
            addNewColumn(e) {
                var column = {
                    index: this.getNextColumnIndex(),
                    sourceDataProviderDisplayedName: "",
                    displayedName: "",
                    description: "",
                    type: "",
                    parameters: {},
                    processType: SOURCE_DATA_COLUMN_PROCESS_TYPE_NEW,
                    isKey: false,
                    isCompanyId: false,
                    claimsAreInherited: true,
                    claims: []
                };
                this.formData.columns.push(column);
            },
            removeAllImportableColumns() {
                var importableColumns = this.formData.columns.filter(t => t.processType == SOURCE_DATA_COLUMN_PROCESS_TYPE_IMPORTABLE);

                importableColumns.forEach(function (v, i, a) {
                    this.removeColumn(v.index);
                }, this);
            },
            removeColumn(columnIndex) {
                this.formData.columns = this.formData.columns.filter(function (v, i, a) {
                    if (v.index != columnIndex) return v;
                });
            },
            async createNewDataContainer(e, withAllErrors) {
                if (!this.submitting) {
                    var validationResult = e.validationGroup.validate();

                    if (validationResult.status === 'pending') {
                        validationResult = await validationResult.complete;
                    }

                    if (validationResult.isValid) {
                        this.submitting = true;
                        var maxErrorCount = 100;
                        if (withAllErrors) maxErrorCount = 100000000;
                        if (this.importId === undefined) {
                            const a = crypto.getRandomValues(new Uint16Array(8));
                            let i = 0;
                            this.importId = '00-0-4-1-000'.replace(/[^-]/g,
                                s => (a[i++] + s * 0x10000 >> s).toString(16).padStart(4, '0')
                            );
                        }
                        var dataSourceTypeParameters = "";
                        switch (this.formData.dataSourceType) {
                            case DATA_SOURCE_TYPE_EXCEL: 
                                dataSourceTypeParameters = {
                                    ExcelParameters: {
                                        UploadedFileName: this.formData.uploadedFileName 
                                    }
                                };
                            case DATA_SOURCE_TYPE_CSV:
                                dataSourceTypeParameters = {
                                    CsvParameters: {
                                        UploadedFileName: this.formData.uploadedFileName 
                                    }
                                };
                                break;
                            case DATA_SOURCE_TYPE_LOCAL_DB_TABLE:
                                dataSourceTypeParameters = {
                                    LocalDbTableParameters: {
                                        TableName: this.formData.localDbTable
                                    }
                                };
                                break;
                            default:
                                dataSourceTypeParameters = {};

                        }

                        let dsClaims = [];
                        if (this.formData.dataSourceClaims.length > 0) {
                            this.formData.dataSourceClaims.forEach((claim, i, a) => {
                                dsClaims.push({ claimName: claim });
                            });
                        }

                        var postData = {
                            importId: this.importId,
                            groupId: this.groupId,
                            maxErrorCount: maxErrorCount,
                            dataContainerType: this.dataContainerType.id,
                            dataSourceType: this.formData.dataSourceType,
                            dataSourceTypeParameters: JSON.stringify(dataSourceTypeParameters),
                            sourceDataDisplayedName: this.formData.sourceDataName,
                            sourceDataDescription: this.formData.sourceDataDescription,
                            externalDataSourceId: this.formData.externalDataSourceId,
                            externalDataTableName: this.formData.externalDataTableName,
                            claims: dsClaims,
                            columns: this.formData.columns
                                //.filter(column => column.importable && column.toBeImported)
                                .filter(column => (column.processType == this.consts.SOURCE_DATA_COLUMN_PROCESS_TYPE_IMPORTABLE && column.importable && column.toBeImported) || column.processType == this.consts.SOURCE_DATA_COLUMN_PROCESS_TYPE_NEW)
                                .map(
                                    (column) => {

                                        let claims = [];
                                        if (!column.claimsAreInherited && column.claims.length > 0) {
                                            column.claims.forEach((claim, i, a) => {
                                                claims.push({ claimName: claim });
                                            });
                                        }

                                        return {
                                            sourceDataProviderDisplayedName: column.providerDisplayedName,
                                            displayedName: column.userDisplayedName,
                                            description: column.description,
                                            type: column.type,
                                            parameters: column.parameters,
                                            processType: column.processType,
                                            isKey: column.isKey,
                                            isCompanyId: column.isCompanyId,
                                            claimsAreInherited: column.claimsAreInherited,
                                            claims: claims
                                        }
                                    })
                        };

                        try {
                            var result = await sourceDataService.postFirstImport(this.importId, postData)
                            var sourceDataId = result;
                            this.saveClicked = true;
                            this.$router.push(dataContainerPathFactory.getDataPageUrl(this.dataContainerType, sourceDataId));
                        }
                        catch(error) {
                            this.submitting = false;
                            console.log(error.cause);
                            if (error.cause.data.exceptionList != undefined) {
                                this.hasMoreError = error.cause.data.hasMoreError;
                                var errors = error.data.exceptionList.map((obj) => {
                                    if(obj.InnerException) {
                                        return obj.Message + " " + obj.InnerException.Message + " (sor: " + obj.rowNumber + ", oszlop: " + obj.columnName + ")";
                                    }
                                    else {
                                        return obj.Message;
                                    }
                                });
                                this.importErrors = errors.join("<br />");
                                handleStandardErrorNotification("Nem sikerült a forrásadatok importálása, a hibákat lásd a popup alján.", error);
                            } else {
                                handleStandardErrorNotification("Nem sikerült a forrásadatok importálása.", error);
                            }
                        }
                    }
                }
            },
            async IsSourceDataNameFree(e) {
                var response = await sourceDataService.getDefinitionExists(this.dataContainerType, e.value);
                return !response;
            },
            async IsTableEnabled(e) {
                var response = await sourceDataService.getExternalTableExists(this.externalDataSourceId, e.value);
                return !response;
            },
            async isColumnNameUnique(e, column) {
                var importableColumns = this.formData.columns.filter(c => c.importable);
                var sameNameColumns = importableColumns.filter(c => c.userDisplayedName === column.userDisplayedName && c.index !== column.index);
                if (!column.userDisplayedNameValidationInProgress) {
                    sameNameColumns.forEach(c => {
                        if (!c.userDisplayedNameValidationInProgress) {
                            c.userDisplayedNameValidationInProgress = true;
                            c.userDisplayedNameValidator.validate()
                            c.userDisplayedNameValidationInProgress = false;
                        }
                    })
                }
                return sameNameColumns.length == 0;
            },
            // submit error methods
            async validateSubmitErrors(e) {
                if (e.formItem) {
                    return !this.submitValidationResult.some(v => v.fieldName == e.formItem.dataField)
                }
                return true;
            },
            getSubmitErrorMessage(fieldName) {
                var serverValidation = this.submitValidationResult.find(v => v.fieldName === fieldName);
                return serverValidation ? serverValidation.message : "";
            },
            // column reordering methods
            onColumnReorder(e) {
                const newColumns = [...this.formData.columns];

                newColumns.splice(e.fromIndex, 1);
                newColumns.splice(e.toIndex, 0, e.itemData);

                this.formData.columns = newColumns;
            },
            onColumnDragStarted(e) {
                e.itemData = this.formData.columns[e.fromIndex];
            },
            columnTypeChanged(e, columnId) {
                var selectedType = e.selectedItem.id;
                EventBus.$emit("SOURCE_DATA_COLUMN_TYPE_CHANGED", columnId, selectedType);
            },
            propertyParametersChanged(columnId, propertyParameters) {
                this.formData.columns.forEach(function (v, i, a) {
                    if (v.index == columnId) {            
                        var pp = JSON.parse(JSON.stringify(propertyParameters))
                        a[i].parameters.PropertyParameters = pp.PropertyParameters;
                    }
                });
            },
            async isAnyColumnSelectedForImport(e, column) {
                var importableColumns = this.formData.columns
                    .filter(column => column.importable && column.toBeImported)

                if (!column.toBeImportedValidationInProgress) {
                    this.formData.columns.filter(c => c.importable).forEach(c => {
                        if (!c.toBeImportedValidationInProgress) {
                            c.toBeImportedValidationInProgress = true;
                            c.toBeImportedValidator.validate()
                            c.toBeImportedValidationInProgress = false;
                        }
                    })
                    return importableColumns.length > 0;
                }
                else {
                    return true;
                }
            },
            async checkMinKeyColumnsForImport(e, column) {

                var keyColumns = this.formData.columns
                    .filter(column => ((column.processType == this.consts.SOURCE_DATA_COLUMN_PROCESS_TYPE_IMPORTABLE && column.importable) || column.processType == this.consts.SOURCE_DATA_COLUMN_PROCESS_TYPE_NEW) && column.isKey);

                var filterCallback = (column) => ((column.processType == this.consts.SOURCE_DATA_COLUMN_PROCESS_TYPE_IMPORTABLE && column.importable) || column.processType == this.consts.SOURCE_DATA_COLUMN_PROCESS_TYPE_NEW);

                var isValid = true;

                if (!column.isKeyValidationInProgress) {
                    this.formData.columns.filter(filterCallback).forEach(c => {
                        if (!c.isKeyValidationInProgress) {
                            c.isKeyValidationInProgress = true;
                            c.isKeyValidator.validate()
                            c.isKeyValidationInProgress = false;
                        }
                    });

                    isValid = keyColumns.length >= this.capability.minKeyColumnCount;
                }

                return isValid;
            },
            async checkMaxKeyColumnsForImport(e, column) {

                var keyColumns = this.formData.columns
                        .filter(column => ((column.processType == this.consts.SOURCE_DATA_COLUMN_PROCESS_TYPE_IMPORTABLE && column.importable) || column.processType == this.consts.SOURCE_DATA_COLUMN_PROCESS_TYPE_NEW) && column.isKey);

                var filterCallback = (column) => ((column.processType == this.consts.SOURCE_DATA_COLUMN_PROCESS_TYPE_IMPORTABLE && column.importable) || column.processType == this.consts.SOURCE_DATA_COLUMN_PROCESS_TYPE_NEW);


                var isValid = true;

                if (!column.isKeyValidationInProgress) {
                    this.formData.columns.filter(filterCallback).forEach(c => {
                        if (!c.isKeyValidationInProgress) {
                            c.isKeyValidationInProgress = true;
                            c.isKeyValidator.validate()
                            c.isKeyValidationInProgress = false;
                        }
                    });

                    isValid = keyColumns.length <= this.capability.maxKeyColumnCount;
                }

                return isValid;
            },
            async checkDataSourceDependantKeyColumnsForImport(e, column) {

                var keyColumns = this.formData.columns
                    .filter(column => ((column.processType == this.consts.SOURCE_DATA_COLUMN_PROCESS_TYPE_IMPORTABLE && column.importable) || column.processType == this.consts.SOURCE_DATA_COLUMN_PROCESS_TYPE_NEW) && column.isKey);

                var filterCallback = (column) => ((column.processType == this.consts.SOURCE_DATA_COLUMN_PROCESS_TYPE_IMPORTABLE && column.importable) || column.processType == this.consts.SOURCE_DATA_COLUMN_PROCESS_TYPE_NEW);

                var isValid = true;

                if (!column.isKeyValidationInProgress) {
                    this.formData.columns.filter(filterCallback).forEach(c => {
                        if (!c.isKeyValidationInProgress) {
                            c.isKeyValidationInProgress = true;
                            c.isKeyValidator.validate()
                            c.isKeyValidationInProgress = false;
                        }
                    });

                    keyColumns.forEach((column, i, a) => {
                        if (isValid && this.formData.dataSourceType != DATA_SOURCE_TYPE_NINCS && column.processType == this.consts.SOURCE_DATA_COLUMN_PROCESS_TYPE_NEW) {
                            isValid = false;
                        }
                    });
                }

                return isValid;
            },
            onUploaded(e) {
                this.importId = e.request.responseText;
                sourceDataService.getFirstImport(this.importId)
                    .then((response) => {
                        this.formData.uploadedFileName = response.UploadedFileName;

                        var importedColumns = response.Columns.map((column, index) => {
                            return {
                                index: column.Index,
                                providerDisplayedName: column.SourceDataProviderDisplayedName,
                                userDisplayedName: column.ProposedDisplayName,
                                description: "",
                                importable: column.Importable,
                                toBeImported: column.Importable,
                                isKey: false,
                                type: column.Type,
                                isCompanyId: false,
                                claimsAreInherited: true,
                                claims: []
                            }
                        });
                        this.removeAllImportableColumns();
                        importedColumns.forEach(function (v, i, a) {
                            this.addImportableColumn(v);
                        }, this);
                        this.dataContainerType = dataContainerTypes.getById(response.DataContainerType);
                    });
            },
            onUploadError(e) {
                if ((e.request.status === 400 || e.request.status === 409) && e.request.responseText) {
                    var response = JSON.parse(e.request.responseText);
                    e.message = "A feltöltés sikertelen. ErrorId=" + response.errorId + ", Message=" + response.message;
                }
            },
            hidePopup() {
                this.instance.hide();
            },
            companyColumnIdChanged(e) {
                let selectedIndex = e.selectedItem != null ? e.selectedItem.index : -1;
                this.formData.columns.forEach((column, i, a) => {
                    column.isCompanyId = column.index == selectedIndex ? true : false;
                });
            },
        },
        computed: {
            instance() {
                return this.$refs[this.newSourceDataPopupRefName].instance;
            },
            uploadUrl: function () {
                var groupId = this.groupId;
                var url = groupId ? `${this.dataContainerApiBasePath}/Data/Import/${this.dataContainerType.name}/UploadToGroup/${groupId}` : `${this.dataContainerApiBasePath}/Data/Import/${this.dataContainerType.name}/Upload`;
                return url;
            },
            allowedFileExtensions: function () {
                if (this.formData.dataSourceType == DATA_SOURCE_TYPE_EXCEL) return ['.xlsx'];
                if (this.formData.dataSourceType == DATA_SOURCE_TYPE_CSV) return ['.csv'];
                return [];
            }
        }
    };
</script>

<style scoped lang="scss">
    .nav > li > a {
        text-decoration: none;
    }
    .nav > li > a > .img-circle {
        width: 100%;
        max-width: 20px;
        height: auto;
        border-radius: 50%;
        border: 0;
        position: relative;
        bottom: 1px;
        vertical-align:middle
    }
    .nav > li > a > span {
        margin-left: 5px;
        line-height: 24px;
        font-size: 14px;
    }
    ::v-deep .localDropdown {
        .dx-button-content {
            line-height: 21px;
            padding: 12px 18px 7px;
        }
        .dx-button-text {
            color: #ffffff;
        }
        .dx-button-mode-outlined {
            border: 0;
        }
        .dx-icon {
            vertical-align: bottom;
            height: 20px !important;
            width: 28px;
            margin-right: 0 !important;
        }
        .dx-icon.dx-icon-right{
            width: 0;
        }
        .dx-overlay-content {
            left: 10px;
        }
        .dx-button-text {
            display: none;
        }
    }
    ul.navbar-nav li {
        padding-left: 31px !important;
        line-height: 40px;
    }
    .bug-report a {
        color: #fff;
    }
    .bug-report-chooser {
        position: absolute;
        left: -120px;
        top: 40px;
        background-color: #012A4A;
        color: #fff;
    }
    ul.bug-report-chooser {
        list-style: none;
        padding: 0px;
    }
    .bug-report-chooser li {
        padding: 0px 20px;
        list-style: none;
        white-space: nowrap;
    }
    .notificationCount {
        position: relative;
        top: -2px;
        background-color: #ff0000;
        color: #fff;
        font-size: 11px;
        font-weight: bold;
        line-height: 22px;
        padding: 0px 0px;
        -webkit-border-radius: 22px;
        -moz-border-radius: 22px;
        border-radius: 22px;
        display: inline-block;
        width: 22px;
        height: 22px;
        text-align: center;
    }


    .import_errors {
        color: #ff0000;
        font-size: 12px;

        .import_errors_header {
            font-weight: bold;
        }
    }

    .source-data-table {
        border-collapse: collapse;
        display: table;

        .source-data-column-handle {
            width: 37px;
        }

        .source-data-column-index {
            text-align: center;
            width: 37px;
        }

        .source-data-column-providerDisplayedName,
        .source-data-column-claims-data {
            width: 200px;
        }

        .source-data-column-toBeImported {
            width: 100px;
        }

        .source-data-column-isKey {
            width: 100px;
        }

        .source-data-column-userDisplayedName.colspan-1 {
            width: 210px;
        }

        .source-data-column-userDisplayedName.colspan-2 {
        }

        .source-data-column-parameters {
            width: 210px;
        }

        .source-data-column-description {
            width: 210px;
        }

        .column-claim-select-wrapper {
            margin-top: 2px;
        }
    }

    .source-data-table-thead {
        display: table-header-group;

        .source-data-table-thead-row {
            display: table-row;

            .source-data-table-thead-row-cell {
                display: table-cell;
                border-style: solid;
                border-width: 1px;
                padding: 7px;
                font-weight: 400;
            }
        }
    }

    .source-data-table-tbody {
        display: table-row-group;

        .source-data-table-tbody-row {
            display: table-row;
            border-style: solid;
            border-width: 1px;

            .source-data-table-tbody-row-cell {
                display: table-cell;
                vertical-align: middle;
            }

            .source-data-table-tbody-row-cell.colspan-2 {
                width: max-content;
                vertical-align: middle;
            }

            .source-data-column-handle {
                width: 37px;
                text-align: center;
                cursor: move;
            }

            .source-data-column-index {
                text-align: center;
            }

            .source-data-column-toBeImported,
            .source-data-column-isKey,
            .source-data-column-is-company-id {
                text-align: center;
            }

            .source-data-column-actions {
                font-size: 18px;
                color: #0c3c60;
                text-align: center;
                text-decoration: none;
                width: 60px;
            }

            .source-data-column-actions a {
                font-size: 18px;
                color: #0c3c60;
                text-decoration: none;
            }
        }
    }

    .container-claims .dx-field {
        max-width: 600px;
    }

</style>
<style>
    .dx-scrollable.dx-scrollview.dx-widget .dx-scrollable-wrapper {
        border: none;
    }
    .dx-list-item-icon {
        vertical-align: bottom;
        height: 13px !important;
    }
    .dx-popup-content {
        text-align: left !important;
    }
    .functionButtons .dx-layout-manager {
        text-align: right;
    }

    .functionButtons .dx-collection {
        display: inline-block !important;
    }

    .functionButtons .dx-item {
        display: inline-block !important;
        margin-left: 16px;
    }

    .functionButtonsLeft .dx-layout-manager {
        text-align: left;
    }

    .functionButtonsLeft .dx-collection {
        display: inline-block !important;
    }

    .functionButtonsLeft .dx-item {
        display: inline-block !important;
        margin-right: 16px;
    }
</style>
