<template>
    <div style="height:100%;">
        <Breadcrumb :items="breadcrumbItems" id="breadcrumb" />
        <DxTabPanel class="tabpanel" @title-click="onTabItemClick" :animation-enabled="false" :selected-index="selectedTabItemIndex">
            <DxTabItem title="Dashboard" icon="smalliconslayout">
                <template #default>
                    <div class="px-2">
                        <DxScrollView :show-scrollbar="'onScroll'"
                                      :use-native="false">
                            <div class="data-group-buttons">
                                <DxButton :width="270"
                                          style="margin-top: 11px"
                                          icon="plus"
                                          text="Új adatcsoport hozzáadása"
                                          type="default"
                                          styling-mode="outlined"
                                          @click="showDataGroupForm(0)" />
                            </div>
                            <DxSortable class="data-group-list"
                                        item-orientation="vertical"
                                        handle=".data-group-list-item-title"
                                        @reorder="onGroupListReorder">
                                <div class="data-group-list-item" v-for="dataGroup in groupList"
                                     :key="dataGroup.Id">
                                    <div class="data-group-list-item-header">
                                        <div class="data-group-list-item-title">
                                            <i class="handle dx-icon dx-icon-dragvertical"></i>
                                            {{ dataGroup.Name }}
                                        </div>
                                        <div class="data-group-list-item-buttons">
                                            <a class="dx-link dx-icon-plus dx-link-icon" title="Új adattábla létrehozása" @click="showNewSourceDataPopupWithGroupId(dataGroup.Id)"></a>
                                            <a class="dx-link dx-icon-edit dx-link-icon" title="Adatcsoport szerkesztése" @click="showDataGroupForm(dataGroup.Id)"></a>
                                            <a class="dx-link dx-icon-trash dx-link-icon" title="Adatcsoport törlése" @click="showConfirmPopup(dataGroup.Id)"></a>
                                        </div>
                                    </div>
                                    <div class="data-table-lista">
                                        <DxSortable class="data-table-list"
                                                    handle=".data-table-handle"
                                                    item-orientation="horizontal"
                                                    @reorder="onTableListReorder"
                                                    drop-feedback-mode="indicate">

                                            <div class="data-table-list-item"
                                                 v-for="dataTable in dataGroup.Tables"
                                                 :key="dataTable.Id"
                                                 :data-group-id="dataGroup.Id"
                                                 :data-table-id="dataTable.Id"
                                                 :title="dataTable.description"
                                                 :style="'background-color:' + (dataTable.BackgroundColor && dataTable.BackgroundColor.length? dataTable.BackgroundColor : tableListDefaultBgColor) ">
                                                <div class="data-table-list-item-buttons">
                                                    <div class="data-table-handle">
                                                        <i class="handle dx-icon dx-icon-dragvertical"></i>
                                                    </div>
                                                    <!-- <a class="dx-link dx-icon-folder dx-link-icon" title="Megtekintés" @click="navigateToSourceDataDetails(dataTable.Id)"></a> -->
                                                    <a class="dx-link dx-icon-palette dx-link-icon" title="Szerkesztés" @click="showDataTablePalette(dataTable.Id)"></a>
                                                    <DxColorBox v-model:value="dataTable.BackgroundColor"
                                                                apply-button-text="Mentés"
                                                                cancel-button-text="Bezár"
                                                                :show-drop-down-button="false"
                                                                :ref="'cbox-' + dataTable.Id"
                                                                width="0"
                                                                height="0"
                                                                :element-attr="colorBoxAttributes(dataTable.Id)"
                                                                :on-value-changed="onValueChanged">
                                                    </DxColorBox>
                                                </div>
                                                <div class="data-table-list-item-text">{{ dataTable.DisplayedName }}</div>
                                                <div class="btn-data-table-list-item" :title="'Megtekintés - ' + dataTable.DisplayedName" @click="navigateToSourceDataDetails(dataTable.Id)"></div>
                                            </div>
                                        </DxSortable>
                                    </div>
                                </div>
                            </DxSortable>
                        </DxScrollView>
                    </div>
                </template>
            </DxTabItem>

            <DxTabItem title="Adattáblák" icon="smalliconslayout">
                <template #default>
                    <div class="px-2">
                        
                        <DxButton :width="220"
                                  style="margin-top: 11px; margin-bottom: 10px;"
                                  icon="plus"
                                  text="Új adattábla létrehozása"
                                  type="default"
                                  styling-mode="outlined"
                                  @click="showNewSourceDataPopup" />


                        <div style="height:100%;">

                            <DxDataGrid :data-source="sourceDataList"
                                        :show-borders="true"
                                        :row-alternation-enabled="true"
                                        :remote-operations="false"
                                        :hover-state-enabled="true"
                                        :onRowInserted="onDataListGridChanged"
                                        :onRowUpdated="onDataListGridChanged"
                                        :onRowRemoved="onDataListGridChanged"
                                        :allow-column-reordering="true"
                                        ref="gridSourceDataList"
                                        height="100%"
                                        :scrolling="scrolling">
                                >
                                <DxEditing :allow-updating="true"
                                           :allow-deleting="true"
                                           mode="cell" />
                                <DxPaging />
                                <DxSorting mode="multiple" />
                                <DxFilterRow :visible="true" />
                                <DxRowDragging :allow-reordering="true"
                                               :allow-updating="true"
                                               :allow-sorting="false"
                                               :on-reorder="onTableGridReorder" />

                                <DxColumn caption=""
                                          :allowEditing="false"
                                          width="36"
                                          alignment="center"
                                          cell-template="validColumnTemplate"
                                          :allow-sorting="false"
                                          header-cell-template="validColumnHeader" />
                                <DxColumn caption="#" data-field="order" width="66" :allowEditing="false" />
                                <DxColumn caption="Tábla neve" data-field="displayedName" :allowEditing="false" />
                                <DxColumn caption="Adatbázistábla neve" data-field="dbTableName" :allowEditing="false" />
                                <DxColumn caption="Adatcsoport"
                                          data-field="sourceDataGroupId"
                                          :calculate-sort-value="sourceDataGroupIdCalculateSortValue">
                                    <DxLookup :data-source="groupListForLookup"
                                              display-expr="Name"
                                              value-expr="Id" />
                                </DxColumn>
                                <DxColumn caption="Akciók"
                                          sortOrder="0"
                                          :allow-sorting="false"
                                          :allow-reordering="false"
                                          :min-width="60"
                                          :width="100"
                                          type="buttons">
                                    <DxGridButton template="viewButtonTemplate" />
                                    <DxGridButton template="exportButtonTemplate" :visible="dataContainerOperation.hasOperation(dataContainerType, dataContainerOperation.exportData)" />
                                    <DxGridButton name="delete"
                                                  hint="Törlés"
                                                  icon="trash" />
                                </DxColumn>

                                <template #viewButtonTemplate="{ data }">
                                    <a v-if="dataContainerOperation.hasOperation(dataContainerType, dataContainerOperation.viewData)" @click="navigateToSourceDataDetails(data.data.id)" class="dx-link dx-icon-edit dx-link-icon" title="Megtekintés" aria-label="Megtekintés"></a>
                                </template>
                                <template #exportButtonTemplate="{ data }">
                                    <a @click="exportToExcel(data.data.id)" class="dx-link dx-icon-download dx-link-icon" title="Exportálás Excelbe" aria-label="Exportálás Excelbe"></a>
                                </template>
                                <template #validColumnTemplate="{ data }">
                                    <div v-if="!data.data.sourceDataGroupId"
                                         class="warning-icon-holder">
                                        <i class="dx-icon-warning warning-icon" title="Az adattáblához nincsen adatcsoport hozzárendelve!"></i>
                                    </div>
                                </template>
                                <template #validColumnHeader="{ data }">
                                    <i class="dx-icon-warning warning-icon-header"></i>
                                </template>

                                <template #title-header="{ data }">
                                    <div class="d-flex">
                                        <div class="flex-1"></div>
                                        <div class="flex-1">{{ data.column.caption }}</div>
                                        <div class="flex-1 text-align-end">
                                            <div v-if="gridSourceDataListColumnsConfig[data.column.dataField] ? gridSourceDataListColumnsConfig[data.column.dataField].sortOrder : undefined">
                                                <i v-if="gridSourceDataListColumnsConfig[data.column.dataField].sortOrder === 'asc'" class="dx-icon dx-icon-sortup mb-0" />
                                                <i v-else class="dx-icon dx-icon-sortdown mb-0" />
                                                <span>{{ gridSourceDataListColumnsConfig[data.column.dataField].sortIndex + 1 }}</span>
                                            </div>
                                            <div v-else>
                                                <i class="w-8-px dx-icon dx-icon-sortup mb-0" />
                                                <i class="dx-icon dx-icon-sortdown mb-0" />
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </DxDataGrid>
                        </div>
                    </div>
                </template>
            </DxTabItem>

            <DxTabItem title="Adatcsoportok" icon="smalliconslayout">
                <template #default>
                    <div class="px-2">
                        <DxButton :width="270"
                                  style="margin-top: 11px; margin-bottom: 10px;"
                                  icon="plus"
                                  text="Új adatcsoport hozzáadása"
                                  type="default"
                                  styling-mode="outlined"
                                  @click="showDataGroupForm(0)" />

                        <div style="height:100%;">

                            <DxDataGrid :data-source="sourceDataGroupList"
                                        :show-borders="true"
                                        :row-alternation-enabled="true"
                                        :remote-operations="false"
                                        :hover-state-enabled="true"
                                        :onRowInserted="onDataGroupListGridChanged"
                                        :onRowUpdated="onDataGroupListGridChanged"
                                        :onRowRemoved="onDataGroupListGridChanged"
                                        :scrolling="scrolling"
                                        height="100%"
                                        ref="gridSourceDataGroupList">
                                <DxEditing :allow-deleting="true"
                                           mode="row" />
                                <DxSorting mode="multiple" />
                                <DxFilterRow :visible="true" />
                                <DxRowDragging :allow-reordering="true"
                                               :on-reorder="onGroupGridReorder" />
                                <DxColumn caption="Id" data-field="Id" :visible="false" />
                                <DxColumn caption="#" data-field="Order" width="50" />
                                <DxColumn caption="Adatcsoport név" data-field="Name" />
                                <DxColumn caption="Akciók"
                                          sortOrder="0"
                                          :allow-resizing="false"
                                          :allow-reordering="false"
                                          :min-width="60"
                                          :width="100"
                                          type="buttons">
                                    <DxGridButton template="editButtonTemplate" />
                                    <DxGridButton template="deleteButtonTemplate" />
                                </DxColumn>

                                <template #editButtonTemplate="{ data }">
                                    <span class="dx-command-edit dx-command-edit-with-icons">
                                        <a @click="showDataGroupForm(data.data.Id)" class="dx-link dx-icon-edit dx-link-icon" title="Adatcsoport szerkesztése" aria-label="Adatcsoport szerkesztése"></a>
                                    </span>
                                </template>
                                <template #deleteButtonTemplate="{ data }">
                                    <span class="dx-command-edit dx-command-edit-with-icons">
                                        <a @click="showConfirmPopup(data.data.Id)" class="dx-link dx-icon-trash dx-link-icon" title="Adatcsoport törlése" aria-label="Adatcsoport törlése"></a>
                                    </span>
                                </template>
                                <template #title-header="{ data }">
                                    <div class="d-flex">
                                        <div class="flex-1"></div>
                                        <div class="flex-1">{{ data.column.caption }}</div>
                                        <div class="flex-1 text-align-end">
                                            <div v-if="sourceDataGroupListColumnsConfig[data.column.dataField] ? sourceDataGroupListColumnsConfig[data.column.dataField].sortOrder : undefined">
                                                <i v-if="sourceDataGroupListColumnsConfig[data.column.dataField].sortOrder === 'asc'" class="dx-icon dx-icon-sortup mb-0" />
                                                <i v-else class="dx-icon dx-icon-sortdown mb-0" />
                                                <span>{{ sourceDataGroupListColumnsConfig[data.column.dataField].sortIndex + 1 }}</span>
                                            </div>
                                            <div v-else>
                                                <i class="w-8-px dx-icon dx-icon-sortup mb-0" />
                                                <i class="dx-icon dx-icon-sortdown mb-0" />
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </DxDataGrid>
                        </div>
                    </div>
                </template>
            </DxTabItem>

            <DxTabItem title="Külső adatforrások" icon="smalliconslayout" :visible="dataContainerOperation.hasOperation(dataContainerType, dataContainerOperation.viewExternalDataSources)">
                <template #default>
                    <div class="px-2">
                        <div style="height:100%;">

                            <DxDataGrid :data-source="externalDataSourceList"
                                        :show-borders="true"
                                        :row-alternation-enabled="true"
                                        :remote-operations="false"
                                        :hover-state-enabled="true"
                                        :onRowInserted="onDataGroupListGridChanged"
                                        :onRowUpdated="onDataGroupListGridChanged"
                                        :onRowRemoved="onDataGroupListGridChanged"
                                        :scrolling="scrolling"
                                        height="100%"
                                        ref="gridExternalDataSourceList">
                                <DxEditing :allow-deleting="false"
                                           mode="row" />
                                <DxSorting mode="multiple" />
                                <DxFilterRow :visible="true" />

                                <DxColumn caption="Id" data-field="Id" :visible="false" />
                                <DxColumn caption="#" data-field="Order" width="50" alignment="center" :allow-filtering="false" />
                                <DxColumn caption="Külső adatforrás neve" data-field="Name" />
                                <DxColumn caption="Verziószám" data-field="Version" />
                                <DxColumn caption="Típus" data-field="Type">
                                    <DxLookup :data-source="externalDataSourceListForLookup"
                                              display-expr="name"
                                              value-expr="id" />
                                </DxColumn>
                                <DxColumn caption="Update státusz"
                                          data-field="LastUpdateStatus"
                                          :width="130"
                                          alignment="center"
                                          :allow-filtering="false"
                                          cell-template="lastUpdateStatusTemplate" />
                                <DxColumn caption="Akciók"
                                          sortOrder="0"
                                          :allow-resizing="false"
                                          :allow-reordering="false"
                                          :min-width="60"
                                          :width="100"
                                          type="buttons">
                                    <DxGridButton template="updateButtonTemplate" />
                                </DxColumn>
                                <template #lastUpdateStatusTemplate="{ data }">
                                    <div>
                                        <div v-if="data.data.LastUpdateStatus == externalSourceDataUpdateStatus.Failed"
                                             class="warning-icon-holder">
                                            <i class="dx-icon-warning warning-icon" title="Hibás"></i>
                                        </div>
                                        <div v-if="data.data.LastUpdateStatus == externalSourceDataUpdateStatus.Succeeded"
                                             class="warning-icon-holder">
                                            <i class="dx-icon-check success-icon" title="Sikeres"></i>
                                        </div>
                                        <div v-if="data.data.LastUpdateStatus == externalSourceDataUpdateStatus.NotYetUpdated"
                                             class="warning-icon-holder">
                                            <i class="dx-icon-minus normal-icon" title="Nem volt még update"></i>
                                        </div>
                                    </div>
                                </template>
                                <template #updateButtonTemplate="{ data }">
                                    <div>
                                        <span :class="edsUpdateButtonClass(data.data)">
                                            <a @click="showUpdateMirroredLocalDbTablesPopup(data.data.Id, ALL_TABLES_TABLE_ID)" class="dx-link dx-icon-refresh dx-link-icon" title="Adatok frissítése" aria-label="Adatok frissítése"></a>
                                        </span>
                                        <span v-if="data.data.Type == externalDataSourceType.hostedWebService.id" class="dx-command-edit dx-command-edit-with-icons">
                                            <a @click="showInputStatuses(data.data.Id)" class="dx-link dx-icon-checklist dx-link-icon" title="Státuszok megjelenítése" aria-label="Státuszok megjelenítése"></a>
                                        </span>
                                        <span class="dx-command-edit dx-command-edit-with-icons">
                                            <a @click="showExternalSourceLog(data.data.Id)" class="dx-link dx-icon-bulletlist dx-link-icon" title="Log megjelenítése" aria-label="Log megjelenítése"></a>
                                        </span>
                                    </div>
                                </template>
                                <template #title-header="{ data }">
                                    <div class="d-flex">
                                        <div class="flex-1"></div>
                                        <div class="flex-1">{{ data.column.caption }}</div>
                                        <div class="flex-1 text-align-end">
                                            <div v-if="sourceDataGroupListColumnsConfig[data.column.dataField] ? sourceDataGroupListColumnsConfig[data.column.dataField].sortOrder : undefined">
                                                <i v-if="sourceDataGroupListColumnsConfig[data.column.dataField].sortOrder === 'asc'" class="dx-icon dx-icon-sortup mb-0" />
                                                <i v-else class="dx-icon dx-icon-sortdown mb-0" />
                                                <span>{{ sourceDataGroupListColumnsConfig[data.column.dataField].sortIndex + 1 }}</span>
                                            </div>
                                            <div v-else>
                                                <i class="w-8-px dx-icon dx-icon-sortup mb-0" />
                                                <i class="dx-icon dx-icon-sortdown mb-0" />
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </DxDataGrid>
                        </div>

                        <ExternalDataSourceLogPopup :ref="externalSourceLogPopupRefName"
                                                    :container-type="dataContainerType"
                                                    :external-data-source-id="selectedExternalDataSourceId" />

                        <InputStatusPopup :ref="inputStatusPopupRefName"
                                          :external-data-source-id="selectedExternalDataSourceId" />
                    </div>
                </template>
            </DxTabItem>

            <!-- <DxTabItem title="Saját fal" icon="smalliconslayout">
            <template #default>

            </template>
        </DxTabItem> -->


        </DxTabPanel>
        <CustomDxPopup ref="popup"
                       :drag-enabled="false"
                       :close-on-outside-click="true"
                       :show-close-button="true"
                       :show-title="true"
                       :width="480"
                       :height="200"
                       :title="popupTitle"
                       container="#app">
            <form @submit="handleSubmit">
                <DxForm id="form" ref="groupForm"
                        :on-content-ready="validateForm"
                        :show-validation-summary="true"
                        :form-data="sourceDataGroupFormData"
                        validation-group="sourceDataGroupValidationGroup">
                    <DxSimpleItem data-field="Name" ref="inp">
                        <DxLabel text="Név" />
                        <DxStringLengthRule :max="sourceDataGroupNameMaxLength" :message="`Az adatcsoport neve legfeljebb ${sourceDataGroupNameMaxLength} karaketer hosszú lehet.`" />
                        <DxAsyncRule :validation-callback="IsSourceDataGroupNameFree"
                                     message="Az oszlop neve nem egyedi a táblában" />
                        <DxRequiredRule message="A név megadása kötelező" />
                    </DxSimpleItem>
                    <DxGroupItem :col-count="2">
                        <DxButtonItem>
                            <DxButtonOptions text="Mégse"
                                             styling-mode="contained"
                                             :on-click="hideDataGroupForm"
                                             :disabled="submitting" />
                        </DxButtonItem>
                        <DxButtonItem :button-options="buttonOptions"
                                      validation-group="sourceDataGroupValidationGroup"
                                      horizontal-alignment="right"
                                      :disabled="submitting" />
                    </DxGroupItem>
                </DxForm>
            </form>
        </CustomDxPopup>
        <CustomDxPopup ref="confirmPopup"
                       :drag-enabled="false"
                       :close-on-outside-click="true"
                       :show-close-button="true"
                       :show-title="false"
                       :width="320"
                       :height="confirmData.HasTable? 190 : 140"
                       container="#app">

            <DxToolbarItem widget="dxButton"
                           toolbar="bottom"
                           location="center"
                           :options="closeButtonOptions"
                           :disable="submitting" />

            <DxToolbarItem widget="dxButton"
                           toolbar="bottom"
                           location="center"
                           :options="deleteButtonOptions"
                           :disable="submitting" />
            <div class="confirm-text">
                <div v-show="confirmData.HasTable" class="warn">A csoport <strong>tartalmaz</strong> forrásadat táblákat!<br />A csoport törlésével a csoporthoz rendelt táblák nem törlődnek.</div>
                Biztos benne, hogy törli a(z) <strong>{{ confirmData.Name }}</strong> csoportot?
            </div>
        </CustomDxPopup>
        <NewSourceDataPopup ref="newSourceDataPopup"></NewSourceDataPopup>
        <UpdateFromMirroredLocalDbPopup ref="updateFromMirroredLocalDbPopup"></UpdateFromMirroredLocalDbPopup>
    </div>        
</template>

<script>
    import { handleStandardErrorNotification, notifySuccess } from "@appfrm/core/index";
    import DxTabPanel, { DxItem as DxTabItem } from "devextreme-vue/tab-panel";
    import { DxSortable } from 'devextreme-vue/sortable';
    import {
        DxPopup,
        DxPosition,
        DxToolbarItem
    } from 'devextreme-vue/popup';
    import DxForm, {
        DxItem,
        DxLabel,
        DxSimpleItem,
        DxButtonItem,
        DxButtonOptions,
        DxRequiredRule,
        DxAsyncRule,
        DxStringLengthRule,
        DxGroupItem
    } from 'devextreme-vue/form';
    import DxFilterBuilder from 'devextreme-vue/filter-builder';
    import {
        DxValidator,
    } from 'devextreme-vue/validator';
    import { DxScrollView } from 'devextreme-vue/scroll-view';
    import DxDraggable from 'devextreme-vue/draggable';
    import DxDataGrid, {
        DxColumn,
        DxEditing,
        DxButton as DxGridButton,
        DxRowDragging,
        DxSorting,
        DxGrouping,
        DxGroupPanel,
        DxSearchPanel,
        DxPaging,
        DxScrolling,
        DxFilterRow,
        DxLookup
    } from "devextreme-vue/data-grid";
    import sourceDataService, { DataContainerApiBasePath, GROUP_NAME_MAX_LENGTH, ExternalSourceDataUpdateStatus } from "../services/sourceData.service";
    import coreService from "@appfrm/core/services/core.service";
    import CustomStore from "devextreme/data/custom_store";
    import notify from 'devextreme/ui/notify';
    import DxColorBox from 'devextreme-vue/color-box';
    import DxButton from 'devextreme-vue/button';
    import CustomDxPopup from "@appfrm/core/components/CustomDxPopup";
    import Breadcrumb from "@appfrm/core/components/Breadcrumb";
    import NewSourceDataPopup from "@appfrm/data-container/components/NewSourceDataPopup";
    import UpdateFromMirroredLocalDbPopup, { ALL_TABLES_TABLE_ID } from "@appfrm/data-container/components/UpdateFromMirroredLocalDbPopup";
    import { dataContainerPathFactory, dataContainerTypes, dataContainerOperation, externalDataSourceType } from "../data-container"
    import ExternalDataSourceLogPopup from "@appfrm/data-container/components/ExternalDataSourceLogPopup";
    import InputStatusPopup from "@appfrm/data-container/components/InputStatusPopup";

    export default {
        name: "sourcedata",
        components: {
            DxTabPanel,
            DxTabItem,
            DxGridButton,
            DxDataGrid,
            DxColumn,
            DxEditing,
            DxSortable,
            DxPopup,
            DxPosition,
            DxToolbarItem,
            DxForm,
            DxItem,
            DxLabel,
            DxSimpleItem,
            DxRequiredRule,
            DxStringLengthRule,
            DxValidator,
            DxAsyncRule,
            DxButtonItem,
            DxButtonOptions,
            DxGroupItem,
            notify,
            DxColorBox,
            DxScrollView,
            DxButton,
            CustomDxPopup,
            DxRowDragging,
            DxSorting,
            DxGrouping,
            DxGroupPanel,
            DxSearchPanel,
            DxPaging,
            DxScrolling,
            DxFilterRow,
            DxLookup,
            Breadcrumb,
            NewSourceDataPopup,
            UpdateFromMirroredLocalDbPopup,
            ExternalDataSourceLogPopup,
            ExternalSourceDataUpdateStatus,
            InputStatusPopup
        },
        data() {
            var dataContainerType = dataContainerTypes.getByPath(this.$route.params.dataContainerType);

            return {
                tabItemRoutes: [
                    /* 0: */ dataContainerPathFactory.getDataContainerTypePageUrl(dataContainerType),
                    /* 1: */ dataContainerPathFactory.getDataContainerTablesUrl(dataContainerType),
                    /* 2: */ dataContainerPathFactory.getDataContainerGroupsUrl(dataContainerType),
                    /* 3: */ dataContainerPathFactory.getDataContainerExternalDataSourcesUrl(dataContainerType)
                ],
                selectedTabItemIndex: 0,
                dataContainerType: dataContainerType,
                dataContainerOperation: dataContainerOperation,
                externalDataSourceType: externalDataSourceType,
                dataContainerApiBasePath: DataContainerApiBasePath,
                sourceDataGroupNameMaxLength: GROUP_NAME_MAX_LENGTH,
                popupTitle: '',
                submitting: false,
                sourceDataList: new CustomStore({
                    key: "id",
                    load: async () => {

                        var data = await sourceDataService.getAllDefinitions(this.dataContainerType);
                        // A szűrés nem működik NULL értékkel
                        data.forEach((row) => {
                            if (row.sourceDataGroupId === null) {
                                row.sourceDataGroupId = 0;
                            }
                        });

                        return data;
                    },
                    remove: (id) => sourceDataService.deleteDefinition(id),
                    update: (key, value) => {
                        sourceDataService.setDefinitionGroup(key, value.sourceDataGroupId).then(
                            (result) => {
                                this.dataGrid.refresh();
                                this.onDataListGridChanged(value);
                            },
                            (error) => {
                                
                            });
                    }
                }),
                sourceDataGroupList: new CustomStore({
                    key: "Id",
                    load: () => sourceDataService.getDefinitionGroups(this.dataContainerType),
                    remove: (id) => sourceDataService.deleteDefinitionGroup(id)
                }),
                groupList: [],
                externalDataSourceList: new CustomStore({
                    key: "Id",
                    load: () => {
                        return sourceDataService.getAllowedExternalSourcesWithLastUpdateStatus().then((sources) => {
                            if (typeof sources == 'object' && sources.length > 0) {
                                let order = 1;
                                sources.forEach((source) => {
                                    source['Order'] = order++;
                                });
                            }                            
                            return sources;
                        });                        
                    }
                }),
                buttonOptions: {
                    text: 'Mentés',
                    type: 'success',
                    useSubmitBehavior: true,
                },
                deleteButtonOptions: {
                    icon: 'trash',
                    text: 'Törlés',
                    onClick: () => {
                        this.deleteGroup();
                    },
                },
                closeButtonOptions: {
                    text: 'Bezár',
                    onClick: () => {
                        this.$refs['confirmPopup'].instance.hide();
                    },
                },
                sourceDataGroupFormData: {
                    Id: 0,
                    Name: '',
                    Order: 0
                },
                confirmData: {
                    Id: 0,
                    Name: '',
                    HasTable: false
                },
                tableListDefaultBgColor: '#ffffff',
                gridSourceDataListColumnsConfig: {
                    order: {},
                    displayedName: {},
                    dbTableName: {},
                    sourceDataGroupId: {}
                },
                sourceDataGroupListColumnsConfig: {
                    Name: {}
                },
                breadcrumbItems: [
                    {
                        icon: dataContainerType.icon,
                        text: dataContainerType.menuTitle,
                        click: false
                    }
                ],
                breadcrumbHeight: '40px',
                scrolling: {
                    mode: 'standard'
                },
                sourceDataGroupIdCalculateSortValue(data) {
                    const column = this;
                    const value = column.calculateCellValue(data);
                    return column.lookup.calculateCellValue(value);
                },
                ALL_TABLES_TABLE_ID: ALL_TABLES_TABLE_ID,
                selectedExternalDataSourceId: null,
                externalSourceLogPopupRefName: 'externalSourceLogPopup',
                externalSourceLogPopup: false,
                externalSourceDataUpdateStatus: ExternalSourceDataUpdateStatus,
                inputStatusPopupRefName: 'inputStatusPopup',
                inputStatusPopupPopup: false,
            };
        },
        computed: {
            dataGrid() {
                return this.$refs['gridSourceDataList'] ? this.$refs['gridSourceDataList'].instance : false;
            },
            dataGroupGrid() {
                return this.$refs['gridSourceDataGroupList'] ? this.$refs['gridSourceDataGroupList'].instance : false;
            },
            groupListForLookup() {
                let list = [{
                    Id: 0,
                    Name: '-'
                }];

                this.groupList.forEach((group) => {
                    list.push(group);
                });

                return list;
            },
            externalDataSourceListForLookup() {
                let list = [];
                Object.getOwnPropertyNames(this.externalDataSourceType).map((edst) => {
                    if (this.externalDataSourceType[edst].id) {
                        list.push({ id: this.externalDataSourceType[edst].id, name: this.externalDataSourceType[edst].name });
                    }
                })
                return list;
            },
            dataExternalSourceLogPopup() {
                if (this.externalSourceLogPopup) {
                    return this.externalSourceLogPopup;
                }
                else if (this.$refs[this.externalSourceLogPopupRefName]) {
                    this.externalSourceLogPopup = this.$refs[this.externalSourceLogPopupRefName];
                }
                return this.externalSourceLogPopup.instance;
            },
            dataInputStatusPopup() {
                if (this.inputStatusPopup) {
                    return this.inputStatusPopup.instance;
                }
                else if (this.$refs[this.inputStatusPopupRefName]) {
                    this.inputStatusPopup = this.$refs[this.inputStatusPopupRefName];
                }
                return this.inputStatusPopup.instance;
            },
        },
        methods: {
            edsUpdateButtonClass(data) {
                var classPostfix = data.Type == this.externalDataSourceType.mirroredLocalDbTable.id ? "": " hidden";
                return "dx-command-edit dx-command-edit-with-icons" + classPostfix;
            },
            onCellPreparedGridSourceDataList(e) {
                if (e.rowType === "header") {
                    e.cellElement.addEventListener("click", (args) => {
                        args.preventDefault();
                        args.stopPropagation();
                        if (e.column.allowSorting) {
                            let sortOrder = this.gridSourceDataListColumnsConfig[e.column.dataField] ? this.gridSourceDataListColumnsConfig[e.column.dataField].sortOrder : undefined;
                            let index = this.gridSourceDataListColumnsConfig[e.column.dataField] ? this.gridSourceDataListColumnsConfig[e.column.dataField].sortIndex : undefined;
                            if (!e.column.type && sortOrder === undefined) {
                                sortOrder = "asc";
                                index = this.getNewIndex(this.gridSourceDataListColumnsConfig);
                            } else if (!e.column.type && sortOrder === "asc") {
                                sortOrder = "desc";
                            } else if (!e.column.type && sortOrder === "desc") {
                                sortOrder = undefined;
                                this.shiftIndexes(index, this.gridSourceDataListColumnsConfig);
                                index = undefined;
                            }
                            this.gridSourceDataListColumnsConfig[e.column.dataField].sortOrder = sortOrder;
                            this.gridSourceDataListColumnsConfig[e.column.dataField].sortIndex = index;
                            let grid = this.dataGrid;
                            grid.columnOption(e.columnIndex, 'sortOrder', sortOrder);
                            grid.columnOption(e.columnIndex, 'sortIndex', index);

                            grid.repaint();
                        }
                    });
                }
            },
            onCellPreparedGridSourceDataGroupList(e) {
                if (e.rowType === "header") {
                    e.cellElement.addEventListener("click", (args) => {
                        args.preventDefault();
                        args.stopPropagation();
                        if (e.column.allowSorting) {
                            let sortOrder = this.sourceDataGroupListColumnsConfig[e.column.dataField] ? this.sourceDataGroupListColumnsConfig[e.column.dataField].sortOrder : undefined;
                            let index = this.sourceDataGroupListColumnsConfig[e.column.dataField] ? this.sourceDataGroupListColumnsConfig[e.column.dataField].sortIndex : undefined;
                            if (!e.column.type && sortOrder === undefined) {
                                sortOrder = "asc";
                                index = this.getNewIndex(this.sourceDataGroupListColumnsConfig);
                            } else if (!e.column.type && sortOrder === "asc") {
                                sortOrder = "desc";
                            } else if (!e.column.type && sortOrder === "desc") {
                                sortOrder = undefined;
                                this.shiftIndexes(index, this.sourceDataGroupListColumnsConfig);
                                index = undefined;
                            }
                            this.sourceDataGroupListColumnsConfig[e.column.dataField].sortOrder = sortOrder;
                            this.sourceDataGroupListColumnsConfig[e.column.dataField].sortIndex = index;
                            let grid = this.dataGroupGrid;
                            grid.columnOption(e.columnIndex, 'sortOrder', sortOrder);
                            grid.columnOption(e.columnIndex, 'sortIndex', index);

                            grid.repaint();
                        }
                    });
                }
            },
            getNewIndex(config) {
                let arr = Object.values(config);
                arr = arr.filter((item) => item.sortIndex !== undefined);
                const max = Math.max(...arr.map((o) => o.sortIndex), -1);
                return max + 1;
            },
            shiftIndexes(removedIndex, config) {
                Object.values(config).forEach((column) => {
                    if (column.sortIndex !== undefined && column.sortIndex > removedIndex) {
                        column.sortIndex--;
                    }
                });
            },
            showUpdateMirroredLocalDbTablesPopup(data, tableId) {
                this.$refs['updateFromMirroredLocalDbPopup'].mirroredLocalDbTableExternalDataSourceDefinitionId = data;
                this.$refs['updateFromMirroredLocalDbPopup'].tableId = tableId;
                this.$refs['updateFromMirroredLocalDbPopup'].instance.show();
            },
            showNewSourceDataPopup() {
                this.$refs['newSourceDataPopup'].instance.show();
            },
            showNewSourceDataPopupWithGroupId(groupId) {
                this.$refs['newSourceDataPopup'].instance.show();
                this.$refs['newSourceDataPopup'].groupId = groupId;
            },
            navigateToSourceDataDetails(e) {
                let id = e.data? e.data.id : e;
                this.$router.push(dataContainerPathFactory.getDataPageUrl(this.dataContainerType, id));
            },
            async exportToExcel(id) {
                try {
                    var result = await sourceDataService.getDownloadUrl(id);
                    document.location.href = result;
                }
                catch (error) {
                    handleStandardErrorNotification("Nem sikerült az exportálás.", error);
                }
            },
            async onGroupListReorder(e) {
 
                if (e.fromIndex != e.toIndex) {

                    const group = this.groupList.splice(e.fromIndex, 1)[0];

                    this.groupList.splice(e.toIndex, 0, group);

                    this.submitting = true;
                    var data = {
                        groupId: group.Id,
                        newOrder: e.toIndex + 1
                    };

                    try {
                        await sourceDataService.reorderDefinitionGroups(data);
                        this.onDataGroupListSortableChanged(data);
                    }
                    catch (error) {
                        handleStandardErrorNotification("Nem sikerült a sorrendezés módosítása.", error);
                    }
                    this.submitting = false;
                }
            },
            onGroupGridReorder(e) {
                this.onGroupListReorder(e);
            },
            async onTableGridReorder(e) {

                if (e.fromIndex != e.toIndex && (e.toIndex + 1) != e.itemData.order) {

                    this.submitting = true;

                    var data = {
                        DataContainerId: e.itemData.id,
                        NewOrder: (e.toIndex + 1)
                    };

                    try {
                        await sourceDataService.reorderDefinitions(data);
                        let grid = this.dataGrid;
                        if (grid) {
                            grid.refresh();
                        }
                    }
                    catch (error) {
                        handleStandardErrorNotification("Nem sikerült a sorrendezés.", error);
                    }
                    this.submitting = false;
                }
            },
            async onTableListReorder(e) {
 
                if (e.fromIndex != e.toIndex) {

                    var groupIdx = -1;
                    for (var idx in this.groupList) {
                        if (groupIdx == -1 && this.groupList[idx].Id == e.itemElement.dataset.groupId) {
                            groupIdx = idx;
                        }
                    }

                    const table = this.groupList[groupIdx].Tables.splice(e.fromIndex, 1)[0];

                    this.groupList[groupIdx].Tables.splice(e.toIndex, 0, table);

                    this.submitting = true;

                    var data = {
                        dataContainerId: table.Id,
                        newOrder: e.toIndex + 1
                    };

                    try {
                        await sourceDataService.reorderTablesInGroup(data);
                        this.onDataListSortableInGroupChanged(data);
                    }
                    catch (error) {
                        handleStandardErrorNotification("Nem sikerült az adatcsoporton belüli táblák sorrendezésének módosítása.", error);
                    }
                    this.submitting = false;
                }
            },
            async updateBackgroundColor(id, bgColor) {

                this.submitting = true;

                var data = {
                    Id: id,
                    BackgroundColor: bgColor
                };

                try {
                    await sourceDataService.updateBackgroundColor(data);
                }
                catch (error) {
                    handleStandardErrorNotification("Nem sikerült a háttérszín módosítása.", error);
                }
            },
            showDataGroupForm(id) {
                this.popupTitle = id ? 'Adatcsoport szerkesztése' : 'Új adatcsoport létrehozása';
                if (id) {
                    let groups = this.groupList.filter(item => item.Id == id);
                    this.sourceDataGroupFormData.Id = groups[0].Id;
                    this.sourceDataGroupFormData.Name = groups[0].Name;
                    this.sourceDataGroupFormData.Order = groups[0].Order;
                }
                else {
                    this.sourceDataGroupFormData.Id = 0;
                    this.sourceDataGroupFormData.Name = '';
                    let maxOrder = 0;
                    this.groupList.forEach((group) => {
                        if (maxOrder < group.Order) {
                            maxOrder = group.Order;
                        }
                    });
                    this.sourceDataGroupFormData.Order = maxOrder + 1;
                }
                this.$refs.groupForm.instance.resetValues();
                this.$refs['popup'].instance.show();
            },
            hideDataGroupForm() {
                this.$refs['popup'].instance.hide();
                this.sourceDataGroupFormData.Id = 0;
                this.sourceDataGroupFormData.Name = '';
                this.sourceDataGroupFormData.Order = 0;
            },
            showConfirmPopup(id) {

                let groups = this.groupList.filter(item => item.Id == id);
                this.confirmData.Id = groups[0].Id;
                this.confirmData.Name = groups[0].Name;
                this.confirmData.HasTable = groups[0].Tables.length > 0;

                this.$refs['confirmPopup'].instance.show();
            },
            validateForm() {

            },
            getTableById(id) {
                for (var i in this.groupList) {
                    for (var j in this.groupList[i].Tables) {
                        if (this.groupList[i].Tables[j].Id == id) {
                            return this.groupList[i].Tables[j];
                        }
                    }
                }
            },
            showDataTablePalette(dataTableId) {

                var table = this.getTableById(dataTableId);

                if (table && !table.BackgroundColor) {
                    table.BackgroundColor = this.tableListDefaultBgColor;
                }

                this.$refs['cbox-' + dataTableId][0].instance.open();
            },
            async IsSourceDataGroupNameFree(e) {
                var response = await sourceDataService.getDefinitionGroupExists(this.dataContainerType, e.value, this.sourceDataGroupFormData.Id);
                return !response;
            },
            handleSubmit(e) {
                if (this.submitting) {
                    e.preventDefault();
                    return;
                }

                if (this.sourceDataGroupFormData.Id == 0) this.createGroup();
                else this.updateGroup();

                e.preventDefault();
            },
            async getGroups() {
                try {
                    var result = await sourceDataService.getDefinitionGroups(this.dataContainerType);
                    let list = result;
                    list.sort((a, b) => { return a.Order - b.Order });
                    this.groupList = list;
                }
                catch (error) {
                    handleStandardErrorNotification("Nem sikerült az adatcsoportok betöltése.", error);
                }
            },
            async createGroup() {

                this.submitting = true;

                var data = this.sourceDataGroupFormData;
                data.DataContainerType = this.dataContainerType.id;

                try {
                    var result = await sourceDataService.addDefinitionGroup(data);
                    this.onDataGroupListSortableChanged(data);
                    this.hideDataGroupForm();
                    this.getGroups();
                    notifySuccess("Az adatcsoport sikeresen létre lett hozva.");
                }
                catch (error) {
                    handleStandardErrorNotification("Az adatcsoport létrehozása nem sikerült.", error);
                }
                this.submitting = false;
            },
            async updateGroup() {

                this.submitting = true;

                var data = this.sourceDataGroupFormData;

                try {
                    await sourceDataService.updateDefinitionGroup(data);
                    this.onDataGroupListSortableChanged(data);
                    this.hideDataGroupForm();
                    this.getGroups();
                    notifySuccess("Az adatcsoport sikeresen módosítva lett.");
                }
                catch (error) {
                    handleStandardErrorNotification("Nem sikerült az adatcsoport módosítása.", error);
                }
                this.submitting = false;
            },
            async deleteGroup() {

                this.submitting = true;

                var data = this.confirmData.Id;

                try {
                    await sourceDataService.deleteDefinitionGroup(data);
                    this.onDataGroupListSortableChanged(data, true);
                    this.$refs['confirmPopup'].instance.hide();
                    this.getGroups();
                    notifySuccess("Az adatcsoport sikeresen törölve lett.");
                }
                catch (error) {
                    this.$refs['confirmPopup'].instance.hide();
                    handleStandardErrorNotification("Nem sikerült az adatcsoport törlése.", error);
                }
                this.submitting = false;
            },
            onValueChanged(e) {
                this.updateBackgroundColor(e.element.dataset.tableId, e.value);
            },
            colorBoxAttributes(id) {
                return {
                    class: 'color-box',
                    'data-table-id': id
                };
            },
            onDataListGridChanged(e) {
                this.$root.$emit('source_data_grid_changed', e);
            },
            onDataGroupListGridChanged(e) {
                this.$root.$emit('source_data_group_grid_changed', e);
            },
            onDataListSortableInGroupChanged(e) {
                this.$root.$emit('source_data_sortable_in_group_changed', e);
            },
            onDataGroupListSortableChanged(e, updateDataListGrid) {
                this.$root.$emit('source_data_group_sortable_changed', e);

                if (updateDataListGrid) {
                    this.$root.$emit('source_data_sortable_in_group_changed', e);
                }
            },
            onTabItemClick(e) {
                let url = '';

                if (e.itemIndex == 1) {
                    url = dataContainerPathFactory.getDataContainerTablesUrl(this.dataContainerType);
                }
                else if (e.itemIndex == 2) {
                    url = dataContainerPathFactory.getDataContainerGroupsUrl(this.dataContainerType);
                }
                else if (e.itemIndex == 3) {
                    url = dataContainerPathFactory.getDataContainerExternalDataSourcesUrl(this.dataContainerType);
                }
                else {
                    url = dataContainerPathFactory.getDataContainerTypePageUrl(this.dataContainerType);
                }

                this.$router.push(url);
            },
            showExternalSourceLog(externalDataSourceId) {
                this.selectedExternalDataSourceId = externalDataSourceId;
                this.dataExternalSourceLogPopup.show();
            },
            showInputStatuses(externalDataSourceId) {
                this.selectedExternalDataSourceId = externalDataSourceId;
                this.dataInputStatusPopup.show();
            }
        },
        mounted() {
            this.getGroups();
        },
        created() {
            this.selectedTabItemIndex = this.tabItemRoutes.indexOf(this.$root._route.path);

            this.$root.$on('source_data_grid_changed', (data) => {
                this.getGroups();
            });

            this.$root.$on('source_data_group_grid_changed', (data) => {
                this.getGroups();
            });

            this.$root.$on('source_data_sortable_in_group_changed', (data) => {
                let grid = this.dataGrid;
                if (grid) {
                    grid.refresh();
                }
            });

            this.$root.$on('source_data_group_sortable_changed', (data) => {
                let grid = this.dataGroupGrid;
                if (grid) {
                    grid.refresh();
                }
            });
        },
        watch: {
            $route(to, from) {
                if (to != from) {                    
                    let itemRouteIndex = this.tabItemRoutes.indexOf(to.path);
                    if (itemRouteIndex != -1 && this.selectedTabItemIndex != itemRouteIndex) {
                        this.selectedTabItemIndex = itemRouteIndex;
                    }                    
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .new-tables, .sorted-tables {
        display: flex;
    }
    .new-tables {
        height: 170px;
        background-color: #efeeee;
        padding: 5px;
        border-bottom: 1px solid #dddddd;
    }
    .sorted-tables {
        height: calc(100vh - 254px);
        background-color: #ffffff;
    }
    .item {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 240px;
        height: 140px;
        margin: 10px;
        background-color: #ffffff;
        border-top: 1px solid #f0f0f0;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

        span {
            font-size: 16px;
            font-weight: bold;
        }

        .preferences {
            position: absolute;
            top: 0;
            right: 0;
            border: none;
            background-color: transparent;
        }
    }
    .drop-area {
        width: 240px;
        height: 140px;
        margin: 10px;
    }
    ::v-deep .dx-draggable-dragging {
        z-index: 1000;
    }
    .data-group-buttons {
        
    }
    .data-group-list .dx-toolbar {
        background-color: rgba(0, 0, 0, 0);
    }
    .dx-button-mode-contained {
        background-color: rgba(0, 0, 0, 0) !important;
    }
    .toolbar-label {
        cursor: move;
        color: #888888;
        font-weight: bold;
        font-style: italic;
        font-size: 150%;
    }
    .data-group-list {
        margin: 10px 0 80px 0;
    }
    .data-group-list-item {
        min-height: 156px;
        background: #ebebeb;
        border: 1px solid #acacac;
        padding: 0 0 10px 10px;
        margin: 0 0 10px 0;
    }
    .data-group-list-item-header {
        display: flex;
        align-items: center;
    }
    .data-group-list-item-title {
        cursor: move;
        margin: 6px 0 0 0;
        color: #888888;
        font-weight: bold;
        font-style: italic;
        font-size: 150%;
        flex-grow: 4;
    }
    .data-group-list-item-title .dx-icon-dragvertical {
        font-size: 18px;
        line-height: 26px;
        bottom: auto;
        margin: 0px -6px 0px -3px;
        opacity: 0.5;
    }
    .data-group-list-item-title:hover {
        background-color: rgba(255, 255, 255, 0.4);
    }
    .data-group-list-item-title:hover .dx-icon-dragvertical {
        opacity: 0.8;
    }
    .data-group-list-item-buttons {
        flex-grow: 1;
        text-align: right;
    }
    .data-group-list-item-buttons .dx-link-icon {
        display: inline-block;
        width: 18px;
        height: 18px;
        background-position: 0 0;
        background-size: 18px 18px;
        font-size: 18px;
        margin: 0 3px;
    }
    .data-table-list {
        display: flex;
        align-items: left;
        gap: 10px;
        margin: auto;
        flex-wrap: wrap;
        align-items: center;
        padding-top: 10px;
    }
    .data-table-list-item {
        width: 160px;
        height: 120px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        border-radius: 8px;
        position: relative;   
    }
    .data-table-list-item-text {
        margin: auto;
        word-wrap: anywhere;
        font-size: 22px;
        overflow: hidden;
    }
    .btn-data-table-list-item {
        position: absolute;
        background: rgba(0, 0, 0, 0);
        width: 100%;
        height: 94px;
        left: 0;
        bottom: 0;
        border-radius: 0px 0px 8px 8px;
        cursor: pointer;
    }
    .data-table-list-item-buttons {
        text-align: right;
        width: 100%;
        margin: -6px -18px -10px 0;
        position: relative;
    }
    .data-table-list-item-buttons .dx-link-icon {
        display: inline-block;
        width: 16px;
        height: 16px;
        background-position: 0 0;
        background-size: 16px 16px;
        font-size: 16px;
        margin: 0 3px 0 0;
        color: rgba(0, 0, 0, 0.3);
    }
    .data-table-list-item-buttons .dx-link-icon:hover {
        color: rgba(0, 0, 0, 0.8);
    }
    .data-table-handle {
        width: 110px;
        height: 20px;
        text-align: left;
        font-size: 20px;
        position: absolute;
        left: -13px;
        cursor: move;
        opacity: 0.3;
    }
    .data-table-handle:hover {
        opacity: 1;
        background-color: rgba(255, 255, 255, 0.05)
    }
    .confirm-text {
        text-align: center;

        .warn {
            margin-bottom: 6px;
        }
    }
    .color-box {
        opacity: 0;
    }
    .warning-icon {
        font-size: 18px;
        color: #ff0000;
        vertical-align: bottom;
    }
    .success-icon {
        font-size: 18px;
        color: #5cb85c;
        vertical-align: bottom;
    }
    .normal-icon {
        font-size: 18px;
        color: #2d6da3;
        vertical-align: bottom;
    }
    .warning-icon-header {
        font-size: 18px;
        vertical-align: bottom;
    }
    .tabpanel {
        height: calc(100% - 40px);
    }
    .hidden {
        display: none;
    }
</style>