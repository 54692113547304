<template>
    <div style="height:100%;">
        <DxTabPanel style="height: 100%;transition:none;" @titleClick="onItemClick">
            <DxItem title="Szintek" icon="hierarchy">
                <template #default>
                    <Levels :levels.sync="levels"
                            ref="levelComponent"></Levels>
                </template>
            </DxItem>
            <DxItem title="Pozíciók" icon="group">
                <template #default>
                    <Positions :levels="levels"></Positions>
                </template>
            </DxItem>
            <DxItem title="Szervezeti egységek" icon="tags">
                <template #default>
                    <Entities :levels="levels"></Entities>
                </template>
            </DxItem>
            <DxItem title="Szervezeti ábra" icon="hierarchy2" ref="foo">
                <template #default>
                    <ResourceMap :levels="levels" v-if="!resourceMapNeedsRebuild" 
                                 ref="ResourceMapComponent"></ResourceMap>
                    
                </template>
            </DxItem>
        </DxTabPanel>
    </div>
</template>

<script>
    import Positions from "../components/Positions";
    import Levels from "../components/Levels";
    import Entities from "../components/Entities";
    import ResourceMap from "../components/ResourceMap";
    import DxTabPanel, { DxItem } from "devextreme-vue/tab-panel";

    export default {
        name: 'HumanResource',
        components: {
            Positions,
            Levels,
            Entities,
            ResourceMap,
            DxTabPanel,
            DxItem
        },
        data() {
            return {
                levels: null,
                resourceMapNeedsRebuild: false,
                resourceMapTabIndex: 3
            };
        },
        methods: {
            onItemClick(e) {
                if (e.itemIndex == this.resourceMapTabIndex && this.resourceMapNeedsRebuild) {
                    this.resourceMapNeedsRebuild = false;
                }
            }
        },
        mounted() {
            this.$root.$on('refreshLevels', () => {
                this.resourceMapNeedsRebuild = true;
            });
        }
    }
</script>

<style lang="scss" scoped>
    #mapScrollview {
        height: 100%;
    }
    .level:nth-child(even) {
        background-color: #dddddd;
    }
    .col-container {
        display: flex;
        width: 100%;
        min-height: calc(100vh - 86px);
    }
    .col {
        flex: 1;
        padding: 0;
    }
    .col-absolute {
        position: absolute;
        left: 300px;
        height: 100%;
        width: 100%;
    }
    .level {
        min-height: 200px;
    }
    .level:last-child {
        min-height: 0 !important;
        flex: 1;
    }
    .ruler:before {
        content: '';
        display: block;
        width: 20px;
        height: 30px;
        position: absolute;
        margin-top: -7px;
        border: 1px solid #808080;
        border-left: none;
        border-right: none;
        border-image-source: linear-gradient(to right, #000000, rgba(0, 0, 0, 0));
        border-image-slice: 1;
    }
    .grid {
        fill-opacity: 0;
    }
    ::v-deep .dx-diagram {
        border: none !important;
    }
    ::v-deep .dxdi-canvas .page {
        fill-opacity: 0;
    }
    ::v-deep .dx-diagram .dxdi-control .dxdi-canvas {
        background: none !important;
    }
    ::v-deep .dx-scrollable {
        width: 100%;
    }
    ::v-deep .dx-resizable-handle-bottom {
        height: 8px !important;
        width: 80px !important;
        margin-left: 22px;
    }
    ::v-deep .dx-scrollable-content {
        padding-bottom: 0 !important;
    }
    ::v-deep .dx-resizable-handle-bottom::after {
        content: '...';
        font-size: 15px;
        background-color: #f2f2f2;
        z-index: 100000000;
        position: absolute;
        display: block;
        line-height: 0px;
        bottom: 0px;
        padding-bottom: 7px;
        width: 80px;
        text-align: center;
        border: solid 1px #e1e1e1;
        border-radius: 3px 3px 0 0;
        line-height: 0px;
        border-bottom: none;
    }
    ::v-deep .dx-datagrid-header-panel .dx-toolbar {
        margin-top: 6px;
        margin-bottom: 6px;
        padding-right: 8px;
    }
    ::v-deep .dx-datagrid-edit-popup .dx-state-readonly {
        border: none !important;
    }
    .positionid {
        display: block;
        float: left;
        width: 19px;
        text-align: right;
        margin-right: 3px;
    }
    ::v-deep .dx-tag-container.dx-texteditor-input-container {
        padding-bottom: 0 !important;
    }
    ::v-deep .dx-datagrid-content .dx-datagrid-table .dx-row > td,
    .dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
        vertical-align: middle !important;
    }

    ::v-deep .dx-header-row .dx-text-content-alignment-left, .dx-header-row .dx-text-content-alignment-right {
        display: unset;
        max-width: 100%;
    }
    .level {
        padding: 10px;
    }
    .level-title {
        font-size: 22px;
        font-weight: bold;
    }
    ::v-deep .dx-toolbar-text-auto-hide .dx-button .dx-button-content {
        padding: 3px;
    }

    ::v-deep .dx-toolbar-text-auto-hide .dx-button .dx-icon {
        line-height: 20px;
    }
    ::v-deep .dx-toolbar .dx-toolbar-after {
        padding-left: 15px;
        padding-top: 7px;
    }
    ::v-deep .dx-icon-hierarchy2 {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAA8SURBVHjaYvz//z8DNQATA5UACy4JExMTrE49c+YMI01dNGoQYcA4eNMRoXRDSH40+kd0OgIAAAD//wMAg04VHWC0GqEAAAAASUVORK5CYII=);
        background-repeat: no-repeat;
        background-position: 0px 0px;
        margin-top: 5px !important;
    }
</style>