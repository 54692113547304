<template>
    <div class="entityholder">
        <div style="margin: 0 10px;">
            <div class="entity"
                    :style="'margin-top: ' + calculateMarginTop(entity) + 'px'"
                    v-bind:class="{ 'visible-entity': visible, 'hidden-entity': !visible}"
                    :id="'entity-'+entity.id">
                <h3>
                    {{ entity.name }}
                    <span class="entity-leader" v-show="entity.firstLeader && entity.firstLeader.length > 0">
                        {{ entity.firstLeader }}<br />
                        {{ entity.positionName }}
                    </span>
                </h3>
                <div v-if="hasChild(entity)" :id="'anchor-bottom-'+entity.id" class="connector" @click="toggleChildren($event)" :handle-sign="entity.childrenVisible ? '-' : '+'"></div>
            </div>
        </div>
        <resourceMapEntity v-for="(childEntity) in entity.children" v-bind:key="childEntity.id" :entity="childEntity" :visible="entity.childrenVisible"/>
    </div>
</template>

<script>
    export default {
        name: 'ResourceMapEntity',
        components: {
        
        },
        props: {
            entity: {
                type: Object,
            },
            visible: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                connectSettings: {
                    connector: [
                        "Flowchart",
                        {
                            stub: [60, 0],
                            gap: 1,
                            midpoint: 0,
                            cornerRadius: 0,
                            alwaysRespectStubs: true
                        }],
                    detachable: false,
                    paintStyle: {
                        stroke: "#012A4A",
                        strokeWidth: 2
                    },
                    endpointStyle: {
                        radius: 1,
                        stroke: 'transparent',
                        fill: 'transparent'
                    }
                },
                connection: false,
                lastUpdateIndex: 0,
            }
        },
        methods: {
            calculateMarginTop(entity) {
                let entityLevelOrderIndex = entity.levelObject.orderIndex
                let top = entity.parent == 0 ? 50 : 106;

                let parentLevelOrderIndex = entity.parentObject ? entity.parentObject.levelObject.orderIndex : 0;

                if(entity.parentObject) {
                    top += ((entityLevelOrderIndex - parentLevelOrderIndex - 1) * 230)
                }
                else {
                    top += ((entityLevelOrderIndex - parentLevelOrderIndex) * 230)
                }

                return top;
            },
            hasChild(entity) {
                return entity.children.length > 0;
            },
            toggleChildren($event) {

                let scrollView = this.$store.state.resourceMapScrollView;
                let targetElem = $event.target;

                let boundingBefore = targetElem.getBoundingClientRect();
                let scrollOffset = scrollView.scrollOffset();

                if (this.entity.childrenVisible) {
                    this.hideChildren(this.entity);
                } else {
                    this.showChildren(this.entity);
                }

                let boundingAfter = targetElem.getBoundingClientRect();

                let moveLeft = boundingBefore.left - boundingAfter.left;
                let scrollToLeft = moveLeft < 0 
                    ? scrollOffset.left + Math.abs(moveLeft) 
                    : scrollOffset.left - moveLeft

                let moveTop = boundingBefore.top - boundingAfter.top;
                let scrollToTop = moveTop < 0 
                    ? scrollToTop = scrollOffset.top + Math.abs(moveTop) 
                    : scrollOffset.top - moveTop;

                scrollView.scrollTo({
                    left: scrollToLeft > 0 ? scrollToLeft : 0,
                    top: scrollToTop > 0 ? scrollToTop : 0
                });

                this.$store.state.jsPlumb.repaintEverything();
            },
            hideChildren(entity) {
                if(entity.children) {
                    entity.children.forEach((child) => {
                        if(child.visible) {
                            this.hideChildren(child);
                            if(!!child.jsPlumbConnection) {
                                this.$store.state.jsPlumb.hide("entity-" +child.id);
                            }
                            child.visible = false;
                        }
                    });
                }
                entity.childrenVisible = false;
            },
            showChildren(entity) {
                entity.childrenVisible = true;

                if(entity.children) {
                    entity.children.forEach((child) => {
                        if(!child.visible) {
                            child.visible = true;
                            if(!child.jsPlumbConnection) {
                                this.connectEntities(child.parentObject, child)
                            }
                        }
                    });
                    this.$store.state.jsPlumb.show("entity-" +entity.id);
                }
            },
            connectEntities(fromEntity, toEntity) {
                var options = {
                    source: "entity-" + fromEntity.id,
                    target: "entity-" + toEntity.id,
                    anchors: ["Bottom", "Top"],
                    anchor: [0, 0.5, 0, 1, 18, 72, "left_dec_start startpoint"],
                    paintStyle: { fillStyle: 'transparent', lineWidth: 5, },
                    endpoint: ["Dot", { width: 0, height: 0 }],
                    endpointStyle: { fillStyle: '#012A4A' },

                    cornerRadius: 50,
                    connectorStyle: {
                        lineWidth: 3,
                        strokeStyle: "#012A4A"
                    },
                    overlays: [
                        [
                            "Arrow", {
                                width: 7,
                                length: 6,
                                foldback: 1,
                                location: 1,
                                id: "arrow"
                            }
                        ]
                    ]
                }

                toEntity.jsPlumbConnection = this.$store.state.jsPlumb.connect(options, this.connectSettings);
            }
        },
        updated() {
            this.$store.state.jsPlumb.repaintEverything();
        },
        mounted() {
            if(this.entity.parent !== 0 && this.entity.parentObject.mounted && this.entity.visible) {
                this.connectEntities(this.entity.parentObject, this.entity)
            }

            this.entity.children.forEach(child => {
                if(child.visible && child.mounted) {
                    this.connectEntities(this.entity, child)
                }
            })

            this.$store.state.jsPlumb.repaintEverything();
        },
    }
</script>

<style lang="scss" scoped>
    .entityholder {
        float: left;
    }
    .entity {
        position: relative;
        width: 250px;
        height: 125px;
        background-color: #ffffff;
        border: solid 2px #012A4A;
        flex-shrink: 0;
        flex-wrap: wrap;
        border-radius: 5px;
        padding: 15px;
        margin: 70px auto 0;
        text-align: center;

        h3 {
            display: flex;
            width: 100%;
            height: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
    }
    .entity-leader {
        border-top: 1px solid #ccc;
        margin-top: 3px;
        font-weight: normal;
        font-size: 0.83em;
    }
    .hidden-entity {
        display: none;
    }
    .visible-entity {
        display: block;
    }
    .connector {
        position: absolute;
        z-index: 1000;
        bottom: -20px;
        left: 100px;
        width: 50px;
        height: 20px;
        border-radius: 0 0 5px 5px;
        cursor: pointer;
        background-color: #ffffff;
        border-width: 0 2px 2px 2px;
        border-color: #012A4A;
        border-style: solid;
        
        &:before {
            content: attr(handle-sign);
            font-size: 18px;
            font-weight: bold;
            color: #333333;
            text-align: center;
            left: 18px;
            position: absolute;
            top: -3px;
        }
    }
</style>