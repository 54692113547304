export class User {
    // Note: Using only optional constructor properties without backing store disables typescript's type checking for the type
    constructor(id, userName, fullName, email, phoneNumber, isEnabled, isLockedOut, roles, isAdmin) {
        this.id = id;
        this.userName = userName;
        this.fullName = fullName;
        this.email = email;
        this.phoneNumber = phoneNumber;
        this.isEnabled = isEnabled;
        this.isLockedOut = isLockedOut;
        this.roles = roles;
        this.isAdmin = isAdmin;
    }
    get friendlyName() {
        return this.fullName || this.userName;
    }
}
export class InsertUser {
    constructor(userName, fullName, email, phoneNumber, isEnabled, isLockedOut, roles, isAdmin) {
        this.userName = userName;
        this.fullName = fullName;
        this.email = email;
        this.phoneNumber = phoneNumber;
        this.isEnabled = isEnabled;
        this.isLockedOut = isLockedOut;
        this.roles = roles;
        this.isAdmin = isAdmin;
    }
}