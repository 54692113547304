<template>
  <div class="users pa-2">
    <DxDataGrid
        v-if="hasPermission('users.view')"
        :ref="gridRefName"
        :allow-column-reordering="true"
        :data-source="users"
        
        :column-auto-width="true"
        :row-alternation-enabled="true"
        :show-row-lines="true"
        @init-new-row="onInitNewRow"
        @editing-start="onEditingStart"
        @editor-preparing="onEditorPreparing"
    >
      <DxColumnFixing :enabled="true" />
      <DxEditing
          :allow-updating="hasPermission('users.update')"
          :allow-deleting="hasPermission('users.delete')"
          :allow-adding="hasPermission('users.add')"
          mode="popup"
      >
        <DxDataGridEditPopup
            id="popup"
            :show-title="true"
            :drag-enabled="false"
            title-template="getTitle"
        >
        </DxDataGridEditPopup>
        <DxDatagridEditForm :customize-item="customizeItem">
          <DxFormItem :col-count="2" :col-span="2" item-type="group">
            <DxFormItem :col-span="2" data-field="id" />
            <DxFormItem data-field="email" />
            <DxFormItem data-field="userName" />
            <DxFormItem data-field="fullName" />
            <DxFormItem data-field="phoneNumber" />
          </DxFormItem>
          <!-- TODO A permission vizsgálatokat tesztelni kell, valószínűleg több helyen kell vizsgálni -->
          <DxFormItem
              v-if="hasPermission('roles.view')"
              :col-count="2"
              :col-span="2"
              item-type="group"
          >
            <DxFormItem
                :col-span="2"
                data-field="roles"
                editor-type="dxTagBox"
                :editor-options="{
                dataSource: rolesLookup,
                valueExpr: 'name',
                displayExpr: 'description'
              }"
            />
          </DxFormItem>
          <DxFormItem :col-span="2" itemType="empty"></DxFormItem>
          <DxFormItem
              :col-count="2"
              :col-span="2"
              item-type="group"
              name="changePasswordButton"
          >
            <DxButtonItem
                :col-span="2"
                :button-options="changePasswordButtonOptions"
                horizontal-alignment="left"
            ></DxButtonItem>
          </DxFormItem>
          <DxFormItem :col-count="2" :col-span="2" item-type="group">
            <DxFormItem
                data-field="newPassword"
                :editorOptions="{ mode: 'password' }"
            />
            <DxFormItem :col-span="2" itemType="empty"></DxFormItem>
            <DxFormItem
                data-field="confirmPassword"
                :editorOptions="{ mode: 'password' }"
            />
          </DxFormItem>
        </DxDatagridEditForm>
      </DxEditing>
      <DxGroupPanel :visible="true" />
      <DxGrouping :auto-expand-all="true" />
      <DxPaging />
      <DxSearchPanel :visible="true" />
      <DxColumn data-field="id" :visible="false" :allowEditing="false" />
      <DxColumn data-field="userName" :fixed="true">
        <DxRequiredRule />
      </DxColumn>
      <DxColumn data-field="email">
        <DxRequiredRule />
      </DxColumn>
      <DxColumn data-field="newPassword" data-type="password" :visible="false">
        <DxRequiredRule />
      </DxColumn>
      <DxColumn
          data-field="confirmPassword"
          data-type="password"
          :visible="false"
      >
        <DxRequiredRule />
      </DxColumn>
      <DxColumn data-field="fullName" />

      <DxColumn data-field="phoneNumber" />
      <DxColumn
          v-if="hasPermission('roles.view')"
          data-field="roles"
          cell-template="rolesCellTemplate"
          width="200"
      >
      </DxColumn>
      <template #rolesCellTemplate="cell">
        <DxTagBox
            :readOnly="true"
            :dataSource="rolesLookup"
            :value="cell.data.value"
            valueExpr="name"
            displayExpr="description"
            tag-template="tagTemplate"
        >
          <template #tagTemplate="item">
            <div class="dx-tag-content" style="padding: 3px 6px 4px 2px;">
              {{ item.data.description }}
            </div>
          </template>
        </DxTagBox>
      </template>
      <DxColumn data-field="isLockedOut" />
      <DxColumn data-field="isEnabled" />
      <template #getTitle>
        <DxToolbar>
          <DxToolbarItem #default location="center">
            <div class="toolbar-label">
              <b>{{ isNewRow ? "New User" : "Edit User" }}</b>
            </div>
          </DxToolbarItem>

          <DxToolbarItem
              :options="closeButtonOptions"
              location="after"
              widget="dxButton"
          />
        </DxToolbar>
      </template>
    </DxDataGrid>
    <CustomDxPopup
        :ref="changePasswordPopupRefName"
        :drag-enabled="false"
        :show-title="true"
        :onShowing="onShowningChangePasswordPopup"
        title="Change Password"
        container="#app"
    >
      <div>
        <DxForm
            :ref="newPasswordFormRefName"
            :form-data.sync="passwordFormData"
        >
          <DxFormItem
              data-field="newPassword"
              editor-type="dxTextBox"
              :editorOptions="{ mode: 'password' }"
          >
            <DxRequiredRule />
          </DxFormItem>
          <DxFormItem
              data-field="confirmPassword"
              editor-type="dxTextBox"
              :editorOptions="{ mode: 'password' }"
          >
            <DxRequiredRule />
          </DxFormItem>
        </DxForm>
      </div>
      <DxPopupToolbarItem
          :options="newPasswordSaveButtonOptions"
          toolbar="bottom"
          location="after"
          widget="dxButton"
      />
      <DxPopupToolbarItem
          :options="newPasswordCancelButtonOptions"
          toolbar="bottom"
          location="after"
          widget="dxButton"
      />
    </CustomDxPopup>
  </div>
</template>

<script>
import accountService from "../../services/account.service";
import roleService from "../../services/role.service";
import DxDataGrid, {
  DxGrouping,
  DxGroupPanel,
  DxSearchPanel,
  DxPaging,
  DxColumn,
  DxEditing,
  DxLookup,
  DxRequiredRule,
  DxPopup as DxDataGridEditPopup,
  DxPosition,
  DxForm as DxDatagridEditForm,
  DxButton,
  DxColumnFixing
} from "devextreme-vue/data-grid";
import DxTagBox from "devextreme-vue/tag-box";
import DxToolbar, { DxItem as DxToolbarItem } from "devextreme-vue/toolbar";
import CustomStore from "devextreme/data/custom_store";
import DxForm, {
  DxItem as DxFormItem,
  DxButtonItem,
  DxLabel
} from "devextreme-vue/form";
import notify from "devextreme/ui/notify";
import DxPopup, {
  DxToolbarItem as DxPopupToolbarItem
    } from "devextreme-vue/popup";

import CustomDxPopup from "@appfrm/core/components/CustomDxPopup";

export default {
  components: {
    DxGrouping,
    DxGroupPanel,
    DxSearchPanel,
    DxPaging,
    DxColumn,
    DxEditing,
    DxLookup,
    DxTagBox,
    DxRequiredRule,
    DxPopup,
    DxPosition,
    DxDatagridEditForm,
    DxFormItem,
    DxToolbar,
    DxToolbarItem,
    DxButton,
    DxButtonItem,
    DxLabel,
    DxDataGridEditPopup,
    DxForm,
    DxPopupToolbarItem,
    DxColumnFixing,
    DxDataGrid,
    CustomDxPopup
  },
  data() {
    return {
      isNewRow: false,
      gridRefName: 'grid',
      newPasswordFormRefName: 'newPasswordForm',
      changePasswordPopupRefName: 'changePasswordPopup',
      passwordFormData: {
        userId: 0,
        newPassword: null,
        confirmPassword: null
      },
      users: new CustomStore({
        key: "id",
        load: () => accountService.getUsers(),
        insert: async (values) => {
          // TODO Van-e DevExtreme-es megoldás arra, hogy ne postolja?
          values.confirmPassword = undefined; // Nem szükséges postolni a confirmPassword-öt
          try {
            await accountService.insertUser(values);
          }
          catch (error) {
            this.showErrorMessage(error);
          }
        },
        update: async (key, values) => {
          try {
            await accountService.updateUser({ key: key, values: JSON.stringify(values) });
          }
          catch (error) {
            this.showErrorMessage(error);
          }
        },
        remove: key => accountService.removeUser(key)
      }),
      rolesLookup: [],
      closeButtonOptions: {
        icon: "close",
        onClick: () => {
          this.dataGrid.cancelEditData();
        }
      },
      changePasswordButtonOptions: {
        icon: "key",
        text: "Change Password",
        onClick: () => {
          this.changePasswordPopup.show();
        }
      },
      newPasswordSaveButtonOptions: {
        text: "Save",
        onClick: async () => {
          const validation = this.newPasswordForm.validate();
          if (validation.isValid) {
            try {
              await accountService.changePassword(this.passwordFormData);
              this.changePasswordPopup.hide();
            }
            catch (error) {
              this.showErrorMessage(error);
            }
          }            
        }
      },
      newPasswordCancelButtonOptions: {
        text: "Cancel",
        onClick: () => {
          this.changePasswordPopup.hide();
        }
      },
    }
  },
  async created() {
      try {
          this.rolesLookup = await roleService.get();
      }
      catch (error) {
          handleStandardErrorNotification("Nem sikerült a szerepkörök betöltése.", error);
      }
    
  },
  computed: {
    dataGrid: function () {
      return (this.$refs[this.gridRefName]).instance;
    },
    newPasswordForm: function () {
      return (this.$refs[this.newPasswordFormRefName]).instance;
    },
    changePasswordPopup: function () {
      return (this.$refs[this.changePasswordPopupRefName]).instance;
    },
  },
  methods: {
    onShowningChangePasswordPopup() {
      this.initPasswordFields();
    },
    initPasswordFields() {
      this.newPasswordForm.resetValues();
    },
    onEditingStart() {
      this.isNewRow = false;
    },
    hasPermission(permissionValue) {
      return accountService.userHasPermission(permissionValue);
    },
    onEditorPreparing(e) {
      if (e.dataField === "id" && e.parentType === "dataRow") {
        this.passwordFormData.userId = e.value;
      }

      if (e.dataField === "roles" && e.parentType === "dataRow") {
        e.editorName = "dxTagBox"; // Changes the editor's type
        e.editorOptions.onValueChanged = function(args) {
          // Implement your logic here
          e.setValue(args.value); // Updates the cell value
        };
      }
    },
    onInitNewRow() {
      this.isNewRow = true;
    },

    customizeItem(item) {
      if (
          item.dataField === "newPassword" ||
          item.dataField === "confirmPassword"
      ) {
        if (!this.isNewRow) {
          item.visible = false;
        }
      }

      if (item.dataField === "id" || item.name === "changePasswordButton") {
        if (this.isNewRow) {
          item.visible = false;
        }
      }
    },
    showErrorMessage(result) {

            let message = '';

            if (result.errortype == 'application') {

                if (result.data && typeof result.data.errors == "object") {
                    
                    let messages = [];
                    for (let idx in result.data.errors) {
                        if (typeof result.data.errors[idx] == 'object') {

                            if (result.data.errors[idx].length > 0) {
                                messages = messages.concat(result.data.errors[idx]);
                            }
                            else {
                                for (let idx2 in result.data.errors[idx]) {
                                    if (result.data.errors[idx][idx2].length > 0) {
                                        messages = messages.concat(result.data.errors[idx][idx2]);
                                    }
                                    else if (typeof result.data.errors[idx][idx2] == "string") {
                                        messages.push(result.data.errors[idx][idx2]);
                                    }
                                }
                            }
                        }
                        else if (typeof result.data.errors[idx] == "string") {
                            messages.push(result.data.errors[idx]);
                        }
                    }

                    if (messages.length) {
                        message = messages.join(', ');
                    }
                }
            }
            else {
                message = 'Hiba történt a művelet során';
            }

            notify({

                message: message,
                position: {
                    at: "top",
                    offset: "0 40"
                }
            }, "error", 5000);

    }
  },
}
</script>

<style lang="scss" scoped>

.about {
  text-align: center;
}

::v-deep .dx-datagrid-content .dx-datagrid-table .dx-row > td,
.dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
  vertical-align: middle !important;
}
</style>
