import RequestHandler from "@appfrm/core/services/request-handler";
class EntityService {
    constructor() {
        this.path = "entities";
        if (EntityService.instance) {
            return EntityService.instance;
        }
        EntityService.instance = this;
        return this;
    }
    async get(loadOptions) {
        return await RequestHandler.getWithParams(this.path, loadOptions);
    }
    async getById(id) {
        return await RequestHandler.get(this.path, id);
    }
    async insert(data) {
        return await RequestHandler.post(this.path, data);
    }
    async update(data) {
        return RequestHandler.putAsForm(this.path, data);
    }
    async delete(id) {
        return await RequestHandler.delete(this.path, id);
    }

    // SzervezetiEgysegek
    async getSzervezetiEgysegek(loadOptions, levelId, entityId) {
        return await RequestHandler.getWithParams(`${this.path}/GetSzervezetiEgysegek/${levelId}/${entityId}`, loadOptions);
    }
    async getSzervezetiEgysegById(szervezetiEgysegKod) {
        return await RequestHandler.get(`${this.path}/GetSzervezetiEgysegek`, encodeURIComponent(szervezetiEgysegKod));
    }
}
const instance = new EntityService();
export default instance;