<template>
    <div>
        <div class="updateFromMirroredLocalDbPopup">
            <CustomDxPopup :ref="updateFromMirroredLocalDbPopupRefName"
                           :drag-enabled="false"
                           :show-title="true"
                           title="Adatforrás frissítése tükrözött adatbázisból"
                           :onShowing="onUpdateFromMirroredLocalDbPopupShowing"
                           :onHiding="onUpdateFromMirroredLocalDbPopupHiding"
                           container="#app">

                <DxScrollView :show-scrollbar="'onScroll'"
                              :use-native="false">
                    <DxLoadIndicator v-if="!loaded" />


                    <DxForm :form-data="updateStatusData">
                        <DxGroupItem caption="Adatforrás adatai">
                            <DxSimpleItem data-field="Name" :editor-options="{readOnly: true}">
                                <DxLabel text="Adatforrás neve" />
                            </DxSimpleItem>
                        </DxGroupItem>
                    </DxForm>

                    <DxForm :form-data="updateStatusData"
                            :show-validation-summary="true"
                            validation-group="updateExternalDataSourceValidationGroup">
                        
                        <DxGroupItem caption="Frissítés által érintett táblák">
                            <template #default>
                                <div class="source-data-table">
                                    <div class="source-data-table-thead">
                                        <div class="source-data-table-thead-row dx-theme-accent-as-background-color dx-theme-background-color-as-text-color">
                                            <div class="source-data-table-tbody-row-cell source-data-column-warning"><i class="dx-icon-warning warning-icon-header dx-template-wrapper"></i></div>
                                            <div class="source-data-table-thead-row-cell source-data-column-mirroredTableName dx-theme-border-color">Tükrözött tábla neve</div>
                                            <div class="source-data-table-thead-row-cell source-data-column-displayedName dx-theme-border-color">Adatkonténer neve</div>
                                            <div class="source-data-table-thead-row-cell source-data-column-completedAt dx-theme-border-color">Utolsó frissítési kísérlet</div>
                                            <div class="source-data-table-thead-row-cell source-data-column-toBeImported dx-theme-border-color">Státusz</div>
                                            <div class="source-data-table-thead-row-cell source-data-column-isKey dx-theme-border-color">Kiválasztás</div>
                                        </div>
                                    </div>
                                    <div class="source-data-table-tbody">
                                        <div v-for="(updateStatus, index) in updateStatusData.TableStatuses" :key="updateStatus.updateId" class="dx-theme-border-color source-data-table-tbody-row">
                                            <div class="source-data-table-tbody-row-cell source-data-column-warning" v-html="getWarning(updateStatus)"></div>
                                            <div class="source-data-table-tbody-row-cell source-data-column-mirroredTableName">{{updateStatus.TableName}}</div>
                                            <div class="source-data-table-tbody-row-cell source-data-column-displayedName">{{updateStatus.DataContainerDisplayedName}}</div>
                                            <div class="source-data-table-tbody-row-cell source-data-column-completedAt">{{formatDateTime(updateStatus.CompletedAt)}}</div>
                                            <div class="source-data-table-tbody-row-cell source-data-column-status">{{getStatusName(updateStatus.Status)}}</div>
                                            <div class="source-data-table-tbody-row-cell source-data-column-select">
                                                <DxCheckBox v-model="updateStatusDataCheckboxes[index]" :disabled="updateStatusDisabled(updateStatus)">
                                                    <DxValidator validation-group="updateExternalDataSourceValidationGroup" :onInitialized="e => updateStatusValidators[index] = e.component">
                                                        <DxAsyncRule message="Legalább egy frissítendő táblát ki kell választani!"
                                                                     validation-group="updateExternalDataSourceValidationGroup"
                                                                     :validation-callback="e => isAnySelected(e, updateStatus)"
                                                                      />
                                                    </DxValidator>
                                                </DxCheckBox>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </DxGroupItem>

                        <DxGroupItem cssClass="functionButtons">
                            <DxButtonItem alignment="left">
                                <DxButtonOptions text="Mégse"
                                                 type="default"
                                                 styling-mode="contained"
                                                 :on-click="hidePopup"
                                                 :disabled="submitting" />
                            </DxButtonItem>
                            <DxButtonItem alignment="left">
                                <DxButtonOptions text="Táblák frissítése"
                                                 type="default"
                                                 styling-mode="contained"
                                                 validation-group="updateExternalDataSourceValidationGroup"
                                                 :on-click="(e) => updateMirroredLocalDbTables(e)"
                                                 :disabled="submitting" />
                            </DxButtonItem>
                        </DxGroupItem>

                        <DxValidationSummary id="summary" />
                    </DxForm>
                </DxScrollView>
            </CustomDxPopup>
        </div>
    </div>
</template>

<script>
    import { handleStandardErrorNotification } from "@appfrm/core/index";
    import DxPopup, {
        DxToolbarItem as DxPopupToolbarItem
    } from "devextreme-vue/popup";
    import DxButton from 'devextreme-vue/button';
    import { DxScrollView } from 'devextreme-vue/scroll-view';
    import DxDropDownButton, { DxDropDownOptions, DxPosition } from 'devextreme-vue/drop-down-button';
    import CustomDxPopup from "@appfrm/core/components/CustomDxPopup";
    import SourceDataColumnTypeParameters from "../components/SourceDataColumnTypeParameters";
    import sourceDataService, {
        DataContainerApiBasePath,
        ImportValidationErrorLevel
    } from "../services/sourceData.service";
    import { DxLoadIndicator } from 'devextreme-vue/load-indicator';
    import DxTextBox from 'devextreme-vue/text-box';
    import { DxCheckBox } from 'devextreme-vue/check-box';
    import { DxSortable } from 'devextreme-vue/sortable';
    import DxForm, {
        DxGroupItem,
        DxSimpleItem,
        DxItem,
        DxButtonItem,
        DxButtonOptions,
        DxLabel,
        DxRequiredRule,
        DxCompareRule,
        DxRangeRule,
        DxStringLengthRule,
        DxPatternRule,
        DxEmailRule,
        DxAsyncRule,
        DxCustomRule
    } from 'devextreme-vue/form';
    import {
        DxValidator,
    } from 'devextreme-vue/validator';
    import { dataContainerPathFactory, dataContainerTypes, dataContainerTypeCapabilities, dataContainerOperation, mirroredLocalDbUpdateStatuses } from "../data-container"
    import Breadcrumb from "@appfrm/core/components/Breadcrumb";
    import DxSelectBox from "devextreme-vue/select-box";
    import DxValidationSummary from 'devextreme-vue/validation-summary';

    export const SOURCE_DATA_COLUMN_PROCESS_TYPE_NEW = 1;
    export const SOURCE_DATA_COLUMN_PROCESS_TYPE_IMPORTABLE = 2;

    export const ALL_TABLES_TABLE_ID = -1;

    export default {
        name: "UpdateFromMirroredLocalDbPopup",
        components: {
            DxPopup,
            DxPopupToolbarItem,
            DxScrollView,
            CustomDxPopup,
            DxLoadIndicator,
            DxButton,
            DxForm,
            DxGroupItem,
            DxSimpleItem,
            DxItem,
            DxButtonItem,
            DxButtonOptions,
            DxLabel,
            DxRequiredRule,
            DxCompareRule,
            DxRangeRule,
            DxStringLengthRule,
            DxPatternRule,
            DxEmailRule,
            DxAsyncRule,
            DxCustomRule,
            DxTextBox,
            DxCheckBox,
            DxValidator,
            DxSortable,
            Breadcrumb,
            DxSelectBox,
            SourceDataColumnTypeParameters,
            DxValidationSummary,
            ImportValidationErrorLevel
        },
        props: { },
        data() {
            var dataContainerType = dataContainerTypes.getByPath(this.$route.params.dataContainerType);
            var capability = dataContainerTypeCapabilities.find(c => c.dataContainerType.id === dataContainerType.id);

            return {
                updateFromMirroredLocalDbPopupRefName: 'UpdateFromMirroredLocalDbPopup',
                mirroredLocalDbTableExternalDataSourceDefinitionId: "",
                tableId: -1,
                groupId: '',
                updateStatusData: {},
                updateStatusDataCheckboxes: [],
                updateStatusValidators: [],
                dataContainerType: dataContainerType,
                dataContainerOperation: dataContainerOperation,
                mirroredLocalDbUpdateStatuses: mirroredLocalDbUpdateStatuses,
                capability: capability,
                dataContainerApiBasePath: DataContainerApiBasePath,
                loaded: false,
                submitting: false,
                submitValidationResult: [],
                dataContainerId: this.containerId,
                ALL_TABLES_TABLE_ID: ALL_TABLES_TABLE_ID,
                timeoutIsSet: false,
                timerId: null,
                currentTableName: ''
            }
        },
        computed: {
        },
        created() {
        },
        mounted() {
            this.loaded = true;
        },
        watch: {
        },
        methods: {
            formatDateTime(dateTime) {
                if(dateTime === undefined || dateTime === null) return "";
                return (new Date(dateTime)).toISOString().slice(0, 19).replace(/-/g, ".").replace("T", " ").replace(" ", ". ");
            },
            setUpdateInterval() {
                if (!this.timeoutIsSet) {
                    this.timeoutIsSet = true;
                    this.timerId = setInterval(this.handleTimeout, 3000);
                }
            },
            handleTimeout() {
                this.loadData(false);
            },
            updateStatusDisabled(updateStatus) {
                if(updateStatus !== undefined && updateStatus.TableIsLinked) {
                    return ![this.mirroredLocalDbUpdateStatuses.notInitialized.id, this.mirroredLocalDbUpdateStatuses.failed.id, this.mirroredLocalDbUpdateStatuses.succeeded.id].includes(updateStatus.Status);
                } else {
                    return true;
                }
            },
            getWarning(updateStatus) {
                return updateStatus.TableIsLinked ? "" : "<i title=\"Az adatforrás egyetlen forrásadathoz sem kötődik, ezért nem frissíthető.\" class=\"dx-icon-warning warning-icon-header dx-template-wrapper\"></i>";
            },
            getStatusName(statusId) {
                var statusObj = Object.getOwnPropertyNames(this.mirroredLocalDbUpdateStatuses).filter(s => this.mirroredLocalDbUpdateStatuses[s].id !== undefined && this.mirroredLocalDbUpdateStatuses[s].id == statusId);
                if(statusObj.length > 0) {
                    return this.mirroredLocalDbUpdateStatuses[statusObj[0]].name;
                }
                return "N/A";
            },
            onUpdateFromMirroredLocalDbPopupShowing(e) {
                this.loadData(true);
                this.setUpdateInterval();
            },
            loadData(setCheckboxes) {
                this.loaded = false;

                sourceDataService.getMirroredLocalDbUpdateStatus(this.mirroredLocalDbTableExternalDataSourceDefinitionId)
                    .then((response) => {
                        this.updateStatusData = response;
                        if (this.updateStatusData.TableStatuses !== undefined && setCheckboxes) {
                            this.updateStatusData.TableStatuses.forEach((v, i, a) => {
                                this.updateStatusDataCheckboxes[i] = false;
                                if(v.TableIsLinked == true) {
                                    if ([this.mirroredLocalDbUpdateStatuses.notInitialized.id, this.mirroredLocalDbUpdateStatuses.failed.id, this.mirroredLocalDbUpdateStatuses.succeeded.id].includes(v.Status)) {
                                        if (this.currentTableName.length) {
                                            this.updateStatusDataCheckboxes[i] = this.currentTableName == v.TableName;
                                        }
                                        else {
                                            this.updateStatusDataCheckboxes[i] = true;
                                        }                                        
                                    }                                    
                                }
                            });
                        }
                        this.loaded = true;
                    });
            },
            resetData() {
                this.updateStatus = { };
            },
            onUpdateFromMirroredLocalDbPopupHiding(e) {
                this.resetData();
                clearInterval(this.timerId);
                this.timerId = null;
            },
            async updateMirroredLocalDbTables(e) {
                if (!this.submitting) {
                    var validationResult = await e.validationGroup.validate();
                    
                    if (validationResult.status === 'pending') {
                        validationResult = await validationResult.complete;
                    }

                    if (validationResult.isValid) {
                        this.submitting = true;
                        
                        var postData = {
                            ExternalDataSourceDefinitionId: this.updateStatusData.Id,
                            IsActive: this.updateStatusData.IsActive,
                            Name: this.updateStatusData.Name,
                            Tables: this.updateStatusData.TableStatuses
                                .filter((v, i, a) => !this.updateStatusDisabled(this.updateStatusData.TableStatuses[i]) && this.updateStatusDataCheckboxes[i])
                                .map((v) => { return { TableName: v.TableName } }),
                            Type: this.updateStatusData.Type,
                            Version: this.updateStatusData.Version
                        };

                        try {
                            var result = await sourceDataService.postStartExternalDataSourceUpdate(postData);
                            this.submitting = false;
                            this.loadData(false);
                        }
                        catch(error) {
                            this.submitting = false;
                            handleStandardErrorNotification("Nem sikerült a frissítést elindítani.", error);
                        }
                    }
                }
            },
            async isAnySelected(e, updateStatus) {
                var selectedTables = this.updateStatusDataCheckboxes
                    .filter(t => t);

                if (!updateStatus.toBeUpdatedValidationInProgress) {
                    this.updateStatusDataCheckboxes.forEach((v, i, a) => {
                        if (!this.updateStatusData.TableStatuses[i].toBeUpdatedValidationInProgress) {
                            this.updateStatusData.TableStatuses[i].toBeUpdatedValidationInProgress = true;
                            this.updateStatusValidators[i].validate();
                            this.updateStatusData.TableStatuses[i].toBeUpdatedValidationInProgress = false;
                        }
                    })
                    return selectedTables.length > 0;
                }
                else {
                    return true;
                }
            },
            hidePopup() {
                this.instance.hide();
            }
        },
        computed: {
            instance() {
                return this.$refs[this.updateFromMirroredLocalDbPopupRefName].instance;
            },
        }
    };
</script>

<style scoped lang="scss">
    .nav > li > a {
        text-decoration: none;
    }
    .nav > li > a > .img-circle {
        width: 100%;
        max-width: 20px;
        height: auto;
        border-radius: 50%;
        border: 0;
        position: relative;
        bottom: 1px;
        vertical-align:middle
    }
    .nav > li > a > span {
        margin-left: 5px;
        line-height: 24px;
        font-size: 14px;
    }
    ::v-deep .localDropdown {
        .dx-button-content {
            line-height: 21px;
            padding: 12px 18px 7px;
        }
        .dx-button-text {
            color: #ffffff;
        }
        .dx-button-mode-outlined {
            border: 0;
        }
        .dx-icon {
            vertical-align: bottom;
            height: 20px !important;
            width: 28px;
            margin-right: 0 !important;
        }
        .dx-icon.dx-icon-right{
            width: 0;
        }
        .dx-overlay-content {
            left: 10px;
        }
        .dx-button-text {
            display: none;
        }
    }
    ul.navbar-nav li {
        padding-left: 31px !important;
        line-height: 40px;
    }
    .bug-report a {
        color: #fff;
    }
    .bug-report-chooser {
        position: absolute;
        left: -120px;
        top: 40px;
        background-color: #012A4A;
        color: #fff;
    }
    ul.bug-report-chooser {
        list-style: none;
        padding: 0px;
    }
    .bug-report-chooser li {
        padding: 0px 20px;
        list-style: none;
        white-space: nowrap;
    }
    .notificationCount {
        position: relative;
        top: -2px;
        background-color: #ff0000;
        color: #fff;
        font-size: 11px;
        font-weight: bold;
        line-height: 22px;
        padding: 0px 0px;
        -webkit-border-radius: 22px;
        -moz-border-radius: 22px;
        border-radius: 22px;
        display: inline-block;
        width: 22px;
        height: 22px;
        text-align: center;
    }


    .import_errors {
        color: #ff0000;
        font-size: 12px;

        .import_errors_header {
            font-weight: bold;
        }
    }

    .source-data-table {
        border-collapse: collapse;
        display: table;
        width: 100%;
        min-width: 800px;

        .source-data-column-mirroredTableName {
            min-width: 100px;
        }

        .source-data-column-select {
            width: 120px;
            text-align: center;
        }
    }

    .source-data-table-thead {
        display: table-header-group;

        .source-data-table-thead-row {
            display: table-row;

            .source-data-table-thead-row-cell {
                display: table-cell;
                border-style: solid;
                border-width: 1px;
                padding: 7px;
                font-weight: 400;
            }

            .source-data-column-warning {
                width: 36px;
                text-align: center;
            }

        }
    }

    .source-data-table-tbody {
        display: table-row-group;

        .source-data-table-tbody-row {
            display: table-row;
            border-style: solid;
            border-width: 1px;

            .source-data-table-tbody-row-cell {
                display: table-cell;
                vertical-align: middle;
                padding: 3px 7px;
            }

            .source-data-column-warning {
                color: #ff0000;
                width: 36px;
                text-align: center;
            }

            .source-data-column-actions {
                font-size: 18px;
                color: #0c3c60;
                text-align: center;
                text-decoration: none;
                width: 55px;
            }

            .source-data-column-actions a {
                font-size: 18px;
                color: #0c3c60;
                text-decoration: none;
            }
        }
    }

</style>
<style>
    .dx-scrollable.dx-scrollview.dx-widget .dx-scrollable-wrapper {
        border: none;
    }
    .dx-list-item-icon {
        vertical-align: bottom;
        height: 13px !important;
    }
    .dx-popup-content {
        text-align: left !important;
    }
    .functionButtons .dx-layout-manager {
        text-align: right;
    }

    .functionButtons .dx-collection {
        display: inline-block !important;
    }

    .functionButtons .dx-item {
        display: inline-block !important;
        margin-left: 16px;
    }

    .functionButtonsLeft .dx-layout-manager {
        text-align: left;
    }

    .functionButtonsLeft .dx-collection {
        display: inline-block !important;
    }

    .functionButtonsLeft .dx-item {
        display: inline-block !important;
        margin-right: 16px;
    }
</style>
