<template>
    <div>
        <div class="editDataTypePropertyParametersPopup">
            <CustomDxPopup :ref="editDataTypePropertyParametersPopupRefName"
                           :drag-enabled="false"
                           :show-title="true"
                           title="Tulajdonság adatainak módosítása"
                           :onShowing="onEditDataTypePropertyParametersPopupShowing"
                           :onHiding="onEditDataTypePropertyParametersPopupHiding"
                           container="#app"
                           width="80vw"
                           height="80vh">

                <DxScrollView :show-scrollbar="'onScroll'"
                              :use-native="false">
                    <DxLoadIndicator v-if="!loaded" />

                    <DxForm :visible="loaded"
                            :form-data="formData"
                            :show-validation-summary="true"
                            validation-group="formValidationGroup">
                        <DxGroupItem caption="Oszlop adatok">
                            <template #default>
                                <div class="property-table">
                                    <div class="property-table-thead">
                                        <div class="property-table-thead-row dx-theme-accent-as-background-color dx-theme-background-color-as-text-color">
                                            <div class="property-table-thead-row-cell property-column-handle dx-theme-border-color"></div>
                                            <div class="property-table-thead-row-cell property-column-index dx-theme-border-color">#</div>
                                            <div class="property-table-thead-row-cell property-column-columnName dx-theme-border-color">Oszlop neve</div>
                                            <div class="property-table-thead-row-cell property-column-datatype dx-theme-border-color">Oszlop típusa</div>
                                            <div class="property-table-thead-row-cell property-column-parameters dx-theme-border-color">Oszlop paraméterei</div>
                                            <div class="property-table-thead-row-cell property-column-actions dx-theme-border-color">Akciók</div>
                                        </div>
                                    </div>
                                    <DxSortable class="property-table-tbody"
                                                handle=".property-column-handle"
                                                dragTemplate="drag"
                                                @drag-start="onColumnDragStarted"
                                                @reorder="onColumnReorder">
                                        <div v-for="column in formData.columns" :key="column.index" class="dx-theme-border-color property-table-tbody-row">
                                            <div class="property-table-tbody-row-cell property-column-handle"><div class="dx-icon-dragvertical"></div></div>
                                            <div class="property-table-tbody-row-cell property-column-index">{{column.index}}</div>
                                            <div class="property-table-tbody-row-cell property-column-columnName">
                                                <DxTextBox v-model="column.columnName">
                                                    <DxValidator validation-group="formValidationGroup" :onInitialized="e => column.columnNameValidator = e.component">
                                                        <DxAsyncRule :validation-callback="e=>isColumnNameUnique(e, column)"
                                                                     message="Az oszlop neve nem egyedi a táblában" />
                                                        <DxRequiredRule />
                                                        <DxStringLengthRule :max="propertyColumnNameMaxLength" :message="`Az oszlop neve legfeljebb ${propertyColumnNameMaxLength} karaketer hosszú lehet.`" />
                                                    </DxValidator>
                                                </DxTextBox>
                                            </div>
                                            <div class="property-table-tbody-row-cell property-column-datatype">
                                                <DxSelectBox width="120"
                                                             :data-source="getAllowedDataTypes()"
                                                             v-model="column.dataType"
                                                             display-expr="name"
                                                             value-expr="id"
                                                             :on-selection-changed="(e) => columnTypeChanged(e, column.index)">
                                                    <DxValidator validation-group="formValidationGroup">
                                                        <DxRequiredRule />
                                                    </DxValidator>
                                                </DxSelectBox>
                                            </div>
                                            <div class="property-table-tbody-row-cell property-column-parameters">
                                                <PropertyColumnTypeParameters v-model="column.parameters" :columnId="column.index" validationGroup="formValidationGroup" />
                                            </div>
                                            <div class="property-table-tbody-row-cell property-column-actions" v-if="column.processType == consts.SOURCE_DATA_COLUMN_PROCESS_TYPE_NEW">
                                                <a href="#" class="dx-link dx-link-delete dx-icon-trash dx-link-icon" title="Törlés" aria-label="Törlés" @click="(e) => removeColumn(column.index)"></a>
                                            </div>
                                        </div>

                                        <template #drag="{ data }">
                                            <div class="property-table dx-widget">
                                                <div class="property-table-tbody">
                                                    <div class="dx-theme-border-color property-table-tbody-row dx-theme-background-color">
                                                        <div class="property-table-tbody-row-cell property-column-handle"><div class="dx-icon-dragvertical"></div></div>
                                                        <div class="property-table-tbody-row-cell property-column-index">{{data.itemData.index}}</div>
                                                        <div class="property-table-tbody-row-cell property-column-columnName">
                                                            <DxTextBox v-model="data.itemData.columnName">
                                                                <DxValidator validation-group="formValidationGroup">
                                                                    <DxStringLengthRule :max="propertyColumnNameMaxLength" :message="`Az oszlop neve neve legfeljebb ${propertyColumnNameMaxLength} karaketer hosszú lehet.`" />
                                                                </DxValidator>
                                                            </DxTextBox>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </DxSortable>
                                </div>
                            </template>
                        </DxGroupItem>

                        <DxGroupItem cssClass="functionButtonsLeft">
                            <DxButtonItem>
                                <DxButtonOptions :width="270"
                                                 style="margin-top: 11px"
                                                 icon="plus"
                                                 text="Új oszlop hozzáadása"
                                                 type="default"
                                                 styling-mode="outlined"
                                                 :on-click="e => addNewColumn(e)" />
                            </DxButtonItem>
                        </DxGroupItem>

                        <DxGroupItem cssClass="functionButtons">
                            <DxButtonItem alignment="left">
                                <DxButtonOptions text="Mégse"
                                                    type="default"
                                                    styling-mode="contained"
                                                    :on-click="hidePopup"
                                                    :disable="submitting" />
                            </DxButtonItem>
                            <DxButtonItem alignment="left">
                                <DxButtonOptions text="Tulajdonság adatainak mentése"
                                                    type="default"
                                                    styling-mode="contained"
                                                    validation-group="formValidationGroup"
                                                    :on-click="(e) => saveProperty(e)"
                                                    :disable="submitting" />
                            </DxButtonItem>
                        </DxGroupItem>

                        <DxValidationSummary id="summary" />
                    </DxForm>
                    <div class="import_errors" v-show="importErrors">
                        <div class="import_errors_header">A következő hibák történtek az importálás során</div>
                        <div class="import_errors_content" v-html="importErrors" />
                    </div>
                </DxScrollView>
            </CustomDxPopup>
        </div>
    </div>
</template>

<script>
    import EventBus from "@/utils/event-bus";
    import cloneDeep from "clone-deep";
    import DxPopup, {
        DxToolbarItem as DxPopupToolbarItem
    } from "devextreme-vue/popup";
    import DxButton from 'devextreme-vue/button';
    import { DxScrollView } from 'devextreme-vue/scroll-view';
    import DxDropDownButton, { DxDropDownOptions, DxPosition } from 'devextreme-vue/drop-down-button';
    import CustomDxPopup from "@appfrm/core/components/CustomDxPopup";
    import PropertyColumnTypeParameters from "@appfrm/data-container/components/PropertyColumnTypeParameters";
    import sourceDataService, { DataContainerApiBasePath, TABLE_DISPLAYED_NAME_MAX_LENGTH, TABLE_COLUMN_DISPLAYED_NAME_MAX_LENGTH } from "../services/sourceData.service";
    import { DxLoadIndicator } from 'devextreme-vue/load-indicator';
    import DxTextBox from 'devextreme-vue/text-box';
    import { DxCheckBox } from 'devextreme-vue/check-box';
    import { DxSortable } from 'devextreme-vue/sortable';
    import DxForm, {
        DxGroupItem,
        DxSimpleItem,
        DxItem,
        DxButtonItem,
        DxButtonOptions,
        DxLabel,
        DxRequiredRule,
        DxCompareRule,
        DxRangeRule,
        DxStringLengthRule,
        DxPatternRule,
        DxEmailRule,
        DxAsyncRule,
        DxCustomRule
    } from 'devextreme-vue/form';
    import {
        DxValidator,
    } from 'devextreme-vue/validator';
    import { dataContainerPathFactory, dataContainerTypes, dataContainerTypeCapabilities } from "../data-container"
    import Breadcrumb from "@appfrm/core/components/Breadcrumb";
    import DxSelectBox from "devextreme-vue/select-box";
    import notify from 'devextreme/ui/notify';
    import DxValidationSummary from 'devextreme-vue/validation-summary';
    import CustomDxFileUploader from "@appfrm/core/components/CustomDxFileUploader";

    export const SOURCE_DATA_COLUMN_PROCESS_TYPE_NEW = 1;
    export const SOURCE_DATA_COLUMN_PROCESS_TYPE_IMPORTABLE = 2;

    export default {
        name: "EditDataTypePropertyParametersPopup",
        components: {
            DxPopup,
            DxPopupToolbarItem,
            DxScrollView,
            CustomDxPopup,
            DxLoadIndicator,
            DxButton,
            DxForm,
            DxGroupItem,
            DxSimpleItem,
            DxItem,
            DxButtonItem,
            DxButtonOptions,
            DxLabel,
            DxRequiredRule,
            DxCompareRule,
            DxRangeRule,
            DxStringLengthRule,
            DxPatternRule,
            DxEmailRule,
            DxAsyncRule,
            DxCustomRule,
            DxTextBox,
            DxCheckBox,
            DxValidator,
            DxSortable,
            Breadcrumb,
            DxSelectBox,
            PropertyColumnTypeParameters,
            notify,
            DxValidationSummary,
            CustomDxFileUploader
        },
        props: {
            columnId: Number
        },
        data() {
            var dataContainerType = dataContainerTypes.property;

            return {
                editDataTypePropertyParametersPopupRefName: 'editDataTypePropertyParametersPopup',
                groupId: '',
                dataContainerType: dataContainerType,
                dataContainerApiBasePath: DataContainerApiBasePath,
                propertyColumnNameMaxLength: TABLE_COLUMN_DISPLAYED_NAME_MAX_LENGTH,
                loaded: false,
                submitting: false,
                files: [],
                formData: {
                    columns: [],
                },
                submitValidationResult: [],
                importErrors: "",
                hasMoreError: false,
                consts: {
                    SOURCE_DATA_COLUMN_PROCESS_TYPE_NEW: SOURCE_DATA_COLUMN_PROCESS_TYPE_NEW,
                    SOURCE_DATA_COLUMN_PROCESS_TYPE_IMPORTABLE: SOURCE_DATA_COLUMN_PROCESS_TYPE_IMPORTABLE
                },
                propertyParametersDefaults: {
                    Columns: []
                },
                saveClicked: false
            }
        },
        computed: {
        },
        mounted() {
            EventBus.$emit("TRANSFER_PROPERTY_PARAMETERS", this.columnId, this.setFormData);
            this.loaded = true;
        },
        watch: {
        },
        methods: {
            getAllowedDataTypes() {
                var capability = dataContainerTypeCapabilities.find(c=>c.dataContainerType.id === this.dataContainerType.id);
                if(!capability) {
                    return []
                }
                return capability.allowedDataTypes;
            },
            onEditDataTypePropertyParametersPopupShowing(e) {
                this.saveClicked = false;
                this.oldFormData = cloneDeep(this.formData);
            },
            onEditDataTypePropertyParametersPopupHiding(e) {
                if (!this.saveClicked) {
                    this.formData = { ...this.oldFormData };
                }
            },
            getNextColumnIndex() {
                var index = 1;
                if (this.formData.columns.length == 0) return index;
                for (let i = 1; i < this.formData.columns.length + 2; i++) {
                    if ((this.formData.columns.filter(t => t.index == i)).length == 0) {
                        return i;
                    }
                }
            },
            addNewColumn(e) {
                var column = {
                    index: this.getNextColumnIndex(),
                    dataType: "",
                    parameters: {},
                    processType: SOURCE_DATA_COLUMN_PROCESS_TYPE_NEW
                };
                this.formData.columns.push(column);
            },
            setFormData(propertyParameters) {
                if (propertyParameters == null) propertyParameters = this.propertyParametersDefaults;
                this.formData.columns = propertyParameters.Columns
                    .map(
                        (column) => {
                            return {
                                columnName: column.PropertyName,
                                dataType: column.DataType,
                                parameters: column.DataTypeParameters,
                            }
                        });
            },
            removeAllImportableColumns() {
                var importableColumns = this.formData.columns.filter(t => t.processType == SOURCE_DATA_COLUMN_PROCESS_TYPE_IMPORTABLE);

                importableColumns.forEach(function (v, i, a) {
                    this.removeColumn(v.index);
                }, this);
            },
            removeColumn(columnIndex) {
                this.formData.columns = this.formData.columns.filter(function (v, i, a) {
                    if (v.index != columnIndex) return v;
                });
            },
            async saveProperty(e) {
                if (!this.submitting) {
                    var validationResult = e.validationGroup.validate();

                    if (validationResult.status === 'pending') {
                        validationResult = await validationResult.complete;
                    }

                    if (validationResult.isValid) {
                        var propertyParameters = {
                            PropertyParameters: {
                                columns: this.formData.columns
                                    .map(
                                        (column) => {
                                            return {
                                                propertyName: column.columnName,
                                                dataType: column.dataType,
                                                dataTypeParameters: column.parameters,
                                            }
                                        })
                            }
                        }
                        EventBus.$emit("PROPERTY_PARAMETERS_CHANGED", this.columnId, propertyParameters);
                        this.saveClicked = true;
                        this.hidePopup();
                    }
                }
            },
            async IsSourceDataNameFree(e) {
                var response = await sourceDataService.getDefinitionExists(this.dataContainerType, e.value);
                return !response;
            },
            async isColumnNameUnique(e, column) {
                var importableColumns = this.formData.columns.filter(c => c.importable);
                var sameNameColumns = importableColumns.filter(c => c.columnName === column.columnName && c.index !== column.index);
                if (!column.columnNameValidationInProgress) {
                    sameNameColumns.forEach(c => {
                        if (!c.columnNameValidationInProgress) {
                            c.columnNameValidationInProgress = true;
                            c.columnNameValidator.validate()
                            c.columnNameValidationInProgress = false;
                        }
                    })
                }
                return sameNameColumns.length == 0;
            },
            // column reordering methods
            onColumnReorder(e) {
                const newColumns = [...this.formData.columns];

                newColumns.splice(e.fromIndex, 1);
                newColumns.splice(e.toIndex, 0, e.itemData);

                this.formData.columns = newColumns;
            },
            onColumnDragStarted(e) {
                e.itemData = this.formData.columns[e.fromIndex];
            },
            columnTypeChanged(e, columnId) {
                var selectedType = e.selectedItem.id;
                EventBus.$emit("PROPERTY_COLUMN_TYPE_CHANGED", columnId, selectedType);
            },
            hidePopup() {
                this.instance.hide();
            }
        },
        computed: {
            instance() {
                return this.$refs[this.editDataTypePropertyParametersPopupRefName].instance;
            },
        }
    };
</script>

<style scoped lang="scss">
    .nav > li > a {
        text-decoration: none;
    }
    .nav > li > a > .img-circle {
        width: 100%;
        max-width: 20px;
        height: auto;
        border-radius: 50%;
        border: 0;
        position: relative;
        bottom: 1px;
        vertical-align:middle
    }
    .nav > li > a > span {
        margin-left: 5px;
        line-height: 24px;
        font-size: 14px;
    }
    ::v-deep .localDropdown {
        .dx-button-content {
            line-height: 21px;
            padding: 12px 18px 7px;
        }
        .dx-button-text {
            color: #ffffff;
        }
        .dx-button-mode-outlined {
            border: 0;
        }
        .dx-icon {
            vertical-align: bottom;
            height: 20px !important;
            width: 28px;
            margin-right: 0 !important;
        }
        .dx-icon.dx-icon-right{
            width: 0;
        }
        .dx-overlay-content {
            left: 10px;
        }
        .dx-button-text {
            display: none;
        }
    }
    ul.navbar-nav li {
        padding-left: 31px !important;
        line-height: 40px;
    }
    .bug-report a {
        color: #fff;
    }
    .bug-report-chooser {
        position: absolute;
        left: -120px;
        top: 40px;
        background-color: #012A4A;
        color: #fff;
    }
    ul.bug-report-chooser {
        list-style: none;
        padding: 0px;
    }
    .bug-report-chooser li {
        padding: 0px 20px;
        list-style: none;
        white-space: nowrap;
    }
    .notificationCount {
        position: relative;
        top: -2px;
        background-color: #ff0000;
        color: #fff;
        font-size: 11px;
        font-weight: bold;
        line-height: 22px;
        padding: 0px 0px;
        -webkit-border-radius: 22px;
        -moz-border-radius: 22px;
        border-radius: 22px;
        display: inline-block;
        width: 22px;
        height: 22px;
        text-align: center;
    }


    .import_errors {
        color: #ff0000;
        font-size: 12px;

        .import_errors_header {
            font-weight: bold;
        }
    }

    .property-table {
        border-collapse: collapse;
        display: table;

        .property-column-handle {
            width: 37px;
        }

        .property-column-index {
            text-align: center;
            width: 37px;
        }

        .property-column-providerDisplayedName {
            width: 200px;
        }

        .property-column-columnName {
            width: 210px;
        }

        .property-column-parameters {
            width: 210px;
        }
    }

    .property-table-thead {
        display: table-header-group;

        .property-table-thead-row {
            display: table-row;

            .property-table-thead-row-cell {
                display: table-cell;
                border-style: solid;
                border-width: 1px;
                padding: 7px;
                font-weight: 400;
            }
        }
    }

    .property-table-tbody {
        display: table-row-group;

        .property-table-tbody-row {
            display: table-row;
            border-style: solid;
            border-width: 1px;

            .property-table-tbody-row-cell {
                display: table-cell;
                vertical-align: middle;
            }

            .property-table-tbody-row-cell.colspan-2 {
                width: max-content;
                vertical-align: middle;
            }

            .property-column-handle {
                width: 37px;
                text-align: center;
                cursor: move;
            }

            .property-column-index {
                text-align: center;
            }

            .property-column-actions {
                font-size: 18px;
                color: #0c3c60;
                text-align: center;
                text-decoration: none;
            }

            .property-column-actions a {
                font-size: 18px;
                color: #0c3c60;
                text-decoration: none;
            }
        }
    }

</style>
<style>
    .dx-scrollable.dx-scrollview.dx-widget .dx-scrollable-wrapper {
        border: none;
    }
    .dx-list-item-icon {
        vertical-align: bottom;
        height: 13px !important;
    }
    .dx-popup-content {
        text-align: left !important;
    }
    .functionButtons .dx-layout-manager {
        text-align: right;
    }

    .functionButtons .dx-collection {
        display: inline-block !important;
    }

    .functionButtons .dx-item {
        display: inline-block !important;
        margin-left: 16px;
    }

    .functionButtonsLeft .dx-layout-manager {
        text-align: left;
    }

    .functionButtonsLeft .dx-collection {
        display: inline-block !important;
    }

    .functionButtonsLeft .dx-item {
        display: inline-block !important;
        margin-right: 16px;
    }
</style>
