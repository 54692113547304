<template>
    <div class="login-holder">
        <div class="panel">
            <div class="d-flex flex-column login-header h-100">
                <div class="panel-logo text-center justify-center mb-10">
                    <img src="../../../public/img/logo.png" style="max-width:420px;" />
                </div>
                <div class="d-flex mb-15 justify-center text-center">
                    <span class="subtitle">{{config.appTitle}}</span>
                    <span class="subtitle d-none">SMART FACTORY<br> OPERATION CENTER</span>
                </div>
                <div>
                    <DxForm id="form"
                            :on-content-ready="validateForm"
                            :col-count="1"
                            :form-data="formData"
                            @submit="onFormSubmit">
                        <DxSimpleItem cssClass="mb-7">
                            <template #default>
                                <div class="d-flex">
                                    <i class="iconset account_profile" style="font-size:30px;"></i>
                                    <DxTextBox class="ml-5 flex-grow-1"
                                               placeholder="Username"
                                               stylingMode="underlined"
                                               :value.sync="formData.email">
                                        <DxValidator validation-group="customerData">
                                            <DxRequiredRule message="Username is required" />
                                        </DxValidator>
                                    </DxTextBox>
                                </div>
                            </template>
                        </DxSimpleItem>
                        <DxSimpleItem cssClass="mb-15">
                            <template #default>
                                <div class="d-flex">
                                    <i class="iconset lock_password" style="font-size:30px;"></i>
                                    <DxTextBox class="ml-5 flex-grow-1"
                                               mode="password"
                                               placeholder="Password"
                                               stylingMode="underlined"
                                               :value.sync="formData.password">
                                        <DxValidator validation-group="customerData">
                                            <DxRequiredRule message="Password is required!" />
                                        </DxValidator>
                                    </DxTextBox>
                                </div>
                            </template>
                        </DxSimpleItem>
                    </DxForm>
                    <div v-if="showLoginError" class="showError pb-5">Your username or password may be incorrect</div>
                    <div class="bottom-bar d-flex mb-12 ml-1 justify-space-between">
                        <DxCheckBox class="pt-2" :focusStateEnabled="false" text="Remember me" v-model="formData.rememberMe" />
                        <DxButton id="button-login"
                                  :use-submit-behavior="true"
                                  text="Sign In"
                                  type="default"
                                  @click="onFormSubmit" />
                    </div>
                </div>
                <div class="link d-flex justify-center">
                    <div>Forgot password?</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DxTabPanel, { DxItem } from 'devextreme-vue/tab-panel';
import DxCheckBox from 'devextreme-vue/check-box';
import DxTextBox from 'devextreme-vue/text-box';
import DxButton from 'devextreme-vue/button';
import { DxForm, DxSimpleItem } from 'devextreme-vue/form';
import DxValidator, {
  DxRequiredRule,
  DxRangeRule,
    DxEmailRule,
} from 'devextreme-vue/validator';
import EventBus from "@/utils/event-bus";
import { LoginWithPassword } from "@/enums/auth-actions";
import { config } from '@/config.js';

export default {
  name: "login-component",
  components: {
    DxTabPanel,
    DxItem,
    DxCheckBox,
    DxForm,
    DxSimpleItem,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxEmailRule,
    DxButton,
  },
  data() {
    return {
      registration: false,
      formData: { email: null, password: null, rememberMe: true, isTimeout: false },
      config: config
    }
  },
  computed: {
    showLoginError() {
      return this.$store.getters.isLoginErrorGetter;
    }
  },
  methods: {
    validateForm(e) {
      e.component.validate();
    },
    itemRendered(e) {
      setTimeout(() => {
        const widthOfOneTab = 100 / e.component.option("items").length;
        const tabs = e.element.querySelectorAll('.dx-tab');

        for(let i = 0; i < tabs.length; i++) {
          tabs[i].style.width = widthOfOneTab + "%";
          tabs[i].style.display = "inline-block";
        }
      });
    },
    onFormSubmit(e) {
      const data = { 
        username: this.formData.email, 
        password: this.formData.password, 
        rememberMe: this.formData.rememberMe, 
        isTimeout: this.formData.isTimeout
      };
      // this.$store.dispatch('login', data);
      EventBus.$emit("LOADING"); // TODO ??
      this.$store
          .dispatch(LoginWithPassword, data)
          .then(() => {
            this.$router.push("/").then(() => {
              // EventBus.$emit("LOGIN");
              EventBus.$emit("START_LOGOUT_TIMER");
            });
          })
          // .catch(error => {
          //   let errorMessage = null;
          //   if (error.isAxiosError) {
          //     errorMessage = error.message;
          //   } else {
          //     errorMessage = error.response.data.error_description;
          //   }
          //   notify(
          //     {
          //       message: errorMessage,
          //       // message: this.$t("error." + errorMessage),
          //       position: {
          //         at: "top",
          //         offset: "0 40"
          //       }
          //     },
          //     "error",
          //     3000
          //   );
          // })
          .finally(() => EventBus.$emit("DONE-LOADING"));
    },
  }
}
</script>

<style lang="scss" scoped>
  * {
    font-size: 18px;
  }
  .h-100 {
    height: 100%;
  }
  
  .login-box {
    min-height: 750px;
  }

    .link {
        cursor: pointer;
        color: #1473b3;
    }
  
  .nowrap {
    white-space: nowrap;
  }

  ::v-deep .dx-tabs-wrapper {
    display: block;
    
    .dx-tab-selected {
      box-shadow: inset 0 1px 0 0 #ddd !important;
    }

    .dx-tab-selected:first-child {
      box-shadow: inset 1px 1px 0 0 #ddd !important;
    }

    .dx-tab {
      border-right: 1px solid #ddd !important;
    }

    .dx-tab-text {
      font-size: 18px !important;
    }
    
    .dx-state-active {
      background-color: #fff !important;
    }

  }

  ::v-deep .dx-multiview-wrapper {
    .dx-placeholder {
      font-size: 18px !important;
    }
    
    .dx-texteditor-input {
      font-size: 18px !important;
    }
    .dx-checkbox-container {
      display: flex;
    }
    .dx-checkbox-text {
      display: inline;
      margin-left: -4px !important;
      font-size: 18px !important;
      padding-top: 1px !important;
    }
    
  }
    .dx-button-text {
        font-size: 18px !important;
        font-weight: normal;
        text-transform: uppercase !important;
    }

    .dx-button {
        background-color: #1473b3;
        border-radius: 4px;
        box-shadow: #b0b0b0 0px 2px 4px;
    }

    .login-holder {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .panel {
        padding: 0px;
        width: 500px;
        flex: 1;
        background-color: #eef9ff;
        background-image: url("../../../public/img/fust.png") !important;
        background-repeat: no-repeat !important;
        background-position: center 720px !important;
        background-size: auto;
    }

    @media (max-width: 650px) {
        .panel {
            padding: 0;
            width: 100%;
        }
    }
  
    .contact-us-tab {
        height: 550px;
        
        div {
            width: 100%;
            text-align: center;
        }
    }

    .showError {
        color: red;
    }

    .subtitle {
        font-size: 30px;
        color: #1473b3;
        font-style: italic;
    }
    .login-header {
        padding: 32px 40px;
    }
</style>