<template>
    <div>
        <div class="externalDataSourceLogPopup">
            <CustomDxPopup :ref="externalDataSourceLogPopupRefName"
                           :drag-enabled="false"
                           :show-title="true"
                           title="Külső adatforrás import log"
                           :onShowing="onExternalDataSourceLogPopupShowing"
                           :onHiding="onExternalDataSourceLogPopupHiding"
                           container="#app">

                <DxScrollView :show-scrollbar="'onScroll'"
                              :use-native="false">
                    <DxLoadIndicator v-if="!loaded" />
                    <!-- Id, ExternalDataSourceId IExternalDataSourceDefinition.Id-ből, UpdateId, LogType, LogMessage, CreatedAt -->

                    <DxDataGrid :data-source="logDataList"
                                :show-borders="true"
                                :row-alternation-enabled="true"
                                :hover-state-enabled="true"
                                :scrolling="scrolling"
                                height="100%"
                                :ref="dataGridRefName">
                        <DxPaging />
                        <DxSorting mode="single" />
                        <DxFilterRow :visible="true" />
                        <DxGroupPanel :visible="true" />

                        <DxColumn caption="Id" data-field="Id" :visible="false" />
                        <DxColumn caption="StartedAt"
                                  data-field="StartedAt"
                                  data-type="datetime"
                                  :visible="true"
                                  :sort-index="0"
                                  sort-order="desc"
                                  :group-index="0"
                                  width="220px" />
                        <DxColumn v-if="containerId == 0" caption="Tábla" data-field="ExternalDataTableName" data-type="string" :visible="true" />
                        <DxColumn caption="CreatedAt" data-field="CreatedAt" data-type="datetime" :visible="true" width="220px" />
                        <DxColumn caption="LogType"
                                  data-field="LogType"
                                  data-type="number"
                                  width="220px">
                            <DxLookup :data-source="logTypeLookupDS"
                                      display-expr="Name"
                                      value-expr="Id" />
                        </DxColumn>
                        <DxColumn caption="LogMessage" data-field="LogMessage" data-type="string" :visible="true" />
                        <DxColumn caption="ExternalDataSourceId" data-field="ExternalDataSourceId" :visible="false" />
                        <DxColumn caption="UpdateId" data-field="UpdateId" :visible="true" :group-index="1" :allow-sorting="false" />
                    </DxDataGrid>
                </DxScrollView>
                <DxPopupToolbarItem widget="dxButton"
                               toolbar="bottom"
                               location="after"
                               :options="closeButtonOptions" />
            </CustomDxPopup>
        </div>
    </div>
</template>

<script>
    import DxPopup, {
        DxToolbarItem as DxPopupToolbarItem
    } from "devextreme-vue/popup";
    import { DxScrollView } from 'devextreme-vue/scroll-view';
    import CustomDxPopup from "@appfrm/core/components/CustomDxPopup";
    import sourceDataService, {
        ExternalDataSourceLogTypes
    } from "../services/sourceData.service";
    import { DxLoadIndicator } from 'devextreme-vue/load-indicator';
    import DxDataGrid, {
        DxColumn,
        DxEditing,
        DxButton as DxGridButton,
        DxRowDragging,
        DxSorting,
        DxGrouping,
        DxGroupPanel,
        DxSearchPanel,
        DxPaging,
        DxScrolling,
        DxFilterRow,
        DxLookup
    } from "devextreme-vue/data-grid";
    import CustomStore from "devextreme/data/custom_store";
    import Breadcrumb from "@appfrm/core/components/Breadcrumb";
    import notify from 'devextreme/ui/notify';

    export default {
        name: "ExternalDataSourceLogPopup",
        components: {
            DxPopup,
            DxPopupToolbarItem,
            DxScrollView,
            CustomDxPopup,
            DxLoadIndicator,
            Breadcrumb,
            notify,
            DxDataGrid,
            DxGridButton,
            DxColumn,
            DxEditing,
            DxSorting,
            DxGrouping,
            DxGroupPanel,
            DxSearchPanel,
            DxPaging,
            DxScrolling,
            DxFilterRow,
            DxLookup
        },
        props: {
            containerId: {
                type: [Number, String],
                default: 0
            },
            externalDataSourceId: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                externalDataSourceLogPopupRefName: 'externalDataSourceLogPopup',
                logDataList: new CustomStore({
                    key: "Id",
                    load: async () => {

                        var data = [];

                        if (this.loaded) {
                            if (this.containerId != 0) {
                                data = await sourceDataService.getExternalDataSourceLogs(this.containerId);
                            }
                            else if (this.externalDataSourceId != null) {
                                data = await sourceDataService.getExternalDataSourceLogsByDataSourceId(this.externalDataSourceId);
                            }

                            if (data && data.length) {
                                // A szűrés nem működik NULL értékkel
                                data.forEach((row) => {

                                    if (row.LogMessage === null) {
                                        row.LogMessage = '';
                                    }
                                });
                            }
                        }

                        return data;
                    }
                }),

                importId: this.$route.params.importId,
                loaded: false,
                logTypeLookupDS: [
                    { "Id": ExternalDataSourceLogTypes.UpdateStarted, "Name": 'Frissítés elindult' },
                    { "Id": ExternalDataSourceLogTypes.UpdateSuccessfullyCompleted, "Name": 'Sikeres frissítés' },
                    { "Id": ExternalDataSourceLogTypes.UpdateFailed, "Name": 'Frissítés nem sikerült' },
                    { "Id": ExternalDataSourceLogTypes.Error, "Name": 'Hiba' },
                    { "Id": ExternalDataSourceLogTypes.Warning, "Name": 'Figyelmeztetés' }
                ],
                saveClicked: false,
                dataContainerId: this.containerId,
                scrolling: {
                    mode: 'standard'
                },
                dataGridInstance: false,
                dataGridRefName: 'gridLogDataList',
                closeButtonOptions: {
                    text: 'Bezár',
                    onClick: () => {
                        this.hidePopup();
                    },
                },

            }
        },
        methods: {
            onExternalDataSourceLogPopupShowing(e) {
                this.loaded = true;
                this.dataGrid.refresh();
            },
            onExternalDataSourceLogPopupHiding(e) {
                //
            },
            hidePopup() {
                this.instance.hide();
            },
            logTypeCalculateSortValue(data) {
                var value = this.calculateCellValue(data);
                return this.lookup.calculateCellValue(value);
            }
        },
        computed: {
            instance() {
                return this.$refs[this.externalDataSourceLogPopupRefName].instance;
            },
            dataGrid() {
                if (this.dataGridInstance) {
                    return this.dataGridInstance;
                }
                else if (this.$refs[this.dataGridRefName]) {
                    this.dataGridInstance = this.$refs[this.dataGridRefName].instance;
                }
                return this.dataGridInstance;
            }
        }
    };
</script>

<style scoped lang="scss">
    .nav > li > a {
        text-decoration: none;
    }
    .nav > li > a > .img-circle {
        width: 100%;
        max-width: 20px;
        height: auto;
        border-radius: 50%;
        border: 0;
        position: relative;
        bottom: 1px;
        vertical-align:middle
    }
    .nav > li > a > span {
        margin-left: 5px;
        line-height: 24px;
        font-size: 14px;
    }

    .notificationCount {
        position: relative;
        top: -2px;
        background-color: #ff0000;
        color: #fff;
        font-size: 11px;
        font-weight: bold;
        line-height: 22px;
        padding: 0px 0px;
        -webkit-border-radius: 22px;
        -moz-border-radius: 22px;
        border-radius: 22px;
        display: inline-block;
        width: 22px;
        height: 22px;
        text-align: center;
    }

</style>
<style>
    .dx-scrollable.dx-scrollview.dx-widget .dx-scrollable-wrapper {
        border: none;
    }
    .dx-list-item-icon {
        vertical-align: bottom;
        height: 13px !important;
    }
    .dx-popup-content {
        text-align: left !important;
    }
    .functionButtons .dx-layout-manager {
        text-align: right;
    }

    .functionButtons .dx-collection {
        display: inline-block !important;
    }

    .functionButtons .dx-item {
        display: inline-block !important;
        margin-left: 16px;
    }

    .functionButtonsLeft .dx-layout-manager {
        text-align: left;
    }

    .functionButtonsLeft .dx-collection {
        display: inline-block !important;
    }

    .functionButtonsLeft .dx-item {
        display: inline-block !important;
        margin-right: 16px;
    }
</style>
