export class Permission {
    constructor(name, value, groupName, description) {
        this.name = name;
        this.value = value;
        this.groupName = groupName;
        this.description = description;
    }
    static getAllPermissions() {
        return [
            this.viewUsersPermission,
            this.addUsersPermission,
            this.updateUsersPermission,
            this.deleteUsersPermission,
            this.viewRolesPermission,
            this.addRolesPermission,
            this.updateRolesPermission,
            this.deleteRolesPermission,
            this.viewAboutPermission,
            this.serverSettingsPermission,

            this.viewLeadsPermission,
            this.addLeadsPermission,
            this.updateLeadsPermission,
            this.deleteLeadsPermission,

            this.viewProjectsPermission,
            this.addProjectsPermission,
            this.updateProjectsPermission,
            this.deleteProjectsPermission,

            this.viewPhasesPermission,
            this.addPhasesPermission,
            this.updatePhasesPermission,
            this.deletePhasesPermission
        ];
    }
}
Permission.viewUsersPermission = new Permission("View Users", "users.view", "Users", "View Users");
Permission.addUsersPermission = new Permission("Add Users", "users.add", "Users", "Add Users");
Permission.updateUsersPermission = new Permission("Update Users", "users.update", "Users", "Update Users");
Permission.deleteUsersPermission = new Permission("Delete Users", "users.delete", "Users", "Delete Users");
Permission.viewRolesPermission = new Permission("View Roles", "roles.view", "Roles", "View Roles");
Permission.addRolesPermission = new Permission("Add Roles", "roles.add", "Roles", "Add Roles");
Permission.updateRolesPermission = new Permission("Update Roles", "roles.update", "Roles", "Update Roles");
Permission.deleteRolesPermission = new Permission("Delete Roles", "roles.delete", "Roles", "Delete Roles");
Permission.viewAboutPermission = new Permission("View About", "about.view", "About", "View About");
Permission.serverSettingsPermission = new Permission("Server Settings", "serversettings", "Server", "Server Settings");

Permission.viewLeadsPermission = new Permission("View Leads", "leads.view", "Leads", "View Leads");
Permission.addLeadsPermission = new Permission("Add Leads", "leads.add", "Leads", "Add Leads");
Permission.updateLeadsPermission = new Permission("Update Leads", "leads.update", "Leads", "Update Leads");
Permission.deleteLeadsPermission = new Permission("Delete Leads", "leads.delete", "Leads", "Delete Leads");

Permission.viewProjectsPermission = new Permission("View Projects", "projects.view", "Projects", "View Projects");
Permission.addProjectsPermission = new Permission("Add Projects", "projects.add", "Projects", "Add Users");
Permission.updateProjectsPermission = new Permission("Update Projects", "projects.update", "Projects", "Update Projects");
Permission.deleteProjectsPermission = new Permission("Delete Projects", "projects.delete", "Projects", "Delete Projects");

Permission.viewPhasesPermission = new Permission("View Phases", "phases.view", "Phases", "View Phases");
Permission.addPhasesPermission = new Permission("Add Phases", "phases.add", "Phases", "Add Phases");
Permission.updatePhasesPermission = new Permission("Update Phases", "phases.update", "Phases", "Update Phases");
Permission.deletePhasesPermission = new Permission("Delete Phases", "phases.delete", "Phases", "Delete Phases");