<template>
    <div>
        <CustomDxPopup ref="editPopup"
                       title="Adattábla adatainak szerkesztése">
            <DxLoadIndicator v-if="!loaded" />
            
            <DxScrollView class="popup-content-scrollview" width="100%" height="100%" ref="formScrollView">

                <DxForm :visible="loaded"
                        :form-data="formData"
                        :show-validation-summary="true"
                        validation-group="editValidationGroup"
                        class="source-data-form dx-fieldset popup-fieldset">

                    <DxGroupItem caption="Tábla adatai">
                        <DxSimpleItem data-field="sourceDataName">
                            <DxLabel text="Adattábla neve" />
                            <DxRequiredRule message="Nincs megadva a tábla neve" />
                            <DxAsyncRule :validation-callback="validateSubmitErrors"
                                         :message="getSubmitErrorMessage('sourceDataName')" />

                            <DxStringLengthRule :max="sourceDataNameMaxLength" :message="`A adattábla neve legfeljebb ${sourceDataNameMaxLength} karaketer hosszú lehet.`" />
                            <DxAsyncRule :validation-callback="IsSourceDataNameFreeExceptThis"
                                         :ignore-empty-value="true"
                                         message="Már létezik tábla ezzel a névvel." />
                        </DxSimpleItem>
                        <DxSimpleItem data-field="sourceDataDescription">
                            <DxLabel text="Adattábla leírása" />
                        </DxSimpleItem>
                        <DxSimpleItem :visible="dataContainerOperation.hasOperation(dataContainerType, dataContainerOperation.setDataSource)"
                                      data-field="dataSourceType"
                                      editor-type="dxSelectBox"
                                      :editor-options="{searchEnabled: false, dataSource: this.dataSourceTypesDS, displayExpr: 'typeName', valueExpr: 'id', onValueChanged: this.dataSourceTypeChanged }">
                            <DxLabel text="Adatforrás típusa" />
                            <DxRequiredRule />
                        </DxSimpleItem>
                        <DxSimpleItem :visible="tableChooserVisibility()" data-field="localDbTable" :editor-options="{ readOnly: true }">
                            <DxLabel text="Helyi adattábla neve" />
                        </DxSimpleItem>
                        <DxSimpleItem :visible="isExternalDataSource()" data-field="externalSourceDataLabel" :editor-options="{ readOnly: true }">
                            <DxLabel text="Adatforrás neve" />
                        </DxSimpleItem>
                        <DxSimpleItem :visible="isExternalDataSource()" data-field="externalDataTableName" :editor-options="{ readOnly: true }">
                            <DxLabel text="Táblanév" />
                        </DxSimpleItem>
                    </DxGroupItem>
                    <DxGroupItem caption="Oszlop adatok">
                        <template #default>
                            <div class="source-data-table">
                                <div class="source-data-table-thead">
                                    <div class="source-data-table-thead-row dx-theme-accent-as-background-color dx-theme-background-color-as-text-color">
                                        <div class="source-data-table-thead-row-cell source-data-column-handle dx-theme-border-color"></div>
                                        <div class="source-data-table-thead-row-cell source-data-column-providerDisplayedName dx-theme-border-color">Oszlop neve a fájlban</div>
                                        <div class="source-data-table-thead-row-cell source-data-column-isKey dx-theme-border-color">Kulcs oszlop</div>
                                        <div class="source-data-table-thead-row-cell source-data-column-userDisplayedName dx-theme-border-color">Oszlop neve</div>
                                        <div class="source-data-table-thead-row-cell source-data-column-type dx-theme-border-color">Oszlop típusa</div>
                                        <div class="source-data-table-thead-row-cell source-data-column-parameters dx-theme-border-color">Oszlop paraméterei</div>
                                        <div class="source-data-table-thead-row-cell source-data-column-description dx-theme-border-color">Oszlop leírása</div>
                                        <div class="source-data-table-thead-row-cell source-data-column-is-company-id dx-theme-border-color">Cégazonosító?</div>
                                        <div class="source-data-table-thead-row-cell source-data-column-claims-data dx-theme-border-color">Jogosultságok</div>
                                        <div class="source-data-table-thead-row-cell source-data-column-actions dx-theme-border-color">Akciók</div>
                                    </div>
                                </div>
                                <DxSortable class="source-data-table-tbody"
                                            handle=".source-data-column-handle"
                                            dragTemplate="drag"
                                            @drag-start="onColumnDragStarted"
                                            @reorder="onColumnReorder">
                                    <div v-for="column in formData.columns" :key="column.index" class="dx-theme-border-color source-data-table-tbody-row">
                                        <div class="source-data-table-tbody-row-cell source-data-column-handle"><div class="dx-icon-dragvertical"></div></div>
                                        <div class="source-data-table-tbody-row-cell source-data-column-providerDisplayedName">
                                            <DxTextBox v-model="column.providerDisplayedName">
                                                <DxValidator validation-group="editValidationGroup">
                                                    <DxAsyncRule :validation-callback="e=>isColumnNameUnique(e, column)"
                                                                 message="Az oszlop fájlbeli neve nem egyedi a táblában" />
                                                    <DxStringLengthRule :max="sourceDataColumnNameMaxLength" :message="`Az oszlop fájlbeli neve legfeljebb ${sourceDataColumnNameMaxLength} karaketer hosszú lehet.`" />
                                                </DxValidator>
                                            </DxTextBox>
                                        </div>
                                        <div class="source-data-table-tbody-row-cell source-data-column-isKey">
                                            <DxCheckBox v-model="column.isKey">
                                                <DxValidator validation-group="editValidationGroup" :onInitialized="e => column.isKeyValidator = e.component">
                                                    <DxAsyncRule :message="'Az adattábla legfeljebb ' + capability.maxKeyColumnCount + ' darab kulcs oszlopot tartalmazhat.'"
                                                                 validation-group="editValidationGroup"
                                                                 :validation-callback="e => checkMaxKeyColumnsForImport(e, column)" />
                                                    <DxAsyncRule :message="'Az adattáblának minimum ' + capability.minKeyColumnCount + ' darab kulcs oszlopot kell tartalmaznia.'"
                                                                 validation-group="editValidationGroup"
                                                                 :validation-callback="e => checkMinKeyColumnsForImport(e, column)" />
                                                </DxValidator>
                                            </DxCheckBox>
                                        </div>
                                        <div class="source-data-table-tbody-row-cell source-data-column-userDisplayedName">
                                            <DxTextBox v-model="column.userDisplayedName">
                                                <DxValidator validation-group="editValidationGroup">
                                                    <DxAsyncRule :validation-callback="e=>isColumnNameUnique(e, column)"
                                                                 message="Az oszlop neve nem egyedi a táblában" />

                                                    <DxRequiredRule message="Az oszlop neve nem lehet üres" />
                                                    <DxStringLengthRule :max="sourceDataColumnNameMaxLength" :message="`Az oszlop neve legfeljebb ${sourceDataColumnNameMaxLength} karaketer hosszú lehet.`" />
                                                </DxValidator>
                                            </DxTextBox>
                                        </div>
                                        <div class="source-data-table-tbody-row-cell source-data-column-type">
                                            <DxSelectBox width="120"
                                                         :data-source="getAllowedDataTypes()"
                                                         v-model="column.type"
                                                         display-expr="name"
                                                         value-expr="id"
                                                         :read-only="column.processType != consts.SOURCE_DATA_COLUMN_PROCESS_TYPE_NEW"
                                                         :on-selection-changed="(e) => columnTypeChanged(e, column.index)">
                                                <DxValidator validation-group="editValidationGroup">
                                                    <DxRequiredRule />
                                                </DxValidator>
                                            </DxSelectBox>
                                        </div>
                                        <div class="source-data-table-tbody-row-cell source-data-column-parameters">
                                            <SourceDataColumnTypeParameters v-model="column.parameters" :columnId="column.index" :dataSourceType="formData.dataSourceType" validation-group="editValidationGroup" />
                                        </div>
                                        <div class="source-data-table-tbody-row-cell source-data-column-description">
                                            <DxTextBox v-model="column.description" />
                                        </div>
                                        <div class="source-data-table-tbody-row-cell source-data-column-is-company-id">
                                            <DxCheckBox v-model="column.isCompanyId" :disabled="true" />
                                        </div>
                                        <div class="source-data-table-tbody-row-cell source-data-column-claims-data">
                                            <DxCheckBox v-model="column.claimsAreInherited" text="táblától örökölt" />
                                            <div v-show="!column.claimsAreInherited" class="column-claim-select-wrapper">
                                                <div class="dx-field">
                                                    <DxTagBox :items="claims" v-model="column.claims" placeholder="jogosultság kiválasztása" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="source-data-table-tbody-row-cell source-data-column-actions">
                                            <a href="#" class="dx-link dx-link-delete dx-icon-trash dx-link-icon" title="Törlés" aria-label="Törlés" @click="(e) => removeColumn(column.index)"></a>
                                        </div>
                                    </div>

                                    <template #drag="{ data }">
                                        <div class="source-data-table dx-widget">
                                            <div class="source-data-table-tbody">
                                                <div class="dx-theme-border-color source-data-table-tbody-row dx-theme-background-color">
                                                    <div class="source-data-table-tbody-row-cell source-data-column-handle"><div class="dx-icon-dragvertical"></div></div>
                                                    <div class="source-data-table-tbody-row-cell source-data-column-userDisplayedName">
                                                        <DxTextBox v-model="data.itemData.userDisplayedName" />
                                                    </div>
                                                    <div class="source-data-table-tbody-row-cell source-data-column-description">
                                                        <DxTextBox v-model="data.itemData.description" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </DxSortable>
                            </div>
                        </template>
                    </DxGroupItem>

                    <DxGroupItem cssClass="functionButtonsLeft">
                        <DxButtonItem>
                            <DxButtonOptions :width="270"
                                             style="margin-top: 11px"
                                             icon="plus"
                                             text="Új oszlop hozzáadása"
                                             type="default"
                                             styling-mode="outlined"
                                             :on-click="e => addNewColumn(e)" />
                        </DxButtonItem>
                    </DxGroupItem>

                    <DxGroupItem caption="Jogosultságok">
                        <template #default>
                            <div class="dx-fieldset container-claims">
                                <div class="dx-field">
                                    <div class="dx-field-label">Tábla szintű jogosultságok</div>
                                    <div class="dx-field-value">
                                        <DxTagBox :items="claims" placeholder="jogosultság kiválasztása" v-model="formData.dataSourceClaims" />
                                    </div>
                                </div>
                                <div class="dx-field">
                                    <div class="dx-field-label">Cégazonosítót tartalmazó oszlop</div>
                                    <div class="dx-field-value">
                                        <DxSelectBox :show-clear-button="true"
                                                     :data-source="formData.columns"
                                                     :value="selectedCompanyColumnIdValue"
                                                     display-expr="userDisplayedName"
                                                     value-expr="index"
                                                     :on-selection-changed="(e) => companyColumnIdChanged(e)"
                                                     placeholder="oszlop kiválasztása" />
                                    </div>
                                </div>
                            </div>
                        </template>
                    </DxGroupItem>

                    <DxGroupItem cssClass="functionButtons">
                        <DxButtonItem alignment="left">
                            <DxButtonOptions text="Bezár"
                                             type="default"
                                             styling-mode="contained"
                                             :disable="submitting"
                                             :on-click="() => cancel()" />
                        </DxButtonItem>
                        <DxButtonItem alignment="left">
                            <DxButtonOptions text="Mentés"
                                             type="default"
                                             styling-mode="contained"
                                             validation-group="editValidationGroup"
                                             :disable="submitting"
                                             :on-click="(e) => saveDefinition(e)" />
                        </DxButtonItem>
                    </DxGroupItem>

                </DxForm>
            
            </DxScrollView>
        </CustomDxPopup>
    </div>
</template>

<script>
    import { handleStandardErrorNotification } from "@appfrm/core/index";
    import EventBus from "@/utils/event-bus";
    import sourceDataService, {
        DataContainerApiBasePath,
        TABLE_DISPLAYED_NAME_MAX_LENGTH,
        TABLE_COLUMN_DISPLAYED_NAME_MAX_LENGTH,
        DATA_SOURCE_TYPE_NINCS,
        DATA_SOURCE_TYPE_CSV,
        DATA_SOURCE_TYPE_EXCEL,
        DATA_SOURCE_TYPE_LOCAL_DB_TABLE,
        DATA_SOURCE_TYPE_EXTERNAL_DATA_SOURCE
    } from "../services/sourceData.service";
    import SourceDataColumnTypeParameters from "../components/SourceDataColumnTypeParameters";
    import { DxLoadIndicator } from 'devextreme-vue/load-indicator';
    import { DxButton } from 'devextreme-vue/button';
    import DxTextBox from 'devextreme-vue/text-box';
    import { DxCheckBox } from 'devextreme-vue/check-box';
    import { DxSortable } from 'devextreme-vue/sortable';
    import CustomDxPopup from "@appfrm/core/components/CustomDxPopup";
    import { DxScrollView } from 'devextreme-vue/scroll-view';
    import DxForm, {
      DxGroupItem,
      DxSimpleItem,
      DxButtonItem,
      DxButtonOptions,
      DxLabel,
      DxRequiredRule,
      DxCompareRule,
      DxRangeRule,
      DxStringLengthRule,
      DxPatternRule,
      DxEmailRule,
      DxAsyncRule,
      DxCustomRule,
    } from 'devextreme-vue/form';
    import {
      DxValidator,
    } from 'devextreme-vue/validator';
    import { dataContainerTypes, dataContainerTypeCapabilities, dataContainerOperation } from "../data-container"

    import DxSelectBox from "devextreme-vue/select-box";
    import {
        DxPosition,
        DxToolbarItem
    } from 'devextreme-vue/popup';
    import DxTagBox from 'devextreme-vue/tag-box';

    export const SOURCE_DATA_COLUMN_PROCESS_TYPE_NEW = 1;
    export const SOURCE_DATA_COLUMN_PROCESS_TYPE_IMPORTABLE = 2;

    export default {
        name: "SourceDataDefinitionEditPopup",
        props: {
            showTitle: {
                type: Boolean,
                default: true
            },
            containerType: {
                type: Object,
                default: null
            },
            containerId: {
                type: [Number,String],
                default: 0
            }
        },
        components: {
            DxLoadIndicator,
            DxButton,
            DxForm,
            DxGroupItem,
            DxSimpleItem,
            DxButtonItem,
            DxButtonOptions,
            DxLabel,
            DxRequiredRule,
            DxCompareRule,
            DxRangeRule,
            DxStringLengthRule,
            DxPatternRule,
            DxEmailRule,
            DxAsyncRule,
            DxCustomRule,
            DxTextBox,
            DxCheckBox,
            DxValidator,
            DxSortable,
            DxSelectBox,
            SourceDataColumnTypeParameters,
            CustomDxPopup,
            DxScrollView,
            DxPosition,
            DxToolbarItem,
            DxTagBox
        },
        data() {
            var capability = dataContainerTypeCapabilities.find(c => c.dataContainerType.id === this.containerType.id);
            return {
                dataContainerType: this.containerType,
                dataContainerOperation: dataContainerOperation,
                capability: capability,
                dataContainerId: this.containerId,
                sourceDataNameMaxLength: TABLE_DISPLAYED_NAME_MAX_LENGTH,
                sourceDataColumnNameMaxLength: TABLE_COLUMN_DISPLAYED_NAME_MAX_LENGTH,
                loaded: false,
                submitting: false,
                formData: {
                    sourceDataName: "",
                    sourceDataDescription: "",
                    dataSourceType: "",
                    dataSourceClaims: [],
                    localDbTable: "",
                    columns: [],
                    externalDataSourceId: "",
                    externalDataTableName: "",
                    externalSourceDataLabel: ""
                },
                originalDataSourceType: '',
                submitValidationResult: [],
                popupRef: false,
                consts: {
                    SOURCE_DATA_COLUMN_PROCESS_TYPE_NEW: SOURCE_DATA_COLUMN_PROCESS_TYPE_NEW,
                    SOURCE_DATA_COLUMN_PROCESS_TYPE_IMPORTABLE: SOURCE_DATA_COLUMN_PROCESS_TYPE_IMPORTABLE
                },
                claims: [],
                externalSourceDataLabel: '',
                externalDataTableName: '',
                dataSourceTypesList: [
                    { "id": DATA_SOURCE_TYPE_NINCS, "typeName": "Nincs" },
                    { "id": DATA_SOURCE_TYPE_CSV, "typeName": "CSV" },
                    { "id": DATA_SOURCE_TYPE_EXCEL, "typeName": "Excel" },
                    { "id": DATA_SOURCE_TYPE_LOCAL_DB_TABLE, "typeName": "Helyi adatbázis tábla" },
                    { "id": DATA_SOURCE_TYPE_EXTERNAL_DATA_SOURCE, "typeName": "Külső adatforrás" }
                ],
                allowedLocalDbTablesDS: [],
                updatingAllowedLocalDbTables: false
            }
        },
        computed: {
            popup() {
                if (!this.popupRef) {
                    this.popupRef = this.$refs['editPopup'].instance;
                }
                return this.popupRef;
            },
            selectedCompanyColumnIdValue() {
                let columnId = 0;
                this.formData.columns.forEach((column, i, a) => {
                    if (columnId == 0 && column.isCompanyId) {
                        columnId = column.index;
                    }
                });
                return columnId;
            },
            dataSourceTypesDS() {
                if ([DATA_SOURCE_TYPE_NINCS, DATA_SOURCE_TYPE_CSV, DATA_SOURCE_TYPE_EXCEL].indexOf(this.originalDataSourceType) != -1) {
                    return this.dataSourceTypesList.filter((type) => {
                        return [DATA_SOURCE_TYPE_NINCS, DATA_SOURCE_TYPE_CSV, DATA_SOURCE_TYPE_EXCEL].indexOf(type.id) != -1;
                    });
                }
                else if (this.originalDataSourceType == DATA_SOURCE_TYPE_LOCAL_DB_TABLE) {
                    return this.dataSourceTypesList.filter((type) => {
                        return [DATA_SOURCE_TYPE_NINCS, DATA_SOURCE_TYPE_CSV, DATA_SOURCE_TYPE_EXCEL, DATA_SOURCE_TYPE_LOCAL_DB_TABLE].indexOf(type.id) != -1;
                    });
                }
                else if (this.originalDataSourceType == DATA_SOURCE_TYPE_EXTERNAL_DATA_SOURCE) {
                    return this.dataSourceTypesList.filter((type) => {
                        return [DATA_SOURCE_TYPE_NINCS, DATA_SOURCE_TYPE_CSV, DATA_SOURCE_TYPE_EXCEL, DATA_SOURCE_TYPE_EXTERNAL_DATA_SOURCE].indexOf(type.id) != -1;
                    });
                }
            }
        },
        methods: {
            dataSourceTypeChanged(e) {
                if (!this.updatingAllowedLocalDbTables) {
                    this.updatingAllowedLocalDbTables = true;
                    this.getAllowedLocalDbTables();
                }
            },
            tableChooserVisibility() {
                if (this.formData.dataSourceType == DATA_SOURCE_TYPE_LOCAL_DB_TABLE) {
                    return true;
                } else {
                    return false;
                }
            },
            getAllowedDataTypes() {
                var capability = dataContainerTypeCapabilities.find(c=>c.dataContainerType.id === this.dataContainerType.id);
                if(!capability) {
                    return []
                }
                return capability.allowedDataTypes;
            },
            getAllowedLocalDbTables() {
                sourceDataService.getAllowedLocalDbTables()
                    .then((response) => {
                        this.updatingAllowedLocalDbTables = false;
                        var allowedTables = response.map((table, index) => {
                            return { id: table, name: table };
                        });
                        this.allowedLocalDbTablesDS = allowedTables;
                    });
            },
            loadClaims() {
                sourceDataService.getAllClaims()
                    .then((response) => {
                        if (!response || !response.length) {
                            this.claims = [];
                        }
                        else {
                            this.claims = response;
                        }
                    });
            },
            isExternalDataSource() {
                return this.formData.dataSourceType == DATA_SOURCE_TYPE_EXTERNAL_DATA_SOURCE;
            },
            cancel() {
                this.popup.hide();
            },
            async saveDefinition(e) {
                if (!this.submitting) {
                    var validationResult = e.validationGroup.validate();

                    if (validationResult.status === 'pending') {
                        validationResult = await validationResult.complete;
                    }
 
                    if (validationResult.isValid) {

                        var postData = {};

                        for (let propName in this.formData) {
                            if (propName == 'columns') {
                                let columns = [];
                                this.formData[propName].forEach(column => {
                                    let copiedColumn = {};
                                    for (let colPropName in column) {                                        
                                        if (colPropName == 'claims') {

                                            let claims = [];
                                            if (!column['claimsAreInherited'] && column[colPropName].length > 0) {
                                                column[colPropName].forEach((claim, i, a) => {
                                                    claims.push({ ClaimName: claim });
                                                });
                                            }

                                            copiedColumn[colPropName] = claims;
                                        }
                                        else if (colPropName != 'isKeyValidator' && colPropName != 'externalSourceDataLabel') {
                                            copiedColumn[colPropName] = column[colPropName];
                                        }
                                    }
                                    columns.push(copiedColumn);
                                });

                                postData[propName] = columns;
                            }
                            else if (propName == 'dataSourceClaims') {
                                let dsClaims = [];
                                if (this.formData[propName].length > 0) {
                                    this.formData[propName].forEach((claim, i, a) => {
                                        dsClaims.push({ ClaimName: claim });
                                    });
                                }

                                postData['claims'] = dsClaims;
                            }
                            else {
                                postData[propName] = this.formData[propName];
                            }
                        }

                        if (this.originalDataSourceType == DATA_SOURCE_TYPE_EXTERNAL_DATA_SOURCE && this.formData['dataSourceType'] != DATA_SOURCE_TYPE_EXTERNAL_DATA_SOURCE) {
                            postData['externalDataSourceId'] = null;
                            postData['externalDataTableName'] = null;
                        }

                        this.submitting = true;

                        var dataSourceTypeParameters = "";
                        switch (this.formData.dataSourceType) {
                            case DATA_SOURCE_TYPE_EXCEL: 
                                dataSourceTypeParameters = {
                                    ExcelParameters: {
                                        UploadedFileName: this.formData.uploadedFileName 
                                    }
                                };
                            case DATA_SOURCE_TYPE_CSV:
                                dataSourceTypeParameters = {
                                    CsvParameters: {
                                        UploadedFileName: this.formData.uploadedFileName 
                                    }
                                };
                                break;
                            case DATA_SOURCE_TYPE_LOCAL_DB_TABLE:
                                dataSourceTypeParameters = {
                                    LocalDbTableParameters: {
                                        TableName: this.formData.localDbTable
                                    }
                                };
                                break;
                            default:
                                dataSourceTypeParameters = {};
                        }
                        postData.dataSourceTypeParameters = JSON.stringify(dataSourceTypeParameters);

                        try {
                            await sourceDataService.updateDefinition(this.dataContainerId, postData);
                            this.submitting = false;
                            this.$root.$emit('source_data_definition_saved', this.formData);
                            this.popup.hide();
                        }
                        catch (error) {
                            this.submitting = false;
                            handleStandardErrorNotification("Nem sikerült a forrásadatok definíciójának módosítása.", error);
                        }
                    }
                }
            },
            async IsSourceDataNameFreeExceptThis(e) {
                var response = await sourceDataService.getDefinitionExistsExceptThis(this.dataContainerType, e.value, this.dataContainerId);
                return !response;
            },
            async isColumnNameUnique(e, column) {
                var importableColumns = this.formData.columns.filter(c => c.importable);
                return !importableColumns.some(c => c.userDisplayedName === column.userDisplayedName && c.index !== column.index);
            },
            // submit error methods
            async validateSubmitErrors(e) {
                if (e.formItem) {
                    return !this.submitValidationResult.some(v => v.fieldName == e.formItem.dataField)
                }
                return true;
            },
            getSubmitErrorMessage(fieldName) {
                var serverValidation = this.submitValidationResult.find(v => v.fieldName === fieldName);
                return serverValidation ? serverValidation.message : "";
            },
            getNextColumnIndex() {
                var index = 1;
                if (this.formData.columns.length == 0) return index;
                for (let i = 1; i < this.formData.columns.length + 2; i++) {
                    if ((this.formData.columns.filter(t => t.index == i)).length == 0) {
                        return i;
                    }
                }
            },
            addNewColumn(e) {
                var column = {
                    index: this.getNextColumnIndex(),
                    providerDisplayedName: "",
                    userDisplayedName: "",
                    description: "",
                    type: "",
                    parameters: {},
                    processType: SOURCE_DATA_COLUMN_PROCESS_TYPE_NEW,
                    isKey: false,
                    isCompanyId: false,
                    claimsAreInherited: true,
                    claims: []
                };
                this.formData.columns.push(column);
            },
            removeColumn(columnIndex) {
                this.formData.columns = this.formData.columns.filter(function (v, i, a) {
                    if (v.index != columnIndex) return v;
                });
            },
            // column reordering methods
            onColumnReorder(e) {
                const newColumns = [...this.formData.columns];

                newColumns.splice(e.fromIndex, 1);
                newColumns.splice(e.toIndex, 0, e.itemData);

                newColumns.forEach(function (element, index, array) {
                    element.order = index + 1;
                });

                this.formData.columns = newColumns;
            },
            onColumnDragStarted(e) {
                e.itemData = this.formData.columns[e.fromIndex];
            },
            columnTypeChanged(e, columnId) {
                let selectedItemId = e.selectedItem ? e.selectedItem.id : 0;
                EventBus.$emit("SOURCE_DATA_COLUMN_TYPE_CHANGED", columnId, selectedItemId);
            },
            async checkMinKeyColumnsForImport(e, column) {
                var keyColumns = this.formData.columns
                    .filter(column => column.isKey);

                var isValid = true;

                if (!column.isKeyValidationInProgress) {
                    this.formData.columns.forEach(c => {
                        if (!c.isKeyValidationInProgress) {
                            c.isKeyValidationInProgress = true;
                            c.isKeyValidator.validate()
                            c.isKeyValidationInProgress = false;
                        }
                    });

                    isValid = keyColumns.length >= this.capability.minKeyColumnCount;
                }

                return isValid;
            },
            async checkMaxKeyColumnsForImport(e, column) {
                var keyColumns = this.formData.columns
                    .filter(column => column.isKey);

                var isValid = true;

                if (!column.isKeyValidationInProgress) {
                    this.formData.columns.forEach(c => {
                        if (!c.isKeyValidationInProgress) {
                            c.isKeyValidationInProgress = true;
                            c.isKeyValidator.validate()
                            c.isKeyValidationInProgress = false;
                        }
                    });

                    isValid = keyColumns.length <= this.capability.maxKeyColumnCount;
                }

                return isValid;
            },
            show() {
                if (this.popup) {
                    this.loadData();
                    this.popup.show();
                }
            },
            loadData() {

                if (this.claims.length == 0) {
                    this.loadClaims();
                }

                if (this.formData.columns.length > 0) {
                    this.formData.columns = [];
                }

                sourceDataService.getDefinition(this.dataContainerId)
                    .then((response) => {

                        // TODO megoldást találni ennek a transzformációnak a kiváltására
                        let dsClaims = [];
                        if (response.Claims && response.Claims.length > 0) {
                            response.Claims.forEach((claim, i, a) => {
                                dsClaims.push(claim.ClaimName);
                            });
                        }

                        this.formData.sourceDataName = response.DisplayedName;
                        this.formData.sourceDataDescription = response.Description;
                        this.formData.dataSourceType = response.DataSourceType;
                        this.originalDataSourceType = response.DataSourceType;

                        var dataSourceTypeParameters = JSON.parse(response.DataSourceTypeParameters);
                        switch(response.DataSourceType) {
                            case DATA_SOURCE_TYPE_LOCAL_DB_TABLE:
                                if(dataSourceTypeParameters !== undefined && 
                                    dataSourceTypeParameters.LocalDbTableParameters !== undefined &&
                                    dataSourceTypeParameters.LocalDbTableParameters.TableName !== undefined)
                                this.formData.localDbTable = dataSourceTypeParameters.LocalDbTableParameters.TableName;
                                break;
                        }

                        this.formData.externalSourceDataLabel = response.ExternalDataSourceDefinition ? response.ExternalDataSourceDefinition.Name + ' (verzió: ' + response.ExternalDataSourceDefinition.Version + ')' : '';
                        this.formData.externalDataSourceId = response.ExternalDataSourceId;
                        this.formData.externalDataTableName = response.ExternalDataTableName;

                        this.formData.columns = response.Columns.map((column, index) => {

                            // TODO megoldást találni ennek a transzformációnak a kiváltására
                            let claims = [];
                            if (column.Claims && column.Claims.length > 0) {
                                column.Claims.forEach((claim, i, a) => {
                                    claims.push( claim.ClaimName );
                                });
                            }

                            return {
                                index: column.Id,
                                providerDisplayedName: column.ProviderDisplayedName,
                                userDisplayedName: column.DisplayedName,
                                description: column.Description,
                                order: column.Order,
                                type: column.Type,
                                parameters: column.Parameters,
                                isKey: column.IsKey,
                                isCompanyId: column.IsCompanyId,
                                claimsAreInherited: column.ClaimsAreInherited,
                                claims: claims
                            }
                        });
                        this.formData.dataSourceClaims = dsClaims;
                        this.dataContainerType = dataContainerTypes.getById(response.DataContainerType);
                        this.loaded = true;
                    });
            },
            transferPropertyParameters(columnId, callbackFunction) {
                this.formData.columns.every(function (v, i, a) {
                    if (v.index == columnId) {
                        if (v.parameters != undefined && v.parameters.PropertyParameters != undefined) {
                            callbackFunction(v.parameters.PropertyParameters);
                            return false;
                        } else {
                            callbackFunction(null);
                        }
                    } else return true;
                })
            },
            companyColumnIdChanged(e) {
                let selectedIndex = e.selectedItem != null ? e.selectedItem.index : -1;
                this.formData.columns.forEach((column, i, a) => {
                    column.isCompanyId = column.index == selectedIndex ? true : false;
                });
            }
        },
        mounted() {
            
        },
        created() {
            EventBus.$on("TRANSFER_PROPERTY_PARAMETERS", (columnId, callbackFunction) => this.transferPropertyParameters(columnId, callbackFunction));
        }
    }
</script>

<style>
    .functionButtons .dx-layout-manager {
        text-align: right;
    }

    .functionButtons .dx-collection {
        display: inline-block !important;
    }
    
    .functionButtons .dx-item {
        display: inline-block !important;
        margin-left: 16px;
    }
</style>
<style lang="scss" scoped>
    .dx-fieldset.popup-fieldset {
        margin: 0 20px 0;
    }
    .source-data-form {
        padding-top: 6px;
    }
    .source-data-table {
        border-collapse: collapse;
        display: table;
        width: 100%;

        .source-data-column-handle {
            width: 37px;
        }

        .source-data-column-index {
            width: 104px;
        }

        .source-data-column-providerDisplayedName {
            width: 200px;
        }

        .source-data-column-isKey {
            width: 100px;
        }

        .source-data-column-type {
            width: 160px;
        }

        .source-data-column-userDisplayedName {
            width: 200px;
        }

        .source-data-column-description {
            width: 200px;
        }

        .source-data-column-parameters {
            width: 210px;
        }

        .column-claim-select-wrapper {
            margin-top: 2px;
        }
    }
  .source-data-table-thead {
    display: table-header-group;

    .source-data-table-thead-row {
      display: table-row;

      .source-data-table-thead-row-cell  {
        display: table-cell;
        border-style: solid;
        border-width: 1px;
        padding: 7px;
        font-weight: 400;
      }
    }
  }
    .source-data-table-tbody {
        display: table-row-group;

        .source-data-table-tbody-row {
            display: table-row;
            border-style: solid;
            border-width: 1px;

            .source-data-table-tbody-row-cell {
                display: table-cell;
                vertical-align: middle;
            }

            .source-data-column-is-company-id,
            .source-data-column-isKey {
                text-align: center;
            }

            .source-data-column-handle {
                text-align: center;
                cursor: move;
            }

            .source-data-column-index {
                text-align: center;
            }

            .source-data-column-actions {
                font-size: 18px;
                color: #0c3c60;
                text-align: center;
                text-decoration: none;
                width: 64px;
            }

            .source-data-column-actions a {
                font-size: 18px;
                color: #0c3c60;
                text-decoration: none;
            }
        }
    }

    .container-claims .dx-field {
        max-width: 600px;
    }
</style>
