<template>
    <div>
        <aside id="leftsidebar" class="sidebar">
            <div v-tt="'header-sidebar-toggle'" class="toggle-sidebar-holder" @click="toggleSidebar">
                <a href="javascript:void(0);" class="toggle-sidebar">
                    <i class="iconset menu_menu"></i>
                </a>
            </div>
            <div class="navbarlogo-holder">
                <div class="navbarlogocont text-center">
                    <img src="../../public/img/logo.png" alt="EKC ERP" style="width:250px;" />
                </div>
            </div>
            <div class="menu">
                <ul class="list">
                    <li v-for="item in menuitems"
                        :key="item.text"
                        :id="item.text"
                        :title="item.text"
                        :ref="'menu-'+item.text"
                        :class="[menuIsActive(item) ? 'active' : '', item.separator ? 'nav-separator' : '', spinnerActive && item.icon.split(' ').includes('bug_report') ? 'spinner' : '']"
                        v-if="hasPermission(item.permission)"
                        @mouseover="setSubmenuTop(item.text)">

                        <a @click="onClickMenu($event, item)"
                           :class="[item.submenu.length > 0 ? 'menu-toggle' : '', item.toggled ? 'toggled' : '']"
                           :content="item.text">
                            <i :class="`${item.icon}`"></i>
                            <span>{{ item.text }}</span>
                        </a>

                        <submenu :ref="'submenu-'+item.text"
                                 :class="item.toggled ? 'd-block' : ''"
                                 v-if="item.submenu.length > 0"
                                 :parent-text="item.text"
                                 :menuitems="item.submenu" />
                    </li>
                </ul>
            </div>
            <!-- Footer -->
            <!--
    <div class="bug-report">
        <a @click="onClickBugReport($event)">
            <i class="iconset bug_report"></i>
            <span>Hibabejelentés</span>
        </a>
    </div>
            <div class="bug-report" style="bottom: 100px; border: 1px solid #ff0000;">
                <a @click="showTaskPopup()">
                    <i class="iconset bug_report"></i>
                    <span>Hibabejelentés</span>
                </a>
            </div>-->

            <div class="legal">
                <div style="display: flex; justify-content: space-between;">
                    <div class="copyright">
                        &copy; {{ currentYear }} <span>{{ appName }}</span>
                    </div>
                    <div class="version">
                        <span>v</span>{{ currentVersion }}
                    </div>
                </div>
            </div>
            <!-- #Footer -->
            <task-popup ref="task-popup" />
        </aside>
    </div>        
</template>

<script>
    import accountService from "@/services/account.service";
    import Submenu from "./Submenu";
    import VueTippy, { TippyComponent } from "vue-tippy";
    import Vue from "vue";
    import 'tippy.js/dist/tippy.css';
    import TaskPopup from "@appfrm/issue-tracker/components/TaskPopup.vue"
    import { dataContainerPathFactory, dataContainerTypes, dataTransformation } from "@appfrm/data-container";
    
    Vue.use(VueTippy);
    Vue.component("tippy", TippyComponent);

    export default {
        name: "Sidebar",
        components: {
            Submenu,
            VueTippy,
            TaskPopup
        },
        props: {
            activeMenu: {
                type: String,
                default: 'false'
            },
            currentYear: {
                type: String,
                default: 2021
            },
            appName: {
                type: String,
                default: ''
            },
            currentVersion: {
                type: String,
                default: '1.00.000'
            },
            userRoles: {
                type: Function,
                default: null
            }
        },
        data() {
            return {
                menuitems: {
                    default: [],
                    type: Array
                },
                spinnerActive: false,
            };
        },
        computed: {
            drawingPopup: function () {
                return (this.$refs[this.drawingScreenshotPopupRefName]).instance;
            },
        },
        created() {
            this.menuitems = [
                /*{
                    text: 'Dashboard', icon: 'iconset home_home', href: '/', submenu: []
                },*/
                /*{
                    text: 'Humántörzs', icon: 'iconset factory_institution', href: 'javascript:void(0);', toggled: false, submenu: [
                */
                { text: dataContainerTypes.sourceData.menuTitle, icon: dataContainerTypes.sourceData.icon, href: dataContainerPathFactory.getDataContainerTypePageUrl(dataContainerTypes.sourceData), submenu: [], activeHrefs: [] },
                { text: dataContainerTypes.derivedData.menuTitle, icon: dataContainerTypes.derivedData.icon, href: dataContainerPathFactory.getDataContainerTypePageUrl(dataContainerTypes.derivedData), submenu: [], activeHrefs: [] },
                { text: dataContainerTypes.masterData.menuTitle, icon: dataContainerTypes.masterData.icon, href: dataContainerPathFactory.getDataContainerTypePageUrl(dataContainerTypes.masterData), submenu: [], activeHrefs: [] },
                { text: dataContainerTypes.dataService.menuTitle, icon: dataContainerTypes.dataService.icon, href: dataContainerPathFactory.getDataContainerTypePageUrl(dataContainerTypes.dataService), submenu: [], activeHrefs: [] },
                { text: dataTransformation.menuTitle, icon: dataTransformation.icon, href: dataTransformation.path, submenu: [], activeHrefs: [] },
                { text: 'Cégcsoport hierarchia', icon: 'iconset hierarchy', href: '/human-resource', submenu: [], activeHrefs: [] },
                { text: 'Cégcsoport hierarchia (Demo)', icon: 'iconset hierarchy', href: '/Ceghierarchia', submenu: [], activeHrefs: [] },
                /*    ]
                },*/
                {
                    text: 'Administration', icon: 'iconset administration', href: 'javascript:void(0);', toggled: false, submenu: [
                        { text: 'Account', icon: 'iconset account_profile', href: '/account', submenu: [], activeHrefs: [] },
                        { text: 'Users', icon: 'iconset people-icon', href: '/users', permission: 'users.view', submenu: [], activeHrefs: [] },
                        { text: 'Roles', icon: 'iconset terms-and-conditions', href: '/roles', permission: 'roles.view', submenu: [], activeHrefs: [] },
                        { text: 'Server Settings', icon: 'iconset information_information', href: '/server-settings', permission: 'serversettings', submenu: [], activeHrefs: [] },
                        { text: 'Fejlesztési napló', icon: 'iconset system-log', href: '/developmentInfo/changelog', submenu: [], activeHrefs: ['/developmentInfo/bugReports', '/developmentInfo/featureRequests'] }
                    ], activeHrefs: []
                },
                /*{
                    text: 'Sales', icon: 'iconset people-icon', href: 'javascript:void(0);', toggled: false, submenu: [
                        { text: 'Leads', icon: '', href: '/leads', submenu: [] },
                        { text: 'Demo Scheduler', icon: '', href: '/scheduler', submenu: [] },
                    ]
                },
                {
                    text: 'Projects', icon: 'iconset chart-line_graphs', href: '/projects', submenu: []
                },*/
                {
                    text: 'Hibabejelentés', icon: 'iconset bug_report', href: 'javascript:void(0);', submenu: [], activeHrefs: []
                },
                {
                    text: 'Logout', icon: 'iconset logout_log-out', href: '/logout', separator: true, submenu: [], activeHrefs: []
                },
            ]
        },
        mounted() {
            this.$root.$on('show_bugreport_popup', (callbackFunction) => {
                this.onClickBugReport();
            });
        },
        methods: {
            toggleSidebar() {
                let body = document.getElementsByTagName('body')[0];
                body.classList.toggle('ls-closed');

                // Close submenus
                this.menuitems.forEach((item) => {
                    item.toggled = false;
                });

                // Main menu background color
                let whichMenuActive = null;
                this.menuitems.forEach(item => {
                    let submenuIsActive = item.submenu.some(item => {
                        return this.menuIsActive(item);
                    });
                    let menuIsActive = this.menuIsActive(item);

                    if (submenuIsActive || menuIsActive)
                        whichMenuActive = item.text;
                });

                if (body.classList.contains('ls-closed')) {
                    document.getElementById(whichMenuActive).classList.add('active');
                }
            },
            menuIsActive(item) {
                return this.$route.path.startsWith(item.href) || item.activeHrefs.indexOf(this.$route.path) != -1;
            },
            hasPermission(permissionValue) {
                if (!permissionValue)
                    return true;

                return accountService.userHasPermission(permissionValue);
            },
            showTaskPopup() {
                this.$refs['task-popup'].showTaskPopup();
            },
            setSubmenuTop: function (menu) {
                let top = this.$refs['menu-' + menu][0].getBoundingClientRect().top - 40;

                if (this.$refs.hasOwnProperty('submenu-' + menu)) {
                    let $ref = this.$refs['submenu-' + menu][0];
                    $ref.$el.style.top = top + 'px';
                }
            },
            onClickMenu(event, item) {

                var icons = item.icon.split(" ");
                if (icons.includes('bug_report')) {
                    // A felhasználó épp megnyomta a Hibabejelentés főmenüt.
                    this.onClickBugReport(event, item);
                    return;
                }

                if (item.submenu.length == 0) {
                    if (item.href == '/logout')
                        this.$emit('logout');
                    else
                        this.$router.push(item.href);
                    return;
                }

                if (!document.querySelector('body').classList.contains('ls-closed'))
                    item.toggled = !item.toggled;

                // Main menu background color
                let submenuIsActive = item.submenu.some(item => {
                    return this.menuIsActive(item);
                });

                let menu = document.getElementById(item.text);
                if (submenuIsActive && menu.firstChild.classList.contains('toggled')) {
                    menu.classList.add('active');
                } else {
                    menu.classList.remove('active');
                }
            },
            onScreenshotReturned(dataUrl) {
                ;
            },
            onScreenshotCreated() {
                this.spinnerActive = false;
                this.$root.$emit('deactivate_bug_spinner');
                this.$root.$emit('get_latest_screenshot', this.onScreenshotReturned);
            },
            onClickBugReport(event, item) {
                this.spinnerActive = true;
                this.$root.$emit('activate_bug_spinner');
                this.$root.$emit('make_screenshot', 'app', this.onScreenshotCreated);
            },
        },
    };
</script>

<style>
    .tippy-content {
        font-family: Roboto;
    }

    li.spinner, li.spinner:hover a {
        background-position: 10px 5px;
        background-size: 30px 30px;
        background-image: url("/img/icons/spinner.gif") !important;
        background-color: rgba(255, 255, 255, 0) !important;
        transition: none !important;
    }
</style>