<template>
    <div style="height:100%;">
        <Breadcrumb :items="breadcrumbItems" id="breadcrumb">
            <template #toolbar>
                <DxButton icon="preferences"
                          type="default"
                          styling-mode="outlined"
                          @click="editTransformation"
                          width="24px"
                          height="24px"
                          hint="Transzformáció szerkesztése" />
            </template>
        </Breadcrumb>
        <DxTabPanel class="tabpanel" :animation-enabled="false" :selected-index="selectedTabItemIndex">
            <DxTabItem title="Transzformáció" icon="smalliconslayout">
                <template #default>
                    <div class="px-2">
                        <div class="container-fluid transformation-step-container">
                            <div class="toolbar-row row">
                                <div class="col-12">
                                    <DxToolbar>
                                        <DxItem
                                            :options="addSelectColumnsTransformationButtonOptions"
                                            location="before"
                                            widget="dxButton"
                                            :disabled="errorInTransactionStep"
                                        />
                                        <DxItem
                                            :options="addGroupByTransformationButtonOptions"
                                            location="before"
                                            widget="dxButton"
                                            :disabled="errorInTransactionStep"
                                        />
                                    </DxToolbar>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-9">
                                    <div class="grid">
                                        <DxLoadIndicator v-show="!transformationInitialized"
                                                        :height="60"
                                                        :width="60" />
                                    </div>

                                    <DxDataGrid :visible="transformationInitialized && !errorInTransactionStep"
                                        :data-source="transformationResultRows"
                                        :show-borders="true"
                                        :row-alternation-enabled="true"
                                        :remote-operations="false"
                                        :hover-state-enabled="true"
                                        :columns="columns"
                                        :filter-row="gridConfig.filterRow"
                                        :editing="gridConfig.editing"
                                        :ref="dataGridRefName"
                                        :sorting="gridConfig.sorting"
                                        :scrolling="gridConfig.scrolling"
                                        :height="gridConfig.height"
                                        :allow-column-resizing="gridConfig.allowColumnResizing"
                                        :column-resizing-mode="gridConfig.columnResizingMode"
                                        @cell-prepared="onCellPrepared"
                                        @contentReady="onContentReady">
                                    </DxDataGrid>

                                    <div class="error-message" v-if="errorInTransactionStep" v-html="errorInTransactionStepMessage">
                                    </div>

                                </div>
                                <div class="col-3">
                                    <div class="steps">
                                        <DxDataGrid :data-source="dataTransformationSteps"
                                        :show-borders="true"
                                        :row-alternation-enabled="true"
                                        :remote-operations="false"
                                        :hover-state-enabled="true"
                                        :onRowInserted="onDataListGridChanged"
                                        :onRowUpdated="onDataListGridChanged"
                                        :onRowRemoved="onDataListGridChanged"
                                        :allow-column-reordering="false"
                                        :selection="{ mode: 'single' }"
                                        ref="gridTransformationStepList"
                                        height="100%"
                                        :scrolling="scrolling"
                                        @row-click="transformationResultRowClicked"
                                        @row-removing="onTransformationStepRemoving"
                                        >
                                            <DxEditing :allow-updating="true"
                                                    :allow-deleting="true"
                                                    mode="cell" />
                                            <DxPaging />
                                            <DxSorting mode="multiple" />
                                            <DxFilterRow :visible="false" />
                                            <DxRowDragging :allow-reordering="false"
                                                        :allow-updating="false"
                                                        :allow-sorting="false"
                                                        :on-reorder="onTableGridReorder" />

                                            <DxColumn caption="Lépés" data-field="StepType" :allowEditing="false">
                                                <DxLookup :data-source="dataTransformationStepTypes"
                                                        display-expr="name"
                                                        value-expr="id" />
                                            </DxColumn>
                                            <DxColumn caption="Akciók"
                                                    sortOrder="0"
                                                    :allow-sorting="false"
                                                    :allow-reordering="false"
                                                    :min-width="60"
                                                    :width="100"
                                                    type="buttons">
                                                <DxGridButton template="viewButtonTemplate" />
                                                <DxGridButton name="delete"
                                                            hint="Törlés"
                                                            icon="trash"
                                                            :visible="(e) => isDeleteButtonVisible(e)" />
                                            </DxColumn>

                                            <template #viewButtonTemplate="{ data }">
                                                <a @click="showTransformationStepPopup(data.data)" class="dx-link dx-icon-edit dx-link-icon" title="Megtekintés, szerkesztés" aria-label="Megtekintés, szerkesztés"></a>
                                            </template>
                                            <template #validColumnTemplate="{ data }">
                                                <div v-if="!data.data.sourceDataGroupId"
                                                    class="warning-icon-holder">
                                                    <i class="dx-icon-warning warning-icon" title="Az adattáblához nincsen adatcsoport hozzárendelve!"></i>
                                                </div>
                                            </template>
                                            <template #validColumnHeader="{ data }">
                                                <i class="dx-icon-warning warning-icon-header"></i>
                                            </template>

                                            <template #title-header="{ data }">
                                                <div class="d-flex">
                                                    <div class="flex-1"></div>
                                                    <div class="flex-1">{{ data.column.caption }}</div>
                                                    <div class="flex-1 text-align-end">
                                                        <div v-if="gridSourceDataListColumnsConfig[data.column.dataField] ? gridSourceDataListColumnsConfig[data.column.dataField].sortOrder : undefined">
                                                            <i v-if="gridSourceDataListColumnsConfig[data.column.dataField].sortOrder === 'asc'" class="dx-icon dx-icon-sortup mb-0" />
                                                            <i v-else class="dx-icon dx-icon-sortdown mb-0" />
                                                            <span>{{ gridSourceDataListColumnsConfig[data.column.dataField].sortIndex + 1 }}</span>
                                                        </div>
                                                        <div v-else>
                                                            <i class="w-8-px dx-icon dx-icon-sortup mb-0" />
                                                            <i class="dx-icon dx-icon-sortdown mb-0" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </DxDataGrid>                                                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </DxTabItem>
        </DxTabPanel>
        <EditDataTransformationPopup 
                    :ref="editDataTransformationPopupRefName" 
                    :transformationId="dataTransformationId" />
        <EditDataTransformationStepSourcePopup :ref="editDataTransformationStepSourcePopupRefName" />
        <EditDataTransformationStepSelectColumnsPopup :ref="editDataTransformationStepSelectColumnsPopupRefName" />
        <EditDataTransformationStepGroupByPopup :ref="editDataTransformationStepGroupByPopupRefName" />
    </div>
</template>

<script>
    import { handleStandardErrorNotification } from "@appfrm/core/index";
    import DxDataGrid, {
        DxColumn,
        DxEditing,
        DxButton as DxGridButton,
        DxRowDragging,
        DxSorting,
        DxGrouping,
        DxGroupPanel,
        DxSearchPanel,
        DxPaging,
        DxScrolling,
        DxFilterRow,
        DxLookup
    } from "devextreme-vue/data-grid";
    import sourceDataService, { DataContainerApiBasePath, DATA_SOURCE_TYPE_NINCS, DATA_SOURCE_TYPE_CSV, DATA_SOURCE_TYPE_EXCEL, DATA_SOURCE_TYPE_LOCAL_DB_TABLE } from "../services/sourceData.service";
    import coreService from "@appfrm/core/services/core.service";
    import { SourceDataColumnNames } from "../enums/SourceDataColumnNames";
    import { HistoryRecordTypes } from "../enums/HistoryRecordTypes";
    import CustomStore from "devextreme/data/custom_store";
    import DxTabPanel, { DxItem as DxTabItem } from "devextreme-vue/tab-panel";
    import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
    import Breadcrumb from "@appfrm/core/components/Breadcrumb";
    import EditDataTransformationPopup from "@appfrm/data-container/components/EditDataTransformationPopup";
    import EditDataTransformationStepSourcePopup from "@appfrm/data-container/components/EditDataTransformationStepSourcePopup";
    import EditDataTransformationStepSelectColumnsPopup from "@appfrm/data-container/components/EditDataTransformationStepSelectColumnsPopup";
    import EditDataTransformationStepGroupByPopup from "@appfrm/data-container/components/EditDataTransformationStepGroupByPopup";
    import { DxScrollView } from 'devextreme-vue/scroll-view';
    import DxButton from 'devextreme-vue/button';
    import { dataContainerPathFactory, dataTransformation, dataContainerDataType, dataTransformationStepTypes } from "../data-container";
    import { DxLoadIndicator } from 'devextreme-vue/load-indicator';
    import copy from 'copy-text-to-clipboard';

    export default {
        name: "TransformationDetails",
        components: {
            DxDataGrid,
            DxColumn,
            DxGridButton,
            DxRowDragging,
            DxSorting,
            DxGrouping,
            DxGroupPanel,
            DxSearchPanel,
            DxPaging,
            DxScrolling,
            DxFilterRow,
            DxLookup,
            DxEditing,
            DxTabPanel,
            DxTabItem,
            SourceDataColumnNames,
            EditDataTransformationPopup,
            EditDataTransformationStepSourcePopup,
            EditDataTransformationStepSelectColumnsPopup,
            EditDataTransformationStepGroupByPopup,
            HistoryRecordTypes,
            Breadcrumb,
            DxScrollView,
            DxButton,
            DxLoadIndicator,
            DxToolbar,
            DxItem
        },
        data() {
            return {
                selectedTabItemIndex: 0,
                dataTransformation: null,
                dataTransformationId: this.$route.params.dataTransformationId,
                dataTransformationSteps: null,
                dataTransformationStepTypes: dataTransformationStepTypes,
                dataContainerDataType: dataContainerDataType,
                breadcrumbItems: [
                        {
                            icon:  dataTransformation.icon,
                            text: dataTransformation.menuTitle,
                            title: `${dataTransformation.menuTitle} kezdőképernyő`,
                            click: () => {
                                this.$router.push(dataTransformation.path);
                            }
                        },
                        {
                            icon: false,
                            text: () => this.dataTransformation?this.dataTransformation.name:"",
                            click: false
                        }
                ],
                editDataTransformationPopupRefName: 'editTransformationPopup',
                editDataTransformationStepSourcePopupRefName: 'editTransformationStepSourcePopup',
                editDataTransformationStepSelectColumnsPopupRefName: 'editTransformationStepSelectColumnsPopup',
                editDataTransformationStepGroupByPopupRefName: 'editTransformationStepGroupByPopup',
                editDataTransformationPopupInstance: false,
                editDataTransformationStepSourcePopupInstance: false,
                editDataTransformationStepSelectColumnsPopupInstance: false,
                editDataTransformationStepGroupByPopupInstance: false,
                transformationInitialized: false,
                defaultColumnWidth: 180,
                headerCellClientOffsetDiff: 14,
                dynamicWidthColumnIds: [],
                dynamicWidthColumnGroups: {},
                dynamicWidthColumnRefs: {},
                dynamicWidthColumnsInitialized: false,
                scrolling: {
                    mode: 'standard'
                },
                columns: [],
                transformationResultRows: [],
                transformationResultDS: new CustomStore({
                    load: async () => {
                        return this.transformationResultRows;
                    }
                }),
                dataGridRefName: 'gridTransformationStepResult',
                dataGridInstance: false,
                gridConfig: {
                    filterRow: {
                        visible: true,
                        showOperationChooser: true
                    },
                    editing: {
                        allowAdding: false,
                        allowUpdating: false,
                        allowDeleting: false,
                        mode: 'row'
                    },
                    sorting: {
                        mode: 'multiple'
                    },
                    scrolling: {
                        mode: 'standard'
                    },
                    height: '100%',
                    allowColumnResizing: true,
                    columnResizingMode: 'widget',
                },
                selectedStep: null,
                numberFormatter: (value, decimal, decimalSeparator, groupSeparator) => {
                    try {
                        value = parseFloat(value);
                        if (decimal !== -1) value = value.toFixed(decimal);
                        else decimal = 0;
                        value = Number(value).toLocaleString('en', { maximumFractionDigits: decimal > this.FRACTION_DIGITS_MAX ? this.FRACTION_DIGITS_MAX : decimal  });
                        value = groupSeparator !== false ? value.replace(/,/g, groupSeparator) : value.replace(/,/g, '');
                        if (decimalSeparator !== false) value = value.replace('.', decimalSeparator);
                    }
                    catch (e) {
                        
                    }
                    return value;
                },
                addSelectColumnsTransformationButtonOptions: {
                    icon: 'plus',
                    onClick: this.addSelectColumnsClicked,
                    hint: "Hozzáadás: Oszlopok kiválasztása",
                    text: "Oszlopok kiválasztása"
                },
                addGroupByTransformationButtonOptions: {
                    icon: 'plus',
                    onClick: this.addGroupByClicked,
                    hint: "Hozzáadás: Csoportosítás",
                    text: "Csoportosítás"
                },
                errorInTransactionStep: false,
                errorInTransactionStepMessage: ""
            };
        },
        computed: {
            editDataTransformationPopup() {
                if (this.editDataTransformationPopupInstance) {
                    return this.editDataTransformationPopupInstance;
                }
                else if (this.$refs[this.editDataTransformationPopupRefName]) {
                    this.editDataTransformationPopupInstance = this.$refs[this.editDataTransformationPopupRefName];
                }
                return this.editDataTransformationPopupInstance;
            },
            editDataTransformationStepSourcePopup() {
                if (this.editDataTransformationStepSourcePopupInstance) {
                    return this.editDataTransformationStepSourcePopupInstance;
                }
                else if (this.$refs[this.editDataTransformationStepSourcePopupRefName]) {
                    this.editDataTransformationStepSourcePopupInstance = this.$refs[this.editDataTransformationStepSourcePopupRefName];
                }
                return this.editDataTransformationStepSourcePopupInstance;
            },
            editDataTransformationStepSelectColumnsPopup() {
                if (this.editDataTransformationStepSelectColumnsPopupInstance) {
                    return this.editDataTransformationStepSelectColumnsPopupInstance;
                }
                else if (this.$refs[this.editDataTransformationStepSelectColumnsPopupRefName]) {
                    this.editDataTransformationStepSelectColumnsPopupInstance = this.$refs[this.editDataTransformationStepSelectColumnsPopupRefName];
                }
                return this.editDataTransformationStepSelectColumnsPopupInstance;
            },
            editDataTransformationStepGroupByPopup() {
                if (this.editDataTransformationStepGroupByPopupInstance) {
                    return this.editDataTransformationStepGroupByPopupInstance;
                }
                else if (this.$refs[this.editDataTransformationStepGroupByPopupRefName]) {
                    this.editDataTransformationStepGroupByPopupInstance = this.$refs[this.editDataTransformationStepGroupByPopupRefName];
                }
                return this.editDataTransformationStepGroupByPopupInstance;
            },
            stepTypeListForLookup() {
                let list = [{
                    Id: 0,
                    Name: 'Adatforrás'
                }];

                return list;
            },
            dataGrid() {
                if (this.dataGridInstance) {
                    return this.dataGridInstance;
                }
                else if (this.$refs[this.dataGridRefName]) {
                    this.dataGridInstance = this.$refs[this.dataGridRefName].instance;
                }
                return this.dataGridInstance;
            },
        },
        methods: {
            onTransformationStepRemoving(e) {
                sourceDataService.deleteTransformationStep(e.data.TransformationId, e.data.StepId).then(
                    (result) => { 
                        this.updateTransformationStep();
                    },
                    (error) => {
                        submitting = false;
                        notify({
                            message: "Nem sikerült a transzformációs lépés törlése: " + result.data,
                            position: {
                                at: "top",
                                offset: "0 40"
                            }
                        }, "error", 5000);
                    }
                );              
            },
            addSelectColumnsClicked(e) {
                var order = this.dataTransformationSteps.length;
                if(this.selectedStep) order = this.selectedStep.Order + 1;
                var newStep = {
                    Order: order,
                    StepId: null,
                    StepParameters: null,
                    StepType: this.dataTransformationStepTypes.find((item) => item.const === 'selectColumns').id,
                    TransformationId: this.dataTransformationId,
                    PreviousStep: this.selectedStep
                }
                this.showTransformationStepPopup(newStep);
            },
            addGroupByClicked(e) {
                var order = this.dataTransformationSteps.length;
                if(this.selectedStep) order = this.selectedStep.Order + 1;
                var newStep = {
                    Order: order,
                    StepId: null,
                    StepParameters: null,
                    StepType: this.dataTransformationStepTypes.find((item) => item.const === 'groupBy').id,
                    TransformationId: this.dataTransformationId,
                    PreviousStep: this.selectedStep
                }
                this.showTransformationStepPopup(newStep);
            },
            async loadTransformationResult() {
                var targetStepId = null;
                if(this.selectedStep !== null && this.selectedStep.StepId !== undefined && this.selectedStep.StepId !== null) {
                    targetStepId = this.selectedStep.StepId;
                }

                try {
                    var result = await sourceDataService.getTransformationResult(this.dataTransformationId, targetStepId);
                    if(result.Succeeded) {
                        await this.initializeGridData(result.DataTable.Definition);
                        this.transformationResultRows = result.DataTable.Rows;
                        this.errorInTransactionStep = false;
                        this.errorInTransactionStepMessage = "";
                        this.dataGrid.refresh();
                    } else {
                        this.errorInTransactionStep = true;
                        this.errorInTransactionStepMessage = result.ErrorMessages[0];
                    }
                }
                catch (error) {
                    this.errorInTransactionStep = true;
                    this.errorInTransactionStepMessage = error;
                }
            },
            async initializeGridData(definition) {

                this.transformationInitialized = false;

                var definitionColumns = definition.Columns.map(c => {
                    return {
                        dataField: c.DbColumnName,
                        caption: c.DisplayedName,
                        name: c.DisplayedName,
                        order: c.Order,
                        allowEditing: false,
                        controlData: {
                            containerDataType: c.DataType,
                            parameters: c.DataTypeParameters
                        }
                    }
                });

                definitionColumns = definitionColumns.sort((a, b) => a.order - b.order);

                var columns = [];

                definitionColumns.forEach((column) => {
                    if (column.allowEditing) {
                        this.editableDataFieldNames.push(column.dataField);
                    }

                    column.width = this.defaultColumnWidth;
                    switch (column.controlData.containerDataType) {
                        // Tulajdonság oszlopra vonatkozó beállítások
                        case this.dataContainerDataType.property.id:
                            var propCount = 0;
                            var cellTemplate = "";
                            column.controlData.parameters.PropertyParameters.Columns.forEach(function (v, i, a) {
                                cellTemplate += '<td class="propertyDataCell prop-' + v.PropertyId + '" data-property-id="' + v.PropertyId + '">[' + v.PropertyName + ']</td>';
                                propCount++;
                            });
                            column.cellTemplate = cellTemplate;
                            column.calculateCellValue = t => this.calculatePropertyCellValue(t, column);
                            column.width = propCount * this.defaultColumnWidth;
                            break;
                        // Boolean oszlopra vonatkozó beállítások
                        case this.dataContainerDataType.boolean.id:
                            column['dataType'] = 'boolean';
                            break;
                        // Integer oszlopra vonatkozó beállítások
                        case this.dataContainerDataType.integer.id:
                            column['dataType'] = 'number';
                            break;
                        // Number oszlopra vonatkozó beállítások
                        case this.dataContainerDataType.number.id:
                            column['dataType'] = 'number';
                            column['format'] = (data) => {
                                if (!isNaN(data)) {

                                    let decimal = -1;
                                    if (column.controlData.parameters && column.controlData.parameters.NumberParameters && column.controlData.parameters.NumberParameters.DecimalPlaces) {
                                        decimal = column.controlData.parameters.NumberParameters.DecimalPlaces;
                                    }

                                    let decimalSeparator = false;
                                    if (column.controlData.parameters && column.controlData.parameters.NumberParameters && column.controlData.parameters.NumberParameters.DecimalSeparator) {
                                        decimalSeparator = column.controlData.parameters.NumberParameters.DecimalSeparator;
                                    }

                                    let groupSeparator = false;
                                    if (column.controlData.parameters && column.controlData.parameters.NumberParameters && column.controlData.parameters.NumberParameters.ThousandSeparator) {
                                        groupSeparator = column.controlData.parameters.NumberParameters.ThousandSeparator;
                                    }

                                    data = this.numberFormatter(data, decimal, decimalSeparator, groupSeparator);
                                }
                                return data;
                            }

                            break;
                        // Decimal oszlopra vonatkozó beállítások
                        case this.dataContainerDataType.decimal.id:
                            column['dataType'] = 'number';
                            column['format'] = (data) => {
                                if (!isNaN(data)) {

                                    let decimal = -1;
                                    if (column.controlData.parameters && column.controlData.parameters.DecimalParameters && column.controlData.parameters.DecimalParameters.DecimalPlaces) {
                                        decimal = column.controlData.parameters.DecimalParameters.DecimalPlaces;
                                    }

                                    let decimalSeparator = false;
                                    if (column.controlData.parameters && column.controlData.parameters.DecimalParameters && column.controlData.parameters.DecimalParameters.DecimalSeparator) {
                                        decimalSeparator = column.controlData.parameters.DecimalParameters.DecimalSeparator;
                                    }

                                    let groupSeparator = false;
                                    if (column.controlData.parameters && column.controlData.parameters.DecimalParameters && column.controlData.parameters.DecimalParameters.ThousandSeparator) {
                                        groupSeparator = column.controlData.parameters.DecimalParameters.ThousandSeparator;
                                    }

                                    data = this.numberFormatter(data, decimal, decimalSeparator, groupSeparator);
                                }
                                return data;
                            }

                            break;
                        // Dátum oszlopra vonatkozó beállítások
                        case this.dataContainerDataType.dateTime.id:
                            column['dataType'] = 'datetime';
                            if (column.controlData.parameters !== undefined &&
                                column.controlData.parameters.DateTimeParameters !== undefined &&
                                column.controlData.parameters.DateTimeParameters.Format !== undefined) {
                                column['format'] = column.controlData.parameters.DateTimeParameters.Format;
                            }
                            break;
                    }

                    if (column.dataField == SourceDataColumnNames.ID_COLUMN_NAME) {
                        column['dataType'] = 'number',
                            column['allowEditing'] = false
                    }
                    columns.push(column);
                });

                this.columns = columns;
                this.transformationInitialized = true;
            },
            async transformationResultRowClicked(e) {
                if(e !== undefined && e !== null && e.rowType == "data") {
                    this.selectedStep = e.data;
                    await this.loadTransformationResult();
                    this.dataGrid.refresh();
                }
            },
            calculatePropertyCellValue(columnData, column) {
                var renderedRows = [];
                var cellValue = columnData[column.dataField];
                if(cellValue) {
                    cellValue.forEach(function (v, i, a) {
                        if (typeof v === "object") {
                            var template = column.cellTemplate;
                            for (const [key, value] of Object.entries(v)) {
                                if (key.substring(0, 3) == "DC_") {
                                    var propertyName = key.substring(3);
                                    var markup = "[" + propertyName + "]";
                                    if (value == null) value = "";
                                    template = template.replace(markup, value);
                                }
                            }
                            renderedRows.push(template);
                        }
                    });
                }
                return '<table class="propertyInnerTable"><tr class="propertyInnerTableRow">' + renderedRows.join('</tr><tr class="propertyInnerTableRow">') + '</tr></table>';
            },
            isDeleteButtonVisible(e) {
                if(e.row !== undefined && e.row.data !== undefined && e.row.data.StepType !== undefined) {
                    return e.row.data.StepType !== 0
                }
                return false;
            },
            async showTransformationStepPopup(data) {
                data.TransformationId = parseInt(this.dataTransformationId);
                switch(data.StepType) {
                    case this.dataTransformationStepTypes.find((item) => item.const === 'dataSource').id:
                        data.StepParameters = {
                            DataSourceType: this.dataTransformation.dataSourceType,
                            DataContainerId: this.dataTransformation.dataContainerId,
                            DataTransformationId: this.dataTransformation.transformationId
                        };
                        await this.editDataTransformationStepSourcePopup.setDataTransformationStep(data);
                        this.editDataTransformationStepSourcePopup.show(this.updateTransformationStep);
                        break;
                    case this.dataTransformationStepTypes.find((item) => item.const === 'selectColumns').id:
                        await this.editDataTransformationStepSelectColumnsPopup.setDataTransformationStep(data);
                        this.editDataTransformationStepSelectColumnsPopup.show(this.updateTransformationStep);
                        break;
                    case this.dataTransformationStepTypes.find((item) => item.const === 'groupBy').id:
                        await this.editDataTransformationStepGroupByPopup.setDataTransformationStep(data);
                        this.editDataTransformationStepGroupByPopup.show(this.updateTransformationStep);
                        break;
                }
            },
            async updateTransformationStep() {
                await this.initializeTransformation();
                await this.loadTransformationResult();
            },
            onTableGridReorder(e) { 
            },
            onDataListGridChanged(e) {
                this.$root.$emit('source_data_grid_changed', e);
            },
            editTransformation() {
                this.editDataTransformationPopup.show(this.initializeTransformation);
            },
            async initializeTransformation() {

                this.transformationInitialized = false;

                var transformation = await sourceDataService.getTransformation(this.dataTransformationId);
                this.dataTransformation = transformation;
                this.breadcrumbItems[1].text = transformation.name;

                var transformationSteps = await sourceDataService.getTransformationSteps(this.dataTransformationId);
                this.dataTransformationSteps = transformationSteps;

                this.transformationInitialized = true;
            },
            onCellPrepared(e) {

                if (e.rowType == "data") {
                    if (e.values != undefined && e.column.controlData != undefined) {
                        if (e.column.controlData.containerDataType == this.dataContainerDataType.property.id) {
                            e.cellElement.innerHTML = e.values[e.columnIndex];
                        }
                    }
                    
                    if (e.column.dataField == SourceDataColumnNames.ERROR_MESSAGES_COLUMN_NAME) {
                        // Hibaüzenetek helye
                        if (false) {
                            let msg = 'Lorem ipsum dolor sit...';
                            e.cellElement.innerHTML = '<i class="dx-icon-warning warning-icon" title="' + msg + '"></i>';
                        }
                    }

                }
                else if (e.rowType == "header") {
                    
                    if (e.column.controlData != undefined) {

                        var headerCellCaption;
                        if (e.column.controlData.containerDataType === this.dataContainerDataType.property.id) {

                            let dynamicWidthColumnGroupRef;
                            let headerButtons = '';
                            let propertyParametersColumns = e.column.controlData.parameters.PropertyParameters.Columns ? e.column.controlData.parameters.PropertyParameters.Columns : [];

                            if (propertyParametersColumns.length > 1) {
                                this.dynamicWidthColumnIds.push(e.column.dataField);
                                this.dynamicWidthColumnGroups[e.column.dataField] = {
                                    dataField: e.column.dataField,
                                    propertyIds: [],
                                    headerId: e.column.headerId,
                                    columnIndex: e.column.index
                                };
                                dynamicWidthColumnGroupRef = this.dynamicWidthColumnGroups[e.column.dataField];
                                headerButtons = ' <span class="btnPropertyColumn btnShowPropertyColumns" onClick="showPropertyColumns(event, \'' + e.column.dataField + '\', \'' + e.column.headerId + '\')"><i class="dx-icon-showpanel" title="' + e.column.caption + ' oszlopok megjelenítése"></i></span> <span class="btnPropertyColumn btnHidePropertyColumns" onClick="hidePropertyColumns(event, \'' + e.column.dataField + '\', \'' + e.column.headerId + '\')"><i class="dx-icon-hidepanel" title="oszlopok elrejtése"></i></span>';
                            }
                            
                            var propertyCells = [];

                            propertyParametersColumns.forEach(function (v, i, a) {
                                if (i > 0) {
                                    dynamicWidthColumnGroupRef.propertyIds.push(v.PropertyId);
                                }
                                let additionalClass = i > 0 ? ' hidden' : '';
                                
                                propertyCells.push('<td class="propertyHeaderCell prop-' + v.PropertyId + additionalClass + '" data-property-id="' + v.PropertyId + '" title="' + v.PropertyName + '"><span class="headerTitle">' + v.PropertyName + '</span></td>');
                            });
                            headerCellCaption = "<strong>" + e.column.caption + '</strong> ' + headerButtons + '<br /><table id="propertyHeaderTable_' + e.column.dataField + '"><tr>' + propertyCells.join('') + "</tr></table>";
                            e.cellElement.innerHTML = e.cellElement.innerHTML.replace(e.column.caption, headerCellCaption);
                        }
                    }
                }
            },
            onContentReady(e) {
                if (!this.dynamicWidthColumnsInitialized) {
                    for (var dataField in this.dynamicWidthColumnGroups) {
                        let headerElement = document.querySelector('#' + this.dynamicWidthColumnGroups[dataField].headerId);
                        headerElement.classList.add('hidden-columns');


                        this.dynamicWidthColumnGroups[dataField].propertyIds.forEach((propertyId, i, a) => {
                            let elements = document.querySelectorAll('.prop-' + propertyId);
                            elements.forEach((element, i, a) => {
                                element.classList.add('hidden');
                            });
                        });
                    }

                    this.dynamicWidthColumnIds.forEach((dataField, i, a) => {
                        this.updateColumnsWidth(dataField);
                    });

                    this.dynamicWidthColumnsInitialized = true;
                }
                else {

                    var filterExpr = e.component.getCombinedFilter();

                    if (filterExpr) {
                        this.showColumnsOnFilterEvent(filterExpr.columnIndex);
                    }
                }
            }
        },
        async mounted() {
            await this.initializeTransformation();
            await this.loadTransformationResult();
        },
        created() {
        },
        watch: {
        }
    }
</script>

<style>
    .breadcrumb-toolbar .dx-button-has-icon .dx-button-content {
        padding: 0;
    }
    .dx-toolbar .dx-toolbar-items-container {
        margin-top: 12px;
    }
</style>
<style lang="scss" scoped>
    .title h1 {
        float: left;
    }

    .title .right-icons {
        float: right;
    }

    .title .right-icons a {
        text-decoration: none;
        line-height: 22px;
        font-size: 22px;
    }

    .grid {
        padding-top: 0px;
        clear: both;
    }

    .tabpanel {
        height: calc(100% - 50px);
    }

    .history-buttons {
        padding-top: 8px;
        float: right;
    }

    .steps .dx-link-icon {
        display: inline-block;
        width: 18px;
        height: 18px;
        background-position: 0 0;
        background-size: 16px 16px;
        font-size: 18px;
        margin: 0 3px 0 0;
        text-decoration: none;
    }

    .transformation-step-container {
        height: 100%;

    }

    .transformation-step-container .row .col-9 {
        border-right: 1px solid #d0d0d0;
        height: 100%;
        padding-left: 0px;
    }

    .toolbar-row {
        border-bottom: 1px solid #d0d0d0;
    }

    .toolbar-row .col-12 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .error-message {
        font-weight: bold;
        color: #cc0000;
        font-size: 15px;
        word-break: break-word;
    }

</style>
