<template>
    <div>
        <div class="editDataTransformationStepGroupByPopup">
            <CustomDxPopup :ref="editDataTransformationStepGroupByPopupRefName"
                           :drag-enabled="false"
                           :show-title="true"
                           title="Adattranszformációs lépés szerkesztése - Oszlopok kiválasztása"
                           :onShowing="onEditDataTransformationStepGroupByPopupShowing"
                           :onHiding="onEditDataTransformationStepGroupByPopupHiding"
                           container="#app"
                           width="95vw"
                           height="95vh">

                <DxScrollView :show-scrollbar="'onScroll'"
                              :use-native="false">
                    <DxLoadIndicator v-if="!loaded" />

                    <DxForm :visible="loaded"
                            :form-data="dataTransformationStepFormData"
                            :show-validation-summary="true"
                            validation-group="editDataTransformationStepGroupByValidationGroup">

                        <DxGroupItem caption="Csoportosítás oszlopai">
                            <template #default>
                                <div class="transaction-step-table">
                                    <div class="transaction-step-table-thead">
                                        <div class="transaction-step-table-thead-row dx-theme-accent-as-background-color dx-theme-background-color-as-text-color">
                                            <div class="transaction-step-table-thead-row-cell transaction-step-column-handle dx-theme-border-color"></div>
                                            <div class="transaction-step-table-thead-row-cell transaction-step-column-columnName dx-theme-border-color">Oszlop neve</div>
                                            <div class="transaction-step-table-thead-row-cell transaction-step-column-actions dx-theme-border-color">Akciók</div>
                                        </div>
                                    </div>
                                    <DxSortable class="transaction-step-table-tbody"
                                                handle=".transaction-step-column-handle"
                                                dragTemplate="drag"
                                                @drag-start="onGroupByColumnDragStarted"
                                                @reorder="onGroupByColumnReorder">
                                        <div v-for="column in dataTransformationStepFormData.GroupByColumns" :key="column.Index" class="dx-theme-border-color transaction-step-table-tbody-row">
                                            <div class="transaction-step-table-tbody-row-cell transaction-step-column-handle"><div class="dx-icon-dragvertical"></div></div>
                                            <div class="transaction-step-table-tbody-row-cell transaction-step-column-dbColumnName">
                                                <DxSelectBox :data-source="precedingTransformationColumns"
                                                             :value="column.DbColumnName"
                                                             display-expr="DisplayedName"
                                                             value-expr="DbColumnName"
                                                             :on-selection-changed="(e) => onGroupByColumnSelectBoxChanged(e, column)"
                                                             placeholder="Oszlop kiválasztása">
                                                    <DxValidator validation-group="editDataTransformationStepGroupByValidationGroup">
                                                        <DxRequiredRule message="A csoportosítandó oszlop kiválasztása kötelező." />
                                                        <DxAsyncRule :validation-callback="e=>isGroupByColumnNameUnique(e, column)"
                                                                 message="A csoportosító oszlop nem egyedi." />
                                                    </DxValidator>
                                                </DxSelectBox>
                                            </div>
                                            <div class="transaction-step-table-tbody-row-cell transaction-step-column-actions">
                                                <a href="#" class="dx-link dx-link-delete dx-icon-trash dx-link-icon" title="Törlés" aria-label="Törlés" v-if="dataTransformationStepFormData.GroupByColumns.length > 1" @click="(e) => removeGroupByColumn(column.Order)"></a>
                                            </div>
                                        </div>

                                        <template #drag="{ data }">
                                            <div class="transaction-step-table dx-widget">
                                                <div class="transaction-step-table-tbody">
                                                    <div class="dx-theme-border-color transaction-step-table-tbody-row dx-theme-background-color">
                                                        <div class="transaction-step-table-tbody-row-cell transaction-step-column-handle"><div class="dx-icon-dragvertical"></div></div>
                                                        <div class="transaction-step-table-tbody-row-cell transaction-step-column-dbColumnName">
                                                            <DxTextBox v-model="data.itemData.DisplayedName" :read-only="true" />
                                                        </div>
                                                        <div class="transaction-step-table-tbody-row-cell transaction-step-column-selectedColumn">
                                                            <DxCheckBox v-model="data.itemData.IsSelectedColumn" />
                                                        </div>
                                                        <div class="transaction-step-table-tbody-row-cell transaction-step-column-isDisplayedNameModified">
                                                            <DxCheckBox v-model="data.itemData.IsDisplayedNameModified" :read-only="!data.itemData.IsSelectedColumn" />
                                                        </div>
                                                        <div class="transaction-step-table-tbody-row-cell transaction-step-column-displayedName">
                                                            <DxTextBox v-model="data.itemData.DisplayedName" :read-only="!(data.itemData.IsSelectedColumn && data.itemData.IsDisplayedNameModified)" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </DxSortable>
                                </div>
                            </template>
                        </DxGroupItem>
                        <DxGroupItem cssClass="functionButtonsLeft">
                            <DxButtonItem>
                                <DxButtonOptions :width="300"
                                                 style="margin-top: 11px"
                                                 icon="plus"
                                                 text="Új csoportosító oszlop hozzáadása"
                                                 type="default"
                                                 styling-mode="outlined"
                                                 :on-click="e => addNewGroupByColumn(e)" />
                            </DxButtonItem>
                        </DxGroupItem>


                        <DxGroupItem caption="Aggregációs oszlopok">
                            <template #default>
                                <div class="transaction-step-table">
                                    <div class="transaction-step-table-thead">
                                        <div class="transaction-step-table-thead-row dx-theme-accent-as-background-color dx-theme-background-color-as-text-color">
                                            <div class="transaction-step-table-thead-row-cell transaction-step-column-handle dx-theme-border-color"></div>
                                            <div class="transaction-step-table-thead-row-cell transaction-step-column-aggregationType dx-theme-border-color">Aggregáció</div>
                                            <div class="transaction-step-table-thead-row-cell transaction-step-column-columnName dx-theme-border-color">Oszlop neve</div>
                                            <div class="transaction-step-table-thead-row-cell transaction-step-column-actions dx-theme-border-color">Akciók</div>
                                        </div>
                                    </div>
                                    <DxSortable class="transaction-step-table-tbody"
                                                handle=".transaction-step-column-handle"
                                                dragTemplate="drag"
                                                @drag-start="onAggregationColumnDragStarted"
                                                @reorder="onAggregationColumnReorder">
                                        <div v-for="column in dataTransformationStepFormData.AggregationColumns" :key="column.Index" class="dx-theme-border-color transaction-step-table-tbody-row">
                                            <div class="transaction-step-table-tbody-row-cell transaction-step-column-handle"><div class="dx-icon-dragvertical"></div></div>
                                            <div class="transaction-step-table-tbody-row-cell transaction-step-column-addgregationType">
                                                <DxSelectBox :data-source="dataTransformationStepGroupByAggregationTypes"
                                                             :value="column.AggregationType"
                                                             display-expr="name"
                                                             value-expr="id"
                                                             :on-selection-changed="(e) => onAggregationTypeSelectBoxChanged(e, column)"
                                                             placeholder="Aggregáció">
                                                    <DxValidator validation-group="editDataTransformationStepGroupByValidationGroup">
                                                        <DxRequiredRule message="Az aggregáció típusának kiválasztása kötelező." />
                                                    </DxValidator>
                                                </DxSelectBox>
                                            </div>
                                            <div class="transaction-step-table-tbody-row-cell transaction-step-column-dbColumnName">
                                                <DxSelectBox :data-source="precedingTransformationColumns"
                                                             :value="column.DbColumnName"
                                                             display-expr="DisplayedName"
                                                             :read-only="!(!!column.AggregationType && column.AggregationType != dataTransformationStepGroupByAggregationTypes.find((item) => item.const === 'count').id)"
                                                             value-expr="DbColumnName"
                                                             :on-selection-changed="(e) => onAggregationColumnSelectBoxChanged(e, column)"
                                                             placeholder="Oszlop kiválasztása">
                                                    <DxValidator validation-group="editDataTransformationStepGroupByValidationGroup">
                                                        <DxAsyncRule :validation-callback="e=>isAggregationColumnNameSet(e, column)"
                                                                 message="Az aggregációs oszlop megadása kötelező." />
                                                        <DxAsyncRule :validation-callback="e=>isAggregationColumnHasValidDataType(e, column)"
                                                                 message="Az aggregációs oszlop adattípusa nem megfelelő." />
                                                    </DxValidator>
                                                </DxSelectBox>
                                            </div>
                                            <div class="transaction-step-table-tbody-row-cell transaction-step-column-actions">
                                                <a href="#" class="dx-link dx-link-delete dx-icon-trash dx-link-icon" title="Törlés" aria-label="Törlés" v-if="dataTransformationStepFormData.AggregationColumns.length > 1" @click="(e) => removeAggregationColumn(column.Order)"></a>
                                            </div>
                                        </div>

                                        <template #drag="{ data }">
                                            <div class="transaction-step-table dx-widget">
                                                <div class="transaction-step-table-tbody">
                                                    <div class="dx-theme-border-color transaction-step-table-tbody-row dx-theme-background-color">
                                                        <div class="transaction-step-table-tbody-row-cell transaction-step-column-handle"><div class="dx-icon-dragvertical"></div></div>
                                                        <div class="transaction-step-table-tbody-row-cell transaction-step-column-dbColumnName">
                                                            <DxTextBox v-model="data.itemData.DbColumnName" :read-only="true" />
                                                        </div>
                                                        <div class="transaction-step-table-tbody-row-cell transaction-step-column-selectedColumn">
                                                            <DxCheckBox v-model="data.itemData.IsSelectedColumn" />
                                                        </div>
                                                        <div class="transaction-step-table-tbody-row-cell transaction-step-column-isDisplayedNameModified">
                                                            <DxCheckBox v-model="data.itemData.IsDisplayedNameModified" :read-only="!data.itemData.IsSelectedColumn" />
                                                        </div>
                                                        <div class="transaction-step-table-tbody-row-cell transaction-step-column-displayedName">
                                                            <DxTextBox v-model="data.itemData.DisplayedName" :read-only="!(data.itemData.IsSelectedColumn && data.itemData.IsDisplayedNameModified)" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </DxSortable>
                                </div>
                            </template>
                        </DxGroupItem>

                        <DxGroupItem cssClass="functionButtonsLeft">
                            <DxButtonItem>
                                <DxButtonOptions :width="300"
                                                 style="margin-top: 11px"
                                                 icon="plus"
                                                 text="Új aggregációs oszlop hozzáadása"
                                                 type="default"
                                                 styling-mode="outlined"
                                                 :on-click="e => addNewAggregationColumn(e)" />
                            </DxButtonItem>
                        </DxGroupItem>

                        <DxGroupItem cssClass="functionButtons">
                            <DxButtonItem alignment="left">
                                <DxButtonOptions text="Mégse"
                                                 type="default"
                                                 styling-mode="contained"
                                                 :on-click="hidePopup"
                                                 :disable="submitting" />
                            </DxButtonItem>
                            <DxButtonItem alignment="left">
                                <DxButtonOptions :text="saveButtonLabel"
                                                 type="default"
                                                 styling-mode="contained"
                                                 validation-group="editDataTransformationStepGroupByValidationGroup"
                                                 :on-click="(e) => saveDataTransformationStep(e)"
                                                 :disable="submitting" />
                            </DxButtonItem>
                        </DxGroupItem>

                        <DxValidationSummary id="summary" />
                    </DxForm>
                </DxScrollView>
            </CustomDxPopup>
        </div>
    </div>
</template>

<script>
    import EventBus from "@/utils/event-bus";
    import cloneDeep from "clone-deep";
    import DxPopup, {
        DxToolbarItem as DxPopupToolbarItem
    } from "devextreme-vue/popup";
    import DxButton from 'devextreme-vue/button';
    import { DxScrollView } from 'devextreme-vue/scroll-view';
    import DxDropDownButton, { DxDropDownOptions, DxPosition } from 'devextreme-vue/drop-down-button';
    import CustomDxPopup from "@appfrm/core/components/CustomDxPopup";
    import SourceDataColumnTypeParameters from "../components/SourceDataColumnTypeParameters";
    import sourceDataService, {
        DataContainerApiBasePath,
        TABLE_DISPLAYED_NAME_MAX_LENGTH,
        TABLE_COLUMN_DISPLAYED_NAME_MAX_LENGTH
    } from "../services/sourceData.service";
    import { DxLoadIndicator } from 'devextreme-vue/load-indicator';
    import DxTextBox from 'devextreme-vue/text-box';
    import { DxCheckBox } from 'devextreme-vue/check-box';
    import { DxSortable } from 'devextreme-vue/sortable';
    import DxForm, {
        DxGroupItem,
        DxSimpleItem,
        DxItem,
        DxButtonItem,
        DxButtonOptions,
        DxLabel,
        DxRequiredRule,
        DxCompareRule,
        DxRangeRule,
        DxStringLengthRule,
        DxPatternRule,
        DxEmailRule,
        DxAsyncRule,
        DxCustomRule
    } from 'devextreme-vue/form';
    import {
        DxValidator,
    } from 'devextreme-vue/validator';
    import { dataContainerTypeCapabilities, dataContainerOperation, dataTransformationStepTypes, dataTransformationStepGroupByAggregationTypes, dataTransformationStepGroupByValidAggregationDataTypes } from "../data-container"
    import Breadcrumb from "@appfrm/core/components/Breadcrumb";
    import DxSelectBox from "devextreme-vue/select-box";
    import notify from 'devextreme/ui/notify';
    import DxValidationSummary from 'devextreme-vue/validation-summary';
    import DxTagBox from 'devextreme-vue/tag-box';

    export const TRANSFORMATION_DATA_SOURCE_TYPE_DATA_CONTAINER = 1;
    export const TRANSFORMATION_DATA_SOURCE_TYPE_DATA_TRANSFORMATION = 2;

    export default {
        name: "EditDataTransformationStepGroupByPopup",
        components: {
            DxPopup,
            DxPopupToolbarItem,
            DxScrollView,
            CustomDxPopup,
            DxLoadIndicator,
            DxButton,
            DxForm,
            DxGroupItem,
            DxSimpleItem,
            DxItem,
            DxButtonItem,
            DxButtonOptions,
            DxLabel,
            DxRequiredRule,
            DxCompareRule,
            DxRangeRule,
            DxStringLengthRule,
            DxPatternRule,
            DxEmailRule,
            DxAsyncRule,
            DxCustomRule,
            DxTextBox,
            DxCheckBox,
            DxValidator,
            DxSortable,
            Breadcrumb,
            DxSelectBox,
            SourceDataColumnTypeParameters,
            notify,
            DxValidationSummary,
            DxTagBox
        },
        props: [],
        data() {
            return {
                editDataTransformationStepGroupByPopupRefName: 'editDataTransformationStepGroupByPopup',
                dataContainerApiBasePath: DataContainerApiBasePath,
                dataTransformationStepTypes: dataTransformationStepTypes,
                dataTransformationStepGroupByValidAggregationDataTypes: dataTransformationStepGroupByValidAggregationDataTypes,
                dataTransformationStepGroupByAggregationTypes: dataTransformationStepGroupByAggregationTypes,
                loaded: false,
                submitting: false,
                submitValidationResult: [],
                dataTransformationStep: {
                    stepParameters: {}
                },
                dataTransformationStepFormData: {
                    GroupByColumns: [],
                    AggregationColumns: []
                },
                popupInstance: false,
                onHideCallbackFunction: null,
                initInProgress: false,
                precedingTransformationColumns: []
            }
        },
        created() {
        },
        mounted() {
            this.loaded = true;
        },
        watch: {
        },
        methods: {
            removeAggregationColumn(columnIndex) {
                this.dataTransformationStepFormData.AggregationColumns = this.dataTransformationStepFormData.AggregationColumns.filter(function (v) {
                    if (v.Order != columnIndex) return v;
                });
                this.onAggregationColumnReorder({ reorderOnly: true });
            },
            removeGroupByColumn(columnIndex) {
                this.dataTransformationStepFormData.GroupByColumns = this.dataTransformationStepFormData.GroupByColumns.filter(function (v) {
                    if (v.Order != columnIndex) return v;
                });
                this.onGroupByColumnReorder({ reorderOnly: true });
            },
            addNewGroupByColumn(e) {
                this.dataTransformationStepFormData.GroupByColumns.push({ DbColumnName: null, DisplayedName: null, Order: this.dataTransformationStepFormData.GroupByColumns.length + 1 });
            },
            addNewAggregationColumn(e) {
                this.dataTransformationStepFormData.AggregationColumns.push({ AggregationType: null, DbColumnName: null, DisplayedName: null, Order: this.dataTransformationStepFormData.AggregationColumns.length + 1 });
            },
            onGroupByColumnSelectBoxChanged(e, column) {
                column.DbColumnName = e.selectedItem.DbColumnName;
                column.DisplayedName = e.selectedItem.DisplayedName;
                column.DataType = e.selectedItem.DataType;
            },
            onAggregationColumnSelectBoxChanged(e, column) {
                if(e.selectedItem) {
                    column.DbColumnName = e.selectedItem.DbColumnName;
                    column.DisplayedName = e.selectedItem.DisplayedName;
                    column.DataType = e.selectedItem.DataType;
                }
            },
            onAggregationTypeSelectBoxChanged(e, column) {
                column.AggregationType = e.selectedItem.id;
                if(column.AggregationType == dataTransformationStepGroupByAggregationTypes.find((item) => item.const === 'count').id) {
                    column.DbColumnName = null;
                    column.DisplayedName = null;
                }
            },
            async isGroupByColumnNameUnique(e, column) {
                var columns = this.dataTransformationStepFormData.GroupByColumns.filter(c => c.DbColumnName);
                return !columns.some(c => c.DbColumnName === column.DbColumnName && c.Order !== column.Order);
            },
            async isAggregationColumnNameSet(e, column) {
                var columns = this.dataTransformationStepFormData.AggregationColumns.filter(c => c.AggregationType != this.dataTransformationStepGroupByAggregationTypes.find((item) => item.const === 'count').id && c.DbColumnName == null);
                return !columns.some(c => c.Order == column.Order);
            },
            async isAggregationColumnNameUnique(e, column) {
                var columns = this.dataTransformationStepFormData.AggregationColumns.filter(c => c.DbColumnName);
                return !columns.some(c => c.DbColumnName === column.DbColumnName && c.Order !== column.Order);
            },
            async isAggregationColumnHasValidDataType(e, column) {
                var dataTypeIds = this.dataTransformationStepGroupByValidAggregationDataTypes.map(t => t.id);
                if(!column.AggregationType) return true;
                if(column.AggregationType == this.dataTransformationStepGroupByAggregationTypes.find((item) => item.const === 'count').id) return true;
                return dataTypeIds.includes(column.DataType);
            },
            stepTypeEquals(stepTypeId) {
                if(this.dataTransformationStep !== undefined && this.dataTransformationStep !== null && this.dataTransformationStep.StepType !== undefined) {
                    return stepTypeId == this.dataTransformationStep.StepType;
                }
                return false;
            },
            async setDataTransformationStep(transformationStep) {
                this.dataTransformationStep = transformationStep;
            },
            show(callbackFunction) {
                this.onHideCallbackFunction = callbackFunction;
                if (this.popup) {
                    this.popup.show();
                }
            },
            async onEditDataTransformationStepGroupByPopupShowing(e) {
                if(this.dataTransformationStep.StepId == null) {
                    // Új transzformációs lépés
                    var result = await sourceDataService.getTransformationResult(this.dataTransformationStep.TransformationId, this.dataTransformationStep.PreviousStep?(this.dataTransformationStep.PreviousStep.StepId?this.dataTransformationStep.PreviousStep.StepId:null):null);
                    if(result) {
                        this.dataTransformationStepFormData.GroupByColumns = [
                            {
                                DbColumnName: null,
                                DisplayedName: null,
                                Order: 1
                            }
                        ];
                        this.dataTransformationStepFormData.AggregationColumns = [
                            {
                                DbColumnName: null,
                                DisplayedName: null,
                                AggregationType: null,
                                Order: 1
                            }
                        ];
                        this.precedingTransformationColumns = result.DataTable.Definition.Columns;
                    }
                }
                else 
                {
                    // Létező transzformációs lépés
                    var result = await sourceDataService.getTransformationResultBeforeStep(this.dataTransformationStep.TransformationId, this.dataTransformationStep.StepId);
                    if(result) {
                        this.precedingTransformationColumns = result.DataTable.Definition.Columns;
                    }

                    var result = await sourceDataService.getTransformationStep(this.dataTransformationStep.TransformationId, this.dataTransformationStep.StepId);
                    if(result) {
                        this.dataTransformationStepFormData = result.StepParameters;
                    }
                }
                
            },
            onEditDataTransformationStepGroupByPopupHiding(e) {
                if (typeof this.onHideCallbackFunction !== null) {
                    this.onHideCallbackFunction();
                }
                this.dataTransformationStepFormData = {
                    GroupByColumns: [],
                    AggregationColumns: []
                };
            },
            onGroupByColumnReorder(e) {
                const newColumns = [...this.dataTransformationStepFormData.GroupByColumns];

                if(!e.reorderOnly) {
                    newColumns.splice(e.fromIndex, 1);
                    newColumns.splice(e.toIndex, 0, e.itemData);
                }

                newColumns.forEach(function (element, index) {
                    element.Order = index + 1;
                });

                this.dataTransformationStepFormData.GroupByColumns = newColumns;
            },
            onAggregationColumnReorder(e) {
                const newColumns = [...this.dataTransformationStepFormData.AggregationColumns];

                if(!e.reorderOnly) {
                    newColumns.splice(e.fromIndex, 1);
                    newColumns.splice(e.toIndex, 0, e.itemData);
                }
                newColumns.forEach(function (element, index) {
                    element.Order = index + 1;
                });

                this.dataTransformationStepFormData.AggregationColumns = newColumns;
            },
            onGroupByColumnDragStarted(e) {
                e.itemData = this.dataTransformationStepFormData.GroupByColumns[e.fromIndex];
            },
            onAggregationColumnDragStarted(e) {
                e.itemData = this.dataTransformationStepFormData.AggregationColumns[e.fromIndex];
            },
            async saveDataTransformationStep(e) {
                if (!this.submitting) {
                    var validationResult = e.validationGroup.validate();

                    if (validationResult.status === 'pending') {
                        validationResult = await validationResult.complete;
                    }

                    if (validationResult.isValid) {
                        this.submitting = true;

                        var postData = {
                            StepId: this.dataTransformationStep.StepId===null?-1:this.dataTransformationStep.StepId,
                            TransformationId: this.dataTransformationStep.TransformationId,
                            StepType: this.dataTransformationStep.StepType,
                            Order: this.dataTransformationStep.Order,
                            StepParameters: this.dataTransformationStepFormData
                        };

                        if(this.isNewStep) {
                            // Új lépés beszúrása
                            sourceDataService.addTransformationStepGroupBy(this.dataTransformationStep.TransformationId, postData).then(
                                (result) => {
                                    if (result.errortype === undefined) {
                                        this.submitting = false;
                                        this.hidePopup();
                                    } else {
                                        this.submitting = false;
                                        if (result.errortype != undefined) {

                                            if (result.errortype == "application") {
                                                notify({
                                                    message: "Nem sikerült a transzformációs lépés módosítása: " + result.data,
                                                    position: {
                                                        at: "top",
                                                        offset: "0 40"
                                                    }
                                                }, "error", 5000);
                                            }
                                        }
                                    }
                                },
                                (error) => {
                                    submitting = false;
                                    notify({
                                        message: "Nem sikerült a transzformációs lépés módosítása: " + result.data,
                                        position: {
                                            at: "top",
                                            offset: "0 40"
                                        }
                                    }, "error", 5000);
                                }
                            );
                        } else {
                            // Létező lépés update-je
                            sourceDataService.updateTransformationStepGroupBy(this.dataTransformationStep.TransformationId, postData).then(
                                (result) => {
                                    if (result.errortype === undefined) {
                                        this.submitting = false;
                                        this.hidePopup();
                                    } else {
                                        this.submitting = false;
                                        if (result.errortype != undefined) {

                                            if (result.errortype == "application") {
                                                notify({
                                                    message: "Nem sikerült a transzformációs lépés módosítása: " + result.data,
                                                    position: {
                                                        at: "top",
                                                        offset: "0 40"
                                                    }
                                                }, "error", 5000);
                                            }
                                        }
                                    }
                                },
                                (error) => {
                                    submitting = false;
                                    notify({
                                        message: "Nem sikerült a transzformációs lépés módosítása: " + result.data,
                                        position: {
                                            at: "top",
                                            offset: "0 40"
                                        }
                                    }, "error", 5000);
                                }
                            );
                        }

                    }
                }
            },
            // submit error methods
            async validateSubmitErrors(e) {
                if (e.formItem) {
                    return !this.submitValidationResult.some(v => v.fieldName == e.formItem.dataField)
                }
                return true;
            },
            async validateToDeleteColumns(e) {
                return !this.isDeleteButtonVisible;
            },
            getSubmitErrorMessage(fieldName) {
                var serverValidation = this.submitValidationResult.find(v => v.fieldName === fieldName);
                return serverValidation ? serverValidation.message : "";
            },
            hidePopup() {
                this.instance.hide();
            },
        },
        computed: {
            saveButtonLabel() {
                return this.dataTransformationStep.StepId == null ? "Hozzáadás" : "Módosítás";
            },
            instance() {
                return this.$refs[this.editDataTransformationStepGroupByPopupRefName].instance;
            },
            popup() {
                if (!this.popupInstance) {
                    this.popupInstance = this.$refs[this.editDataTransformationStepGroupByPopupRefName].instance;
                }
                return this.popupInstance;
            },
            isNewStep() {
                return (this.dataTransformationStep.StepId === null && this.dataTransformationStep.StepType != this.dataTransformationStepTypes.find((item) => item.const === 'dataSource').id);
            }
        }
    };
</script>

<style scoped lang="scss">
    .nav > li > a {
        text-decoration: none;
    }
    .nav > li > a > .img-circle {
        width: 100%;
        max-width: 20px;
        height: auto;
        border-radius: 50%;
        border: 0;
        position: relative;
        bottom: 1px;
        vertical-align:middle
    }
    .nav > li > a > span {
        margin-left: 5px;
        line-height: 24px;
        font-size: 14px;
    }
    ::v-deep .localDropdown {
        .dx-button-content {
            line-height: 21px;
            padding: 12px 18px 7px;
        }
        .dx-button-text {
            color: #ffffff;
        }
        .dx-button-mode-outlined {
            border: 0;
        }
        .dx-icon {
            vertical-align: bottom;
            height: 20px !important;
            width: 28px;
            margin-right: 0 !important;
        }
        .dx-icon.dx-icon-right{
            width: 0;
        }
        .dx-overlay-content {
            left: 10px;
        }
        .dx-button-text {
            display: none;
        }
    }
    ul.navbar-nav li {
        padding-left: 31px !important;
        line-height: 40px;
    }
    .bug-report a {
        color: #fff;
    }
    .bug-report-chooser {
        position: absolute;
        left: -120px;
        top: 40px;
        background-color: #012A4A;
        color: #fff;
    }
    ul.bug-report-chooser {
        list-style: none;
        padding: 0px;
    }
    .bug-report-chooser li {
        padding: 0px 20px;
        list-style: none;
        white-space: nowrap;
    }
    .notificationCount {
        position: relative;
        top: -2px;
        background-color: #ff0000;
        color: #fff;
        font-size: 11px;
        font-weight: bold;
        line-height: 22px;
        padding: 0px 0px;
        -webkit-border-radius: 22px;
        -moz-border-radius: 22px;
        border-radius: 22px;
        display: inline-block;
        width: 22px;
        height: 22px;
        text-align: center;
    }


    .import_errors {
        color: #ff0000;
        font-size: 12px;

        .import_errors_header {
            font-weight: bold;
        }
    }

    .transaction-step-table {
        border-collapse: collapse;
        display: table;

        .transaction-step-column-handle {
            width: 37px;
        }

        .transaction-step-column-index {
            text-align: center;
            width: 37px;
        }

        .transaction-step-column-dbColumnName,
        .transaction-step-column-claims-data {
            width: 200px;
        }

        .transaction-step-column-toBeImported {
            width: 100px;
        }

        .transaction-step-column-isDisplayedNameModified {
            width: 100px;
        }

        .transaction-step-column-displayedName.colspan-1 {
            width: 210px;
        }

        .transaction-step-column-displayedName.colspan-2 {
        }

        .transaction-step-column-parameters {
            width: 210px;
        }

        .transaction-step-column-description {
            width: 210px;
        }

        .column-claim-select-wrapper {
            margin-top: 2px;
        }
    }

    .transaction-step-table-thead {
        display: table-header-group;

        .transaction-step-table-thead-row {
            display: table-row;

            .transaction-step-table-thead-row-cell {
                display: table-cell;
                border-style: solid;
                border-width: 1px;
                padding: 7px;
                font-weight: 400;
            }
        }
    }

    .transaction-step-table-tbody {
        display: table-row-group;

        .transaction-step-table-tbody-row {
            display: table-row;
            border-style: solid;
            border-width: 1px;

            .transaction-step-table-tbody-row-cell {
                display: table-cell;
                vertical-align: middle;
            }

            .transaction-step-table-tbody-row-cell.colspan-2 {
                width: max-content;
                vertical-align: middle;
            }

            .transaction-step-column-handle {
                width: 37px;
                text-align: center;
                cursor: move;
            }

            .transaction-step-column-index {
                text-align: center;
            }

            .transaction-step-column-toBeImported,
            .transaction-step-column-isDisplayedNameModified,
            .transaction-step-column-isSelectedColumn,
            .transaction-step-column-toDelete,
            .transaction-step-column-is-company-id {
                text-align: center;
            }

            .transaction-step-column-actions {
                font-size: 18px;
                color: #0c3c60;
                text-align: center;
                text-decoration: none;
                width: 60px;
            }

            .transaction-step-column-actions a {
                font-size: 18px;
                color: #0c3c60;
                text-decoration: none;
            }
        }
    }

    .container-claims .dx-field {
        max-width: 600px;
    }

</style>
<style>
    .dx-scrollable.dx-scrollview.dx-widget .dx-scrollable-wrapper {
        border: none;
    }
    .dx-list-item-icon {
        vertical-align: bottom;
        height: 13px !important;
    }
    .dx-popup-content {
        text-align: left !important;
    }
    .functionButtons .dx-layout-manager {
        text-align: right;
    }

    .functionButtons .dx-collection {
        display: inline-block !important;
    }

    .functionButtons .dx-item {
        display: inline-block !important;
        margin-left: 16px;
    }

    .functionButtonsLeft {
        padding-top: 0px !important;
    }

    .functionButtonsLeft .dx-layout-manager {
        text-align: left;
    }

    .functionButtonsLeft .dx-collection {
        display: inline-block !important;
    }

    .functionButtonsLeft .dx-item {
        display: inline-block !important;
        margin-right: 16px;
    }
</style>
