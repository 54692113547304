<template>
  <div class="pa-10" v-if="user">
    <div>
      <h1>Username: {{ user.userName }}</h1>
    </div>
    <p><strong>Full Name:</strong> {{ user.fullName }}</p>
    <p><strong>Email:</strong> {{ user.email }}</p>
    <p><strong>Phone:</strong> {{ user.phoneNumber }}</p>
    <p><strong>Roles:</strong> {{ user.roles }}</p>
    <p><strong>Permissions:</strong> {{ permissions }}</p>
    <v-text-field
        label="Test message"
        hide-details="auto"
        v-model="messageText"
    ></v-text-field>
    <br>
    <v-btn
        depressed
        color="primary"
        @click="sendMessage"
    >Send</v-btn>
  </div>
</template>

<script>
export default {
  data() {
    return {
      messageText: 'Write something'
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    }, 
    permissions() {
      return this.$store.state.permissions;
    }
  },
  methods: {
    sendMessage() {
      this.$store.dispatch('sendMessage', this.messageText);
    }
  }
}
</script>

<style lang="scss" scoped></style>
