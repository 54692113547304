<template>
    <div>
        <navigation-header :username="userFullname" 
                           :user-roles="userRoles"
                           @logout="logoutRequest" />
        <navigation-sidebar :active-menu="activeMenu"
                            :current-year="currentYear"
                            :app-name="appName"
                            :current-version="currentVersion"
                            :user-roles="userRoles"
                            :public-website="publicWebsite" 
                            @logout="logoutRequest" />

        <CustomDxPopup :visible="logoutPopupVisible"
                       :drag-enabled="false"
                       :close-on-outside-click="true"
                       :show-close-button="false"
                       :show-title="true"
                       :width="360"
                       :height="170"
                       title="Log out"
                       container="#app">
            <DxToolbarItem widget="dxButton"
                           toolbar="bottom"
                           location="center"
                           :options="logout" />
            <DxToolbarItem widget="dxButton"
                           toolbar="bottom"
                           location="center"
                           :options="cancel" />
            <p class="text-center">
                Are you sure you want to log out?
            </p>
        </CustomDxPopup>
    </div>
</template>

<script>
import Header from "./Header.vue";
import Sidebar from "./Sidebar.vue";
    import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
    import { Logout } from "@/enums/auth-actions";
    import CustomDxPopup from "@appfrm/core/components/CustomDxPopup";

export default {
    components: {
        "navigation-header": Header,
        "navigation-sidebar": Sidebar,
        DxPopup,
        DxToolbarItem,
        CustomDxPopup
    },
    props: [
        "active-menu",
        "user-fullname",
        "current-year",
        "app-name",
        "current-version",
        "user-roles",
        "public-website",
    ],
    data() {
        return {
            logout: {
                icon: 'export',
                text: 'Logout',
                onClick: () => {
                    this.$store.dispatch(Logout);
                    this.$router.push('/');
                }
            },
            cancel: {
                text: 'Cancel',
                onClick: () => {
                    this.logoutPopupVisible = false;
                }
            },
            logoutPopupVisible: false,
        }
        },
        methods: {
            logoutRequest: function () {
                this.logoutPopupVisible = true;
            }
        }
};
</script>
<style>
    .dx-popup-title {
        background-color: #012A4A;
        color: #ffffff;
    }
    .dx-popup-content {
        text-align: center;
    }
</style>