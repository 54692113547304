<template>
    <div>
        <div class="inputStatusPopup">
            <CustomDxPopup :ref="inputStatusPopupRefName"
                           :drag-enabled="false"
                           :show-title="true"
                           title="Külső adatforrás transzfer státuszok"
                           :onShowing="onInputStatusPopupShowing"
                           :onHiding="onInputStatusPopupHiding"
                           container="#app">

                <DxScrollView :show-scrollbar="'onScroll'"
                              :use-native="false">
                    <DxLoadIndicator v-if="!loaded" />
                    <!-- Id, ExternalDataSourceId IExternalDataSourceDefinition.Id-ből, UpdateId, LogType, LogMessage, CreatedAt -->
                    <div class="dx-field">
                        <DxCheckBox v-model="isNotFiltered"
                                    text="Mutassa a 'Sikeres' és 'Kihagyva' státuszokat is" />
                    </div>
                    <DxDataGrid :data-source="statusDataList"
                                :show-borders="true"
                                :row-alternation-enabled="true"
                                :hover-state-enabled="true"
                                :scrolling="scrolling"
                                height="100%"
                                :ref="dataGridRefName">
                        <DxPaging />
                        <DxSorting mode="single" />
                        <DxFilterRow :visible="true" />
                        <DxColumn data-field="Id" :visible="false" />
                        <DxColumn caption="FirstReceivedAt"
                                  data-field="FirstReceivedAt"
                                  data-type="datetime"
                                  :visible="true"
                                  :sort-index="0"
                                  sort-order="desc"
                                  width="220px" />
                        <DxColumn caption="TransferId" data-field="TransferId" :visible="true" />
                        <DxColumn caption="UpdateId" data-field="UpdateId" :visible="true" />
                        <DxColumn caption="Status"
                                  data-field="Status"
                                  data-type="number"
                                  width="220px">
                            <DxLookup :data-source="statusLookupDS"
                                      display-expr="Name"
                                      value-expr="Id" />
                        </DxColumn>
                        <DxColumn caption="Akciók"
                                  sortOrder="0"
                                  :allow-resizing="false"
                                  :allow-reordering="false"
                                  :min-width="60"
                                  :width="100"
                                  type="buttons">
                            <DxGridButton template="updateButtonTemplate" />
                        </DxColumn>
                        <template #updateButtonTemplate="{ data }">
                            <div>
                                <span v-if="data.data.Status == sourceDataInputTransferStatus.Failed && data.data.WaitingToBeProcessedEnabled" :class="'dx-command-edit dx-command-edit-with-icons' + (submitting? ' isDisabled' : '')">
                                    <a @click="reRunProcessing(data.data.UpdateId)" class="dx-link dx-icon-refresh dx-link-icon" title="Újrafuttatás" aria-label="Újrafuttatás"></a>
                                </span>
                                <span v-if="data.data.Status == sourceDataInputTransferStatus.Failed" :class="'dx-command-edit dx-command-edit-with-icons' + (submitting? ' isDisabled' : '')">
                                    <a @click="skipTransfer(data.data.UpdateId)" class="dx-link dx-icon-remove dx-link-icon" title="Kihagyás" aria-label="Kihagyás"></a>
                                </span>
                                <span v-if="data.data.Status == sourceDataInputTransferStatus.ReadyForProcessing && data.data.WaitingToBeProcessedEnabled" :class="'dx-command-edit dx-command-edit-with-icons' + (submitting? ' isDisabled' : '')">
                                    <a @click="runProcessing(data.data.UpdateId)" class="dx-link dx-icon-arrowright dx-link-icon" title="Feldolgozás megkezdése" aria-label="Feldolgozás megkezdése"></a>
                                </span>
                            </div>
                        </template>
                    </DxDataGrid>
                </DxScrollView>
                <DxPopupToolbarItem widget="dxButton"
                               toolbar="bottom"
                               location="after"
                               :options="closeButtonOptions" />
            </CustomDxPopup>
        </div>
    </div>
</template>

<script>
    import DxPopup, {
        DxToolbarItem as DxPopupToolbarItem
    } from "devextreme-vue/popup";
    import { DxScrollView } from 'devextreme-vue/scroll-view';
    import CustomDxPopup from "@appfrm/core/components/CustomDxPopup";
    import sourceDataService, {
        SourceDataInputTransferStatus
    } from "../services/sourceData.service";
    import { DxLoadIndicator } from 'devextreme-vue/load-indicator';
    import DxDataGrid, {
        DxColumn,
        DxEditing,
        DxButton as DxGridButton,
        DxRowDragging,
        DxSorting,
        DxGrouping,
        DxGroupPanel,
        DxSearchPanel,
        DxPaging,
        DxScrolling,
        DxFilterRow,
        DxLookup
    } from "devextreme-vue/data-grid";
    import CustomStore from "devextreme/data/custom_store";
    import Breadcrumb from "@appfrm/core/components/Breadcrumb";
    import notify from 'devextreme/ui/notify';
    import { DxCheckBox } from 'devextreme-vue/check-box';
    import { handleStandardErrorNotification, notifySuccess } from "@appfrm/core/index";

    export default {
        name: "InputStatusPopup",
        components: {
            DxPopup,
            DxPopupToolbarItem,
            DxScrollView,
            CustomDxPopup,
            DxLoadIndicator,
            Breadcrumb,
            notify,
            DxDataGrid,
            DxGridButton,
            DxColumn,
            DxEditing,
            DxSorting,
            DxGrouping,
            DxGroupPanel,
            DxSearchPanel,
            DxPaging,
            DxScrolling,
            DxFilterRow,
            DxLookup,
            DxCheckBox
        },
        props: {
            externalDataSourceId: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                inputStatusPopupRefName: 'inputStatusPopup',
                isNotFiltered: false,
                statusDataList: new CustomStore({
                    key: "Id",
                    load: async () => {

                        var data = [];

                        if (this.loaded) {

                            data = await sourceDataService.getSourceDataInputStatuses(this.externalDataSourceId, !this.isNotFiltered);

                            if (data && data.length) {
                                data.forEach((row) => {
                                    row['Id'] = row.UpdateId;

                                });
                            }
                        }

                        return data;
                    }
                }),
                submitting: false,
                importId: this.$route.params.importId,
                loaded: false,
                saveClicked: false,
                dataContainerId: this.containerId,
                scrolling: {
                    mode: 'standard'
                },
                dataGridInstance: false,
                dataGridRefName: 'gridLogDataList',
                closeButtonOptions: {
                    text: 'Bezár',
                    onClick: () => {
                        this.hidePopup();
                    },
                },
                sourceDataInputTransferStatus: SourceDataInputTransferStatus,
                statusLookupData: [
                    { "Id": SourceDataInputTransferStatus.WaitingToBeProcessed, "Name": 'Feldolgozásra vár' },
                    { "Id": SourceDataInputTransferStatus.Receiving, "Name": 'Adatfogadás folyamatban' },
                    { "Id": SourceDataInputTransferStatus.Succeeded, "Name": 'Sikeres' },
                    { "Id": SourceDataInputTransferStatus.Failed, "Name": 'Nem sikerült' },
                    { "Id": SourceDataInputTransferStatus.ReadyForProcessing, "Name": 'Feldolgozásra készen áll' },
                    { "Id": SourceDataInputTransferStatus.Processing, "Name": 'Feldolgozás folyamatban' },
                    { "Id": SourceDataInputTransferStatus.Skipped, "Name": 'Kihagyva' }
                ]
            }
        },
        methods: {
            onInputStatusPopupShowing(e) {
                this.loaded = true;
                this.dataGrid.refresh();
            },
            onInputStatusPopupHiding(e) {
                //
            },
            hidePopup() {
                this.instance.hide();
            },
            logTypeCalculateSortValue(data) {
                var value = this.calculateCellValue(data);
                return this.lookup.calculateCellValue(value);
            },
            async updateInputStatus(updateId, newStatus) {

                this.submitting = true;

                let data = {
                    UpdateId: updateId,
                    Status: newStatus
                }

                try {
                    await sourceDataService.updateSourceDataInputStatus(data).then((result) => {
                        this.submitting = false;
                        this.dataGrid.refresh();
                    });
                }
                catch (error) {                    
                    this.submitting = false;
                    handleStandardErrorNotification("Nem sikerült az transzfer státusz módosítása.", error);
                    this.dataGrid.refresh();
                }
            },
            reRunProcessing(updateId) {
                this.updateInputStatus(updateId, SourceDataInputTransferStatus.WaitingToBeProcessed);
            },
            runProcessing(updateId) {
                this.updateInputStatus(updateId, SourceDataInputTransferStatus.WaitingToBeProcessed);
            },
            skipTransfer(updateId) {
                this.updateInputStatus(updateId, SourceDataInputTransferStatus.Skipped);
            }
        },
        computed: {
            instance() {
                return this.$refs[this.inputStatusPopupRefName].instance;
            },
            dataGrid() {
                if (this.dataGridInstance) {
                    return this.dataGridInstance;
                }
                else if (this.$refs[this.dataGridRefName]) {
                    this.dataGridInstance = this.$refs[this.dataGridRefName].instance;
                }
                return this.dataGridInstance;
            },
            statusLookupDS() {
                if (this.isNotFiltered) {
                    return this.statusLookupData;
                }
                else {
                    return this.statusLookupData.filter((data) => data.Id != SourceDataInputTransferStatus.Succeeded && data.Id != SourceDataInputTransferStatus.Skipped);
                }
            }
        },
        watch: {
            isNotFiltered(to, from) {
                if (to != from) {
                    this.dataGrid.refresh();
                }
            }
        }
    };
</script>

<style scoped lang="scss">
    .nav > li > a {
        text-decoration: none;
    }
    .nav > li > a > .img-circle {
        width: 100%;
        max-width: 20px;
        height: auto;
        border-radius: 50%;
        border: 0;
        position: relative;
        bottom: 1px;
        vertical-align:middle
    }
    .nav > li > a > span {
        margin-left: 5px;
        line-height: 24px;
        font-size: 14px;
    }

    .notificationCount {
        position: relative;
        top: -2px;
        background-color: #ff0000;
        color: #fff;
        font-size: 11px;
        font-weight: bold;
        line-height: 22px;
        padding: 0px 0px;
        -webkit-border-radius: 22px;
        -moz-border-radius: 22px;
        border-radius: 22px;
        display: inline-block;
        width: 22px;
        height: 22px;
        text-align: center;
    }

</style>
<style>
    .dx-scrollable.dx-scrollview.dx-widget .dx-scrollable-wrapper {
        border: none;
    }
    .dx-list-item-icon {
        vertical-align: bottom;
        height: 13px !important;
    }
    .dx-popup-content {
        text-align: left !important;
    }
    .functionButtons .dx-layout-manager {
        text-align: right;
    }

    .functionButtons .dx-collection {
        display: inline-block !important;
    }

    .functionButtons .dx-item {
        display: inline-block !important;
        margin-left: 16px;
    }

    .functionButtonsLeft .dx-layout-manager {
        text-align: left;
    }

    .functionButtonsLeft .dx-collection {
        display: inline-block !important;
    }

    .functionButtonsLeft .dx-item {
        display: inline-block !important;
        margin-right: 16px;
    }

    .isDisabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    .isDisabled > a {
        color: currentColor;
        display: inline-block; /* For IE11/ MS Edge bug */
        pointer-events: none;
        text-decoration: none;
    }
</style>
