export const AppStatus = {
    Init: 'Init',
    Logout: 'Logout',
    Logging: 'Logging',
    AutoLogging: 'AutoLogging',
    AutoReLogging: 'AutoReLogging',
    Success: 'Success',
    Error: 'Error',
    Reconnecting: 'Reconnecting',
};
