import RequestHandler from "@appfrm/core/services/request-handler";
class LevelService {
    constructor() {
        this.path = "levels";
        if (LevelService.instance) {
            return LevelService.instance;
        }
        LevelService.instance = this;
        return this;
    }
    async get() {
        return await RequestHandler.get(this.path);
    }
    async insert(data) {
        return await RequestHandler.post(this.path, data);
    }
    async update(data) {
        return RequestHandler.putAsForm(this.path, data);
    }
    async delete(id) {
        return await RequestHandler.delete(this.path, id);
    }
    async hasEntities(id) {
        return await RequestHandler.get(`${this.path}/HasEntities`, id);
    }
}
const instance = new LevelService();
export default instance;