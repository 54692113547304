import RequestHandler from "@appfrm/core/services/request-handler";
class PositionNameService {
    constructor() {
        this.path = "positionNames";
        if (PositionNameService.instance) {
            return PositionNameService.instance;
        }
        PositionNameService.instance = this;
        return this;
    }
    async get(loadOptions) {
        return await RequestHandler.getWithParams(this.path, loadOptions);
    }
    async insert(data) {
        return await RequestHandler.post(this.path, data);
    }
    async update(data, reorder) {
        return RequestHandler.put(this.path, data);
    }
    async delete(id) {
        return await RequestHandler.delete(this.path, id);
    }
}
const instance = new PositionNameService();
export default instance;