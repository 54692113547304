const products = [{
  id: '1',
  text: 'Birodalom 1',
  szintkod: 'A',
  expanded: true,
  items: [{
    id: '1_1',
    text: 'Csoport 1',
    szintkod: 'B',
    expanded: true,
    items: [{
      id: '1_1_1000',
      text: 'Cég 1000',
      szintkod: 'D',
      image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/2.png',
      price: 270,
    },{
      id: '1_1_1',
      text: 'Ágazat 1',
      szintkod: 'C',
      items: [{
        id: '1_1_1_1',
        text: 'Cég 1',
        szintkod: 'D',
        price: 220,
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/1.png',
      }, {
        id: '1_1_1_2',
        text: 'Cég 2',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/2.png',
        price: 270,
      }],
    }, {
      id: '1_1_2',
      text: 'Ágazat 2',
      szintkod: 'C',
      expanded: true,
      items: [{
        id: '1_1_2_1',
        text: 'Cég 400',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/7.png',
        price: 1200,
      }, {
        id: '1_1_2_2',
        text: 'Cég 401',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/5.png',
        price: 1450,
      }, {
        id: '1_1_2_3',
        text: 'Cég 402',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/4.png',
        price: 1600,
      }, {
        id: '1_1_2_4',
        text: 'Cég 403',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/6.png',
        price: 1350,
      }, {
        id: '1_1_2_5',
        text: 'Cég 404',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/5.png',
        price: 1450,
      }, {
        id: '1_1_2_6',
        text: 'Cég 405',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/4.png',
        price: 1600,
      }, {
        id: '1_1_2_7',
        text: 'Cég 406',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/6.png',
        price: 1350,
      }, {
        id: '1_1_2_8',
        text: 'Cég 407',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/5.png',
        price: 1450,
      }, {
        id: '1_1_2_9',
        text: 'Cég 408',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/4.png',
        price: 1600,
      }, {
        id: '1_1_2_10',
        text: 'Cég 409',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/6.png',
        price: 1350,
      }, {
        id: '1_1_2_11',
        text: 'Cég 410',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/5.png',
        price: 1450,
      }, {
        id: '1_1_2_12',
        text: 'Cég 411',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/4.png',
        price: 1600,
      }, {
        id: '1_1_2_13',
        text: 'Cég 412',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/6.png',
        price: 1350,
      }, {
        id: '1_1_2_14',
        text: 'Cég 413',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/5.png',
        price: 1450,
      }, {
        id: '1_1_2_15',
        text: 'Cég 414',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/4.png',
        price: 1600,
      }, {
        id: '1_1_2_16',
        text: 'Cég 415',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/6.png',
        price: 1350,
      }, {
        id: '1_1_2_17',
        text: 'Cég 416',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/5.png',
        price: 1450,
      }, {
        id: '1_1_2_18',
        text: 'Cég 417',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/4.png',
        price: 1600,
      }, {
        id: '1_1_2_19',
        text: 'Cég 418',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/6.png',
        price: 1350,
      }, {
        id: '1_1_2_20',
        text: 'Cég 419',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/5.png',
        price: 1450,
      }, {
        id: '1_1_2_21',
        text: 'Cég 420',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/4.png',
        price: 1600,
      }, {
        id: '1_1_2_22',
        text: 'Cég 421',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/6.png',
        price: 1350,
      }, {
        id: '1_1_2_23',
        text: 'Cég 422',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/5.png',
        price: 1450,
      }, {
        id: '1_1_2_24',
        text: 'Cég 423',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/4.png',
        price: 1600,
      }, {
        id: '1_1_2_25',
        text: 'Cég 424',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/6.png',
        price: 1350,
      }, {
        id: '1_1_2_26',
        text: 'Cég 425',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/5.png',
        price: 1450,
      }, {
        id: '1_1_2_27',
        text: 'Cég 426',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/4.png',
        price: 1600,
      }, {
        id: '1_1_2_28',
        text: 'Cég 427',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/6.png',
        price: 1350,
      }, {
        id: '1_1_2_29',
        text: 'Cég 428',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/5.png',
        price: 1450,
      }, {
        id: '1_1_2_30',
        text: 'Cég 429',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/4.png',
        price: 1600,
      }, {
        id: '1_1_2_31',
        text: 'Cég 430',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/6.png',
        price: 1350,
      }, {
        id: '1_1_2_32',
        text: 'Cég 431',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/5.png',
        price: 1450,
      }, {
        id: '1_1_2_33',
        text: 'Cég 432',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/4.png',
        price: 1600,
      }, {
        id: '1_1_2_34',
        text: 'Cég 433',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/6.png',
        price: 1350,
      }, {
        id: '1_1_2_35',
        text: 'Cég 434',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/9.png',
        price: 4000,
      }],
    }, {
      id: '1_1_3',
      text: 'Ágazat 3',
      szintkod: 'C',
      expanded: true,
      items: [{
        id: '1_1_3_1',
        text: 'Cég 8',
        szintkod: 'D',
        expanded: true,
        items: [{
          id: '1_1_3_1_1',
          text: 'Üzletág 1',
          szintkod: 'E',
          image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/10.png',
          price: 160,
        }],
      }, {
        id: '1_1_3_2',
        text: 'Cég 9',
        szintkod: 'D',
        items: [{
          id: '1_1_3_2_1',
          text: 'Üzletág 2',
          szintkod: 'E',
          image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/12.png',
          price: 170,
        }, {
          id: '1_1_3_2_2',
          text: 'Üzletág 3',
          szintkod: 'E',
          image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/13.png',
          price: 175,
        }],
      }],
    }, {
      id: '1_1_4',
      text: 'Ágazat 4',
      szintkod: 'C',
      items: [{
        id: '1_1_4_1',
        text: 'Cég 10',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/14.png',
        price: 550,
      }, {
        id: '1_1_4_2',
        text: 'Cég 11',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/15.png',
        price: 750,
      }],
    }],

  }, {
    id: '1_2',
    text: 'Csoport 2',
    szintkod: 'B',
    items: [{
      id: '1_2_1',
      text: 'Ágazat 300',
      szintkod: 'C',
      items: [{
        id: '1_2_1_1',
        text: 'Cég 12',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/1.png',
        price: 240,
      }, {
        id: '1_2_1_2',
        text: 'Cég 13',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/2.png',
        price: 300,
      }],
    }, {
      id: '1_2_2',
      text: 'Ágazat 5',
      szintkod: 'C',
      items: [{
        id: '1_2_2_1',
        text: 'Cég 14',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/3.png',
        price: 1800,
      }, {
        id: '1_2_2_2',
        text: 'Cég 15',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/8.png',
        price: 3500,
      }],
    }, {
      id: '1_2_3',
      text: 'Ágazat 6',
      szintkod: 'C',
      items: [{
        id: '1_2_3_1',
        text: 'Cég 16',
        szintkod: 'D',
        items: [{
          id: '1_2_3_1_1',
          text: 'Üzletág 4',
          szintkod: 'E',
          image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/10.png',
          price: 170,
        }],
      }, {
        id: '1_2_3_2',
        text: 'Cég 17',
        szintkod: 'D',
        items: [{
          id: '1_2_3_2_1',
          text: 'Üzletág 5',
          szintkod: 'E',
          image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/12.png',
          price: 180,
        }, {
          id: '1_2_3_2_2',
          text: 'Üzletág 6',
          szintkod: 'E',
          image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/13.png',
          price: 190,
        }],
      }],
    }],

  }, {
    id: '1_3',
    text: 'Csoport 3',
    szintkod: 'B',
    items: [{
      id: '1_3_1',
      text: 'Ágazat 7',
      szintkod: 'C',
      items: [{
        id: '1_3_1_1',
        text: 'Cég 18',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/1.png',
        price: 220,
      }, {
        id: '1_3_1_2',
        text: 'Cég 19',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/2.png',
        price: 275,
      }],
    }, {
      id: '1_3_3',
      text: 'Ágazat 8',
      szintkod: 'C',
      items: [{
        id: '1_3_3_1',
        text: 'Cég 100',
        szintkod: 'D',
        items: [{
          id: '1_3_3_1_1',
          text: 'Üzletág 100',
          szintkod: 'E',
          image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/10.png',
          price: 165,
        }],
      }, {
        id: '1_3_3_2',
        text: 'Cég 101',
        szintkod: 'D',
        items: [{
          id: '1_3_3_2_1',
          text: 'Üzletág 101',
          szintkod: 'E',
          image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/12.png',
          price: 175,
        }],
      }],
    }],
  }, {
    id: '1_4',
    text: 'Csoport 4',
    szintkod: 'B',
    items: [{
      id: '1_4_1',
      text: 'Ágazat 9',
      szintkod: 'C',
      items: [{
        id: '1_4_1_1',
        text: 'Cég 102',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/1.png',
        price: 210,
      }, {
        id: '1_4_1_2',
        text: 'Cég 103',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/2.png',
        price: 250,
      }],
    }, {
      id: '1_4_2',
      text: 'Ágazat 10',
      szintkod: 'C',
      items: [{
        id: '1_4_2_1',
        text: 'Cég 104',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/7.png',
        price: 1100,
      }, {
        id: '1_4_2_2',
        text: 'Cég 105',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/5.png',
        price: 1400,
      }, {
        id: '1_4_2_3',
        text: 'Cég 106',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/4.png',
        price: 1500,
      }, {
        id: '1_4_2_4',
        text: 'Cég 107',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/6.png',
        price: 1300,
      }, {
        id: '1_4_2_5',
        text: 'Cég 108',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/9.png',
        price: 4000,
      }, {
        id: '1_4_2_6',
        text: 'Cég 109',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/3.png',
        price: 1700,
      }],
    }, {
      id: '1_4_3',
      text: 'Ágazat 11',
      szintkod: 'C',
      items: [{
        id: '1_4_3_1',
        text: 'Cég 110',
        szintkod: 'D',
        items: [{
          id: '1_4_3_1_1',
          text: 'Üzletág 200',
          szintkod: 'E',
          image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/10.png',
          price: 160,
        }],
      }, {
        id: '1_4_3_2',
        text: 'Cég 111',
        szintkod: 'D',
        items: [{
          id: '1_4_3_2_1',
          text: 'Üzletág 201',
          szintkod: 'E',
          image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/12.png',
          price: 170,
        }, {
          id: '1_4_3_2_2',
          text: 'Üzletág 202',
          szintkod: 'E',
          image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/13.png',
          price: 180,
        }],
      }],
    }, {
      id: '1_4_4',
      text: 'Ágazat 12',
      szintkod: 'C',
      items: [{
        id: '1_4_4_1',
        text: 'Cég 112',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/14.png',
        price: 550,
      }, {
        id: '1_4_4_2',
        text: 'Cég 113',
        szintkod: 'D',
        image: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/products/15.png',
        price: 750,
      }],
    }],

  }],
}];

export default {
  getProducts() {
    return products;
  },
};
