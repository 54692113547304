export const ColumnTypes = [{
    id: String,
    name: 'Szöveg'
}, {
    id: Number,
    name: 'Szám'
}, {
    id: Date,
    name: 'Dátum'
}];
