<template>
    <div>
        <div class="editDataTransformationStepSourcePopup">
            <CustomDxPopup :ref="editDataTransformationStepSourcePopupRefName"
                           :drag-enabled="false"
                           :show-title="true"
                           title="Adattranszformációs lépés szerkesztése"
                           :onShowing="onEditDataTransformationStepSourcePopupShowing"
                           :onHiding="onEditDataTransformationStepSourcePopupHiding"
                           container="#app"
                           width="95vw"
                           height="95vh">

                <DxScrollView :show-scrollbar="'onScroll'"
                              :use-native="false">
                    <DxLoadIndicator v-if="!loaded" />

                    <DxForm :visible="loaded"
                            :form-data="dataTransformationStepFormData"
                            :show-validation-summary="true"
                            :v-if="stepTypeEquals(0)"
                            validation-group="editDataTransformationStepValidationGroup">

                        <DxGroupItem caption="Transzformációs lépés adatai">
                            <DxSimpleItem data-field="DataSourceType"
                                          editor-type="dxSelectBox"
                                          :editor-options="{ searchEnabled: false, dataSource: this.dataSourceTypesDS, displayExpr: 'name', valueExpr: 'id', onValueChanged: this.dataSourceTypeChanged }">
                                <DxLabel text="Adatforrás típusa" />
                                <DxRequiredRule />
                            </DxSimpleItem>

                            <DxSimpleItem editor-type="dxSelectBox"
                                          data-field="DataContainerType"
                                          :visible="dataContainerTypeChooserVisibility()"
                                          :editor-options="{searchEnabled: false, dataSource: this.dataContainerTypeDS, displayExpr: 'name', valueExpr: 'id', onValueChanged: this.dataContainerTypeChanged }">
                                >
                                <DxLabel text="Adatkonténer típusa" />
                                <DxRequiredRule />
                            </DxSimpleItem>

                            <DxSimpleItem editor-type="dxSelectBox"
                                          data-field="DataContainerId"
                                          :visible="dataContainerChooserVisibility()"
                                          :editor-options="{searchEnabled: false, dataSource: this.dataContainerDS, displayExpr: 'name', valueExpr: 'id' }">
                                >
                                <DxLabel text="Adatkonténer" />
                                <DxRequiredRule />
                            </DxSimpleItem>

                            <DxSimpleItem editor-type="dxSelectBox"
                                          data-field="DataTransformationId"
                                          :visible="dataTransformationChooserVisibility()"
                                          :editor-options="{searchEnabled: false, dataSource: this.dataTransformationDS, displayExpr: 'name', valueExpr: 'id' }">
                                >
                                <DxLabel text="Adattranszformáció" />
                                <DxRequiredRule />
                            </DxSimpleItem>

                        </DxGroupItem>

                        <DxGroupItem cssClass="functionButtons">
                            <DxButtonItem alignment="left">
                                <DxButtonOptions text="Mégse"
                                                 type="default"
                                                 styling-mode="contained"
                                                 :on-click="hidePopup"
                                                 :disable="submitting" />
                            </DxButtonItem>
                            <DxButtonItem alignment="left">
                                <DxButtonOptions text="Adattranszformációs lépés mentése"
                                                 type="default"
                                                 styling-mode="contained"
                                                 validation-group="editDataTransformationStepValidationGroup"
                                                 :on-click="(e) => updateDataTransformationStep(e)"
                                                 :disable="submitting" />
                            </DxButtonItem>
                        </DxGroupItem>

                        <DxValidationSummary id="summary" />
                    </DxForm>
                </DxScrollView>
            </CustomDxPopup>
        </div>
    </div>
</template>

<script>
    import { handleStandardErrorNotification } from "@appfrm/core/index";
    import EventBus from "@/utils/event-bus";
    import cloneDeep from "clone-deep";
    import DxPopup, {
        DxToolbarItem as DxPopupToolbarItem
    } from "devextreme-vue/popup";
    import DxButton from 'devextreme-vue/button';
    import { DxScrollView } from 'devextreme-vue/scroll-view';
    import DxDropDownButton, { DxDropDownOptions, DxPosition } from 'devextreme-vue/drop-down-button';
    import CustomDxPopup from "@appfrm/core/components/CustomDxPopup";
    import SourceDataColumnTypeParameters from "../components/SourceDataColumnTypeParameters";
    import sourceDataService, {
        DataContainerApiBasePath,
        TABLE_DISPLAYED_NAME_MAX_LENGTH
    } from "../services/sourceData.service";
    import { DxLoadIndicator } from 'devextreme-vue/load-indicator';
    import DxTextBox from 'devextreme-vue/text-box';
    import { DxCheckBox } from 'devextreme-vue/check-box';
    import { DxSortable } from 'devextreme-vue/sortable';
    import DxForm, {
        DxGroupItem,
        DxSimpleItem,
        DxItem,
        DxButtonItem,
        DxButtonOptions,
        DxLabel,
        DxRequiredRule,
        DxCompareRule,
        DxRangeRule,
        DxStringLengthRule,
        DxPatternRule,
        DxEmailRule,
        DxAsyncRule,
        DxCustomRule
    } from 'devextreme-vue/form';
    import {
        DxValidator,
    } from 'devextreme-vue/validator';
    import { dataContainerPathFactory, dataContainerTypes, dataContainerTypeCapabilities, dataContainerOperation } from "../data-container"
    import Breadcrumb from "@appfrm/core/components/Breadcrumb";
    import DxSelectBox from "devextreme-vue/select-box";
    import DxValidationSummary from 'devextreme-vue/validation-summary';
    import DxTagBox from 'devextreme-vue/tag-box';

    export const TRANSFORMATION_DATA_SOURCE_TYPE_DATA_CONTAINER = 1;
    export const TRANSFORMATION_DATA_SOURCE_TYPE_DATA_TRANSFORMATION = 2;

    export default {
        name: "EditDataTransformationStepSourcePopup",
        components: {
            DxPopup,
            DxPopupToolbarItem,
            DxScrollView,
            CustomDxPopup,
            DxLoadIndicator,
            DxButton,
            DxForm,
            DxGroupItem,
            DxSimpleItem,
            DxItem,
            DxButtonItem,
            DxButtonOptions,
            DxLabel,
            DxRequiredRule,
            DxCompareRule,
            DxRangeRule,
            DxStringLengthRule,
            DxPatternRule,
            DxEmailRule,
            DxAsyncRule,
            DxCustomRule,
            DxTextBox,
            DxCheckBox,
            DxValidator,
            DxSortable,
            Breadcrumb,
            DxSelectBox,
            SourceDataColumnTypeParameters,
            DxValidationSummary,
            DxTagBox
        },
        props: [],
        data() {
            return {
                editDataTransformationStepSourcePopupRefName: 'editDataTransformationStepSourcePopup',
                groupId: null,
                dataContainerApiBasePath: DataContainerApiBasePath,
                transformationNameMaxLength: TABLE_DISPLAYED_NAME_MAX_LENGTH,
                loaded: false,
                submitting: false,
                formData: {
                    Name: "",
                    DataSourceType: null,
                    DataContainerType: null,
                    DataContainerId: null,
                    DataTransformationId: null
                },
                submitValidationResult: [],
                dataSourceTypesDS: [
                    { "id": 1, "name": "Adatkonténer" },
                    { "id": 2, "name": "Adattranszformáció" }
                ],
                dataContainerTypes: dataContainerTypes,
                dataContainerTypeDS: [],
                dataContainerDS: [],
                dataTransformationDS: [],
                dataTransformationDataSourceType: {
                    TRANSFORMATION_DATA_SOURCE_TYPE_DATA_CONTAINER: TRANSFORMATION_DATA_SOURCE_TYPE_DATA_CONTAINER,
                    TRANSFORMATION_DATA_SOURCE_TYPE_DATA_TRANSFORMATION: TRANSFORMATION_DATA_SOURCE_TYPE_DATA_TRANSFORMATION
                },
                dataTransformationStep: {
                    stepParameters: {}
                },
                dataTransformationStepFormData: {
                    
                },
                popupInstance: false,
                onHideCallbackFunction: null,
                initInProgress: false
            }
        },
        created() {
        },
        mounted() {
            this.dataContainerTypeDS = [
                {
                    "id": this.dataContainerTypes.sourceData.id,
                    "name": this.dataContainerTypes.sourceData.menuTitle
                },
                {
                    "id": this.dataContainerTypes.derivedData.id,
                    "name": this.dataContainerTypes.derivedData.menuTitle
                },
                {
                    "id": this.dataContainerTypes.masterData.id,
                    "name": this.dataContainerTypes.masterData.menuTitle
                }
            ];
            this.loaded = true;
        },
        watch: {
        },
        methods: {
            stepTypeEquals(stepTypeId) {
                if(this.dataTransformationStep !== undefined && this.dataTransformationStep !== null && this.dataTransformationStep.StepType !== undefined) {
                    return stepTypeId == this.dataTransformationStep.StepType;
                }
                return false;
            },
            async setDataTransformationStep(transformationStep) {
                this.dataTransformationStep = transformationStep;
                switch(this.dataTransformationStep.StepType) {
                    case 0:
                        var formData;
                        if(this.dataTransformationStep.StepParameters.DataContainerId !== undefined && this.dataTransformationStep.StepParameters.DataContainerId !== null) {
                            var dataContainer = await sourceDataService.getDefinition(this.dataTransformationStep.StepParameters.DataContainerId);
                            formData = {
                                DataSourceType: this.dataTransformationDataSourceType.TRANSFORMATION_DATA_SOURCE_TYPE_DATA_CONTAINER,
                                DataContainerType: dataContainer.DataContainerType,
                                DataContainerId: dataContainer.Id,
                                DataTransformationId: null
                            }
                        }
                        if(this.dataTransformationStep.StepParameters.DataTransformationId !== undefined && this.dataTransformationStep.StepParameters.DataTransformationId !== null) {
                            formData = {
                                DataSourceType: this.dataTransformationDataSourceType.TRANSFORMATION_DATA_SOURCE_TYPE_DATA_TRANSFORMATION,
                                DataContainerType: null,
                                DataContainerId: null,
                                DataTransformationId: this.dataTransformationStep.StepParameters.DataTransformationId
                            }
                        }
                        if(formData.DataSourceType == this.dataTransformationDataSourceType.TRANSFORMATION_DATA_SOURCE_TYPE_DATA_TRANSFORMATION) {
                        await sourceDataService.getAllTransformations()
                            .then((response) => {
                                let transformations = response.map((value) => {
                                    return {
                                        id: value.id,
                                        name: value.name
                                    }
                                });
                                
                                this.dataTransformationDS = transformations.filter((transformation) => transformation.id != this.dataTransformationStep.TransformationId);
                            });
                        } else {
                            var dataContainerObj = this.dataContainerTypes.getById(formData.DataContainerType);
                            try {
                                var response = await sourceDataService.getAllDefinitions(dataContainerObj)
                                this.dataContainerDS = response.map((value) => {
                                    return {
                                        id: value.id,
                                        name: value.displayedName
                                    }
                                });
                                this.dataContainerChooserVisibility();
                            }
                            catch (error) {
                                handleStandardErrorNotification("Nem sikerült az adatkonténer definíciók lekérése.", error);
                            }
                        }
                        this.initInProgress = true;
                        this.dataTransformationStepFormData = formData;
                        break;
                }
            },
            show(callbackFunction) {
                this.onHideCallbackFunction = callbackFunction;
                if (this.popup) {
                    this.popup.show();
                }
            },
            dataSourceTypeChanged(e) {
                if(!this.initInProgress) {
                    this.dataTransformationStepFormData.DataContainerType = null;
                    this.dataTransformationStepFormData.DataContainerId = null;
                    this.dataTransformationStepFormData.DataTransformationId = null;
                }
                if(e.value == this.dataTransformationDataSourceType.TRANSFORMATION_DATA_SOURCE_TYPE_DATA_TRANSFORMATION) {
                    sourceDataService.getAllTransformations()
                        .then((response) => {
                            let transformations = response.map((value) => {
                                return {
                                    id: value.id,
                                    name: value.name
                                }
                            });
                            this.dataTransformationDS = transformations.filter((transformation) => transformation.id != this.dataTransformationStep.TransformationId);
                        });
                }
                this.dataContainerTypeChooserVisibility();
                this.dataTransformationChooserVisibility()
            },
            dataContainerTypeChanged(e) {
                var dataContainer = this.dataContainerTypes.getById(e.value);
                if(dataContainer !== undefined) {
                    sourceDataService.getAllDefinitions(dataContainer)
                        .then((response) => {

                            this.dataContainerDS = response.map((value) => {
                                return {
                                    id: value.id,
                                    name: value.displayedName
                                }
                            });
                            this.dataContainerChooserVisibility();
                        });
                }
            },
            dataContainerTypeChooserVisibility() {
                if (this.dataTransformationStepFormData.DataSourceType == this.dataTransformationDataSourceType.TRANSFORMATION_DATA_SOURCE_TYPE_DATA_CONTAINER) {
                    return true;
                } else {
                    return false;
                }
            },
            dataContainerChooserVisibility() {
                if (this.dataTransformationStepFormData.DataSourceType == this.dataTransformationDataSourceType.TRANSFORMATION_DATA_SOURCE_TYPE_DATA_CONTAINER &&
                    this.dataTransformationStepFormData.DataContainerType > 0) {
                    return true;
                } else {
                    return false;
                }
            },
            dataTransformationChooserVisibility() {
                if (this.dataTransformationStepFormData.DataSourceType == this.dataTransformationDataSourceType.TRANSFORMATION_DATA_SOURCE_TYPE_DATA_TRANSFORMATION) {
                    return true;
                } else {
                    return false;
                }
            },
            tableChooserVisibility() {
                if (this.formData.DataSourceType == DATA_SOURCE_TYPE_LOCAL_DB_TABLE) {
                    return true;
                } else {
                    return false;
                }
            },
            externalChooserVisibility() {
                if (this.formData.DataSourceType == DATA_SOURCE_TYPE_EXTERNAL_DATA_SOURCE) {
                    return true;
                } else {
                    return false;
                }
            },
            onEditDataTransformationStepSourcePopupShowing(e) {
            },
            onEditDataTransformationStepSourcePopupHiding(e) {
                if (typeof this.onHideCallbackFunction !== null) {
                    this.onHideCallbackFunction();
                }
            },            
            async updateDataTransformationStep(e) {
                if (!this.submitting) {
                    var validationResult = e.validationGroup.validate();

                    if (validationResult.status === 'pending') {
                        validationResult = await validationResult.complete;
                    }
                    if (validationResult.isValid) {
                        this.submitting = true;
                        
                        switch(this.dataTransformationStepFormData.DataSourceType) {
                            case TRANSFORMATION_DATA_SOURCE_TYPE_DATA_CONTAINER:
                                this.dataTransformationStepFormData.DataTransformationId = null;
                                break;
                            case TRANSFORMATION_DATA_SOURCE_TYPE_DATA_TRANSFORMATION:
                                this.dataTransformationStepFormData.DataContainerId = null;
                                break;
                        }

                        var postData = {
                            StepId: this.dataTransformationStep.StepId,
                            TransformationId: this.dataTransformationStep.TransformationId,
                            StepType: this.dataTransformationStep.StepType,
                            Order: this.dataTransformationStep.Order,
                            StepParameters: {
                                DataSourceType: this.dataTransformationStepFormData.DataSourceType,
                                DataContainerId: this.dataTransformationStepFormData.DataContainerId,
                                DataTransformationId: this.dataTransformationStepFormData.DataTransformationId
                            }
                        };

                        try {
                            await sourceDataService.updateTransformationStepSource(this.dataTransformationStep.TransformationId, postData);
                            this.submitting = false;
                            this.hidePopup();
                        }
                        catch (error) {
                            this.submitting = false;
                            handleStandardErrorNotification("Nem sikerült a transzformációs lépés módosítása.", error);
                        }
                    }
                }
            },
            // submit error methods
            async validateSubmitErrors(e) {
                if (e.formItem) {
                    return !this.submitValidationResult.some(v => v.fieldName == e.formItem.dataField)
                }
                return true;
            },
            getSubmitErrorMessage(fieldName) {
                var serverValidation = this.submitValidationResult.find(v => v.fieldName === fieldName);
                return serverValidation ? serverValidation.message : "";
            },
            hidePopup() {
                this.instance.hide();
            },
        },
        computed: {
            instance() {
                return this.$refs[this.editDataTransformationStepSourcePopupRefName].instance;
            },
            popup() {
                if (!this.popupInstance) {
                    this.popupInstance = this.$refs[this.editDataTransformationStepSourcePopupRefName].instance;
                }
                return this.popupInstance;
            }
        }
    };
</script>

<style scoped lang="scss">
    .nav > li > a {
        text-decoration: none;
    }
    .nav > li > a > .img-circle {
        width: 100%;
        max-width: 20px;
        height: auto;
        border-radius: 50%;
        border: 0;
        position: relative;
        bottom: 1px;
        vertical-align:middle
    }
    .nav > li > a > span {
        margin-left: 5px;
        line-height: 24px;
        font-size: 14px;
    }
    ::v-deep .localDropdown {
        .dx-button-content {
            line-height: 21px;
            padding: 12px 18px 7px;
        }
        .dx-button-text {
            color: #ffffff;
        }
        .dx-button-mode-outlined {
            border: 0;
        }
        .dx-icon {
            vertical-align: bottom;
            height: 20px !important;
            width: 28px;
            margin-right: 0 !important;
        }
        .dx-icon.dx-icon-right{
            width: 0;
        }
        .dx-overlay-content {
            left: 10px;
        }
        .dx-button-text {
            display: none;
        }
    }
    ul.navbar-nav li {
        padding-left: 31px !important;
        line-height: 40px;
    }
    .bug-report a {
        color: #fff;
    }
    .bug-report-chooser {
        position: absolute;
        left: -120px;
        top: 40px;
        background-color: #012A4A;
        color: #fff;
    }
    ul.bug-report-chooser {
        list-style: none;
        padding: 0px;
    }
    .bug-report-chooser li {
        padding: 0px 20px;
        list-style: none;
        white-space: nowrap;
    }
    .notificationCount {
        position: relative;
        top: -2px;
        background-color: #ff0000;
        color: #fff;
        font-size: 11px;
        font-weight: bold;
        line-height: 22px;
        padding: 0px 0px;
        -webkit-border-radius: 22px;
        -moz-border-radius: 22px;
        border-radius: 22px;
        display: inline-block;
        width: 22px;
        height: 22px;
        text-align: center;
    }


    .import_errors {
        color: #ff0000;
        font-size: 12px;

        .import_errors_header {
            font-weight: bold;
        }
    }

    .source-data-table {
        border-collapse: collapse;
        display: table;

        .source-data-column-handle {
            width: 37px;
        }

        .source-data-column-index {
            text-align: center;
            width: 37px;
        }

        .source-data-column-providerDisplayedName,
        .source-data-column-claims-data {
            width: 200px;
        }

        .source-data-column-toBeImported {
            width: 100px;
        }

        .source-data-column-isKey {
            width: 100px;
        }

        .source-data-column-userDisplayedName.colspan-1 {
            width: 210px;
        }

        .source-data-column-userDisplayedName.colspan-2 {
        }

        .source-data-column-parameters {
            width: 210px;
        }

        .source-data-column-description {
            width: 210px;
        }

        .column-claim-select-wrapper {
            margin-top: 2px;
        }
    }

    .source-data-table-thead {
        display: table-header-group;

        .source-data-table-thead-row {
            display: table-row;

            .source-data-table-thead-row-cell {
                display: table-cell;
                border-style: solid;
                border-width: 1px;
                padding: 7px;
                font-weight: 400;
            }
        }
    }

    .source-data-table-tbody {
        display: table-row-group;

        .source-data-table-tbody-row {
            display: table-row;
            border-style: solid;
            border-width: 1px;

            .source-data-table-tbody-row-cell {
                display: table-cell;
                vertical-align: middle;
            }

            .source-data-table-tbody-row-cell.colspan-2 {
                width: max-content;
                vertical-align: middle;
            }

            .source-data-column-handle {
                width: 37px;
                text-align: center;
                cursor: move;
            }

            .source-data-column-index {
                text-align: center;
            }

            .source-data-column-toBeImported,
            .source-data-column-isKey,
            .source-data-column-is-company-id {
                text-align: center;
            }

            .source-data-column-actions {
                font-size: 18px;
                color: #0c3c60;
                text-align: center;
                text-decoration: none;
                width: 60px;
            }

            .source-data-column-actions a {
                font-size: 18px;
                color: #0c3c60;
                text-decoration: none;
            }
        }
    }

    .container-claims .dx-field {
        max-width: 600px;
    }

</style>
<style>
    .dx-scrollable.dx-scrollview.dx-widget .dx-scrollable-wrapper {
        border: none;
    }
    .dx-list-item-icon {
        vertical-align: bottom;
        height: 13px !important;
    }
    .dx-popup-content {
        text-align: left !important;
    }
    .functionButtons .dx-layout-manager {
        text-align: right;
    }

    .functionButtons .dx-collection {
        display: inline-block !important;
    }

    .functionButtons .dx-item {
        display: inline-block !important;
        margin-left: 16px;
    }

    .functionButtonsLeft .dx-layout-manager {
        text-align: left;
    }

    .functionButtonsLeft .dx-collection {
        display: inline-block !important;
    }

    .functionButtonsLeft .dx-item {
        display: inline-block !important;
        margin-right: 16px;
    }
</style>
