import SourceData from "./pages/SourceData";
import SourceDataDetails from "./pages/SourceDataDetails";
import DataTransformations from "./pages/DataTransformations";
import DataTransformationDetails from "./pages/DataTransformationDetails";

export const allDataContainerTypes = [
    {
        name: "SourceData",
        id: 1,
        path: "SourceData",
        menuTitle: "Forrásadatok",
        icon: "iconset import_sourcedata"
    },
    {
        name: "MasterData",
        id: 2,
        path: "MasterData",
        menuTitle: "Törzsadatok",
        icon: "iconset folder_folder"
    },
    {
        name: "DataService",
        id: 3,
        path: "DataService",
        menuTitle: "Adatszolgáltatások",
        icon: "iconset export_sourcedata"
    },
    {
        name: "Property",
        id: 4,
        path: "Property",
        menuTitle: "Tulajdonságok",
        icon: "iconset export_sourcedata"
    },
    {
        name: "DerivedData",
        id: 5,
        path: "DerivedData",
        menuTitle: "Származtatott adatok",
        icon: "iconset derived_data"
    }
]

function getByName(name) {
    return allDataContainerTypes.find((item) => item.name === name);
}

export const dataContainerTypes = {
    all: allDataContainerTypes,
    sourceData: getByName("SourceData"),
    masterData: getByName("MasterData"),
    dataService: getByName("DataService"),
    property: getByName("Property"),
    derivedData: getByName("DerivedData"),
    getByName: getByName,
    getById: function(id) {
        return allDataContainerTypes.find((item) => item.id === id);
    },
    getByPath: function(path) {
        return allDataContainerTypes.find((item) => item.path === path);
    }
}

export const dataContainerPathFactory = {
    getDataContainerTypePageUrl: function(dataContainerType) {
        return `/dataContainer/${dataContainerType.path}/dashboard`;
    },
    getDataContainerTablesUrl: function (dataContainerType) {
        return `/dataContainer/${dataContainerType.path}/tables`;
    },
    getDataContainerGroupsUrl: function (dataContainerType) {
        return `/dataContainer/${dataContainerType.path}/groups`;
    },
    getDataPageUrl: function(dataContainerType, dataContainerId) {
        return `/dataContainer/${dataContainerType.path}/data/${dataContainerId}/current`;
    },
    getDataHistoryPageUrl: function (dataContainerType, dataContainerId) {
        return `/dataContainer/${dataContainerType.path}/data/${dataContainerId}/history`;
    },
    getODataUrl: function (dataContainerType, dataContainerId) {
        return `/api/${dataContainerType.path}/Data/${dataContainerId}/odata`;
    },
    getDataContainerExternalDataSourcesUrl: function (dataContainerType) {
        return `/dataContainer/${dataContainerType.path}/external`;
    }
}

export const dataTransformation = {
    name: "DataTransformations",
    id: 1,
    path: "/dataTransformations",
    detailsPath: "/dataTransformations/data/:dataTransformationId",
    menuTitle: "Adattranszformációk",
    icon: "iconset data_transformation"
};

export const dataTransformationStepTypes = [
    {
        id: 0,
        const: 'dataSource',
        name: 'Adatforrás'
    },
    {
        id: 1,
        const: 'selectColumns',
        name: 'Oszlopok kiválasztása'
    },
    {
        id: 2,
        const: 'selectRows',
        name: 'Sorok kiválasztása'
    },
    {
        id: 3,
        const: 'groupBy',
        name: 'Csoportosítás'
    }
];

export const dataTransformationStepGroupByAggregationTypes = [
    {
        id: 0,
        const: 'count',
        name: 'Számosság'
    },
    {
        id: 1,
        const: 'min',
        name: 'Minimum'
    },
    {
        id: 2,
        const: 'max',
        name: 'Maximum'
    },
    {
        id: 3,
        const: 'sum',
        name: 'Összeg'
    },
    {
        id: 4,
        const: 'átlag',
        name: 'Átlag'
    }
];

export const dataContainerRoutes = [
    {
        path: dataContainerPathFactory.getDataContainerTypePageUrl({path:":dataContainerType"}),
        name: "SourceData",
        component: SourceData
    },
    {
        path: dataContainerPathFactory.getDataContainerTablesUrl({ path: ":dataContainerType" }),
        name: "SourceDataTables",
        component: SourceData
    },
    {
        path: dataContainerPathFactory.getDataContainerGroupsUrl({ path: ":dataContainerType" }),
        name: "SourceDataGroups",
        component: SourceData
    },
    {
        path: dataContainerPathFactory.getDataPageUrl({path:":dataContainerType"}, ":dataContainerId"),
        name: "SourceDataDetails",
        component: SourceDataDetails
    },
    {
        path: dataContainerPathFactory.getDataHistoryPageUrl({ path: ":dataContainerType" }, ":dataContainerId"),
        name: "SourceDataHistory",
        component: SourceDataDetails
    },
    {
        path: dataContainerPathFactory.getDataContainerExternalDataSourcesUrl({ path: ":dataContainerType" }),
        name: "SourceDataExternalDataSources",
        component: SourceData
    },
    {
        path: dataTransformation.path,
        name: "DataTransformations",
        component: DataTransformations
    },
    {
        path: dataTransformation.detailsPath,
        name: "DataTransformationDetails",
        component: DataTransformationDetails
    }
]

export const externalDataSourceType = {
    hostedWebService: {
        id: 1,
        name: "Hosztolt webszolgáltatás"
    },
    mirroredLocalDbTable: {
        id: 2,
        name: "Tükrözött adattáblák"
    }
}

export const mirroredLocalDbUpdateStatuses = {
    notStarted: {
        id: 0,
        name: "Feldolgozásra vár"
    },
    inProgress: {
        id: 1,
        name: "Folyamatban"
    },
    failed: {
        id: 2,
        name: "Hiba történt"
    },
    succeeded: {
        id: 3,
        name: "Sikeresen lezárult"
    },
    notInitialized: {
        id: 4,
        name: "-"
    }
}

export const dataContainerDataType = {
    text: {
        id: 1,
        name: 'Szöveg'
    },
    number: {
        id: 2,
        name: 'Szám'
    },
    property: {
        id: 3,
        name: 'Tulajdonság'
    },
    propertyForeignKey: {
        id: 4,
        name: 'Tulajdonság idegen kulcs'
    },
    dateTime: {
        id: 5,
        name: 'Dátum'
    },
    boolean: {
        id: 6,
        name: 'Logikai'
    },
    integer: {
        id: 7,
        name: 'Egész szám'
    },
    decimal: {
        id: 8,
        name: 'Decimális'
    }
}

export const dataTransformationStepGroupByValidAggregationDataTypes = [
    dataContainerDataType.number,
    dataContainerDataType.decimal,
    dataContainerDataType.integer           
];

export const dataContainerOperation = {
    hasOperation: function (dataContainerType, dataContainerOperation) {
        var dcCaps = dataContainerTypeCapabilities.filter(c => c.dataContainerType.id == dataContainerType.id);
        if (dcCaps[0] != undefined) {
            var allowedOperations = dcCaps[0].allowedOperations;
            var filteredOperations = allowedOperations.filter(o => o.id == dataContainerOperation.id);
            return filteredOperations.length > 0;
        } else return false;
    },
    setDataSource: {
        id: 1,
        name: 'Adatforrás beállítása'
    },
    viewData: {
        id: 2,
        name: 'Adatok megtekintése'
    },
    exportData: {
        id: 3,
        name: 'Adatok exportálása'
    },
    viewHistoricData: {
        id: 4,
        name: 'Hisztorikus adatok megtekintése'
    },
    exportHistoricData: {
        id: 5,
        name: 'Hisztorikus adatok exportálása'
    },
    updateData: {
        id: 6,
        name: 'Adatok frissítése'
    },
    editData: {
        id: 7,
        name: 'Adatok szerkesztése'
    },
    viewODataFeed: {
        id: 8,
        name: 'OData adatcsatorna megtekintése'
    },
    viewExternalDataSources: {
        id: 9,
        name: 'Külső adatforrások megtekintése'
    }
}

export const dataContainerTypeCapabilities = [
    {
        dataContainerType: dataContainerTypes.sourceData,
        allowedDataTypes: [
            dataContainerDataType.text,
            dataContainerDataType.number,
            dataContainerDataType.decimal,
            dataContainerDataType.boolean,
            dataContainerDataType.integer,
            dataContainerDataType.dateTime
        ],
        allowedOperations: [
            dataContainerOperation.setDataSource,
            dataContainerOperation.viewData,
            dataContainerOperation.exportData,
            dataContainerOperation.viewHistoricData,
            dataContainerOperation.exportHistoricData,
            dataContainerOperation.updateData,
            dataContainerOperation.editData,
            dataContainerOperation.viewExternalDataSources
        ],
        minKeyColumnCount: 0,
        maxKeyColumnCount: 100
    },
    {
        dataContainerType: dataContainerTypes.masterData,
        allowedDataTypes: [
            dataContainerDataType.text,
            dataContainerDataType.number,
            dataContainerDataType.decimal,
            dataContainerDataType.property,
            dataContainerDataType.boolean,
            dataContainerDataType.integer,
            dataContainerDataType.dateTime
        ],
        allowedOperations: [
            dataContainerOperation.viewData,
            dataContainerOperation.exportData,
        ],
        minKeyColumnCount: 1,
        maxKeyColumnCount: 1
    },
    {
        dataContainerType: dataContainerTypes.dataService,
        allowedDataTypes: [
            dataContainerDataType.text,
            dataContainerDataType.number,
            dataContainerDataType.decimal,
            dataContainerDataType.boolean,
            dataContainerDataType.integer,
            dataContainerDataType.dateTime
        ],
        allowedOperations: [
            dataContainerOperation.viewData,
            dataContainerOperation.exportData,
            dataContainerOperation.viewHistoricData,
            dataContainerOperation.exportHistoricData,
            dataContainerOperation.viewODataFeed
        ],
        minKeyColumnCount: 0,
        maxKeyColumnCount: 100
    },
    {
        dataContainerType: dataContainerTypes.property,
        allowedDataTypes: [
            dataContainerDataType.text,
            dataContainerDataType.number,
            dataContainerDataType.decimal,
            dataContainerDataType.boolean,
            dataContainerDataType.integer,
            dataContainerDataType.dateTime
        ],
        allowedOperations: [
            dataContainerOperation.viewData,
            dataContainerOperation.exportData,
            dataContainerOperation.viewHistoricData,
            dataContainerOperation.exportHistoricData
        ],
        minKeyColumnCount: 0,
        maxKeyColumnCount: 100
    },
    {
        dataContainerType: dataContainerTypes.derivedData,
        allowedDataTypes: [
            dataContainerDataType.text,
            dataContainerDataType.number,
            dataContainerDataType.decimal,
            dataContainerDataType.boolean,
            dataContainerDataType.integer,
            dataContainerDataType.dateTime,
            dataContainerDataType.property
        ],
        allowedOperations: [
            dataContainerOperation.viewData,
            dataContainerOperation.exportData,
            dataContainerOperation.viewHistoricData,
            dataContainerOperation.exportHistoricData,
        ],
        minKeyColumnCount: 0,
        maxKeyColumnCount: 100
    }
]
