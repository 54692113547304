import RequestHandler from "@appfrm/core/services/request-handler";
class EntityColumnsService {
    constructor() {
        this.path = "entitycolumns";
        if (EntityColumnsService.instance) {
            return EntityColumnsService.instance;
        }
        EntityColumnsService.instance = this;
        return this;
    }
    async get(loadOptions) {
        return await RequestHandler.getWithParams(this.path, loadOptions);
    }
    async insert(data) {
        return await RequestHandler.post(this.path, data);
    }
    async update(data) {
        return RequestHandler.put(this.path, data);
    }
    async delete(id) {
        return await RequestHandler.delete(this.path, id);
    }
}
const instance = new EntityColumnsService();
export default instance;