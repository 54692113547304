<template>
    <dxScrollView :show-scrollbar="'onScroll'"
                  :use-native="false">
        <div style="margin: 10px;">
            <DxBtn name="editPositionNames"
                   text="Pozíció megnevezések"
                   hint="Pozíció megnevezések szerkesztése"
                   icon="edit"
                   class="positionNameBtn"
                   @click="editPositionNames" />
        </div>
        <div>
            <div v-for="(level, index) in levels"
                 v-if="level.name"
                 :key="level.id"
                 class="level">
                <div class="flex-column">
                    <div style="position:absolute;z-index:1;padding: 15px 8px 4px;">
                        <span class="level-title">({{ level.levelCode }}) {{ level.name }}</span>
                    </div>
                    <div style="width:100%">
                        <DxDataGrid :id="'level-'+index"
                                    :ref="'positionList'+index"
                                    :data-source="getDataSource(level.id)"
                                    :column-auto-width="true"
                                    :row-alternation-enabled="true"
                                    :allow-column-resizing="true"
                                    :show-row-lines="true"
                                    :show-borders="true"
                                    @init-new-row="onInitNewRow"
                                    @row-prepared="onRowPrepared">

                            <DxColumn caption=""
                                      :allowEditing="false"
                                      width="36"
                                      alignment="center"
                                      cell-template="validColumnTemplate"
                                      header-cell-template="validColumnHeader" />
                            <DxColumn data-field="id"
                                      caption="#"
                                      :allowEditing="false"
                                      width="50"
                                      alignment="center"
                                      cell-template="idColumnTemplate"
                                      :visible="false" />
                            <DxColumn data-field="positionLevel"
                                      caption="Pozíció szint"
                                      width="120"
                                      alignment="center"
                                      :allowEditing="false" />
                            <DxColumn v-if="false"
                                      data-field="name"
                                      data-type="number"
                                      caption="Pozíció megnevezése"
                                      header-cell-template="title-header">
                                <DxLookup :data-source="positionNamesDataSource"
                                          value-expr="id"
                                          display-expr="name" />
                            </DxColumn>

                            <DxColumn data-field="positionNames"
                                      data-type="object"
                                      caption="Lehetséges pozíciók megnevezése"
                                      :cell-template="cellTemplate"
                                      edit-cell-template="tagBoxEditor"
                                      :allow-reordering="false"
                                      :width="350" >
                            </DxColumn>

                            <DxColumn data-field="description"
                                      data-type="string"
                                      caption="Megjegyzés" />

                            <DxColumn data-field="mandatory"
                                      caption="Kötelező megadni"
                                      :width="150"
                                      data-type="boolean">
                            </DxColumn>
                            <DxColumn caption="Akciók"
                                      sortOrder="0"
                                      :allow-resizing="false"
                                      :allow-reordering="false"
                                      :width="100"
                                      type="buttons">
                                <DxButton template="deleteButtonTemplate" />
                            </DxColumn>

                            <DxEditing :allow-updating="true"
                                       :allow-deleting="true"
                                       :allow-adding="true"
                                       mode="cell" />

                            <DxPaging :page-size="500" />
                            <DxPager :visible="false" />

                            <DxScrolling show-scrollbar="always"
                                         column-rendering-mode="standard"
                                         row-rendering-mode="standard"
                                         mode="virtual"
                                         :preload-enabled="false" />

                            <template #title-header="{ data }">
                                <div class="d-flex">
                                    <div class="flex-1 text-left">{{ data.column.caption }}</div>
                                </div>
                            </template>

                            <template #validColumnTemplate="{ data: cellInfo }">
                                <div v-if="!cellInfo.data.positionNames || cellInfo.data.positionNames.length == 0"
                                     class="warning-icon-holder">
                                    <i class="dx-icon-warning warning-icon" title="Nincs kiválasztva pozíció megnevezés!"></i>
                                </div>
                            </template>

                            <template #idColumnTemplate="{ data: cellInfo }">
                                {{ cellInfo.row.rowIndex + 1 }}
                            </template>

                            <template #validColumnHeader="{ data }">
                                <i class="dx-icon-warning warning-icon-header"></i>
                            </template>

                            <template #tagBoxEditor="{ data: cellInfo }">
                                <PositionNameTagBox :value="cellInfo.value"
                                                    :on-value-changed="(value) => onValueChanged(value, cellInfo)"
                                                    :data-source="positionNamesDataSource"
                                                    :data-grid-component="cellInfo.component" />
                            </template>

                            <template #deleteButtonTemplate="{ data }">
                                <span class="dx-command-edit dx-command-edit-with-icons" v-if="data.data.isLastPositionLevel">
                                    <a @click="showConfirmPopup(data.data.id)" class="dx-link dx-icon-trash dx-link-icon" title="Törlés" aria-label="Törlés"></a>
                                </span>
                            </template>

                        </DxDataGrid>
                    </div>
                </div>
            </div>
        </div>

        <CustomDxPopup width="80%"
                       :height="600"
                       :show-title="true"
                       title="Pozíció megnevezések szerkesztése"
                       :drag-enabled="false"
                       :close-on-outside-click="true"
                       :show-close-buttton="true"
                       :onInitialized="onPopupInit"
                       :onHiding="onPopupClose"
                       container="#app">

            <DxPopupToolbarItem widget="dxButton"
                                toolbar="bottom"
                                location="after"
                                :options="close" />

            <template #content="{ data }">
                <div style="width:100%">
                    <DxDataGrid :data-source="positionNamesDataSource"
                                :column-auto-width="true"
                                :row-alternation-enabled="true"
                                :allow-column-resizing="true"
                                :show-row-lines="true"
                                :show-borders="true"
                                class="positionNameEditor">

                        <DxColumn data-field="id"
                                  caption="#"
                                  :allowEditing="false"
                                  width="50"
                                  alignment="center"
                                  cell-template="idColumnTemplate" />
                        <DxColumn data-field="name"
                                  caption="Mező neve" />
                        <DxColumn caption="Akciók"
                                  sortOrder="0"
                                  :allow-resizing="false"
                                  :allow-reordering="false"
                                  :width="100"
                                  type="buttons">
                        </DxColumn>

                        <DxEditing :allow-updating="true"
                                   :allow-deleting="true"
                                   :allow-adding="true"
                                   mode="cell"
                                   :use-icons="true" />

                        <DxScrolling show-scrollbar="always"
                                     column-rendering-mode="standard"
                                     row-rendering-mode="standard"
                                     :mode="'virtual'"
                                     :preload-enabled="true" />

                        <template #idColumnTemplate="{ data: cellInfo }">
                            {{ cellInfo.row.rowIndex + 1 }}
                        </template>

                    </DxDataGrid>
                </div>
            </template>
        </CustomDxPopup>

        <CustomDxPopup ref="confirmPopup"
                       :drag-enabled="false"
                       :close-on-outside-click="true"
                       :show-close-button="true"
                       :show-title="false"
                       :width="320"
                       :height="120"
                       container="#app">

            <DxPopupToolbarItem widget="dxButton"
                           toolbar="bottom"
                           location="center"
                           :options="closeButtonOptions"
                           :disable="submitting" />

            <DxPopupToolbarItem widget="dxButton"
                           toolbar="bottom"
                           location="center"
                           :options="deleteButtonOptions"
                           :disable="submitting" />
            <div class="confirm-text">              
                Biztos benne, hogy törli a pozíciószintet?
            </div>
        </CustomDxPopup>

    </dxScrollView>
</template>

<script>
    import { handleStandardErrorNotification } from "@appfrm/core/index";
    import positionService from "../services/position.service";
    import positionNameService from "../services/positionName.service";
    import CustomStore from "devextreme/data/custom_store";
    import DataSource from "devextreme/data/data_source";
    import DxList from "devextreme-vue/list";
    import DxForm, {
        DxItem as DxFormItem,
        DxButtonItem,
        DxLabel
    } from "devextreme-vue/form";
    import DxPopup, {
        DxToolbarItem as DxPopupToolbarItem
    } from "devextreme-vue/popup";
    import DxToolbar, { DxItem as DxToolbarItem } from "devextreme-vue/toolbar";
    import DxSelectBox from "devextreme-vue/select-box";
    import DxDataGrid, {
        DxGrouping,
        DxGroupPanel,
        DxSearchPanel,
        DxPaging,
        DxPager,
        DxSorting,
        DxScrolling,
        DxFilterRow,
        DxColumn,
        DxEditing,
        DxLookup,
        DxRequiredRule,
        DxPopup as DxDataGridEditPopup,
        DxPosition,
        DxForm as DxDatagridEditForm,
        DxButton,
        DxColumnFixing,
        DxRowDragging
    } from "devextreme-vue/data-grid";
    import { DxScrollView } from 'devextreme-vue/scroll-view';
    import { ColumnTypes } from "@appfrm/data-container/enums/ColumnTypes";
    import { DxButton as DxBtn } from 'devextreme-vue/button';
    import CustomDxPopup from "@appfrm/core/components/CustomDxPopup";
    import PositionNameTagBox from './PositionNameTagBox';

    export default {
        name: 'Positions',
        components: {
            DxPopup,
            DxFormItem,
            DxButtonItem,
            DxLabel,
            DxForm,
            DxToolbarItem,
            DxPopupToolbarItem,
            DxSelectBox,
            DxList,
            DxGrouping,
            DxGroupPanel,
            DxSearchPanel,
            DxPaging,
            DxPager,
            DxSorting,
            DxScrolling,
            DxFilterRow,
            DxColumn,
            DxEditing,
            DxLookup,
            DxRequiredRule,
            DxPopup,
            DxPosition,
            DxDatagridEditForm,
            DxButton,
            DxButtonItem,
            DxLabel,
            DxDataGridEditPopup,
            DxColumnFixing,
            DxRowDragging,
            DxDataGrid,
            DxScrollView,
            DxToolbar,
            DxBtn,
            CustomDxPopup,
            PositionNameTagBox
        },
        props: {
            levels: {
                type: Array,
                default: null
            }
        },
        data() {
            return {
                ColumnTypes: ColumnTypes,
                positionNamesDataSource: {
                    store: new CustomStore({
                        key: "id",
                        load: (loadOptions) => {
                            return positionNameService.get(loadOptions).then(result => {
                                if (loadOptions.searchValue) {
                                    let positions = result.filter(i => i.name != null && i.name.toLowerCase().includes(loadOptions.searchValue.toLowerCase()));
                                    return positions;
                                }
                                return result;
                            });
                        },
                        byKey: (key) => positionNameService.get({ 'key': key }),
                        insert: (values) => {
                            return positionNameService.insert(values);
                        },
                        update: (key, values) =>
                            positionNameService.update({
                                key: key,
                                values: JSON.stringify(values)
                            }),
                        remove: key => positionNameService.delete(key)
                    })
                },
                preferencePopup: null,
                close: {
                    icon: 'close',
                    text: 'Bezár',
                    onClick: () => this.preferencePopup.hide()
                },
                deleteButtonOptions: {
                    icon: 'trash',
                    text: 'Törlés',
                    onClick: () => {
                        this.deletePositionLevel();
                    },
                },
                closeButtonOptions: {
                    text: 'Bezár',
                    onClick: () => {
                        this.$refs['confirmPopup'].instance.hide();
                    },
                },
                confirmData: {
                    Id: 0,
                    Name: ''
                },
                submitting: false
            };
        },
        methods: {
            showConfirmPopup(id) {
                this.confirmData.Id = id;
                this.$refs['confirmPopup'].instance.show();
            },
            async deletePositionLevel() {
                this.submitting = true;
                try {
                    await positionService.delete(this.confirmData.Id);
                    this.submitting = false;
                    this.$refs['confirmPopup'].instance.hide();
                }
                catch (error) {
                    this.submitting = false;
                    handleStandardErrorNotification("Nem sikerült a pozíció törlése.", error);
                }
            },
            onInitNewRow(e) {
                new Promise(resolve => setTimeout(resolve, 5)).then(() => {
                    e.component.saveEditData();
                });
            },
            onRowPrepared(e) {
                if (e.component.getVisibleRows()[e.rowIndex] &&
                    e.component.getVisibleRows()[e.rowIndex].isNewRow &&
                    e.rowType == 'data') 
                    e.rowElement.classList.add('d-none');
            },
            getDataSource(hierarchyLevelId) {
                return new CustomStore({
                    key: "id",
                    load: (loadOptions) => {
                        return positionService.get(loadOptions).then(result => {
                            let positions = result.filter(item => item.hierarchyLevelId == hierarchyLevelId);
                            let lastIndex = positions.length - 1

                            positions.forEach((item, index) => {
                                item['isLastPositionLevel'] = index == lastIndex;
                            });

                            return positions;
                        });                        
                    },
                    insert: (values) => {
                        values.hierarchyLevelId = hierarchyLevelId;
                        return positionService.insert(values);
                    },
                    update: (key, values) =>
                        positionService.update({
                            key: key,
                            values: JSON.stringify(values),
                            changeNames: values.hasOwnProperty('positionNames')
                        }),
                    remove: key => positionService.delete(key)
                });
            },
            editPositionNames() {
                if (this.preferencePopup) {
                    this.preferencePopup.show();
                }
            },
            onPopupInit(e) {
                this.preferencePopup = e.component;
            },
            onPopupClose() {
                this.$parent.$parent.$parent.$refs.levelComponent.refreshLevels();
            },
            onValueChanged(value, cellInfo) {
                cellInfo.setValue(value ?? 0);
                cellInfo.component.updateDimensions();
            },
            cellTemplate(container, options) {
                const noBreakSpace = '\u00A0';
                const text = (options.value || []).map((element) => element.name).join(', ');
                container.textContent = text || noBreakSpace;
                container.title = text;
            },
        }
    }
</script>

<style>
    #mapScrollview {
        height: 100%;
    }
    .grid {
        fill-opacity: 0;
    }
    .dx-scrollable {
        width: 100%;
    }

    .dx-scrollable-content {
        padding-bottom: 0 !important;
    }

    .dx-datagrid-header-panel .dx-toolbar {
        margin-top: 6px;
        margin-bottom: 6px;
        padding-right: 10px;
    }

    .dx-datagrid-edit-popup .dx-state-readonly {
        border: none !important;
    }
</style>
<style lang="scss" scoped>
    .level {
        padding: 10px;
        padding-bottom: 30px;
    }
    .level:nth-child(even) {
        background-color: #dddddd;
    }
    .level-title {
        font-size: 22px;
        font-weight: bold;
    }
    ::v-deep .positionNameEditor {
        height: 455px;
    }
    ::v-deep .dx-datagrid .dx-row {
        background-color: #ffffff;
    }

    ::v-deep .dx-datagrid, ::v-deep .dx-toolbar {
        background-color: transparent;
    }
    ::v-deep .dx-toolbar-text-auto-hide .dx-button .dx-button-content {
        padding: 5px;
    }
    ::v-deep .dx-toolbar-text-auto-hide .dx-button .dx-icon {
        line-height: 20px;
    }
    .warning-icon {
        font-size: 18px;
        color: #ff0000;
        vertical-align: bottom;
    }
    .warning-icon-header {
        font-size: 18px;
        vertical-align: bottom;
    }
    ::v-deep .positionNameBtn {
        background-color: #012A4A;
        color: #ffffff;
        border-radius: 5px;
        border: none;

        &:active, &:hover {
            background-color: #1473b3;
        }

        i {
            color: #ffffff;
        }
    }
    .confirm-text {
        text-align: center;
    }
</style>