import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import VuexPersistence from "vuex-persist";
import { AppStatus } from "@/enums/AppStatus";
import OAuthService from "../utils/oauth-service";
import {MainWebsocketCallbackOptions, MainWebsocketHub} from "@/utils/main-websocket-hub";
import JwtHelper from "@/utils/jwt-helper";
import {User} from "@/models/user";
import {LoginWithPassword, Logout} from "@/enums/auth-actions";
import notify from "devextreme/ui/notify";
import accessTokenProver from "@appfrm/core/services/access-token-provider"

const vuexPersist = new VuexPersistence({
    key: "my-app",
    storage: window.localStorage,
    reducer: (state) => {
        const { appStatus, ...others } = state;
        return others;
    },
});

Vue.use(Vuex);

let hub = {};
hub.hubConnection = undefined;
hub = null;
let healthCheckId = null;
let refreshTokenTimerId = null;

function logStatus(state) {
    console.log(`%c ${state.appStatus}`, 'background-color: blue; color: white');
}

const store = new Vuex.Store({
    plugins: [vuexPersist.plugin],
    state: {
        counter: 0,
        isLogin: false,
        isLoginError: false,
        appStatus: AppStatus.Init,
        user: null,
        permissions: [],
        accessToken: null,
        refreshToken: null,
        accessTokenExpiry: null,
        rememberMe: null,
        isTimeout: null,
        errorMessage: null,
        serverOffline: null,
        jsPlumb: null,
    },
    getters: {
        currentCount(state) {
            return state.counter;
        },
        // isLoggedIn(state) {
        //     return state.isLogin;
        // },
        isLoginErrorGetter(state) {
            return state.isLoginError;
        },
        isSessionExpired: (state) => {
            if (state.accessTokenExpiry == null) {
                return true;
            }
            
            return new Date(state.accessTokenExpiry).valueOf() <= new Date().valueOf();
        },
        isLoggedIn: (state) => {
            return state.user != null;
        },
    },
    actions: {
        increment({ commit }) {
            commit('incrementCounter');
        },
        reset({ commit }) {
            commit('resetCounter');
        },
        login({ commit }, data) {
            return axios.post('api/users/login', data).then((response)=> {
                if (response.data === true) {
                    commit('hideLoginError');
                    commit('login');
                } else {
                    commit('showLoginError');
                }
            })
        },
        redirectForLogin({ commit }) {
            commit('storeToDefault');
        },
        [LoginWithPassword](context, loginUser) {
            return new Promise((resolve, reject) => {
                context.commit(LoginWithPassword, loginUser);
                OAuthService.loginWithPassword(loginUser)
                    .then((response) => {
                        context.commit('setStoreDatas', response);
                        context.dispatch('createWebSocket').then(r => {
                            context.dispatch('startRefreshTokenTimer');
                            if (!context.state.rememberMe) {
                                context.dispatch('startTimeoutTimer'); // TODO Implement
                            }

                            context.commit('loginSuccess');
                            resolve();
                        });
                    })
                    .catch((error) => {
                        if (!error.response) {
                            context.commit('serverOffline');
                        } else {
                            if (error.response.status === 400) {
                                context.commit('loginError');
                            }
                        }

                        reject(error);
                    });
            });
        },
        loginWithRefreshToken(context) {
            return new Promise((resolve, reject) => {
                context.commit('loginWithRefreshToken');
                OAuthService.refreshLogin(context.state.refreshToken)
                    .then((response) => {
                        context.commit('setStoreDatas', response);
                        context.dispatch('createWebSocket').then(r => {
                            context.dispatch('startRefreshTokenTimer');
                            context.commit('refreshLoginSuccess');
                        });
                        resolve();
                    })
                    .catch((error) => {
                        if (!error.response) {
                            context.commit('serverOffline');
                        } else {
                            if (error.response.status === 400) {
                                if (hub) {
                                    hub.hubConnection.stop().then(() => {
                                        context.commit('tokenError');
                                        context.dispatch(Logout).then(r => {
                                            reject(error);
                                        });
                                    });
                                } else {
                                    context.commit('tokenError');
                                    context.dispatch(Logout).then(r => {
                                        reject(error);
                                    });
                                }
                            }
                        }
                        resolve();
                    });
            });
        },
        createWebSocket(context) {
            return new Promise(resolve => {
                hub = new MainWebsocketHub(
                    '/mainHub',
                    new MainWebsocketCallbackOptions(context)
                );

                hub.startConnection(context).then((r) => {
                    resolve();
                });
            });
        },
        forceRefreshToken(context) {
            return context.dispatch('renewAccessTokenWithRefreshToken');
        },
        reconnecting(context) {
            context.commit('reconnecting');
        },
        sendMessage(context, data) {
            hub.sendMessage(context.state.user.id, data);
        },
        receiveMessage(context, data) {
            notify({
                message: data.msg,
                position: {
                    at: "top",
                    offset: "0 100"
                },
                shading: true,
            }, "info", 5000);
        },
        renewAccessTokenWithRefreshToken(context) {
            console.log(`%c RenewAccessTokenWithRefreshToken`, 'background-color: lightblue; color: white');
            return new Promise((resolve, reject) => {
                OAuthService.refreshLogin(context.state.refreshToken)
                    .then((response) => {
                        context.commit('setStoreDatas', response);
                        context.commit('refreshLoginSuccess');
                        resolve();
                    })
                    .catch((error) => {
                        if (!error.response) {
                            context.commit('serverOffline');
                            context.dispatch('stopRefreshTokenTimer').then(r => {
                                reject(error);
                            });
                        } else {
                            if (error.response.status === 400) {
                                context.commit('tokenError');
                                context.dispatch(Logout).then(r => {
                                    reject(error);
                                });
                            }
                        }
                    });
            });
        },
        [Logout](context) {
            return new Promise((resolve, reject) => {
                context.commit(Logout);
                context.dispatch('stopRefreshTokenTimer').then((r) => {
                    console.log('RefreshTokenTimer is stopped');
                    if (hub) {
                        try {
                            hub.hubConnection.stop().then(r => {
                                context.commit('storeToDefault');
                                resolve();
                            });
                        } catch (err) {
                            console.log(err);
                            setTimeout(start, 5000);
                            reject(error);
                        }
                    } else {
                        context.commit('storeToDefault');
                        resolve();
                    }
                });
            });
        },
        startRefreshTokenTimer(context) {
            console.log(`%c StartRefreshTokenTimer`, 'background-color: green; color: white');
            const accessTokenExpiry = context.state.accessTokenExpiry;
            const now = new Date().valueOf();
            const difference = accessTokenExpiry - now;
            console.log(`%c accessTokenExpiry: ${accessTokenExpiry}`, 'background-color: green; color: white');
            context.dispatch('refreshTokenTimer', difference);
        },
        refreshTokenTimer: (context, difference) => {
            const refreshTime = difference - 10000; // Lejárat előtt 10 másodperc
            refreshTokenTimerId = setTimeout(
                () => context.dispatch('renewer'),
                Math.max(refreshTime, 0)
            );
            context.commit('refreshTokenTimer', refreshTokenTimerId);
        },
        renewer(context) {
            if (context.state.appStatus === AppStatus.Success) {
                context.dispatch('renewAccessTokenWithRefreshToken').then(() => {
                    context.dispatch('startRefreshTokenTimer');
                });
            }
        },
        stopRefreshTokenTimer(context) {
            return new Promise(resolve=> {
                console.log('Stop RefreshTokenTimer');
                clearTimeout(refreshTokenTimerId); // TODO TEST
                resolve();
            })
        },
        refreshLoginSuccess(context) {
            return new Promise(resolve => {
                context.commit('refreshLoginSuccess');
                resolve();
            });
        },
        errorMessage(context, errorMessage) {
            context.commit('errorMessage', errorMessage);
        },
        startHealthCheck(context) {
            console.log(`startHealthCheck`);
            return new Promise(resolve => {
                context.dispatch('healthCheck').then(() => {
                    resolve();
                });
            });
        },
        healthCheck(context) {
            console.log(`healthCheck`);
            return new Promise(resolve => {
                healthCheckId = setInterval(
                    () => {
                        axios
                            .get(
                                `/api/healthcheck`
                            )
                            .then(() => {
                                clearInterval(healthCheckId);
                                context.commit('serverOnline');
                                resolve();
                            })
                            .catch(() => {
                                context.commit('healthCheck');
                            });
                    }, 5000
                );
            });
        }
    },
    mutations: {
        incrementCounter(state) {
            state.counter++
        },
        resetCounter(state) {
            state.counter = 0;
        },
        login(state) {
            state.isLogin = true;
        },
        showLoginError(state) {
            state.isLoginError = true;
        },
        hideLoginError(state) {
            state.isLoginError = false;
        },
        storeToDefault(state) {
            state.appStatus = AppStatus.Logout;
            logStatus(state);
            state.hasLoadedOnce = false; // TODO Ezt még át kell nézni
            state.loginUrl = "/login"; // TODO config
            state.homeUrl = "/"; // TODO config
            state.user = null;
            state.permissions = [];
            state.accessToken = null;
            state.refreshToken = null;
            state.accessTokenExpiry = null;
            state.rememberMe = null;
        },
        [LoginWithPassword] (state, loginUser) {
            state.appStatus = AppStatus.Logging;
            logStatus(state);
            state.rememberMe = loginUser.rememberMe;
            state.isTimeout = loginUser.isTimeout;
            state.errorMessage = null;
        },
        loginWithRefreshToken(state) {
            state.appStatus = AppStatus.AutoLogging;
            logStatus(state);
        },
        setStoreDatas(state, response) {
            const jwtHelper = new JwtHelper();
            const decodedAccessToken = jwtHelper.decodeToken(
                response.access_token
            );
            const permissions = Array.isArray(
                decodedAccessToken.permission
            )
                ? decodedAccessToken.permission
                : [decodedAccessToken.permission];
            const user = new User(
                decodedAccessToken.sub,
                decodedAccessToken.name,
                decodedAccessToken.fullname,
                decodedAccessToken.email,
                decodedAccessToken.phone_number,
                true, // TODO Paraméterezhető?
                false, // TODO Paraméterezhető?
                Array.isArray(decodedAccessToken.role)
                    ? decodedAccessToken.role
                    : [decodedAccessToken.role],
                decodedAccessToken.is_admin
            );

            const tokenExpiryDate = new Date();
            tokenExpiryDate.setSeconds(
                tokenExpiryDate.getSeconds() + response.expires_in
            );

            state.user = user;
            state.permissions = permissions;
            state.accessToken = response.access_token;
            accessTokenProver.setAccessToken(response.access_token)
            state.refreshToken = response.refresh_token;
            state.accessTokenExpiry = tokenExpiryDate;
        },
        loginSuccess(state) {
            state.appStatus = AppStatus.Success;
            logStatus(state);
        },
        refreshLoginSuccess(state) {
            state.appStatus = AppStatus.Success;
            logStatus(state);
            state.errorMessage = null;
        },
        loginError(state) {
            state.appStatus = AppStatus.Error;
            logStatus(state);
            state.errorMessage = "Hiba a bejelentkezési adatokban.";
        },
        tokenError(state) {
            state.appStatus = AppStatus.Error;
            logStatus(state);
            state.errorMessage = ""; //"A munkamenet lejárt. Kérem jelentkezzen be újra.";
        },
        [Logout](state) {
            state.appStatus = AppStatus.Logout;
            logStatus(state);
        },
        refreshTokenTimer(state, refreshTokenTimerId) {
            state.refreshTokenTimerId = refreshTokenTimerId;
        },
        serverOnline(state) {
            state.serverOffline = false;
            state.errorMessage = null;
        },
        healthCheck(state) {
            state.serverOffline = true;
            state.errorMessage = "A szerver nem érhető el.";
            state.appStatus = AppStatus.AutoReLogging;
            logStatus(state);
        },
        serverOffline(state) {
            state.serverOffline = true;
            state.errorMessage = "A szerver nem érhető el.";
        },
        errorMessage(state, errorMessage) {
            state.errorMessage = errorMessage; // TODO lehet, hogy már nincs rá szükség?
        },
        reconnecting(state) {
            state.appStatus = AppStatus.Reconnecting; // TODO lehet, hogy már nincs rá szükség?
            logStatus(state);
        },
        setInitLoadingState(state) {
            state.appStatus = AppStatus.Init;
            logStatus(state);
        }
    }
});

export { store as default };