<template>
    <div>
        <DxLookup v-if="currentValue == 0 || currentValue == null"
                  :data-source="getLevelLookupDataSource()"
                  display-expr="displayName"
                  value-expr="id"
                  search-expr="name"
                  v-model="selectedLevel"
                  placeholder="Szint kiválasztása..."
                  :opened="false"
                  :search-enabled="false"
                  :on-value-changed="onLevelValueChanged"
                  :show-clear-button="showClearButton"
                  :apply-button-text="lookupTexts.applyButtonText"
                  :cancel-button-text="lookupTexts.cancelButtonText"
                  :clear-button-text="lookupTexts.clearButtonText"
                  :next-button-text="lookupTexts.nextButtonText"
                  :no-data-text="lookupTexts.noDataText"
                  :search-placeholder="lookupTexts.searchPlaceholder">
            <DxDropDownOptions :close-on-outside-click="true"
                               :show-title="false" />
        </DxLookup>

        <DxLookup v-if="currentValue > 0 || (selectedLevel != null && selectedLevel > 0)"
                  :data-source="dataSource(levelIndex, { selectedEntityId: currentValue, selectedLevelId: selectedLevel })"
                  display-expr="displayName"
                  value-expr="id"
                  search-expr="name"
                  :value="currentValue"
                  v-model="currentValue"
                  :on-value-changed="(value) => onEntityValueChanged(value)"
                  :show-clear-button="showClearButton"
                  :opened="true"
                  placeholder="Szervezeti egység kiválasztása..."
                  :apply-button-text="lookupTexts.applyButtonText"
                  :cancel-button-text="lookupTexts.cancelButtonText"
                  :clear-button-text="lookupTexts.clearButtonText"
                  :next-button-text="lookupTexts.nextButtonText"
                  :no-data-text="lookupTexts.noDataText"
                  :search-placeholder="lookupTexts.searchPlaceholder">
            <DxDropDownOptions :close-on-outside-click="true"
                               :show-title="false" />
        </DxLookup>
    </div>
</template>
<script>

    import { DxLookup, DxDropDownOptions } from 'devextreme-vue/lookup';

    export default {
        name: 'EntityParentLookup',
        components: {
            DxLookup,
            DxDropDownOptions
        },
        props: {
            levels: {
                type: Array,
                default: () => [],
            },
            levelIndex: {
                type: Number,
                default: 0,
            },
            value: {
                type: Number,
                default: 0,
            },
            onValueChanged: {
                type: Function,
                default: () => function () { },
            },
            dataSource: {
                type: Function,
                default: () => function () { },
            },
            dataGridComponent: {
                type: Object,
                default: () => { },
            },
            showClearButton: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                currentValue: this.value,
                selectedLevel: 0,
                lookupTexts: {
                    placeholder: 'kiválasztás',
                    applyButtonText: 'OK',
                    cancelButtonText: 'Mégse',
                    clearButtonText: 'Törlés',
                    nextButtonText: 'Több',
                    noDataText: 'Nincs találat',
                    searchPlaceholder: 'Keresés',
                }
            };
        },
        methods: {
            onSelectionChanged() {
                this.dataGridComponent.updateDimensions();
            },
            onEntityValueChanged(item) {
                this.onValueChanged(item.value == null ? null : item.value);
            },
            onLevelValueChanged(e) {
                
            },
            getLevelLookupDataSource() {
                let levelList = this.levels.filter((level) => level.orderIndex < this.levelIndex);
                let modifiedList = [];
                levelList.forEach((item) => {
                    modifiedList.push({
                        id: item.id,
                        levelCode: item.levelCode,
                        levelType: item.levelType,
                        name: item.name,
                        displayName: `(${item.levelCode}) ${item.name}`
                    });
                });
                return modifiedList;
            },
        },
    };
</script>
