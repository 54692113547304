<template>
    <DxTagBox :data-source="dataSource"
                v-model:value="currentValue"
                :show-selection-controls="false"
                :max-displayed-tags="10000"
                :show-multi-tag-only="true"
                :on-value-changed="(e) => onValueChanged(e.value)"
                :on-selection-changed="onSelectionChanged"
                :search-enabled="true"
                value-expr="id"
                display-expr="name" />
</template>
<script>

    import DxTagBox from 'devextreme-vue/tag-box';

    export default {
        name: 'PositionNameTagBox',
        components: { DxTagBox },
        props: {
            value: {
                type: Array,
                default: () => [],
            },
            onValueChanged: {
                type: Function,
                default: () => function () { },
            },
            dataSource: {
                type: Object,
                default: () => { },
            },
            dataGridComponent: {
                type: Object,
                default: () => { },
            },
        },
        data() {
            console.log(this.value);
            return {
                currentValue: this.value.map((item) => item.id),
            };
        },
        methods: {
            onSelectionChanged() {
                this.dataGridComponent.updateDimensions();
            },
        },
    };
</script>
