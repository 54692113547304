import HumanResource from "./pages/HumanResource"
import Ceghierarchia from "./pages/Ceghierarchia"

export const organigramRoutes = [
    {
        path: "/human-resource",
        name: "Human-resource",
        component: HumanResource
    },
    {
        path: "/ceghierarchia",
        name: "Ceghierarchia",
        component: Ceghierarchia
    },
]