<template>
    <div style="height:100%;">
        <Breadcrumb :items="breadcrumbItems" id="breadcrumb">
            <template #toolbar>
                <DxButton icon="preferences"
                          type="default"
                          styling-mode="outlined"
                          @click="editSourceData"
                          width="24px"
                          height="24px"
                          hint="Tábla adatainak szerkesztése" />
                <DxButton icon="copy"
                          type="default"
                          styling-mode="outlined"
                          @click="copyODataUrl"
                          width="24px"
                          height="24px"
                          hint="OData URL másolása vágólapra"
                          :visible="dataContainerOperation.hasOperation(dataContainerType, dataContainerOperation.viewODataFeed)" />
                <DxButton icon="refresh"
                          type="default"
                          styling-mode="outlined"
                          @click="runTransformation"
                          width="24px"
                          height="24px"
                          :visible="isTargetOfCodedTransformation"
                          hint="Tábla frissítése" />
                <DxButton icon="pulldown"
                          type="default"
                          styling-mode="outlined"
                          @click="updateDataSource"
                          width="24px"
                          height="24px"
                          :visible="showUpdateButton"
                          :disabled="disabledUpdateButton"
                          :hint="!disabledUpdateButton? 'Tábla frissítése adatforrásból' : 'Nem lehet frissíteni a táblát, mert nem tartalmaz kulcs oszlopot'" />
                <DxButton icon="bulletlist"
                          type="default"
                          styling-mode="outlined"
                          @click="showExternalSourceLog"
                          width="24px"
                          height="24px"
                          :visible="showExternalSourceLogButton"
                          hint="Log megjelenítése" />
                <DxButton icon="refresh"
                          type="default"
                          styling-mode="outlined"
                          @click="showUpdateMirroredLocalDbTable"
                          width="24px"
                          height="24px"
                          :visible="isMirroredLocalDbTable"
                          hint="Adatok frissítése" />
            </template>
        </Breadcrumb>
        <DxTabPanel class="tabpanel" @title-click="onTabItemClick" :animation-enabled="false" :selected-index="selectedTabItemIndex">
            <DxTabItem title="Aktuális adatok" icon="smalliconslayout">
                <template #default>
                    <div class="px-2">
                        <div class="grid">
                            <DxLoadIndicator v-show="!sourceDataInitialized"
                                             :height="60"
                                             :width="60" />
                            <DxDataGrid v-if="sourceDataInitialized"
                                        :data-source="sourceDataDetails"
                                        :show-borders="true"
                                        :row-alternation-enabled="true"
                                        :remote-operations="false"
                                        :hover-state-enabled="true"
                                        :columns="columns"
                                        :filter-row="gridConfig.filterRow"
                                        :editing="gridConfig.editing"
                                        :ref="dataGridRefName"
                                        :sorting="gridConfig.sorting"
                                        :scrolling="gridConfig.scrolling"
                                        :height="gridConfig.height"
                                        :allow-column-resizing="gridConfig.allowColumnResizing"
                                        :column-resizing-mode="gridConfig.columnResizingMode"
                                        :column-chooser="gridConfig.columnChooser"
                                        @cell-prepared="onCellPrepared"
                                        @contentReady="onContentReady">
                            </DxDataGrid>
                        </div>
                    </div>
                </template>
            </DxTabItem>

            <DxTabItem title="Hisztorikus adatok" icon="smalliconslayout" :visible="dataContainerOperation.hasOperation(dataContainerType, dataContainerOperation.viewHistoricData)">
                <template #default>
                    <div class="px-2">
                        <div class="history-buttons">
                            <DxButton icon="download"
                                      text="Exportálás Excelbe"
                                      type="default"
                                      styling-mode="outlined"
                                      :visible="dataContainerOperation.hasOperation(dataContainerType, dataContainerOperation.exportHistoricData)"
                                      @click="exportToExcel" />
                        </div>
                        <div class="grid-history">
                            <DxLoadIndicator v-show="!sourceDataHistoryInitialized"
                                             :height="60"
                                             :width="60" />
                            <DxDataGrid v-if="sourceDataHistoryInitialized"
                                        :data-source="historicalSourceDataSource"
                                        id="grid-history"
                                        :show-borders="true"
                                        :row-alternation-enabled="true"
                                        :remote-operations="false"
                                        :hover-state-enabled="true"
                                        :columns="historyColumns"
                                        :filter-row="dataHistoryGridConfig.filterRow"
                                        :editing="dataHistoryGridConfig.editing"
                                        :ref="dataHistoryGridRefName"
                                        :sorting="dataHistoryGridConfig.sorting"
                                        :scrolling="dataHistoryGridConfig.scrolling"
                                        :height="dataHistoryGridConfig.height"
                                        :group-panel="dataHistoryGridConfig.groupPanel"
                                        :allow-column-resizing="dataHistoryGridConfig.allowColumnResizing"
                                        :column-resizing-mode="dataHistoryGridConfig.columnResizingMode">
                            </DxDataGrid>
                        </div>
                    </div>
                </template>
            </DxTabItem>
        </DxTabPanel>
        <SourceDataDefinitionEditPopup :ref="editPopupRefName"
                                       :container-type="dataContainerType"
                                       :container-id="dataContainerId" />
        <UpdateSourceDataPopup :ref="updatePopupRefName"
                               :container-type="dataContainerType"
                               :container-id="dataContainerId" />
        <ExternalDataSourceLogPopup :ref="externalSourceLogPopupRefName"
                                    :container-type="dataContainerType"
                                    :container-id="dataContainerId" />
        <UpdateFromMirroredLocalDbPopup ref="updateFromMirroredLocalDbPopup" v-if="isMirroredLocalDbTable" />
    </div>
</template>

<script>
    import { handleStandardErrorNotification, notifySuccess } from "@appfrm/core/index";
    import DxDataGrid, {
        DxColumn,
        DxColumnChooser,
        DxButton as DxGridButton,
        DxEditing
    } from "devextreme-vue/data-grid";
    import sourceDataService, { DataContainerApiBasePath, DATA_SOURCE_TYPE_NINCS, DATA_SOURCE_TYPE_CSV, DATA_SOURCE_TYPE_EXCEL, DATA_SOURCE_TYPE_LOCAL_DB_TABLE, DATA_SOURCE_TYPE_EXTERNAL_DATA_SOURCE, ExternalDataSourceType } from "../services/sourceData.service";
    import coreService from "@appfrm/core/services/core.service";
    import { SourceDataColumnNames } from "../enums/SourceDataColumnNames";
    import { HistoryRecordTypes } from "../enums/HistoryRecordTypes";
    import CustomStore from "devextreme/data/custom_store";
    import notify from 'devextreme/ui/notify';
    import DxTabPanel, { DxItem as DxTabItem } from "devextreme-vue/tab-panel";
    import Breadcrumb from "@appfrm/core/components/Breadcrumb";
    import SourceDataDefinitionEditPopup from "@appfrm/data-container/components/SourceDataDefinitionEditPopup";
    import UpdateSourceDataPopup from "@appfrm/data-container/components/UpdateSourceDataPopup";
    import { DxScrollView } from 'devextreme-vue/scroll-view';
    import DxButton from 'devextreme-vue/button';
    import { dataContainerPathFactory, dataContainerTypes, dataContainerOperation, dataContainerDataType } from "../data-container";
    import { DxLoadIndicator } from 'devextreme-vue/load-indicator';
    import copy from 'copy-text-to-clipboard';
    import ExternalDataSourceLogPopup from "@appfrm/data-container/components/ExternalDataSourceLogPopup";
    import UpdateFromMirroredLocalDbPopup from "@appfrm/data-container/components/UpdateFromMirroredLocalDbPopup";

    export default {
        name: "SourceDataDetails",
        components: {
            DxDataGrid,
            DxColumn,
            DxColumnChooser,
            DxGridButton,
            DxEditing,
            notify,
            DxTabPanel,
            DxTabItem,
            SourceDataColumnNames,
            SourceDataDefinitionEditPopup,
            UpdateSourceDataPopup,
            HistoryRecordTypes,
            Breadcrumb,
            DxScrollView,
            DxButton,
            DxLoadIndicator,
            ExternalDataSourceLogPopup,
            UpdateFromMirroredLocalDbPopup
        },
        data() {
            var dataContainerType = dataContainerTypes.getByPath(this.$route.params.dataContainerType);

            return {
                tabItemRoutes: [
                    /* 0: */ dataContainerPathFactory.getDataPageUrl(dataContainerType, this.$route.params.dataContainerId),
                    /* 1: */ dataContainerPathFactory.getDataHistoryPageUrl(dataContainerType, this.$route.params.dataContainerId)
                ],
                selectedTabItemIndex: 0,
                dataContainerTypes: dataContainerTypes,
                dataContainerType: dataContainerType,
                dataContainerDataType: dataContainerDataType,
                dataContainerOperation: dataContainerOperation,
                dataContainerApiBasePath: DataContainerApiBasePath,
                dataContainerId: this.$route.params.dataContainerId,
                isTargetOfCodedTransformation: false,
                sourceDataDetails: new CustomStore({
                    key: SourceDataColumnNames.ID_COLUMN_NAME,
                    insert: async (data) => {

                        this.editableDataFieldNames.forEach((enabledField) => {
                            if (data[enabledField] === undefined) {
                                data[enabledField] = null;
                            }
                        });

                        try {
                            await sourceDataService.insertDataRow(this.dataContainerId, data);
                            this.dataGrid.refresh();
                            this.$root.$emit('source_data_row_changed', data);
                            notifySuccess("Az adat létrehozása sikerült");
                        }
                        catch (error) {
                            handleStandardErrorNotification("Nem sikerült a forrásadat létrehozása.", error);
                        }
                    },
                    update: async (key, data) => {

                        if (data[SourceDataColumnNames.ID_COLUMN_NAME] === undefined) {
                            data[SourceDataColumnNames.ID_COLUMN_NAME] = key;
                        }

                        let selectedRowsData = this.dataGrid.getVisibleRows();
                        
                        selectedRowsData.forEach((row) => {
                            if (row.data.Id == key) {
                                this.editableDataFieldNames.forEach((enabledField) => {
                                    if (data[enabledField] === undefined) {
                                        if (row.data[enabledField] !== undefined) {
                                            data[enabledField] = row.data[enabledField];
                                        }
                                        else {
                                            data[enabledField] = '';
                                        }
                                    }
                                });
                            }
                        });

                        try {
                            await sourceDataService.updateDataRow(this.dataContainerId, data);
                            this.dataGrid.refresh();
                            this.$root.$emit('source_data_row_changed', data);
                            notifySuccess("Forrásadat sikeresen módosítva");
                        }
                        catch (error) {
                            handleStandardErrorNotification("Nem sikerült az adat módosítása.", error);
                        }
                    },
                    remove: async (key) => {
                        try {
                            await sourceDataService.removeDataRow(this.dataContainerId, key);
                            this.dataGrid.refresh();
                            this.$root.$emit('source_data_row_changed', key);
                        }
                        catch(error) {
                            handleStandardErrorNotification("Nem sikerült az adat törlése.", error);
                        }
                    },
                    load: async () => {
                        try {
                            var sourceDataDetails = await sourceDataService.getData(this.dataContainerId);
                            let countNum = 1;
                            sourceDataDetails.Rows.forEach((value) => {
                                value[SourceDataColumnNames.ORDER_COLUMN_NAME] = countNum++;
                            });
                            return sourceDataDetails.Rows;
                        }
                        catch (error) {
                            handleStandardErrorNotification("Nem sikerült az adatkonténer adatainak betöltése.", error);
                        }
                    }
                }),
                historicalSourceDataSource: new CustomStore({
                    key: "Id",
                    load: async () => {
                        try {
                            var sourceDataDetails = await sourceDataService.getDataHistory(this.dataContainerId);
                            return sourceDataDetails.Rows;
                        }
                        catch (error) {
                            handleStandardErrorNotification("Nem sikerült a hisztorikus adatok betöltése.", error);
                        }
                    }
                }),
                sourceDataInitialized: false,
                sourceDataName: "",
                sourceDataDescription: "",
                columns: [],
                editableDataFieldNames: [],
                gridConfig: {
                    filterRow: {
                        visible: true,
                        showOperationChooser: true
                    },
                    editing: {
                        allowAdding: true,
                        allowUpdating: true,
                        allowDeleting: true,
                        mode: 'row'
                    },
                    sorting: {
                        mode: 'multiple'
                    },
                    scrolling: {
                        mode: 'standard'
                    },
                    height: '100%',
                    allowColumnResizing: true,
                    columnResizingMode: 'widget',
                    columnChooser: {
                        enabled: true,
                        mode: 'select',
                        title: 'Oszlopválasztó'
                    }
                },
                dataGridRefName: 'gridSourceData',
                dataGridInstance: false,
                sourceDataHistoryInitialized: false,
                historyColumns: [],
                dataHistoryGridConfig: {
                    filterRow: {
                        visible: true,
                        showOperationChooser: true
                    },
                    editing: {
                        allowAdding: false,
                        allowUpdating: false,
                        allowDeleting: false,
                        mode: 'row'
                    },
                    sorting: {
                        mode: 'multiple'
                    },
                    scrolling: {
                        mode: 'standard'
                    },
                    height: '100%',
                    groupPanel: {
                        visible: true
                    },
                    allowColumnResizing: true,
                    columnResizingMode: 'widget'
                },
                dataHistoryGridRefName: 'gridSourceDataHistory',
                dataHistoryGridInstance: false,
                sourceDataDefinition: false,
                breadcrumbItems: [
                        {
                            icon: dataContainerType.icon,
                            text: dataContainerType.menuTitle,
                            title: `${dataContainerType.menuTitle} kezdőképernyő`,
                            click: () => {
                                this.$router.push(dataContainerPathFactory.getDataContainerTypePageUrl(this.dataContainerType));
                            }
                        },
                        {
                            icon: false,
                            text: () => this.sourceDataName + (this.sourceDataDescription ? ' (' + this.sourceDataDescription + ')' : ''),
                            click: false
                        }
                ],
                editPopupRefName: 'editPopup',
                updatePopupRefName: 'updatePopup',
                externalSourceLogPopupRefName: 'externalSourceLogPopup',
                editPopup: false,
                updatePopup: false,
                externalSourceLogPopup: false,
                defaultColumnWidth: 180,
                headerCellClientOffsetDiff: 14,
                dynamicWidthColumnIds: [],
                dynamicWidthColumnGroups: {},
                dynamicWidthColumnRefs: {},
                dynamicWidthColumnsInitialized: false,
                FRACTION_DIGITS_MAX: 20,// maximumFractionDigits MAX
                numberFormatter: (value, decimal, decimalSeparator, groupSeparator) => {
                    try {
                        value = parseFloat(value);
                        if (decimal !== -1) value = value.toFixed(decimal);
                        else decimal = 0;
                        value = Number(value).toLocaleString('en', { maximumFractionDigits: decimal > this.FRACTION_DIGITS_MAX ? this.FRACTION_DIGITS_MAX : decimal  });
                        value = groupSeparator !== false ? value.replace(/,/g, groupSeparator) : value.replace(/,/g, '');
                        if (decimalSeparator !== false) value = value.replace('.', decimalSeparator);
                    }
                    catch (e) {
                        
                    }
                    return value;
                }                
            };
        },
        computed: {
            dataGrid() {
                if (this.dataGridInstance) {
                    return this.dataGridInstance;
                }
                else if (this.$refs[this.dataGridRefName]) {
                    this.dataGridInstance = this.$refs[this.dataGridRefName].instance;
                }
                return this.dataGridInstance;
            },
            dataHistoryGrid() {
                if (this.dataHistoryGridInstance) {
                    return this.dataHistoryGridInstance;
                }
                else if (this.$refs[this.dataHistoryGridRefName]) {
                    this.dataHistoryGridInstance = this.$refs[this.dataHistoryGridRefName].instance;
                }
                return this.dataHistoryGridInstance;
            },
            popup() {
                if (this.editPopup) {
                    return this.editPopup;
                }
                else if (this.$refs[this.editPopupRefName]) {
                    this.editPopup = this.$refs[this.editPopupRefName];
                }
                return this.editPopup;
            },
            updateDataPopup() {
                if (this.updatePopup) {
                    return this.updatePopup;
                }
                else if (this.$refs[this.updatePopupRefName]) {
                    this.updatePopup = this.$refs[this.updatePopupRefName];
                }
                return this.updatePopup.instance;
            },
            dataExternalSourceLogPopup() {
                if (this.externalSourceLogPopup) {
                    return this.externalSourceLogPopup;
                }
                else if (this.$refs[this.externalSourceLogPopupRefName]) {
                    this.externalSourceLogPopup = this.$refs[this.externalSourceLogPopupRefName];
                }
                return this.externalSourceLogPopup.instance;
            },
            showUpdateButton() {
                let enabledTypes = [DATA_SOURCE_TYPE_CSV, DATA_SOURCE_TYPE_EXCEL, DATA_SOURCE_TYPE_LOCAL_DB_TABLE];
                return this.sourceDataDefinition && enabledTypes.indexOf(this.sourceDataDefinition.DataSourceType) !== -1;
            },
            showExternalSourceLogButton() {
                return this.sourceDataDefinition && (this.sourceDataDefinition.DataSourceType == DATA_SOURCE_TYPE_EXTERNAL_DATA_SOURCE || this.sourceDataDefinition.DataSourceType == DATA_SOURCE_TYPE_LOCAL_DB_TABLE);
            },
            disabledUpdateButton() {
                if (this.sourceDataDefinition) {
                    let enabled = this.sourceDataDefinition.Columns.some((item) => item.IsKey == true);
                    return !enabled;
                }
            },
            isMirroredLocalDbTable() {
                return this.sourceDataDefinition && this.sourceDataDefinition.ExternalDataSourceDefinition && this.sourceDataDefinition.ExternalDataSourceDefinition.Type == ExternalDataSourceType.MirroredLocalDbTable;
            }
        },
        methods: {
            copyODataButtonVisible() {
                return this.dataContainerType.id == this.dataContainerTypes.sourceData.id;
            },
            calculatePropertyCellValue(columnData, column) {
                var renderedRows = [];
                var cellValue = columnData[column.dataField];
                cellValue.forEach(function (v, i, a) {
                    if (typeof v === "object") {
                        var template = column.cellTemplate;
                        for (const [key, value] of Object.entries(v)) {
                            if (key.substring(0, 3) == "DC_") {
                                var propertyName = key.substring(3);
                                var markup = "[" + propertyName + "]";
                                if (value == null) value = "";
                                template = template.replace(markup, value);
                            }
                        }
                        renderedRows.push(template);
                    }
                });
                return '<table class="propertyInnerTable"><tr class="propertyInnerTableRow">' + renderedRows.join('</tr><tr class="propertyInnerTableRow">') + '</tr></table>';
            },
            async initializeSourceData() {

                this.sourceDataInitialized = false;

                var definition = await sourceDataService.getDefinition(this.dataContainerId);
                this.sourceDataDefinition = definition;

                var definitionColumns = definition.Columns.map(c => {
                    return {
                        dataField: c.DbColumnName,
                        caption: c.DisplayedName,
                        order: c.Order,
                        // Ha nem tulajdonság, akkor engedjük szerkeszteni az oszlopbeli mezőt.
                        allowEditing: (c.Type != this.dataContainerDataType.property.id),
                        controlData: {
                            containerDataType: c.Type,
                            parameters: c.Parameters
                        }
                    }
                });

                definitionColumns = definitionColumns.sort((a, b) => a.order - b.order);

                var columns = [{
                    name: SourceDataColumnNames.ERROR_MESSAGES_COLUMN_NAME,
                    dataField: SourceDataColumnNames.ERROR_MESSAGES_COLUMN_NAME,
                    width: '36',
                    allowEditing: false,
                    allowFiltering: false,
                    allowSorting: false,
                    alignment: 'center',
                    headerCellTemplate(container) {
                        let elem = document.createElement('I');
                        elem.classList = 'dx-icon-warning warning-icon-header';
                        container.append(elem);
                    }
                },
                { // Sorszám oszlop hozzáadás - TODO jobb megoldást találni
                    caption: '#',
                    width: '50',
                    dataField: SourceDataColumnNames.ORDER_COLUMN_NAME,
                    name: SourceDataColumnNames.ORDER_COLUMN_NAME,
                    allowEditing: false
                },
                {
                    caption: SourceDataColumnNames.ID_COLUMN_NAME,
                    width: '50',
                    dataField: SourceDataColumnNames.ID_COLUMN_NAME,
                    name: SourceDataColumnNames.ID_COLUMN_NAME,
                    allowEditing: false,
                    visible: false
                }];

                definitionColumns.forEach((column) => {
                    if (column.allowEditing) {
                        this.editableDataFieldNames.push(column.dataField);
                    }

                    column.width = this.defaultColumnWidth;
                    switch (column.controlData.containerDataType) {
                        // Tulajdonság oszlopra vonatkozó beállítások
                        case this.dataContainerDataType.property.id:
                            var propCount = 0;
                            var cellTemplate = "";
                            column.controlData.parameters.PropertyParameters.Columns.forEach(function (v, i, a) {
                                cellTemplate += '<td class="propertyDataCell prop-' + v.PropertyId + '" data-property-id="' + v.PropertyId + '">[' + v.PropertyName + ']</td>';
                                propCount++;
                            });
                            column.cellTemplate = cellTemplate;
                            column.calculateCellValue = t => this.calculatePropertyCellValue(t, column);
                            column.width = propCount * this.defaultColumnWidth;
                            break;
                        // Boolean oszlopra vonatkozó beállítások
                        case this.dataContainerDataType.boolean.id:
                            column['dataType'] = 'boolean';
                            break;
                        // Integer oszlopra vonatkozó beállítások
                        case this.dataContainerDataType.integer.id:
                            column['dataType'] = 'number';
                            break;
                        // Number oszlopra vonatkozó beállítások
                        case this.dataContainerDataType.number.id:
                            column['dataType'] = 'number';
                            column['format'] = (data) => {
                                if (!isNaN(data)) {

                                    let decimal = -1;
                                    if (column.controlData.parameters && column.controlData.parameters.NumberParameters && column.controlData.parameters.NumberParameters.DecimalPlaces) {
                                        decimal = column.controlData.parameters.NumberParameters.DecimalPlaces;
                                    }

                                    let decimalSeparator = false;
                                    if (column.controlData.parameters && column.controlData.parameters.NumberParameters && column.controlData.parameters.NumberParameters.DecimalSeparator) {
                                        decimalSeparator = column.controlData.parameters.NumberParameters.DecimalSeparator;
                                    }

                                    let groupSeparator = false;
                                    if (column.controlData.parameters && column.controlData.parameters.NumberParameters && column.controlData.parameters.NumberParameters.ThousandSeparator) {
                                        groupSeparator = column.controlData.parameters.NumberParameters.ThousandSeparator;
                                    }

                                    data = this.numberFormatter(data, decimal, decimalSeparator, groupSeparator);
                                }
                                return data;
                            }

                            break;
                        // Decimal oszlopra vonatkozó beállítások
                        case this.dataContainerDataType.decimal.id:
                            column['dataType'] = 'number';
                            column['format'] = (data) => {
                                if (!isNaN(data)) {

                                    let decimal = -1;
                                    if (column.controlData.parameters && column.controlData.parameters.DecimalParameters && column.controlData.parameters.DecimalParameters.DecimalPlaces) {
                                        decimal = column.controlData.parameters.DecimalParameters.DecimalPlaces;
                                    }

                                    let decimalSeparator = false;
                                    if (column.controlData.parameters && column.controlData.parameters.DecimalParameters && column.controlData.parameters.DecimalParameters.DecimalSeparator) {
                                        decimalSeparator = column.controlData.parameters.DecimalParameters.DecimalSeparator;
                                    }

                                    let groupSeparator = false;
                                    if (column.controlData.parameters && column.controlData.parameters.DecimalParameters && column.controlData.parameters.DecimalParameters.ThousandSeparator) {
                                        groupSeparator = column.controlData.parameters.DecimalParameters.ThousandSeparator;
                                    }

                                    data = this.numberFormatter(data, decimal, decimalSeparator, groupSeparator);
                                }
                                return data;
                            }

                            break;
                        // Dátum oszlopra vonatkozó beállítások
                        case this.dataContainerDataType.dateTime.id:
                            column['dataType'] = 'datetime';
                            if (column.controlData.parameters !== undefined &&
                                column.controlData.parameters.DateTimeParameters !== undefined &&
                                column.controlData.parameters.DateTimeParameters.Format !== undefined) {
                                column['format'] = column.controlData.parameters.DateTimeParameters.Format;
                            }
                            break;
                    }

                    if (column.dataField == SourceDataColumnNames.ID_COLUMN_NAME) {
                        column['dataType'] = 'number',
                            column['allowEditing'] = false
                    }
                    columns.push(column);
                });

                // Akciók oszlop hozzáadása
                let commandButtons = {
                    caption: 'Akciók',
                    width: '100',
                    allowEditing: false,
                    allowSorting: false,
                    type: 'buttons',
                    buttons: [
                        {
                            name: 'edit',
                            icon: 'edit',
                            hint: 'Szerkesztés'
                        },
                        {
                            name: 'delete',
                            icon: 'trash',
                            hint: 'Törlés'
                        },
                        {
                            name: 'save',
                            icon: 'save',
                            hint: 'Mentés'
                        },
                        {
                            name: 'cancel',
                            icon: 'undo',
                            hint: 'Mégse'
                        }
                    ]
                };
                if (this.dataContainerOperation.hasOperation(this.dataContainerType, this.dataContainerOperation.editData)) {
                    columns.push(commandButtons);
                }

                this.setSourceData(definition.DisplayedName, definition.Description, definition.IsTargetOfCodedTransformation, columns);                
                this.sourceDataInitialized = true;

                this.initializeSourceHistoryData();

            },
            async initializeSourceHistoryData() {

                this.sourceDataHistoryInitialized = false;

                var definition;

                if (this.sourceDataInitialized) {
                    definition = this.sourceDataDefinition;
                }
                else {
                    definition = await sourceDataService.getDefinition(this.dataContainerId);
                }

                var definitionColumns = definition.Columns.map(c => {
                    return {
                        dataField: c.DbColumnName,
                        caption: c.DisplayedName,
                        order: c.Order,
                        allowEditing: false,
                        controlData: {
                            containerDataType: c.Type,
                            parameters: c.Parameters
                        }
                    }
                });

                definitionColumns = definitionColumns.sort((a, b) => a.order - b.order);

                var columns = [
                    {
                        caption: SourceDataColumnNames.ID_COLUMN_NAME,
                        width: '50',
                        dataField: SourceDataColumnNames.ID_COLUMN_NAME,
                        name: SourceDataColumnNames.ID_COLUMN_NAME,
                        dataType: 'number',
                        allowEditing: false,
                        visible: true,
                        groupIndex: 0
                    },
                    {
                        caption: SourceDataColumnNames.HISTORY_ID_COLUMN_NAME,
                        width: '80',
                        dataField: SourceDataColumnNames.HISTORY_ID_COLUMN_NAME,
                        name: SourceDataColumnNames.HISTORY_ID_COLUMN_NAME,
                        allowEditing: false,
                        dataType: 'number',
                        visible: true
                    },
                    {
                        caption: SourceDataColumnNames.RECEIVED_AT_COLUMN_NAME,
                        width: '120',
                        dataField: SourceDataColumnNames.RECEIVED_AT_COLUMN_NAME,
                        name: SourceDataColumnNames.RECEIVED_AT_COLUMN_NAME,
                        allowEditing: false,
                        dataType: 'datetime',
                        visible: true
                    },
                    {
                        caption: SourceDataColumnNames.CHANGED_AT_COLUMN_NAME,
                        width: '120',
                        dataField: SourceDataColumnNames.CHANGED_AT_COLUMN_NAME,
                        name: SourceDataColumnNames.CHANGED_AT_COLUMN_NAME,
                        allowEditing: false,
                        dataType: 'datetime',
                        visible: true
                    },
                    {
                        caption: SourceDataColumnNames.RECORD_TYPE_COLUMN_NAME,
                        width: '120',
                        dataField: SourceDataColumnNames.RECORD_TYPE_COLUMN_NAME,
                        name: SourceDataColumnNames.RECORD_TYPE_COLUMN_NAME,
                        visible: true,
                        lookup: {
                            dataSource: {
                                store: {
                                    type: 'array',
                                    data: HistoryRecordTypes,
                                    key: "Id"
                                }
                            },
                            valueExpr: 'Id',
                            displayExpr: 'Name'
                        }
                    },
                    {
                        caption: SourceDataColumnNames.CHANGED_BY_COLUMN_NAME,
                        width: '100',
                        dataField: SourceDataColumnNames.CHANGED_BY_COLUMN_NAME,
                        name: SourceDataColumnNames.CHANGED_BY_COLUMN_NAME,
                        allowEditing: false,
                        visible: true
                    }

                ];

                definitionColumns.forEach((column) => {
                    if (column.allowEditing) {
                        this.editableDataFieldNames.push(column.dataField);
                    }

                    if (column.dataField == SourceDataColumnNames.ID_COLUMN_NAME) {
                        column['dataType'] = 'number',
                            column['allowEditing'] = false
                    }

                    switch (column.controlData.containerDataType) {
                        // Boolean oszlopra vonatkozó beállítások
                        case this.dataContainerDataType.boolean.id:
                            column['dataType'] = 'boolean';
                            break;
                        // Integer oszlopra vonatkozó beállítások
                        case this.dataContainerDataType.integer.id:
                            column['dataType'] = 'number';
                            break;
                        // Number oszlopra vonatkozó beállítások
                        case this.dataContainerDataType.number.id:
                            column['dataType'] = 'number';
                            column['format'] = (data) => {
                                if (!isNaN(data)) {

                                    let decimal = -1;
                                    if (column.controlData.parameters && column.controlData.parameters.NumberParameters && column.controlData.parameters.NumberParameters.DecimalPlaces) {
                                        decimal = column.controlData.parameters.NumberParameters.DecimalPlaces;
                                    }

                                    let decimalSeparator = false;
                                    if (column.controlData.parameters && column.controlData.parameters.NumberParameters && column.controlData.parameters.NumberParameters.DecimalSeparator) {
                                        decimalSeparator = column.controlData.parameters.NumberParameters.DecimalSeparator;
                                    }

                                    let groupSeparator = false;
                                    if (column.controlData.parameters && column.controlData.parameters.NumberParameters && column.controlData.parameters.NumberParameters.ThousandSeparator) {
                                        groupSeparator = column.controlData.parameters.NumberParameters.ThousandSeparator;
                                    }

                                    data = this.numberFormatter(data, decimal, decimalSeparator, groupSeparator);
                                }
                                return data;
                            }

                            break;
                        // Decimal oszlopra vonatkozó beállítások
                        case this.dataContainerDataType.decimal.id:
                            column['dataType'] = 'number';
                            column['format'] = (data) => {
                                if (!isNaN(data)) {

                                    let decimal = -1;
                                    if (column.controlData.parameters && column.controlData.parameters.DecimalParameters && column.controlData.parameters.DecimalParameters.DecimalPlaces) {
                                        decimal = column.controlData.parameters.DecimalParameters.DecimalPlaces;
                                    }

                                    let decimalSeparator = false;
                                    if (column.controlData.parameters && column.controlData.parameters.DecimalParameters && column.controlData.parameters.DecimalParameters.DecimalSeparator) {
                                        decimalSeparator = column.controlData.parameters.DecimalParameters.DecimalSeparator;
                                    }

                                    let groupSeparator = false;
                                    if (column.controlData.parameters && column.controlData.parameters.DecimalParameters && column.controlData.parameters.DecimalParameters.ThousandSeparator) {
                                        groupSeparator = column.controlData.parameters.DecimalParameters.ThousandSeparator;
                                    }

                                    data = this.numberFormatter(data, decimal, decimalSeparator, groupSeparator);
                                }
                                return data;
                            }

                            break;
                        // Dátum oszlopra vonatkozó beállítások
                        case this.dataContainerDataType.dateTime.id:
                            column['dataType'] = 'datetime';
                            if (column.controlData.parameters !== undefined &&
                                column.controlData.parameters.DateTimeParameters !== undefined &&
                                column.controlData.parameters.DateTimeParameters.Format !== undefined) {
                                column['format'] = column.controlData.parameters.DateTimeParameters.Format;
                            }
                            break;
                    }

                    columns.push(column);

                    
                });

                //this.setSourceData(definition.DisplayedName, definition.Description, definition.IsTargetOfCodedTransformation, columns);

                this.historyColumns = columns;
                this.sourceDataHistoryInitialized = true;
            },
            setSourceData(sourceDataName, sourceDataDescription, isTargetOfCodedTransformation, columns) {
                this.sourceDataName = sourceDataName;
                this.sourceDataDescription = sourceDataDescription;
                this.columns = columns;
                this.isTargetOfCodedTransformation = isTargetOfCodedTransformation;
            },
            editSourceData() {
                this.popup.show();
            },
            copyODataUrl() {

                if (copy(document.location.protocol + "//" + document.location.host + dataContainerPathFactory.getODataUrl(this.dataContainerType, this.dataContainerId))) {
                    notifySuccess("A konténer OData Url-jének vágólapra másolása megtörtént.");
                } else {
                    handleStandardErrorNotification("A konténer OData Url-jének vágólapra másolása nem sikerült.", null);
                }
            },
            async runTransformation() {
                try {
                    await sourceDataService.runCodedTransformation(this.dataContainerType, this.sourceDataName);
                    this.dataGrid.refresh();
                    notifySuccess("A transzformáció sikeresen lefutott.");
                }
                catch (error) {
                    handleStandardErrorNotification("Hiba történt a transzformáció futtatása során. A hiba részletekért nézd meg a logfájlokat.", error);
                }
            },
            updateDataSource() {
                this.updateDataPopup.show();
            },
            showExternalSourceLog() {
                this.dataExternalSourceLogPopup.show();
            },
            async exportToExcel() {
                try {
                    var result = await sourceDataService.getHistoryDownloadUrl(this.dataContainerId);
                    if (result) {
                        document.location.href = result;
                    }
                }
                catch (error) {
                    handleStandardErrorNotification("Nem sikerült az exportálás.", error);
                }
            },
            onTabItemClick(e) {
                let url = '';

                if (e.itemIndex == 1) {
                    url = dataContainerPathFactory.getDataHistoryPageUrl(this.dataContainerType, this.dataContainerId);
                }
                else {
                    url = dataContainerPathFactory.getDataPageUrl(this.dataContainerType, this.dataContainerId);
                }
                this.$router.push(url);
            },
            onCellPrepared(e) {

                if (e.rowType == "data") {
                    if (e.values != undefined && e.column.controlData != undefined) {
                        if (e.column.controlData.containerDataType == this.dataContainerDataType.property.id) {
                            e.cellElement.innerHTML = e.values[e.columnIndex];
                        }
                    }
                    
                    if (e.column.dataField == SourceDataColumnNames.ERROR_MESSAGES_COLUMN_NAME) {
                        // Hibaüzenetek helye
                        if (false) {
                            let msg = 'Lorem ipsum dolor sit...';
                            e.cellElement.innerHTML = '<i class="dx-icon-warning warning-icon" title="' + msg + '"></i>';
                        }
                    }

                }
                else if (e.rowType == "header") {
                    
                    if (e.column.controlData != undefined) {

                        var headerCellCaption;
                        if (e.column.controlData.containerDataType === this.dataContainerDataType.property.id) {

                            let dynamicWidthColumnGroupRef;
                            let headerButtons = '';
                            let propertyParametersColumns = e.column.controlData.parameters.PropertyParameters.Columns ? e.column.controlData.parameters.PropertyParameters.Columns : [];

                            if (propertyParametersColumns.length > 1) {
                                this.dynamicWidthColumnIds.push(e.column.dataField);
                                this.dynamicWidthColumnGroups[e.column.dataField] = {
                                    dataField: e.column.dataField,
                                    propertyIds: [],
                                    headerId: e.column.headerId,
                                    columnIndex: e.column.index
                                };
                                dynamicWidthColumnGroupRef = this.dynamicWidthColumnGroups[e.column.dataField];
                                headerButtons = ' <span class="btnPropertyColumn btnShowPropertyColumns" onClick="showPropertyColumns(event, \'' + e.column.dataField + '\', \'' + e.column.headerId + '\')"><i class="dx-icon-showpanel" title="' + e.column.caption + ' oszlopok megjelenítése"></i></span> <span class="btnPropertyColumn btnHidePropertyColumns" onClick="hidePropertyColumns(event, \'' + e.column.dataField + '\', \'' + e.column.headerId + '\')"><i class="dx-icon-hidepanel" title="oszlopok elrejtése"></i></span>';
                            }
                            
                            var propertyCells = [];

                            propertyParametersColumns.forEach(function (v, i, a) {
                                if (i > 0) {
                                    dynamicWidthColumnGroupRef.propertyIds.push(v.PropertyId);
                                }
                                let additionalClass = i > 0 ? ' hidden' : '';
                                
                                propertyCells.push('<td class="propertyHeaderCell prop-' + v.PropertyId + additionalClass + '" data-property-id="' + v.PropertyId + '" title="' + v.PropertyName + '"><span class="headerTitle">' + v.PropertyName + '</span></td>');
                            });
                            headerCellCaption = "<strong>" + e.column.caption + '</strong> ' + headerButtons + '<br /><table id="propertyHeaderTable_' + e.column.dataField + '"><tr>' + propertyCells.join('') + "</tr></table>";
                            e.cellElement.innerHTML = e.cellElement.innerHTML.replace(e.column.caption, headerCellCaption);
                        }
                    }
                }
            },
            onContentReady(e) {
                if (!this.dynamicWidthColumnsInitialized) {
                    for (var dataField in this.dynamicWidthColumnGroups) {
                        let headerElement = document.querySelector('#' + this.dynamicWidthColumnGroups[dataField].headerId);
                        headerElement.classList.add('hidden-columns');


                        this.dynamicWidthColumnGroups[dataField].propertyIds.forEach((propertyId, i, a) => {
                            let elements = document.querySelectorAll('.prop-' + propertyId);
                            elements.forEach((element, i, a) => {
                                element.classList.add('hidden');
                            });
                        });
                    }

                    this.dynamicWidthColumnIds.forEach((dataField, i, a) => {
                        this.updateColumnsWidth(dataField);
                    });

                    this.dynamicWidthColumnsInitialized = true;
                }
                else {

                    var filterExpr = e.component.getCombinedFilter();

                    if (filterExpr) {
                        this.showColumnsOnFilterEvent(filterExpr.columnIndex);
                    }
                }
            },
            updateColumnsWidth(dataField) {
                if (!this.dynamicWidthColumnRefs[dataField]) {
                    this.dynamicWidthColumnRefs[dataField] = document.querySelector('#propertyHeaderTable_' + dataField);
                }

                let width = this.dynamicWidthColumnRefs[dataField].offsetWidth;
                this.dataGrid.columnOption(dataField, 'width', (width + this.headerCellClientOffsetDiff));
            },
            showColumnsOnFilterEvent(columnIndex) {
                let columnData;
                for (var dataField in this.dynamicWidthColumnGroups) {
                    if (!columnData && this.dynamicWidthColumnGroups[dataField].columnIndex == columnIndex) {
                        columnData = this.dynamicWidthColumnGroups[dataField];
                    }
                }

                if (columnData) {
                    showPropertyColumns(null, columnData.dataField, columnData.headerId)
                }
            },
            showUpdateMirroredLocalDbTable() {
                if (this.isMirroredLocalDbTable) {
                    let popup = this.$refs['updateFromMirroredLocalDbPopup'];
                    popup.mirroredLocalDbTableExternalDataSourceDefinitionId = this.sourceDataDefinition.ExternalDataSourceDefinition.Id;
                    popup.tableId = this.dataContainerId;
                    popup.currentTableName = this.sourceDataDefinition.ExternalDataTableName;
                    popup.instance.show();
                }
            }
        },
        mounted() {
            if (!dataContainerOperation.hasOperation(this.dataContainerType, this.dataContainerOperation.editData)) {
                this.gridConfig.editing = {
                    allowAdding: false,
                    allowUpdating: false,
                    allowDeleting: false,
                    mode: 'row'
                };
            }
            this.initializeSourceData();
        },
        created() {

            window.showPropertyColumns = (event, dataField, headerId) => {
                if (event) event.stopPropagation();

                var headerElement = document.querySelector('#' + headerId);
                headerElement.classList.remove('hidden-columns');

                this.dynamicWidthColumnGroups[dataField].propertyIds.forEach((propertyId, i, a) => {
                    let elements = document.querySelectorAll('.prop-' + propertyId);
                    elements.forEach((element, i, a) => {
                        element.classList.remove('hidden');
                    });
                });

                this.updateColumnsWidth(dataField);
            };

            window.hidePropertyColumns = (event, dataField, headerId) => {
                if (event) event.stopPropagation();

                var headerElement = document.querySelector('#' + headerId);
                headerElement.classList.add('hidden-columns');

                this.dynamicWidthColumnGroups[dataField].propertyIds.forEach((propertyId, i, a) => {
                    let elements = document.querySelectorAll('.prop-' + propertyId);
                    elements.forEach((element, i, a) => {
                        element.classList.add('hidden');
                    });
                });

                this.updateColumnsWidth(dataField);
            };

            this.selectedTabItemIndex = this.tabItemRoutes.indexOf(this.$root._route.path);

            this.$root.$on('source_data_definition_saved', (data) => {

                this.sourceDataInitialized = false;
                this.sourceDataHistoryInitialized = false;

                this.initializeSourceData();

                let grid = this.dataGrid;
                if (grid) {
                    grid.refresh();
                }

                grid = this.dataHistoryGrid;
                if (grid) {
                    grid.refresh();
                }
            });

            this.$root.$on('source_data_row_changed', (data) => {

                this.sourceDataHistoryInitialized = false;
                let grid = this.dataHistoryGrid;
                if (grid) {
                    grid.refresh();
                }
            });

            this.$root.$on('source_data_updated', (data) => {

                let grid = this.dataGrid;
                if (grid) {
                    grid.refresh();
                }

                grid = this.dataHistoryGrid;
                if (grid) {
                    grid.refresh();
                }
            });
        },
        watch: {
            $route(to, from) {
                if (to != from) {
                    let itemRouteIndex = this.tabItemRoutes.indexOf(to.path);
                    if (itemRouteIndex != -1 && this.selectedTabItemIndex != itemRouteIndex) {
                        this.selectedTabItemIndex = itemRouteIndex;
                    }
                }
            }
        }
    }
</script>

<style>
    .breadcrumb-toolbar .dx-button-has-icon .dx-button-content {
        padding: 0;
    }
    .propertyDataCell {
        width: 180px;
        min-width: 180px;
        white-space: normal;
        padding-right: 4px;
        padding-top: 7px;
    }

    .propertyDataCell.hidden {
        width: 26px;
        min-width: 26px;
        max-width: 26px;
        overflow: clip;
        white-space: nowrap;
        opacity: 0;
    }

    .propertyInnerTableRow:first-child .propertyDataCell {
        padding-top: 0;
    }

    .propertyHeaderCell {
        width: 180px;
        min-width: 180px;
        text-align: left;
    }

    .propertyHeaderCell.hidden {
        width: 0px;
        min-width: 0px;
        max-width: 0px;
        white-space: nowrap;
    }

    .btnShowPropertyColumns,
    .hidden-columns .btnHidePropertyColumns,
    .propertyHeaderCell.hidden .headerTitle {
        display: none;
    }

    .hidden-columns .btnShowPropertyColumns {
        display: inline-block;
    }

    .propertyHeaderCell.hidden .headerTitle {
        display: none;
    }

    .btnPropertyColumn {
        cursor: pointer;
        position: relative;
        z-index: 200;
    }

    .warning-icon {
        font-size: 18px;
        color: #ff0000;
        vertical-align: bottom;
    }

    .warning-icon-header {
        font-size: 18px;
        vertical-align: bottom;
    }

</style>
<style lang="scss" scoped>
    .title h1 {
        float: left;
    }

    .title .right-icons {
        float: right;
    }

    .title .right-icons a {
        text-decoration: none;
        line-height: 22px;
        font-size: 22px;
    }

    .grid {
        padding-top: 6px;
        height: 100%;
        clear: both;
    }

    .grid-history {
        padding-top: 6px;
        height: 100%;
        clear: both;
    }

    .tabpanel {
        height: calc(100% - 50px);
    }

    .history-buttons {
        padding-top: 8px;
        float: right;
    }

    #grid-history::v-deep .dx-datagrid-header-panel {
        width: 90px;
        display: inline-block;
        position: absolute;
        top: -48px;
    }
</style>
