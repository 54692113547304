<template>
  <div class="form">
    <DxTreeView
      id="simple-treeview"
      :items="products"
      :width="300"
      :height="900"
      :search-enabled="true"
      @item-click="selectItem"
    >
      <template #item="{ data }">
        <span>
          <span class="szintkod" :class="'szintkod-' + data.szintkod">{{data.szintkod}}</span>
          {{ data.text }}
        </span>
      </template>
    </DxTreeView>
    <div
      id="product-details"
    >
      <h1><span class="szintkod" :class="'szintkod-' + currentItem.szintkod">{{currentItem.szintkod}}</span> {{ currentItem.text }}</h1>
      <DxTabPanel>
        <DxTabItem title="Vezetők">
          <template #default>
            <div v-if="currentItem.price">
            </div>
          </template>
        </DxTabItem>
        <DxTabItem title="Egyéb vezető pozíciók">
          <template #default>
            <div v-if="currentItem.price">
            </div>
          </template>
        </DxTabItem>
        <DxTabItem title="Munkavállalók">
          <template #default>
            <div v-if="currentItem.price">
            </div>
          </template>
        </DxTabItem>
        <DxTabItem title="Szervezeti ábra">
          <template #default>
            <div v-if="currentItem.price">
            </div>
          </template>
        </DxTabItem>
      </DxTabPanel>
    </div>
  </div>
</template>
<script>
import DxTreeView from 'devextreme-vue/tree-view';
import DxTabPanel, { DxItem as DxTabItem } from "devextreme-vue/tab-panel";

import service from '../services/ceghierarchia-data';

export default {
  components: {
    DxTreeView,
    DxTabPanel,
    DxTabItem
  },
  data() {
    const products = service.getProducts();
    return {
      products,
      currentItem: products[0],
    };
  },
  methods: {
    selectItem(e) {
      this.currentItem = e.itemData;
    },
  },
};
</script>
<style scoped>
.szintkod {
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 9px;
}

.szintkod-A {
  background-color: lightblue;
  color: black;
}

.szintkod-B {
  background-color: lightgreen;
  color: black;
}

.szintkod-C {
  background-color: lightcoral;
  color: black;
}

.szintkod-D {
  background-color: lightsalmon;
  color: black;
}

.szintkod-E {
  background-color: lightseagreen;
  color: black;
}

#simple-treeview,
#product-details {
  display: inline-block;
}

#product-details {
  vertical-align: top;
  width: 1000px;
  height: 600px;
  margin-left: 20px;
}

#product-details > img {
  border: none;
  height: 300px;
  width: 400px;
}

#product-details > .name {
  text-align: center;
  font-size: 20px;
}

#product-details > .price {
  text-align: center;
  font-size: 24px;
}

.dark #product-details > div {
  color: #f0f0f0;
}

.hidden {
  visibility: hidden;
}
</style>
