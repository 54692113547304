export const SourceDataColumnNames = {
    ID_COLUMN_NAME: "Id",
    HISTORY_ID_COLUMN_NAME: "HistoryId",
    RECEIVED_AT_COLUMN_NAME: "ReceivedAt",
    CHANGED_AT_COLUMN_NAME: "ChangedAt",
    CHANGED_BY_COLUMN_NAME: "ChangedBy",
    RECORD_TYPE_COLUMN_NAME: "RecordType",
    CURRENT_VALUE_COLUMN_NAME: "CurrentValue",
    ORDER_COLUMN_NAME: "Order",
    ERROR_MESSAGES_COLUMN_NAME: "ErrorMessages"
};