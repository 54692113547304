import Vue from 'vue';
import Router from 'vue-router';
import store from "../store";
import accountService from "../services/account.service";
import Account from "@/router/views/Account";
import Users from "@/router/views/Users";
import Roles from "@/router/views/Roles";
import Dashboard from "@/router/views/Dashboard";
import Login from "@/router/views/Login";
import Logout from "@/router/views/Logout";
import Tasks from "@appfrm/issue-tracker/pages/Tasks";
import { dataContainerRoutes } from "@appfrm/data-container"
import { organigramRoutes } from "@ekcerp/organigram"
import { issueTrackerRoutes } from "@appfrm/issue-tracker"

Vue.use(Router);

var routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
    },
    {
        path: "/account",
        name: "Account",
        component: Account
    },
    {
        path: "/users",
        name: "Users",
        component: Users
    },
    {
        path: "/roles",
        name: "Roles",
        component: Roles
    },
    {
        path: '/server-settings',
        name: 'server-settings',
        component: () => import(/* webpackChunkName: "server-settings" */ './views/ServerSettings.vue'),
    },
    /*{
        path: "/login",
        name: "Login",
        component: Login
    },
    {
        path: "/logout",
        name: "Logout",
        component: Logout
    },*/
    {
        path: "/developmentInfo/bugReports",
        name: "Bugreports",
        component: Tasks
    },
    {
        path: "/developmentInfo/featureRequests",
        name: "ImprovementProposals",
        component: Tasks
    },
    {
        path: "/developmentInfo/changelog",
        name: "DevelopmentDiary",
        component: Tasks
    },
// {
//   path: "/users",
//   name: "Users",
//   component: Users,
//   meta: {
//     permissions: ["users.view"]
//   }
// },
// {
//   path: "/roles",
//   name: "Roles",
//   component: Roles,
//   // component: () =>
//   //   import(/* webpackChunkName: "roles" */ "./views/Roles.vue"),
//   meta: {
//     permissions: ["roles.view"]
//   }
// },
    {
        // will match everything
        path: '*',
        redirect: '/'
    }
]

routes = routes.concat(dataContainerRoutes);
routes = routes.concat(organigramRoutes);
routes = routes.concat(issueTrackerRoutes);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes,
});

const originalPush = Router.prototype.push;
Router.prototype.push = function push(
    location,
    onComplete,
    onAbort
) {
  if (onComplete || onAbort)
return originalPush.call(this, location, onComplete, onAbort);
    return originalPush.call(this, location).catch((err) => err);
};

router.beforeEach(async (to, from, next) => {
    to.matched.forEach(record => {
        if (record.meta.permissions) {
            if (!accountService.userHasPermissions(record.meta.permissions)) {
                next("/");
            }
        }
    });

    if (store.getters.isSessionExpired && to.path != '/login') {
        next("/login");
        return;
    }

    next();
});

export default router;
