<template>
    <ul class="ml-menu">
        <li v-if="this.parentText" class="submenu-title">
            <a>
                <span>{{ this.parentText }}</span>
            </a>
        </li>
        <li v-for="item in menuitems"
            :id="item.text"
            :key="item.text"
            :ref="'menu-'+item.text"
            :class="[menuIsActive(item) ? 'active' : '', item.separator ? 'nav-separator' : '']"
            v-if="hasPermission(item.permission)"
        >
            <router-link :to="item.href"
                :class="item.submenu.length > 0 ? 'menu-toggle' : ''"
                :content="item.text">
                <i :class="`iconset ${item.icon}`"></i>
                <span>{{ item.text }}</span>
            </router-link>
            <submenu v-if="item.submenu.length > 0" :menuitems="item.submenu" />
        </li>
    </ul>
</template>

<script>
    import accountService from "@/services/account.service";

export default {
    name: "Submenu",
    props: {
        menuitems: Array,
        parentText: null
    },
    methods: {
        menuIsActive: function (item) {
            let active = this.$parent.menuIsActive(item);
            if (active) {
                this.$nextTick(() => {
                    if (!document.getElementById(item.text).parentElement.parentElement.firstChild.classList.contains('toggled')) {
                        document.getElementById(item.text).parentElement.parentElement.classList.add('active');
                    }
                });
            } else {
                this.$nextTick(() => {
                    document.getElementById(item.text).parentElement.parentElement.classList.remove('active');
                });
            }
            
            return active;
        },
        hasPermission(permissionValue) {
            if (!permissionValue)
                return true;

            return accountService.userHasPermission(permissionValue);
        },
    },
};
</script>
