<template>
    <div class="map-template-wrapper dx-template-wrapper dx-item-content dx-multiview-item-content">
        <div class="zoom-btn-holder">
            <i class="dx-icon dx-icon-add" @click="zoomIn"></i>
            <i class="dx-icon dx-icon-minus" @click="zoomOut"></i>
        </div>
        <dxScrollView :show-scrollbar="'always'"
                      :use-native="false"
                      direction="both"
                      :scroll-by-content="true"
                      :scroll-by-thumb="true"
                      ref="resourceMapScrollView"
                      id="resourceMapScrollView">

            <div id="zoom-content" style="width: 30000px; transform-origin: 0 0;" v-bind:style="{transform: `scale(${this.scale})`}">
                <div v-for="(level, index) in  getLevels()" v-bind:key="`L2_${level.id}`" class="level-background" v-bind:class="{'alternate': index%2 === 1}" :style="'top:' + (index * 230) + 'px; left: -9px; height:230px; width: 100%'">
                </div>
                <span v-for="(level, index) in  getLevels()" v-bind:key="`L_${level.id}`" :id="'level-'+level.id" class="level-title" :style="'top:' + (index * 230 + 15) + 'px;'">({{ level.levelCode }}) {{ level.name }}</span>
                <resourceMapEntity v-for="(entity) in rootEntities" v-bind:key="`E_${entity.id}`" :entity="entity" />
            </div>
        </dxScrollView>
    </div>
</template>

<script>
    import entityService from "../services/entity.service";
    import { DxScrollView } from 'devextreme-vue/scroll-view';
    import { jsPlumb } from 'jsplumb';
    import resourceMapEntity from "./ResourceMapEntity.vue";

    const LEVEL_HEIGHT = 230;
    const DEFAULT_SHOW_LEVELS = 4;

    export default {
        name: 'ResourceMap',
        components: {
            DxScrollView,
            jsPlumb,
            resourceMapEntity
        },
        props: {
            levels: {
                type: Array,
                default: null
            },
        },
        data() {
            return {
                entities: [],
                rootEntities: [],
                scale: 1,
                scrollContainer: false,
                entityUpdateIndex: 1,
            }
        },
        methods: {
            async fetchEntities() {
                await entityService.get().then(result => {
                    this.entities = result.filter(e => e.name != null).map(v=> {
                        v.children = [];
                        v.position = 0;
                        v.parentLevel = 0;
                        v.childrenVisible = false;
                        v.visible = false;
                        v.parentObject = null;
                        v.jsPlumbConnection = null;
                        v.mounted = false;
                        v.levelObject = this.levels.find(l=>l.id === v.level);
                        return v;
                    });
                    this.rootEntities = this.listToTree(result.filter(e => e.name != null).sort(function (a, b) {
                        return a.orderIndex > b.orderIndex;
                    }));
                });
            },
            getLevels() {
                return this.levels.filter(l => l.name != null);
            },
            listToTree(list) {
                var map = {}, node, roots = [], i;
                
                for (i = 0; i < list.length; i += 1) {
                    map[list[i].id] = i;
                }
                
                for (i = 0; i < list.length; i += 1) {
                    node = list[i];
                    if (node.parent !== 0) {
                        node.parentLevel = list[map[node.parent]].level;
                        list[map[node.parent]].children.push(node);
                        node.parentObject = list[map[node.parent]]
                    } else {
                        // Ha nincs szülője akkor a root-hooz kötöm
                        node.visible=true;
                        roots.push(node);
                    }
                }
                return roots;
            },
            zoomIn() {
                let scale = this.scale;
                scale += 0.02;
                return this.setZoom(scale);
            },
            zoomOut() {
                let scale = this.scale;
                scale -= 0.02;
                return this.setZoom(scale);
            },
            setZoom(scale) {
                this.scale = scale;


                this.refreshLevelTitlePosition();
                this.$store.state.jsPlumb.repaintEverything();

            },
            refreshLevelTitlePosition() {
                var cols = document.querySelectorAll('#resourceMapScrollView .level-title');
                let container = this.scrollContainer;

                for (let i = 0; i < cols.length; i++) {
                    cols[i].style.left = (container.scrollLeft / this.scale) + 10 + 'px';
                }
            },
            mouseMove(e) {
                let container = this.scrollContainer;
                let MouseY = container.scrollTop + e.clientY - 83;

                let titles = document.querySelectorAll('#resourceMapScrollView .level-title');
                let active = Math.max(Math.ceil(((MouseY / 230) / this.scale)), 0);

                Array.from(titles).forEach(el => el.style.opacity = 0.3);
                if (titles[active - 1 ] !== undefined) titles[active - 1].style.opacity = 1;
            }
        },
        updated: function () {
            // Positions
            if(this.rootEntities.length > 0) {
                // Set origin to highest entity
                let highest = this.rootEntities[0];
                let entity = document.getElementById('entity-' + highest.id);

                this.scrollContainer.scrollTo(entity.offsetLeft - this.scrollContainer.offsetWidth / 2 + entity.offsetWidth / 2, 0);

                // Map level name transform
                this.scrollContainer.onscroll = this.refreshLevelTitlePosition;
                this.scrollContainer.onmousemove = this.mouseMove;

                // Hand cursor
                this.scrollContainer.onmousedown = () => document.body.style.cursor = 'grab';
                this.scrollContainer.onmouseup = () => document.body.style.cursor = 'default';
            }
        },
        created() {
            this.$store.state.jsPlumb = jsPlumb.getInstance({ Container: "zoom-content" });
            this.fetchEntities();
        },
        mounted() {
            this.$store.state.resourceMapScrollView = this.$refs['resourceMapScrollView'].instance; 
            this.scrollContainer = document.querySelector('#resourceMapScrollView .dx-scrollable-container');

            let offsetHeight = this.scrollContainer.offsetHeight;
            let showableHeight = LEVEL_HEIGHT * DEFAULT_SHOW_LEVELS;

            if (offsetHeight > 0 && offsetHeight < showableHeight) {
                let scale = offsetHeight / showableHeight;
                this.setZoom(scale);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .level-background {
        position: absolute;
        background-color: rgba(255,255,255,0);
    }
    .level-background.alternate {
        background-color: #ddd
    }
    .level-title {
        position: absolute;
        font-size: 22px;
        font-weight: bold;
        left: 10px;
        opacity: 0.3;
        transition: opacity 0.3s;
        padding: 5px 10px;
        z-index: 1;
        background-color: rgba(255,255,255,0);
    }
    #zoom-content {
        display: inline-block;
        transform: scale(1);
        padding-left: 9px;
    }
    .zoom-btn-holder {
        position: fixed;
        font-size: 22px;
        font-weight: bold;
        right: 15px;
        top: 10px;
        z-index: 1000;
        
        i {
            display: block;
            padding: 7px;
            border: 1px solid gray;
            margin: 10px 5px;
            cursor: pointer;
            background-color: #ffffff;

            &:active {
                background-color: #012A4A;
                color: #ffffff;
            }
        }
    }
</style>