import RequestHandler from "@appfrm/core/services/request-handler";
class SourceDataService {
    constructor() {
        this.path = "SourceData";
        if (SourceDataService.instance) {
            return SourceDataService.instance;
        }
        SourceDataService.instance = this;
        return this;
    }
    // Definitions
    async getAllDefinitions(dataContainerType) {
        return await RequestHandler.get(`${this.path}/Definitions/${dataContainerType.id}`);
    }

    // Definition
    async getDefinition(dataContainerId) {
        return await RequestHandler.get(`${this.path}/Definition/${dataContainerId}`);
    }

    async getDefinitionExists(dataContainerType, dataContainerName) {
        return await RequestHandler.get(`${this.path}/Definition/Exists/${dataContainerType.id}/${encodeURIComponent(dataContainerName)}`);
    }

    async getDefinitionExistsExceptThis(dataContainerType, dataContainerName, dataContainerId) {
        return await RequestHandler.get(`${this.path}/Definition/Exists/${dataContainerType.id}/${encodeURIComponent(dataContainerName)}/${dataContainerId}`);
    }

    async updateDefinition(dataContainerId, data) {
        return await RequestHandler.put(`${this.path}/Definition/${dataContainerId}`, data);
    }

    async updateBackgroundColor(data) {
        return await RequestHandler.put(`${this.path}/Definition/UpdateBackgroundColor`, data);
    }

    async reorderDefinitions(data) {
        return await RequestHandler.put(`${this.path}/Definition/Reorder`, data);
    }

    async setDefinitionGroup(dataContainerId, definitionGroupId) {
        return await RequestHandler.put(`${this.path}/Definition/SetGroup/${dataContainerId}/${definitionGroupId}`);
    }

    async deleteDefinition(dataContainerId) {
        return await RequestHandler.delete(`${this.path}/Definition/${dataContainerId}`);
    }


    // Definition groups
    async getDefinitionGroups(dataContainerType) {
        return await RequestHandler.get(`${this.path}/Definition/Group/${dataContainerType.id}`);
    }

    async getDefinitionGroupExists(dataContainerType, sourceDataGroupName, sourceDataGroupId) {
        return await RequestHandler.get(`${this.path}/Definition/Group/${dataContainerType.id}/Exists/${encodeURIComponent(sourceDataGroupName)}/${sourceDataGroupId}`);
    }

    async addDefinitionGroup(data) {
        return await RequestHandler.post(`${this.path}/Definition/Group`, data);
    }

    async updateDefinitionGroup(data) {
        return await RequestHandler.put(`${this.path}/Definition/Group`, data);
    }

    async reorderDefinitionGroups(data) {
        return await RequestHandler.put(`${this.path}/Definition/Group/Reorder`, data);
    }

    async reorderTablesInGroup(data) {
        return await RequestHandler.put(`${this.path}/Definition/Group/ReorderInGroup`, data);
    }

    async deleteDefinitionGroup(definitionGroupId) {
        return await RequestHandler.delete(`${this.path}/Definition/Group/${definitionGroupId}`);
    }

    // Data
    async getData(dataContainerId) {
        return await RequestHandler.get(`${this.path}/Data/${dataContainerId}`);
    }

    async insertDataRow(sourceDataId, data) {
        return await RequestHandler.post(`${this.path}/Data/${sourceDataId}`, data);
    }

    async updateDataRow(sourceDataId, data) {
        return await RequestHandler.put(`${this.path}/Data/${sourceDataId}`, data);
    }

    async removeDataRow(sourceDataId, rowId) {
        return await RequestHandler.delete(`${this.path}/Data/${sourceDataId}`, rowId);
    }

    async getDownloadUrl(dataContainerId) {
        return await RequestHandler.get(`${this.path}/downloadUrl/${dataContainerId}`);
    }

    // Data History
    async getDataHistory(dataContainerId) {
        return await RequestHandler.get(`${this.path}/Data/${dataContainerId}/History`);
    }

    async getHistoryDownloadUrl(dataContainerId) {
        return await RequestHandler.get(`${this.path}/historyDownloadUrl/${dataContainerId}`);
    }


    // Data import (first)

    async getFirstImport(importId) {
        return await RequestHandler.get(`${this.path}/Data/Import/First/${importId}`);
    }

    async postFirstImport(importId, data) {
        return await RequestHandler.post(`${this.path}/Data/Import/First/${importId}`, data);
    }

    // Data import (update)

    async getUpdateImport(importId, dataContainerId) {
        return await RequestHandler.get(`${this.path}/Data/Import/Update/${importId}/${dataContainerId}`);
    }

    async postUpdateImport(importId, data) {
        return await RequestHandler.post(`${this.path}/Data/Import/Update/${importId}`, data);
    }

    // Transformation
    async runCodedTransformation(dataContainerType, targetDataContainerName) {
        return await RequestHandler.post(`${this.path}/Transformation/Coded/${dataContainerType.id}/${targetDataContainerName}`, {});
    }

    async getAllTransformations() {
        return await RequestHandler.get(`${this.path}/Transformation`);
    }

    async getTransformationExists(transformationName) {
        return await RequestHandler.get(`${this.path}/Transformation/Exists/${encodeURIComponent(transformationName)}`);
    }

    async getTransformationExistsExceptThis(transformationName, transformationId) {
        return await RequestHandler.get(`${this.path}/Transformation/Exists/${encodeURIComponent(transformationName)}/${transformationId}`);
    }

    async getTransformation(transformationId) {
        return await RequestHandler.get(`${this.path}/Transformation/${encodeURIComponent(transformationId)}`);
    }

    async getTransformationStep(transformationId, stepId) {
        return await RequestHandler.get(`${this.path}/Transformation/${encodeURIComponent(transformationId)}/Step/${encodeURIComponent(stepId)}`);
    }

    async getTransformationResult(transformationId, targetStepId) {
        if(targetStepId === null) {
            return await RequestHandler.get(`${this.path}/Transformation/${encodeURIComponent(transformationId)}/Result`);
        } else {
            return await RequestHandler.get(`${this.path}/Transformation/${encodeURIComponent(transformationId)}/Result/${encodeURIComponent(targetStepId)}`);
        }
    }

    async getTransformationResultBeforeStep(transformationId, targetStepId) {
        return await RequestHandler.get(`${this.path}/Transformation/${encodeURIComponent(transformationId)}/ResultBeforeStep/${encodeURIComponent(targetStepId)}`);
    }

    async postNewTransformation(data) {
        return await RequestHandler.post(`${this.path}/Transformation`, data);
    }

    async updateTransformation(data) {
        return await RequestHandler.put(`${this.path}/Transformation`, data);
    }

    async setTransformationGroup(transformationId, newTransformationGroupId) {
        return await RequestHandler.put(`${this.path}/Transformation/SetGroup/${transformationId}/${newTransformationGroupId}`);
    }

    async updateTransformationBackgroundColor(data) {
        return await RequestHandler.put(`${this.path}/Transformation/UpdateBackgroundColor`, data);
    }

    async reorderTransformations(data) {
        return await RequestHandler.put(`${this.path}/Transformation/Reorder`, data);
    }

    async reorderTransformationInGroup(data) {
        return await RequestHandler.put(`${this.path}/Transformation/Group/ReorderInGroup`, data);
    }

    async deleteTransformation(transformationId) {
        return await RequestHandler.delete(`${this.path}/Transformation/${transformationId}`);
    }

    async getTransformationSteps(transformationId) {
        return await RequestHandler.get(`${this.path}/Transformation/${encodeURIComponent(transformationId)}/Step`);
    }

    async updateTransformationStepSource(transformationId, data) {
        return await RequestHandler.put(`${this.path}/Transformation/${encodeURIComponent(transformationId)}/Step/Source`, data);
    }

    async updateTransformationStepSelectColumns(transformationId, data) {
        return await RequestHandler.put(`${this.path}/Transformation/${encodeURIComponent(transformationId)}/Step/SelectColumns`, data);
    }

    async addTransformationStepSelectColumns(transformationId, data) {
        return await RequestHandler.post(`${this.path}/Transformation/${encodeURIComponent(transformationId)}/Step/SelectColumns`, data);
    }

    async addTransformationStepGroupBy(transformationId, data) {
        return await RequestHandler.post(`${this.path}/Transformation/${encodeURIComponent(transformationId)}/Step/GroupBy`, data);
    }

    async updateTransformationStepGroupBy(transformationId, data) {
        return await RequestHandler.put(`${this.path}/Transformation/${encodeURIComponent(transformationId)}/Step/GroupBy`, data);
    }

    async deleteTransformationStep(transformationId, transformationStepId) {
        return await RequestHandler.delete(`${this.path}/Transformation/${transformationId}/Step/${transformationStepId}`);
    }

    // Transformation groups
    async getTransformationGroups() {
        return await RequestHandler.get(`${this.path}/Transformation/Group`);
    }

    async getTransformationGroupExists(groupName, groupId) {
        return await RequestHandler.get(`${this.path}/Transformation/Group/Exists/${encodeURIComponent(groupName)}/${groupId}`);
    }

    async addTransformationGroup(data) {
        return await RequestHandler.post(`${this.path}/Transformation/Group`, data);
    }

    async updateTransformationGroup(data) {
        return await RequestHandler.put(`${this.path}/Transformation/Group`, data);
    }

    async deleteTransformationGroup(groupId) {
        return await RequestHandler.delete(`${this.path}/Transformation/Group/${groupId}`);
    }

    async reorderTransformationGroups(data) {
        return await RequestHandler.put(`${this.path}/Transformation/Group/Reorder`, data);
    }

    // Local DB tables
    async getAllowedLocalDbTables() {
        return await RequestHandler.get(`${this.path}/GetAllowedLocalDbTables`);
    }

    async getLocalDbTableColumns(tableName) {
        return await RequestHandler.get(`${this.path}/GetLocalDbTableColumns/${tableName}`);
    }

    async getMirroredLocalDbUpdateStatus(definitionId) {
        return await RequestHandler.get(`${this.path}/GetMirroredLocalDbUpdateStatus/${definitionId}`);
    }

    async postStartExternalDataSourceUpdate(postData) {
        return await RequestHandler.post(`${this.path}/ExternalDataSource/Update`, postData);
    }

    // Claims
    async getAllClaims() {
        return await RequestHandler.get(`${this.path}/GetAllClaims`);
    }

    // External sources
    async getAllowedExternalSources() {
        return await RequestHandler.get(`${this.path}/GetAllowedExternalSources`);
    }

    async getAllowedExternalSourcesWithLastUpdateStatus() {
        return await RequestHandler.get(`${this.path}/GetAllowedExternalSourcesWithLastUpdateStatus`);
    }

    async getExternalDataSourceTables(definitionId) {
        return await RequestHandler.get(`${this.path}/GetExternalDataSourceTables/${definitionId}`);
    }

    async getExternalTableExists(definitionId, tableName) {
        return await RequestHandler.get(`${this.path}/ExternalTableExists/${definitionId}/${tableName}`);
    }

    async getExternalDataSourceLogs(definitionId) {
        return await RequestHandler.get(`${this.path}/ExternalDataSourceLogs/${definitionId}`);
    }

    async getExternalDataSourceLogsByDataSourceId(externalDataSourceId) {
        return await RequestHandler.get(`${this.path}/ExternalDataSourceLogsByDataSourceId/${externalDataSourceId}`);
    }

    async getSourceDataInputStatuses(externalDataSourceId, isFiltered) {
        return await RequestHandler.get(`${this.path}/GetSourceDataInputStatuses/${externalDataSourceId}/${isFiltered}`);
    }

    async updateSourceDataInputStatus(data) {
        return await RequestHandler.put(`${this.path}/UpdateSourceDataInputStatus`, data);
    }
}
const instance = new SourceDataService();
export default instance;

export const TABLE_DISPLAYED_NAME_MAX_LENGTH = 63;
export const TABLE_COLUMN_DISPLAYED_NAME_MAX_LENGTH = 63;
export const GROUP_NAME_MAX_LENGTH = 100;
export const DataContainerApiBasePath = "/api/SourceData";
export const DATA_SOURCE_TYPE_NINCS = 0;
export const DATA_SOURCE_TYPE_CSV = 1;
export const DATA_SOURCE_TYPE_EXCEL = 2;
export const DATA_SOURCE_TYPE_LOCAL_DB_TABLE = 3;
export const DATA_SOURCE_TYPE_EXTERNAL_DATA_SOURCE = 4;
export const EXCEL_PARSING_ENABLED = false;
export const ImportValidationErrorLevel = {
    Warning: 0,
    Error: 1
};
export const ExternalSourceDataUpdateStatus = {
    NotYetUpdated: 0,
    Succeeded: 1,
    Failed: 2 
};
export const ExternalDataSourceLogTypes = {
    UpdateStarted: 0,
    UpdateSuccessfullyCompleted: 1,
    UpdateFailed: 2,
    Error: 3,
    Warning: 4
};
export const ExternalDataSourceType = {
    HostedWebService: 1,
    MirroredLocalDbTable: 2
};
export const SourceDataInputTransferStatus = {
    WaitingToBeProcessed: 1,
    Receiving: 2,
    Succeeded: 3,
    Failed: 4,
    ReadyForProcessing: 5,
    Processing: 6,
    Skipped: 7
};
