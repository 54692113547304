export const HistoryRecordTypes = [
    {
        Id: 0,
        Name: 'Létrehozva'
    },
    {
        Id: 1,
        Name: 'Módosítva'
    },
    {
        Id: 2,
        Name: 'Törölve'
    }
];
