<template>
    <div class="column-parameters">
        <input @input="handleInput" class="d-none" />
        <div class="not-chosen">
            <div v-if="!chosenType">
                Kérjük, válasszon oszlop típust!
            </div>
        </div>
        <!-- Szöveg típusú oszlop paraméterei -->
        <div v-show="chosenType == dataContainerDataType.text.id">
            <div class="fieldGroupTitle">Paraméterek</div>
            <div class="dx-field">
                <div class="dx-field-label">Maximális hossz</div>
                <div class="dx-field-value">
                    <DxTextBox data-field="parameters.Text.MaxLength"
                               width="55"
                               placeholder=""
                               v-model="parameters.Text.MaxLength">
                        <DxValidator validation-group="firstImportValidationGroup">
                            <DxNumericRule :message="`Csak szám lehet.`" />
                        </DxValidator>
                    </DxTextBox>
                </div>
            </div>
        </div>

        <!-- Szám típusú oszlop paraméterei -->
        <div v-show="chosenType == dataContainerDataType.number.id">
            <div class="fieldGroupTitle">Paraméterek</div>
            <div class="dx-field">
                <div class="dx-field-label">Tizedes elválasztó</div>
                <div class="dx-field-value">
                    <DxTextBox data-field="parameters.Number.DecimalSeparator"
                               width="55"
                               placeholder=""
                               v-model="parameters.Number.DecimalSeparator" />
                </div>
            </div>
            <div class="dx-field">
                <div class="dx-field-label">Ezres elválasztó</div>
                <div class="dx-field-value">
                    <DxTextBox data-field="parameters.Number.ThousandSeparator"
                               width="55"
                               placeholder=""
                               v-model="parameters.Number.ThousandSeparator" />
                </div>
            </div>
            <div class="dx-field">
                <div class="dx-field-label">Tizedesjegyek</div>
                <div class="dx-field-value">
                    <DxTextBox data-field="parameters.Number.DecimalPlaces"
                               width="55"
                               placeholder=""
                               v-model="parameters.Number.DecimalPlaces">
                        <DxValidator validation-group="firstImportValidationGroup">
                            <DxNumericRule :message="`Csak szám lehet.`" />
                        </DxValidator>
                    </DxTextBox>

                </div>
            </div>
        </div>

        <!-- Decimal típusú oszlop paraméterei -->
        <div v-show="chosenType == dataContainerDataType.decimal.id">
            <div class="fieldGroupTitle">Paraméterek</div>
            <div class="dx-field">
                <div class="dx-field-label">Tizedes elválasztó</div>
                <div class="dx-field-value">
                    <DxTextBox data-field="parameters.Decimal.DecimalSeparator"
                               width="55"
                               placeholder=""
                               v-model="parameters.Decimal.DecimalSeparator" />
                </div>
            </div>
            <div class="dx-field">
                <div class="dx-field-label">Ezres elválasztó</div>
                <div class="dx-field-value">
                    <DxTextBox data-field="parameters.Decimal.ThousandSeparator"
                               width="55"
                               placeholder=""
                               v-model="parameters.Decimal.ThousandSeparator" />
                </div>
            </div>
            <div class="dx-field">
                <div class="dx-field-label">Tizedesjegyek</div>
                <div class="dx-field-value">
                    <DxTextBox data-field="parameters.Decimal.DecimalPlaces"
                               width="55"
                               placeholder=""
                               v-model="parameters.Decimal.DecimalPlaces">
                        <DxValidator validation-group="firstImportValidationGroup">
                            <DxNumericRule :message="`Csak szám lehet.`" />
                        </DxValidator>
                    </DxTextBox>

                </div>
            </div>
        </div>

        <!-- Boolean típusú oszlop paraméterei -->
        <div v-show="chosenType == dataContainerDataType.boolean.id">
            Nincs
        </div>

        <!-- Integer típusú oszlop paraméterei -->
        <div v-show="chosenType == dataContainerDataType.integer.id">
            Nincs
        </div>

        <!-- Dátum típusú oszlop paraméterei -->
        <div v-show="chosenType == dataContainerDataType.dateTime.id">
            <div class="fieldGroupTitle">Paraméterek</div>
            <div class="dx-field">
                <div class="dx-field-label">Megjelenés formátuma</div>
                <div class="dx-field-value">
                    <DxTextBox data-field="parameters.DateTime.Format"
                               width="55"
                               placeholder=""
                               v-model="parameters.DateTime.Format" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // Event Bus import
    import EventBus from "@/utils/event-bus";

    // DevExtreme imports
    import { DxTextBox } from 'devextreme-vue/text-box';
    import { DxValidator, DxNumericRule, DxRequiredRule } from 'devextreme-vue/validator';
    import { DxButton } from 'devextreme-vue/button';

    // Custom imports
    import { dataContainerDataType } from "@appfrm/data-container/data-container";
    import EditDataTypePropertyParametersPopup from "@appfrm/data-container/components/EditDataTypePropertyParametersPopup";

    export default {
        name: 'PropertyColumnTypeParameters',
        components: {
            DxTextBox,
            DxButton,
            DxValidator,
            DxNumericRule,
            DxRequiredRule,
            EditDataTypePropertyParametersPopup
        },
        props: {
            columnId: Number,
            value: Object
        },
        data() {
            return {
                chosenType: null,
                columnTypeParameters: true,
                parametersDefaults: {
                    'Text': {
                        'MaxLength': '255'
                    },
                    'Number': {
                        'DecimalSeparator': '',
                        'ThousandSeparator': '',
                        'DecimalPlaces': '0'
                    },
                    'Decimal': {
                        'DecimalSeparator': '',
                        'ThousandSeparator': '',
                        'DecimalPlaces': '0'
                    },
                    'DateTime': {
                        'Format': ''
                    }
                },
                dataContainerDataType: dataContainerDataType
            };
        },
        computed: {
            parameters: {
                get() {
                    if (this.value === undefined ||
                        this.value.Number === undefined ||
                        this.value.Decimal === undefined ||
                        this.value.DateTime === undefined ||
                        this.value.Text === undefined
                    ) {
                        this.parameters = this.parametersDefaults;
                        return this.parametersDefaults;
                    }
                    return this.value;
                },
                set(val) {
                    this.$emit('input', val);
                }
            }
        },
        watch: {
        },
        methods: {
            columnTypeChanged(columnId, columnType) {
                if (columnId == this.columnId) {
                    this.chosenType = columnType;
                }
            },
            showPropertyParameters() {
                this.$refs['editDataTypePropertyParametersPopup_' + this.columnId].instance.show();
            },
            handleInput(e) {
                this.$emit('input', this.parameters);
            }
        },
        created() {
            EventBus.$on("PROPERTY_COLUMN_TYPE_CHANGED", (columnId, columnType) => this.columnTypeChanged(columnId, columnType));
        },
        mounted() {
        },
    }
</script>

<style>
    .column-parameters {
        width: 170px;
        margin: 4px 8px 8px 8px;
    }

    .column-parameters .fieldGroupTitle {
        font-weight: bold;
        text-decoration: underline;
        padding-bottom: 8px;
    }

    .column-parameters .dx-field {
        white-space: nowrap;
    }

    .column-parameters .dx-field-label {
        width: 125px;
    }

    .column-parameters .dx-field-value {
        width: 30px !important;
    }
</style>
<style lang="scss" scoped>
    
</style>