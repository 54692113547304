<template>
    <div>aa</div>
</template>

<script>
    import { Logout } from "@/enums/auth-actions";

    export default {
        name: "Logout",
        components: {},
        created() {

            this.$store.dispatch(Logout).then(() => {
                this.$router.push("/");
            });
        },
    }
</script>

<style scoped>
</style>
