import axios from "axios";

// let openIdConfig;
//
// async function getOpenIdConfiguration() {
//     return axios({
//         url: `.well-known/openid-configuration`,
//         data: null,
//         method: "GET"
//     });
// }

async function sendForm(formData) {
    return axios({
        url: '/connect/token',
        method: "POST",
        data: formData,
        headers: {"Content-Type": "multipart/form-data"}
    });
}

async function getToken(user) {
    const formData = new FormData();
    formData.set("grant_type", "password");
    formData.append("client_id", "kesz_csoport_spa");
    formData.append(
        "scope",
        "openid email phone profile offline_access roles kesz_csoport_api"
    );
    formData.append("username", user.username);
    formData.append("password", user.password);
    return sendForm(formData);
}

async function getTokenByRefreshToken(refreshToken) {
    const formData = new FormData();
    formData.set("grant_type", "refresh_token");
    formData.append("client_id", "kesz_csoport_spa");
    formData.append("refresh_token", refreshToken);
    return sendForm(formData);
}

async function loginWithPassword(user) {
    // openIdConfig = (await getOpenIdConfiguration()).data;
    return (await getToken(user)).data;
}

async function refreshLogin(refreshToken) {
    // openIdConfig = (await getOpenIdConfiguration()).data;
    return (await getTokenByRefreshToken(refreshToken)).data;
}

export default { loginWithPassword, refreshLogin };
