<template>
    <dxScrollView :show-scrollbar="'onScroll'"
                  :use-native="false">
        <div class="level">
            <div class="flex-column">
                <div style="position:absolute;z-index:1;padding: 15px 8px 4px;">
                    <span class="level-title">Szintek</span>
                </div>
                <div style="width:100%">
                    <DxDataGrid ref="levelList"
                                :data-source="dataSource"
                                :column-auto-width="true"
                                :row-alternation-enabled="true"
                                :allow-column-resizing="true"
                                :show-row-lines="true"
                                :show-borders="true"
                                :repaint-changes-only="true"
                                @init-new-row="onInitNewRow"
                                @content-ready="onContentReady"
                                @row-prepared="onRowPrepared">

                        <DxRowDragging :allow-reordering="false"
                                       :on-reorder="onReorder"
                                       :show-drag-icons="true"
                                       drop-feedback-mode="push" />

                        <DxEditing :allow-updating="true"
                                   :allow-deleting="true"
                                   :allow-adding="true"
                                   :use-icons="true"
                                   mode="cell" />

                        <DxPaging :page-size="500" />
                        <DxPager :visible="false" />

                        <DxScrolling show-scrollbar="always"
                                     column-rendering-mode="standard"
                                     row-rendering-mode="standard"
                                     :mode="'virtual'"
                                     :preload-enabled="false" />

                        <DxColumn caption=""
                                  :allowEditing="false"
                                  width="36"
                                  alignment="center"
                                  cell-template="validColumnTemplate"
                                  header-cell-template="validColumnHeader" />
                        <DxColumn data-field="id"
                                  caption="#"
                                  :allowEditing="false"
                                  width="50"
                                  alignment="center"
                                  cell-template="idColumnTemplate" />
                        <DxColumn data-field="levelCode"
                                  caption="Szintkód"
                                  header-cell-template="title-header" />
                        <DxColumn data-field="name"
                                  caption="Szint megnevezése"
                                  header-cell-template="title-header" />
                        <DxColumn caption="Típus"
                                  data-field="levelType">
                            <DxLookup :data-source="LevelTypeDS"
                                      display-expr="Name"
                                      value-expr="Id" />
                        </DxColumn>
                        <DxColumn caption="Szervezeti egység adatforrása"
                                  data-field="entityDataSourceType"
                                  width="220">
                            <DxLookup :data-source="EntityDataSourceTypeDS"
                                      display-expr="Name"
                                      value-expr="Id" />
                        </DxColumn>
                        <DxColumn caption="Akciók"
                                  sortOrder="0"
                                  :allow-resizing="false"
                                  :allow-reordering="false"
                                  :min-width="60"
                                  :width="100"
                                  type="buttons">
                            <DxButton name="edit"
                                      hint="Szerkesztés"
                                      icon="background"
                                      :visible="false" />
                            <DxButton name="save"
                                      hint="Mentés"
                                      icon="check" />
                            <DxButton name="cancel"
                                      hint="Mégsem"
                                      icon="close" />
                            <DxButton template="deleteButtonTemplate" />
                        </DxColumn>

                        <template #title-header="{ data }">
                            <div class="d-flex">
                                <div class="flex-1 text-left">{{ data.column.caption }}</div>
                            </div>
                        </template>

                        <template #idColumnTemplate="{ data: cellInfo }">
                            {{ rowId(cellInfo) }}
                        </template>

                        <template #validColumnTemplate="{ data: cellInfo }">
                            <div v-if="levelHasError(cellInfo.data.id)"
                                 class="warning-icon-holder">
                                <i class="dx-icon-warning warning-icon" :title="getLevelErrorMessages(cellInfo.data.id)"></i>
                            </div>
                        </template>

                        <template #validColumnHeader="{ data }">
                            <i class="dx-icon-warning warning-icon-header"></i>
                        </template>

                        <template #deleteButtonTemplate="{ data }">
                            <span class="dx-command-edit dx-command-edit-with-icons">
                                <a @click="showConfirmPopup(data.data)" class="dx-link dx-icon-trash dx-link-icon" title="Adatcsoport törlése" aria-label="Adatcsoport törlése"></a>
                            </span>
                        </template>

                    </DxDataGrid>
                </div>
            </div>
            <CustomDxPopup ref="confirmPopup"
                           :drag-enabled="false"
                           :close-on-outside-click="true"
                           :show-close-button="true"
                           :show-title="false"
                           :width="320"
                           :height="140"
                           container="#app">

                <DxPopupToolbarItem widget="dxButton"
                               toolbar="bottom"
                               location="center"
                               :options="closeButtonOptions"
                               :disable="submitting" />

                <DxPopupToolbarItem widget="dxButton"
                               toolbar="bottom"
                               location="center"
                               :options="deleteButtonOptions"
                               :disable="submitting" />
                <div class="confirm-text">
                    Biztos törli a <strong>{{ confirmData.Name }}</strong> szintet?
                </div>
            </CustomDxPopup>
        </div>
    </dxScrollView>
</template>

<script>
    import { handleStandardErrorNotification, notifySuccess } from "@appfrm/core/index";
    import DxList from "devextreme-vue/list";
    import levelService from "../services/level.service";
    import { LevelType } from "../enums/LevelType";
    import { EntityDataSourceType } from "../enums/EntityDataSourceType";
    import DxForm, {
        DxItem as DxFormItem,
        DxButtonItem,
        DxLabel
    } from "devextreme-vue/form";
    import DxPopup, {
        DxToolbarItem as DxPopupToolbarItem
    } from "devextreme-vue/popup";
    import DxToolbar, { DxItem as DxToolbarItem } from "devextreme-vue/toolbar";
    import DxSelectBox from "devextreme-vue/select-box";
    import DxDataGrid, {
        DxGrouping,
        DxGroupPanel,
        DxSearchPanel,
        DxPaging,
        DxPager,
        DxSorting,
        DxScrolling,
        DxFilterRow,
        DxColumn,
        DxEditing,
        DxLookup,
        DxRequiredRule,
        DxPopup as DxDataGridEditPopup,
        DxPosition,
        DxForm as DxDatagridEditForm,
        DxButton,
        DxColumnFixing,
        DxRowDragging
    } from "devextreme-vue/data-grid";
    import { DxScrollView } from 'devextreme-vue/scroll-view';
    import { ColumnTypes } from "@appfrm/data-container/enums/ColumnTypes";
    import CustomStore from "devextreme/data/custom_store";
    import CustomDxPopup from "@appfrm/core/components/CustomDxPopup";
    import notify from 'devextreme/ui/notify';

    export default {
        name: 'Levels',
        components: {
            DxPopup,
            DxFormItem,
            DxButtonItem,
            DxLabel,
            DxForm,
            DxToolbarItem,
            DxPopupToolbarItem,
            DxSelectBox,
            DxList,
            DxGrouping,
            DxGroupPanel,
            DxSearchPanel,
            DxPaging,
            DxPager,
            DxSorting,
            DxScrolling,
            DxFilterRow,
            DxColumn,
            DxEditing,
            DxLookup,
            DxRequiredRule,
            DxPosition,
            DxDatagridEditForm,
            DxButton,
            DxDataGridEditPopup,
            DxColumnFixing,
            DxRowDragging,
            DxDataGrid,
            DxScrollView,
            DxToolbar,
            CustomDxPopup,
            notify,
            LevelType,
            EntityDataSourceType
        },
        props: {
            levels: {
                type: Array,
                default: null
            }
        },
        data() {
            return {
                dataSource: new CustomStore({
                    key: "id",
                    load: () => {
                        var levels = levelService.get();
                        this.checkLevelErrors(levels);
                        return levels;
                    },
                    insert: (values) => {
                        return levelService.insert(values);
                    },
                    update: (key, values, reorder) => {

                        let levels = this.$refs['levelList'].instance.getVisibleRows();
                        let currentLevel = levels.find((level) => level.data.id == key);

                        if (values.levelType !== undefined) {

                            try {
                                if (values.levelType == LevelType.Private) {

                                    if (levels.some((level) => level.data.levelType == LevelType.Private && level.data.id != key)) {
                                        throw 'Már létezik egy privát típusú szint';
                                    }

                                    if (currentLevel.data.orderIndex > 0) {
                                        throw 'Privát típus csak a legfelső szint lehet';
                                    }
                                }
                                else if (values.levelType == LevelType.Logical) {

                                    let previousLevel = levels.find((level) => level.data.orderIndex == currentLevel.data.orderIndex - 1);

                                    if (previousLevel !== undefined && previousLevel.data.levelType != LevelType.Private && previousLevel.data.levelType != LevelType.Logical) {
                                        throw 'Logikai típus csak akkor választható, ha az előző szint típusa privát vagy logikai';
                                    }
                                }
                                else if (values.levelType == LevelType.Physical) {
                                    var previousLevel = levels.find((level) => level.data.orderIndex == currentLevel.data.orderIndex - 1);

                                    if (previousLevel !== undefined && previousLevel.data.levelType != LevelType.Logical && previousLevel.data.levelType != LevelType.Physical) {
                                        throw 'Fizikai típus csak akkor választható, ha az előző szint típusa logikai vagy fizikai';
                                    }

                                    var nextLevel = levels.find((level) => level.data.orderIndex == currentLevel.data.orderIndex + 1);

                                    if (nextLevel !== undefined && nextLevel.data.levelType != LevelType.Default && nextLevel.data.levelType != LevelType.Physical) {
                                        throw 'Fizikai típus csak akkor választható, ha a következő szint típusa fizikai';
                                    }
                                }

                            }
                            catch (error) {
                                notify({
                                    message: error,
                                    position: {
                                        at: "top",
                                        offset: "0 40"
                                    }
                                }, "error", 5000);
                                return;
                            }
                        }
                        else {
                            // Ha nem küldjük át a típust, az enum default értékét veszi fel a mentésnél a Level
                            values['levelType'] = currentLevel.data.levelType;
                        }

                        if (values.entityDataSourceType == undefined) {
                            // Ha nem küldjük át a szervezeti egység adatforrása mezőt, az enum default értékét veszi fel a mentésnél a Level
                            values['entityDataSourceType'] = currentLevel.data.entityDataSourceType;
                        }

                        return levelService.update({
                            key: key,
                            values: JSON.stringify(values),
                            reorder: values.hasOwnProperty('OrderIndex')
                        })
                    },
                    remove: key => levelService.delete(key)
                }),
                deleteButtonOptions: {
                    icon: 'trash',
                    text: 'Törlés',
                    onClick: () => {
                        this.deleteLevel();
                    },
                },
                closeButtonOptions: {
                    text: 'Bezár',
                    onClick: () => {
                        this.$refs['confirmPopup'].instance.hide();
                    },
                },
                confirmData: {
                    Id: 0,
                    Name: ''
                },
                submitting: false,
                LevelTypeDS: [
                    {
                        Id: LevelType.Default,
                        Name: '-'
                    },
                    {
                        Id: LevelType.Private,
                        Name: 'Privát'
                    },
                    {
                        Id: LevelType.Logical,
                        Name: 'Logikai'
                    },
                    {
                        Id: LevelType.Physical,
                        Name: 'Fizikai'
                    }
                ],
                EntityDataSourceTypeDS: [
                    {
                        Id: EntityDataSourceType.Manual,
                        Name: 'Kézi bevitel'
                    },
                    {
                        Id: EntityDataSourceType.Table,
                        Name: 'Háttértábla'
                    }
                ],
                levelErrors: []
            };
        },
        methods: {
            onReorder(e) {
                e.promise = this.processReorder(e);
            },
            async processReorder(e) {
                const visibleRows = e.component.getVisibleRows();
                const newOrderIndex = visibleRows[e.toIndex].data.orderIndex;

                await this.dataSource.update(e.itemData.id, { OrderIndex: newOrderIndex });
                await e.component.refresh();
            },
            onInitNewRow(e) {
                new Promise(resolve => setTimeout(resolve, 10)).then(() => {
                    e.component.saveEditData();
                    e.component.deselectAll();
                });
            },
            onContentReady(e) {
                levelService.get().then((result) => {
                    this.$emit('update:levels', result);
                });
            },
            onRowPrepared(e) {
                if (e.component.getVisibleRows()[e.rowIndex] &&
                    e.component.getVisibleRows()[e.rowIndex].isNewRow &&
                    e.rowType == 'data')
                    e.rowElement.classList.add('d-none');
            },
            rowId: function (cellInfo) {
                return cellInfo.row.rowIndex + 1;
            },
            refreshLevels() {

                levelService.get().then((result) => {
                    this.$emit('update:levels', result);
                    this.$root.$emit('refreshLevels', result);
                    this.$refs['levelList'].instance.refresh();
                });
            },
            showConfirmPopup(data) {
                this.confirmData.Id = data.id;
                this.confirmData.Name = data.name;
                this.$refs['confirmPopup'].instance.show();
            },
            async deleteLevel() {

                this.submitting = true;

                try {
                    var result = await levelService.hasEntities(this.confirmData.Id);
                    if (result === true) {
                        handleStandardErrorNotification("A(z) " + this.confirmData.Name + " törlése előtt a szintről minden szervezeti egységet el kell mozgatni.", null);
                        this.$refs['confirmPopup'].instance.hide();
                        this.submitting = false;
                    }
                    else if (result === false) {
                        try {
                            result = await levelService.delete(this.confirmData.Id);
                            this.$refs['confirmPopup'].instance.hide();
                            this.submitting = false;
                            notifySuccess("A(z) " + this.confirmData.Name + " szint sikeresen törölve lett.");
                        }
                        catch (error) {
                            this.$refs['confirmPopup'].instance.hide();
                            this.submitting = false;
                            handleStandardErrorNotification("Nem sikerült a szint törlése.", error);
                        }
                    }
                }
                catch (error) {
                    this.$refs['confirmPopup'].instance.hide();
                    this.submitting = false;
                    handleStandardErrorNotification("Nem sikerült a szint törlése.", error);
                }

            },
            checkLevelErrors(resultPromise) {
                resultPromise.then((levels) => {
                    if (typeof levels == 'object' && levels.length > 0) {

                        let levelNames = {};
                        let levelCodes = {};

                        this.levelErrors = [];

                        levels.forEach((level) => {

                            let levelError = {
                                Id: level.id,
                                Messages: []
                            };

                            if (level.name) {
                                if (levelNames[level.name] !== undefined) {
                                    levelNames[level.name]++;
                                }
                                else {
                                    levelNames[level.name] = 1;
                                }
                            }
                            else {
                                levelError.Messages.push("A 'szint megnevezése' mező nincs kitöltve!");
                            }

                            if (level.levelCode) {
                                if (levelCodes[level.levelCode] !== undefined) {
                                    levelCodes[level.levelCode]++;
                                }
                                else {
                                    levelCodes[level.levelCode] = 1;
                                }
                            }
                            else {
                                levelError.Messages.push("A 'szintkód' mező nincs kitöltve!");
                            }

                            this.levelErrors.push(levelError);
                        });

                        let multipleNames = [];
                        for (let name in levelNames) {
                            if (levelNames[name] > 1) {
                                multipleNames.push(name);
                            }
                        }

                        multipleNames.forEach((name) => {
                            let wrongLevels = levels.filter((level) => level.name == name);

                            wrongLevels.forEach((level) => {
                                let levelError = this.levelErrors.find((item) => item.Id == level.id);
                                levelError.Messages.push('Nem egyedi a szintnév!');
                            });
                        });

                        let multipleCodes = [];
                        for (let code in levelCodes) {
                            if (levelCodes[code] > 1) {
                                multipleCodes.push(code);
                            }
                        }

                        multipleCodes.forEach((code) => {
                            let wrongLevels = levels.filter((level) => level.levelCode == code);

                            wrongLevels.forEach((level) => {
                                let levelError = this.levelErrors.find((item) => item.Id == level.id);
                                levelError.Messages.push('Nem egyedi a szintkód!');
                            });
                        });
                    }
                });
            },
            levelHasError(id) {
                let levelError = this.levelErrors.find((lErr) => lErr.Id == id);
                return levelError && levelError.Messages.length > 0;
            },
            getLevelErrorMessages(id) {
                let levelError = this.levelErrors.find((lErr) => lErr.Id == id);
                if (levelError && levelError.Messages.length > 0) {
                    return levelError.Messages.join("\n");
                }
                return '';
            }
        }
    }
</script>

<style>
    #mapScrollview {
        height: 100%;
    }
    .grid {
        fill-opacity: 0;
    }
    .dx-scrollable {
        width: 100%;
    }

    .dx-scrollable-content {
        padding-bottom: 0 !important;
    }

    .dx-datagrid-header-panel .dx-toolbar {
        margin-top: 6px;
        margin-bottom: 6px;
        padding-right: 10px;
    }

    .dx-datagrid-edit-popup .dx-state-readonly {
        border: none !important;
    }

    .positionid {
        display: block;
        float: left;
        width: 19px;
        text-align: right;
        margin-right: 3px;
    }
</style>
<style lang="scss" scoped>
    .level {
        padding: 10px;
        padding-bottom: 30px;
    }
    .level:nth-child(even) {
        background-color: #dddddd;
    }
    .level-title {
        font-size: 22px;
        font-weight: bold;
    }
    ::v-deep .dx-datagrid .dx-row {
        background-color: #ffffff;
    }

    ::v-deep .dx-datagrid, ::v-deep .dx-toolbar {
        background-color: transparent;
    }
    ::v-deep .dx-toolbar-text-auto-hide .dx-button .dx-button-content {
        padding: 5px;
    }
    ::v-deep .dx-toolbar-text-auto-hide .dx-button .dx-icon {
        line-height: 20px;
    }
    .warning-icon {
        font-size: 18px;
        color: #ff0000;
        vertical-align: bottom;
    }
    .warning-icon-header {
        font-size: 18px;
        vertical-align: bottom;
    }
    .confirm-text {
        text-align: center;
    }
</style>