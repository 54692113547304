<template>
    <dxScrollView :show-scrollbar="'onScroll'"
                  :use-native="false">
        <div>
            <div v-for="(level, index) in levels"
                 v-if="level.name"
                 :key="level.id"
                 class="level">
                <div class="flex-column">
                    <div style="position:absolute;z-index:1;padding: 15px 8px 4px;">
                        <span class="level-title">({{ level.levelCode }}) {{ level.name }}</span>
                    </div>
                    <div>
                        <DxDataGrid :id="'level-'+index"
                                    :ref="'entityList'+index"
                                    :data-source="getDataSource(level.id)"
                                    :column-auto-width="true"
                                    :row-alternation-enabled="true"
                                    :allow-column-resizing="true"
                                    :show-row-lines="true"
                                    :show-borders="true"
                                    :columns="columns(index)"
                                    width="100%"
                                    @initialized="onInitialized($event, level.id)"
                                    @cellPrepared="onCellPrepared"
                                    @init-new-row="onInitNewRow"
                                    @row-prepared="onRowPrepared">

                            <DxRowDragging :allow-reordering="true"
                                           :on-reorder="onReorder"
                                           :show-drag-icons="true"
                                           drop-feedback-mode="push" />

                            <DxEditing :allow-updating="true"
                                       :allow-adding="true"
                                       :allow-deleting="true"
                                       mode="cell"
                                       :use-icons="true" />

                            <DxPaging :page-size="500" />
                            <DxPager :visible="false" />

                            <DxScrolling show-scrollbar="always"
                                         column-rendering-mode="standard"
                                         row-rendering-mode="standard"
                                         :mode="'virtual'"
                                         :preload-enabled="false" />

                            <template #title-header="{ data }">
                                <div class="d-flex">
                                    <div class="flex-1 text-left">{{ data.column.caption }}</div>
                                </div>
                            </template>

                            <template #validColumnTemplate="{ data }">
                                <div v-if="entityRowHasError(data.data, level)"
                                     class="warning-icon-holder">
                                    <i class="dx-icon-warning warning-icon" :title="getEntityRowErrorMessages(data.data, level)"></i>
                                </div>
                            </template>

                            <template #parentEditor="{ data: cellInfo }">
                                <EntityParentLookup :levels="levels"
                                                    :level-index="index"
                                                    :data-source="getParentEditorDataSource"
                                                    :value="cellInfo.value"
                                                    :show-clear-button="true"
                                                    :on-value-changed="(value) => onValueChanged(value, cellInfo)" />
                            </template>

                            <template #szervezetiEgysegEditor="{ data: cellInfo }">
                                <DxLookup :data-source="getSzervezetiEgyegEditorDataSource(level.id, cellInfo.data.id)"
                                           display-expr="szervezetiEgysegNev"
                                           value-expr="szervezetiEgysegKod"
                                           search-expr="szervezetiEgysegNev"
                                           :value="cellInfo.data.szervezetiEgysegKod"
                                           :show-clear-button="true"
                                           :opened="true"
                                           :on-value-changed="(value) => onSzervezetiEgysegValueChanged(value.value, cellInfo)"
                                           :placeholder="lookupTexts.placeholder"
                                           :apply-button-text="lookupTexts.applyButtonText"
                                           :cancel-button-text="lookupTexts.cancelButtonText"
                                           :clear-button-text="lookupTexts.clearButtonText"
                                           :next-button-text="lookupTexts.nextButtonText"
                                           :no-data-text="lookupTexts.noDataText"
                                           :search-placeholder="lookupTexts.searchPlaceholder" />
                            </template>

                        </DxDataGrid>
                    </div>
                </div>
            </div>
        </div>

        <CustomDxPopup width="80%"
                       :height="600"
                       :show-title="true"
                       title="Mezők szerkesztése"
                       :drag-enabled="false"
                       :close-on-outside-click="true"
                       :show-close-buttton="true"
                       :onInitialized="onPopupInit"
                       :onHiding="onPopupClose"
                       container="#app">

            <DxPopupToolbarItem widget="dxButton"
                                toolbar="bottom"
                                location="after"
                                :options="close" />

            <template #content="{ data }">
                <div style="width:100%">
                    <DxDataGrid :data-source="getEntityColumns(selectedLevel)"
                                :column-auto-width="true"
                                :row-alternation-enabled="true"
                                :allow-column-resizing="true"
                                :show-row-lines="true"
                                :show-borders="true"
                                class="columnEditor"
                                @init-new-row="onInitNewRow"
                                @row-prepared="onRowPrepared">

                        <DxRowDragging :allow-reordering="true"
                                       :on-reorder="onReorder"
                                       :show-drag-icons="true" />

                        <DxColumn data-field="id"
                                  caption="#"
                                  :allowEditing="false"
                                  width="50"
                                  alignment="center"
                                  cell-template="idColumnTemplate" />
                        <DxColumn data-field="name"
                                  caption="Mező neve" />
                        <DxColumn data-field="type"
                                  caption="Mező típusa">
                            <DxDataGridLookup :data-source="ColumnTypes"
                                      value-expr="id"
                                      display-expr="name" />
                        </DxColumn>
                        <DxColumn data-field="properties"
                                  caption="Mező tulajdonságok">
                        </DxColumn>
                        <DxColumn caption="Akciók"
                                  sortOrder="0"
                                  :allow-resizing="false"
                                  :allow-reordering="false"
                                  :width="100"
                                  type="buttons">

                        </DxColumn>

                        <DxEditing :allow-updating="true"
                                   :allow-deleting="true"
                                   :allow-adding="true"
                                   mode="cell"
                                   :use-icons="true" />

                        <DxPaging :page-size="500" />
                        <DxPager :visible="false" />

                        <DxScrolling show-scrollbar="always"
                                     column-rendering-mode="standard"
                                     row-rendering-mode="standard"
                                     :mode="'virtual'"
                                     :preload-enabled="false" />

                        <template #idColumnTemplate="{ data: cellInfo }">
                            {{ cellInfo.row.rowIndex + 1 }}
                        </template>

                    </DxDataGrid>
                </div>
            </template>
        </CustomDxPopup>

        <CustomDxPopup ref="confirmPopup"
                       :drag-enabled="false"
                       :close-on-outside-click="true"
                       :show-close-button="true"
                       :show-title="false"
                       :width="320"
                       :height="confirmData.ParentEntityName.length > 0? 160 : 140"
                       container="#app">

            <DxPopupToolbarItem widget="dxButton"
                                toolbar="bottom"
                                location="center"
                                :options="closeButtonOptions"
                                :disable="submitting" />

            <DxPopupToolbarItem widget="dxButton"
                                toolbar="bottom"
                                location="center"
                                :options="deleteButtonOptions"
                                :disable="submitting" />
            <div class="confirm-text">
                Biztos törli a(z) <strong>{{ confirmData.Name }}</strong> {{ confirmData.CurrentEntityName }}-t?
                <span v-show="confirmData.ParentEntityName.length > 0"><br>Minden gyerek szinten lévő entitás a(z) <strong>{{ confirmData.ParentEntityName }}</strong> egységhez kerül hozzárendelésre!</span>
            </div>
        </CustomDxPopup>
    </dxScrollView>
</template>

<script>
    import { handleStandardErrorNotification, notifySuccess } from "@appfrm/core/index";
    import entityService from "../services/entity.service";
    import entityColumnsService from "../services/entity-columns.service";
    import { LevelType } from "../enums/LevelType";
    import { EntityDataSourceType } from "../enums/EntityDataSourceType";
    import { DxScrollView } from 'devextreme-vue/scroll-view';
    import CustomStore from "devextreme/data/custom_store";
    import { ColumnTypes } from "@appfrm/data-container/enums/ColumnTypes";
    import DxDataGrid, {
        DxSearchPanel,
        DxPaging,
        DxPager,
        DxSorting,
        DxScrolling,
        DxFilterRow,
        DxColumn,
        DxEditing,
        DxLookup as DxDataGridLookup,
        DxRequiredRule,
        DxButton,
        DxColumnFixing,
        DxRowDragging
    } from "devextreme-vue/data-grid";
    import DxToolbar, { DxItem as DxToolbarItem } from "devextreme-vue/toolbar";
    import { DxPopup, DxToolbarItem as DxPopupToolbarItem } from 'devextreme-vue/popup';
    import CustomDxPopup from "@appfrm/core/components/CustomDxPopup";
    import notify from 'devextreme/ui/notify';
    import { DxLookup, DxDropDownOptions } from 'devextreme-vue/lookup';
    import EntityParentLookup from './EntityParentLookup';

    export default {
        name: "Entities",
        components: {
            DxScrollView,
            DxDataGrid,
            DxSearchPanel,
            DxPaging,
            DxPager,
            DxSorting,
            DxScrolling,
            DxFilterRow,
            DxColumn,
            DxEditing,
            DxDataGridLookup,
            DxRequiredRule,
            DxButton,
            DxColumnFixing,
            DxRowDragging,
            DxToolbar,
            DxToolbarItem,
            DxPopup,
            DxPopupToolbarItem,
            CustomDxPopup,
            notify,
            DxLookup,
            DxDropDownOptions,
            EntityParentLookup,
            LevelType,
            EntityDataSourceType
        },
        props: {
            levels: {
                type: Array,
                default: []
            }
        },
        data() {
            return {
                preferencePopup: null,
                selectedLevel: null,
                selectedDataGrid: null,
                Levels: null,
                addBtn: null,
                close: {
                    icon: 'close',
                    text: 'Bezár',
                    onClick: () => this.preferencePopup.hide()
                },
                ColumnTypes: ColumnTypes,
                deleteButtonOptions: {
                    icon: 'trash',
                    text: 'Törlés',
                    onClick: (e) => {
                        this.deleteEntity(e);
                    },
                },
                closeButtonOptions: {
                    text: 'Bezár',
                    onClick: () => {
                        this.$refs['confirmPopup'].instance.hide();
                    },
                },
                confirmData: {
                    Id: 0,
                    Name: '',
                    CurrentEntityName: '',
                    ParentEntityName: ''
                },
                submitting: false,
                lookupTexts: {
                    placeholder: 'kiválasztás',
                    applyButtonText: 'OK',
                    cancelButtonText: 'Mégse',
                    clearButtonText: 'Törlés',
                    nextButtonText: 'Több',
                    noDataText: 'Nincs találat',
                    searchPlaceholder: 'Keresés',
                }
            }
        },
        methods: {
            onPopupInit(e) {
                this.preferencePopup = e.component;
            },
            showColumnEditor(e, level) {
                if (this.preferencePopup) {
                    this.selectedLevel = level;
                    this.selectedDataGrid = e;
                    this.preferencePopup.show();
                }
            },
            onInitialized(e, level) {
                e.element.setAttribute("data-levelid", level);
            },
            onPopupClose() {
                this.$parent.$parent.$parent.$refs.levelComponent.refreshLevels();
            },
            onReorder(e) {
                e.promise = this.processReorder(e);
            },
            async processReorder(e) {
                const level = parseInt(e.element.id.split('-')[1]);
                const visibleRows = e.component.getVisibleRows();
                const newOrderIndex = visibleRows[e.toIndex].data.orderIndex;
                await this.getDataSource(level).update(e.itemData.id, { OrderIndex: newOrderIndex });
                await e.component.refresh();
            },
            onCellPrepared(e) {
                if (e.rowType == 'header') {
                    
                    if (e.column.type == 'drag') {
                        e.column.width = '36px';

                        // Header add button
                        let level = e.element.getAttribute('data-levelid');
                        let customButton = document.createElement("i");
                        customButton.classList.add("dx-icon");
                        customButton.classList.add("dx-icon-preferences");
                        customButton.classList.add("header-add-btn");
                        customButton.onclick = () => {
                            this.showColumnEditor(e, level);
                        };
                        e.cellElement.innerHTML = '';
                        e.cellElement.append(customButton);
                    }
    
                    if (e.column.dataField == 'valid') {
                        e.column.width = '36px';
                        e.cellElement.innerText = '';
                        e.cellElement.insertAdjacentHTML('afterbegin', '<i class="dx-icon-warning warning-icon-header"></i>');
                        e.column.alignment = 'center';
                    }

                    if (e.column.dataField == 'parent') {
                        e.column.width = '250px';
                        e.column.alignment = 'center';
                    }

                    if (e.column.dataField == 'id') {
                        e.column.width = '50px';
                        e.cellElement.innerText = '#';
                        e.column.alignment = 'center';
                    }

                    if (e.column.dataField == 'name') {
                        e.column.alignment = 'left';

                        let level = this.levels.find((item) => item.id == e.element.getAttribute('data-levelid'));
                        e.column.width = level.entityColumns.length > 0 ? '250px' : 'auto';
                        e.column.minWidth = '250px';
                    }

                    if (e.column.dataField == 'szervezetiEgysegKod') {
                        e.column.width = '150px';
                    }

                    if (e.column.type == 'buttons') {
                        e.column.width = '100px';
                    }
                }

                if (e.rowType == 'data') {
                    if (e.column.dataField == 'id') {
                        e.column.alignment = 'center';
                        e.cellElement.innerText = e.row.rowIndex + 1;
                    }
                }
            },
            onInitNewRow(e) {
                new Promise(resolve => setTimeout(resolve, 5)).then(() => {
                    e.component.saveEditData();
                });
            },
            onRowPrepared(e) {
                if (e.component.getVisibleRows()[e.rowIndex] &&
                    e.component.getVisibleRows()[e.rowIndex].isNewRow &&
                    e.rowType == 'data')
                    e.rowElement.classList.add('d-none');
            },
            async getLookupEntities(index, loadOptions, currentValue) {
                let parentLevels = this.levels.filter(i => i.name != null && i.orderIndex < index).map(i => i.id);
                let levelNames = [],
                    levelCodes = [];
                this.levels.forEach(function (value) {
                    levelNames[value.id] = value.name;
                    levelCodes[value.id] = value.levelCode;
                });

                try {
                    var result = await entityService.get(loadOptions);
                    var entities = result.filter(i => i.name != null && parentLevels.includes(i.level) && (loadOptions.searchValue ? (i.name ? i.name.toLowerCase().includes(loadOptions.searchValue.toLowerCase()) : false) : true)).map(i => ({ id: i.id, name: (levelNames[i.level] + ' - ' + i.name), displayName: ('(' + levelCodes[i.level] + ') ' + levelNames[i.level] + ' - ' + i.name), levelId: i.level }));

                    // Szűrés a kiválasztott entity szintjére vagy a kiválasztott szintre - mert csak az entity ID van eltárolva
                    if (currentValue !== undefined) {
                        if (currentValue.selectedEntityId != null && currentValue.selectedEntityId != 0) {
                            // 1. van kiválasztott entity: szűrés a kiválasztott szintjére
                            let currentEntity = entities.find((entity) => entity.id == currentValue.selectedEntityId);
                            entities = entities.filter((entity) => entity.levelId == currentEntity.levelId);
                        }
                        else if (currentValue.selectedLevelId != 0) {
                            // 2. van kiválasztott szint: szűrés a szintre
                            entities = entities.filter((entity) => entity.levelId == currentValue.selectedLevelId);
                        }
                    }

                    return entities;
                }
                catch (error) {
                    handleStandardErrorNotification("Nem sikerült a szervezeti egységek betöltése és szűrése.", error);
                }
            },
            async getLookupEntityByKey(key) {
                let levelNames = [],
                    levelCodes = [];
                this.levels.forEach(function (value) {
                    levelNames[value.id] = value.name;
                    levelCodes[value.id] = value.levelCode;
                });

                try {
                    var result = await entityService.get();
                    let options = [{ id: 0, name: 'Nincs' }].concat(result.map(i => ({ id: i.id, name: (levelNames[i.level] + ' - ' + i.name), displayName: ('(' + levelCodes[i.level] + ') ' + levelNames[i.level] + ' - ' + i.name) })));
                    return options.filter(i => i.id == key);
                } catch (error) {
                    handleStandardErrorNotification("Nem sikerült a szervezeti egységek betöltése.", error);
                }
            },
            getParentEditorDataSource(index, currentValue) {
                return new CustomStore({
                        key: "id",
                        load: (loadOptions) => {
                            return this.getLookupEntities(index, loadOptions, currentValue);
                        },
                        byKey: (key) => {
                            return this.getLookupEntityByKey(key);
                        }
                    });
            },
            async getLookupSzervezetiEgysegek(loadOptions, levelId, entityId) {
                try {
                    return await entityService.getSzervezetiEgysegek(loadOptions, levelId, entityId);
                }
                catch (error) {
                    handleStandardErrorNotification("Nem sikerült a szervezeti egységek betöltése.", error);
                }
            },
            async getLookupSzervezetiEgysegByKey(key) {
                return await entityService.getSzervezetiEgysegById(key);
            },
            getSzervezetiEgyegEditorDataSource(levelId, entityId) {
                return new CustomStore({
                    key: "id",
                    load: (loadOptions) => {
                        return this.getLookupSzervezetiEgysegek(loadOptions, levelId, entityId);
                    },
                    byKey: (key) => {
                        return this.getLookupSzervezetiEgysegByKey(key);
                    }
                });
            },
            getLevelLookupDataSource(index) {
                let levelList = this.levels;
                return levelList;
            },
            onValueChanged(value, cellInfo) {
                cellInfo.setValue(value?? 0);
                cellInfo.component.updateDimensions();
            },
            onSzervezetiEgysegValueChanged(value, cellInfo) {
                cellInfo.setValue(value ?? 0);
                cellInfo.component.updateDimensions();
            },
            getColumns(index) {
                let that = this;

                let level = this.levels.find((item) => item.orderIndex == index);

                let entityColumns = this.levels[index].entityColumns.map(c => c.name);

                let defaultColumns = [
                    {
                        dataField: 'valid',
                        allowEditing: false,
                        alignment: 'center',
                        cellTemplate: 'validColumnTemplate',
                        width: '36px'
                    },
                    {
                        dataField: 'id',
                        alignment: 'center',
                        allowEditing: false,
                        width: '50px'
                    },
                    {
                        dataField: 'parent',
                        dataType: 'number',
                        caption: 'Szülő szervezeti egység',
                        alignment: 'center',
                        editCellTemplate: 'parentEditor',
                        allowEditing: index > 0,
                        width: '250px',
                        minWidth: '250px',
                        lookup: {
                            dataSource: this.getParentEditorDataSource(index),
                            valueExpr: "id",
                            displayExpr: "displayName",
                            searchExpr: "name",
                            grouped: true
                        }
                    },
                    {
                        dataField: 'name',
                        caption: 'Szervezeti egység megnevezése',
                        allowEditing: true,
                        alignment: 'left',
                        editCellTemplate: level.entityDataSourceType == EntityDataSourceType.Table ? 'szervezetiEgysegEditor' : undefined,
                        width: entityColumns.length > 0 ? '250px' : 'auto',
                        minWidth: '250px'
                    },
                    {
                        dataField: 'szervezetiEgysegKod',
                        caption: 'Azonosító',
                        allowEditing: level.entityDataSourceType == EntityDataSourceType.Manual,
                        alignment: 'left',
                        width: '150px'
                    }                    
                ];
                
                var columns = entityColumns.length > 0? defaultColumns.concat(entityColumns) : defaultColumns;

                columns.push({
                    caption: 'Akciók',
                    width: '100px',
                    allowEditing: false,
                    allowSorting: false,
                    type: 'buttons',
                    buttons: [
                        {
                            name: 'edit',
                            icon: 'edit',
                            hint: 'Szerkesztés'
                        },
                        {
                            name: 'delete',
                            icon: 'trash',
                            hint: 'Törlés',
                            onClick: this.showConfirmPopup
                        },
                        {
                            name: 'save',
                            icon: 'save',
                            hint: 'Mentés'
                        },
                        {
                            name: 'cancel',
                            icon: 'undo',
                            hint: 'Mégse'
                        }
                    ]
                });

                return columns;
            },
            getDataSource(level) {
                return new CustomStore({
                    key: "id",
                    load: (loadOptions) => {
                        return entityService.get(loadOptions).then(result => {
                            return result.filter(item => item.level == level);
                        });
                    },
                    insert: (values) => {
                        values.level = level;
                        entityService.insert(values).then(
                            (success) => {
                                this.$parent.$parent.$parent.$refs.levelComponent.refreshLevels();
                            },
                            (failed) => { }
                        );
                    },
                    update: (key, values) => {
                        

                        if (values.parent !== undefined && values.parent == null) {
                            values.parent = 0;
                        }

                        let currentLevel = this.levels.find((item) => item.id == level);

                        let updateSzervezetiEgyseg = values.name !== undefined && currentLevel.entityDataSourceType == EntityDataSourceType.Table;

                        entityService.update({
                            key: key,
                            values: JSON.stringify(values),
                            reorder: values.hasOwnProperty('OrderIndex'),
                            updateSzervezetiEgyseg: updateSzervezetiEgyseg
                        }).then(
                            (success) => {
                                this.$parent.$parent.$parent.$refs.levelComponent.refreshLevels();
                            },
                            (failed) => { }
                        );
                    },
                    remove: key => {
                        entityService.delete(key).then(
                            (success) => {
                                this.$parent.$parent.$parent.$refs.levelComponent.refreshLevels();
                            },
                            (failed) => { }
                        );
                    }
                });
            },
            getEntityColumns(level) {
                return new CustomStore({
                    key: "id",
                    load: (loadOptions) => {
                        return entityColumnsService.get(loadOptions).then(result => {
                            return result.filter(item => item.level == level);
                        });
                    },
                    insert: (values) => {
                        values.level = level;
                        return entityColumnsService.insert(values);

                    },
                    update: (key, values) =>
                        entityColumnsService.update({
                            key: key,
                            values: JSON.stringify(values)
                        }),
                    remove: key => entityColumnsService.delete(key)
                });
            },
            async showConfirmPopup(e) {
                e.event.preventDefault();

                this.confirmData.Id = e.row.data.id;
                this.confirmData.Name = e.row.data.name ?? '[névtelen szervezeti egység]';

                if (e.row.data.parent > 0) {
                    try {
                        var entityData = await entityService.getById(e.row.data.parent);
                        if (entityData) {
                            this.confirmData.ParentEntityName = entityData.name ?? '[névtelen szervezeti egység]';
                        }
                        else {
                            this.confirmData.ParentEntityName = '';
                        }

                        this.$refs['confirmPopup'].instance.show();
                    }
                    catch (error) {
                        handleStandardErrorNotification("Nem sikerült a szervezeti egység adatainak lekérése.", error);
                    }
                }
                else {
                    
                    this.confirmData.ParentEntityName = '';
                    this.$refs['confirmPopup'].instance.show();
                }
            },
            async deleteEntity() {

                this.submitting = true;

                var data = this.confirmData.Id;

                try {
                    await entityService.delete(data);
                    this.$refs['confirmPopup'].instance.hide();
                    this.submitting = false;
                    this.$root.$emit('refreshLevels', true);
                    notifySuccess("A(z) " + this.confirmData.Name + " nevű szervezeti egység sikeresen törölve lett.");
                }
                catch (error) {
                    this.$refs['confirmPopup'].instance.hide();
                    this.submitting = false;
                    handleStandardErrorNotification("Nem sikerült a szervezeti egység törlése.", error);
                }
            },
            entityRowHasError(rowData, level) {
                return this.getEntityRowErrorMessages(rowData, level).length > 0;
            },
            getEntityRowErrorMessages(rowData, level) {
                let messages = [];

                if (level.entityDataSourceType == EntityDataSourceType.Table) {
                    if (!rowData.name && !rowData.szervezetiEgysegKod) {
                        messages.push("Nincs szervezeti egység megadva!");
                    }
                    else if (!rowData.isSzervezetiEgysegExists) {
                        messages.push("A kiválasztott szervezeti egység nem található meg a háttértáblában!");
                    }
                }
                else if (level.entityDataSourceType == EntityDataSourceType.Manual && !rowData.name) {
                    if (!rowData.name) {
                        messages.push("A 'Szervezeti egység megnevezése' mező nincs kitöltve!");
                    }

                    if (!rowData.szervezetiEgysegKod) {
                        messages.push("Az 'Azonosító' mező nincs kitöltve!");
                    }
                }


                if (rowData.parent == 0 && level.level != 0 && level.levelType != LevelType.Private) {
                    messages.push("Nincs kiválasztva szülő szervezeti egység!");
                }

                if (messages.length > 0) {
                    return messages.join("\n");
                }
                return '';
            }
        },
        computed: {
            columns() {
                return (index) => {
                    return this.getColumns(index);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .level {
        padding: 10px;
        padding-bottom: 30px;
    }
    .level:nth-child(even) {
        background-color: #dddddd;
    }
    .level-title {
        font-size: 22px;
        font-weight: bold;
    }
    ::v-deep .dx-datagrid .dx-row {
        background-color: #ffffff;
    }
    ::v-deep .dx-datagrid, ::v-deep .dx-toolbar {
        background-color: transparent;
    }
    ::v-deep .dx-toolbar-text-auto-hide .dx-button .dx-button-content {
        padding: 5px;
    }
    ::v-deep .dx-button-has-icon .dx-button-content {
        padding: 3px;
    }
    ::v-deep .dx-toolbar-text-auto-hide .dx-button .dx-icon {
        line-height: 20px;
    }
    ::v-deep .prefBtn {
        width: 30px;
        height: 30px;
    }
    ::v-deep .dx-icon-close {
        color: #ffffff !important;
    }
    ::v-deep .warning-icon {
        font-size: 18px;
        color: #ff0000;
        vertical-align: bottom;
    }
    ::v-deep .warning-icon-header {
        font-size: 18px;
        vertical-align: bottom;
    }
    ::v-deep .header-add-btn {
        font-size: 18px;
        vertical-align: bottom;
        line-height: 11px;
        cursor: pointer;
    }
    .confirm-text {
        text-align: center;
    }
</style>
<style lang="scss">
    .columnEditor {
        margin: -15px -10px;

        .dx-datagrid-header-panel .dx-toolbar {
            padding-right: 0px;
        }
    }

    .dx-dropdowneditor-overlay .dx-popup-title {
        display: none;
    }
</style>